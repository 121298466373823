export enum NotificationTypes {
  SUBSCRIPTION_NEAR_TO_EXPIRE,
  SUBSCRIPTION_EXPIRE_TODAY,
  SMS_LIMIT_NEAR_EXCEED,
  SMS_LIMIT_OFF,
  USER_LIMIT_NEAR_EXCEED,
  USER_LIMIT_OFF,
  PAYMENT_UNSUCCESSFUL,
  DEVICES_LIMIT_NEAR_EXCEED,
  DEVICES_LIMIT_OFF,
  DEVICE_LIMIT_EXCEED,
}
