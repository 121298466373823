import React, { useState } from "react";
import { Colors } from "../Theme";
import { FormFieldText } from "./FormFieldText";
import { StyledTooltip } from "./StyledTooltip";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";

const PasswordValidationElement: React.FC<{ condition: boolean; label: string; touched: boolean; value?: string }> = ({
  condition,
  label,
  touched,
  value,
}) => {
  if (!touched && !value) {
    return <div>{label}</div>;
  }

  return (
    <div style={{ color: condition ? Colors.success : Colors.alert }}>
      {condition ? <i className="fa fa-check"></i> : <i className="fa fa-times"></i>} {label}
    </div>
  );
};

export const PasswordInput: React.FC<{ password?: string; touched: boolean; name?: string; label?: string }> = ({
  password,
  touched = false,
  name = "password",
  label,
}) => {
  const [tooltipOpened, setTooltipOpened] = useState(false);
  const { t } = useTranslation("common");

  return (
    <Box className="col-sm-4" sx={{ display: "flex" }} onBlur={() => setTooltipOpened(false)}>
      <FormFieldText
        required={true}
        className="u-full-width"
        name={name}
        label={label ?? t("common:password")}
        type="password"
        onChange={() => setTooltipOpened(true)}
        autoComplete="new-password"
      />
      <StyledTooltip
        open={tooltipOpened}
        title={
          <div className="u-text-small text-right u-full-width" onMouseLeave={() => setTooltipOpened(false)}>
            <PasswordValidationElement
              touched={touched}
              value={password}
              label={t("common:x_characters_long", { count: 8 })}
              condition={!!(password && password.length >= 8)}
            />
            <PasswordValidationElement
              touched={!!password}
              value={password}
              label={t("common:uppercase_character")}
              condition={!!(password && /[A-Z]/.test(password))}
            />
            <PasswordValidationElement
              touched={touched}
              value={password}
              label={t("common:lowercase_character")}
              condition={!!(password && /[a-z]/.test(password))}
            />
            <PasswordValidationElement
              touched={touched}
              value={password}
              label={t("common:number_character")}
              condition={!!(password && /[0-9]/.test(password))}
            />
            <PasswordValidationElement
              touched={!!password}
              value={password}
              label={t("common:special_character")}
              condition={!!(password && /[!@#$%^&*=()_+[\]{}|;:',.<>?/`~"\\-]/.test(password))}
            />
          </div>
        }>
        <i
          onMouseEnter={() => setTooltipOpened(true)}
          onMouseLeave={() => setTooltipOpened(false)}
          onClick={() => setTooltipOpened(!tooltipOpened)}
          style={{ margin: "auto 0 24px 8px" }}
          className="fa fa-question-circle"
          aria-hidden="true"
        />
      </StyledTooltip>
    </Box>
  );
};
