import { Modal } from "../../Components/Modal";
import { AppState, showAppModal } from "../../AppState";
import React, { useEffect, useState } from "react";
import { LegalType } from "../../Enums";
import { getLegal, useLegal } from "../../Managers";
import { ILegal } from "../../Managers/Types";
import { AxiosError } from "axios";
import { useTranslation } from "react-i18next";

interface ISelf {
  accepted?: boolean;
  onAccept?: () => void;
  onCancel?: () => void;
  cancelText?: string;
  acceptText?: string;
  noScroll?: boolean;
}

const ScrollToBottomModal: React.FC<ISelf & { children: React.ReactNode; title: string }> = ({
  accepted = false,
  onAccept,
  onCancel,
  children,
  title,
  cancelText,
  acceptText,
  noScroll,
}) => {
  const [scrolledToBottom, setScrolledToBottom] = useState(accepted || noScroll);
  const { t } = useTranslation("common");

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const target = event.target as HTMLTextAreaElement;
    const { scrollTop, scrollHeight, clientHeight } = target;
    const isNearBottom = scrollTop + clientHeight >= scrollHeight - 50;
    setScrolledToBottom(isNearBottom);
  };

  const buttons = accepted ? (
    <button className="btn btn-primary" onClick={() => showAppModal(null)}>
      {t("common:ok")}
    </button>
  ) : (
    <>
      <button
        className="btn btn-secondary"
        onClick={() => {
          onCancel && onCancel();
          showAppModal(null);
        }}>
        {cancelText ?? t("common:close")}
      </button>
      <button
        disabled={!scrolledToBottom}
        className="btn btn-primary"
        onClick={() => {
          onAccept && onAccept();
          showAppModal(null);
        }}>
        {acceptText ?? t("common:accept")}
      </button>
    </>
  );

  return (
    <Modal title={title} buttons={buttons}>
      {noScroll ? (
        <div>{children}</div>
      ) : (
        <div
          onScroll={(e) => {
            if (!scrolledToBottom) {
              handleScroll(e);
            }
          }}
          className={"legal-modal"}>
          {children}
        </div>
      )}
    </Modal>
  );
};

interface ILegalModal extends ISelf {
  type: LegalType;
  countryCode?: string;
}

export const LegalModal: React.FC<ILegalModal> = (props) => {
  const [defaultLegal, setDefaultLegal] = useState<ILegal>();
  const { t } = useTranslation("legal");
  const legal = useLegal(props.countryCode ?? AppState.user?.country_code ?? "US", props.type);

  useEffect(() => {
    if ((legal.error as AxiosError)?.response?.status === 404) {
      getLegal("US", props.type).then((res) => setDefaultLegal(res));
    }
  }, [legal.status]);

  return (
    <ScrollToBottomModal {...props} title={t("legal:" + props.type) ?? ""}>
      <div
        style={{ height: "unset", marginBottom: 40 }}
        className="ql-content-display"
        dangerouslySetInnerHTML={{ __html: `${legal.data?.content ?? defaultLegal?.content ?? ""}` }}></div>
    </ScrollToBottomModal>
  );
};

export const MarketingModal: React.FC<ISelf> = (props) => {
  const { t } = useTranslation("register");
  return (
    <ScrollToBottomModal {...props} title={t("steps.account.marketing_modal_title") ?? ""}>
      <p style={{ textAlign: "center", fontWeight: "bold" }}>{t("steps.account.marketing_modal")}</p>
    </ScrollToBottomModal>
  );
};
