import { HeaderRow, LeftRail, Page, Wrapper } from "../../Components";
import { getTranslations, useAppTranslations } from "../../Managers/TranslationsService";
import { Table, TableBody, TableCell, TableHead, TableRow } from "../../Components/Table";
import { useTranslation } from "react-i18next";
import React, { useCallback, useEffect, useState } from "react";
import { ILanguage, ITranslation } from "../../Managers/Types";
import { toggleLanguage, useLanguages } from "../../Managers/LanguageService";
import "./Translations.scss";
import { exportToXLSX } from "../../Managers";
import { showAppModal } from "../../AppState";
import { AddLanguageModal } from "./Modals/AddLanguageModal";
import { UploadTranslationsModal } from "./Modals/UploadTranslationsModal";
import classNames from "classnames";
import { PaginationButtons } from "../../Components/PaginationButtons";

export const Translations: React.FC = () => {
  const [englishTranslations, setEnglishTranslations] = useState<ITranslation[]>([]);
  const [translations, setTranslations] = useState<ITranslation[]>([]);
  const [language, setLanguage] = useState("en");
  const [languageOptions, setLanguageOptions] = useState<ILanguage[]>([]);
  const [paginatedTranslations, setPaginatedTranslations] = useState<ITranslation[]>([]);

  const translationsQuery = useAppTranslations("en");
  const languageQuery = useLanguages();
  const { t } = useTranslation(["translations", "common"]);

  useEffect(() => {
    setEnglishTranslations(translationsQuery.data ?? []);
    setPaginatedTranslations(translationsQuery.data?.slice(0, 100) ?? []);
  }, [translationsQuery.dataUpdatedAt]);

  useEffect(() => {
    if (language !== "en") {
      getTranslations(language).then((res) => setTranslations(res));
    }
  }, [language]);

  useEffect(() => {
    setLanguageOptions(languageQuery.data ?? []);
  }, [languageQuery.dataUpdatedAt]);

  const exportTranslations = () => {
    const exportData = englishTranslations.map((t) => ({
      key: t.namespace + ":" + t.key,
      english_label: findTranslation(t.namespace, t.key),
      label: findTranslation(t.namespace, t.key, translations) ?? "",
    }));

    exportToXLSX(`${t("translations:export_title")}_${language}_${new Date().toDateString()}`, exportData, [
      t("translations:key"),
      t("translations:english_label"),
      t("translations:label"),
    ]);
  };

  const languageIsActive = useCallback(() => {
    return languageOptions.find((o) => o.code === language)?.active ?? false;
  }, [language, languageOptions]);

  const disableLanguage = () => {
    toggleLanguage(language, false);
  };
  const enableLanguage = () => {
    toggleLanguage(language, true);
  };

  const changePagination = (page: number) => {
    const pageModifier = page - 1;

    setPaginatedTranslations(
      englishTranslations.slice(pageModifier * 100, Math.min((pageModifier + 1) * 100, englishTranslations.length - 1)),
    );
  };

  const findTranslation = (namespace: string, key: string, translationsToLookIn = englishTranslations) =>
    translationsToLookIn.find((t) => t.namespace === namespace && t.key === key)?.label ?? "";

  return (
    <Page>
      <Wrapper>
        <LeftRail
          header={<></>}
          body={
            <nav className="left-rail-nav">
              <div className="left-rail-nav-header">
                <h2 className="left-rail-nav-header-title mod-with-btn">{t("translations:languages")}</h2>
                <button
                  className="btn btn-circle btn-primary"
                  onClick={() => showAppModal(<AddLanguageModal existingLanguages={languageQuery.data?.map((l) => l.code) ?? []} />)}>
                  <i className="fa fa-plus" />
                  <span className="sr-only">{t("translations:languages")}</span>
                </button>
              </div>
              <ul className="left-rail-nav-group">
                {languageOptions.map((l) => (
                  <li className={classNames("left-rail-nav-item", { active: l.code === language })}>
                    <div onClick={() => setLanguage(l.code)} title={l.name} className="u-text-eclipse">
                      {l.name}&nbsp;
                      {!l.active && (
                        <span className="type-small-regular u-color-light-teal" style={{ marginRight: "5px" }}>
                          ({t("common:disabled")})
                        </span>
                      )}
                    </div>
                  </li>
                ))}
              </ul>
            </nav>
          }
        />
        <main className="bapi-main translations mod-dashboard">
          <HeaderRow
            info={{ name: t("translations:title") }}
            control={
              <>
                {language !== "en" && languageIsActive() && (
                  <button className="btn btn-primary" onClick={disableLanguage}>
                    <span>{t("translations:disable_language")}</span>
                  </button>
                )}
                {language !== "en" && !languageIsActive() && (
                  <button className="btn btn-primary" onClick={enableLanguage}>
                    <span>{t("translations:enable_language")}</span>
                  </button>
                )}
                <button disabled={translations?.length === 0 && language !== "en"} className="btn btn-primary" onClick={exportTranslations}>
                  <span>{t("translations:xlx_export")}</span>
                </button>
                <button className="btn btn-primary" onClick={() => showAppModal(<UploadTranslationsModal language={language} />)}>
                  <span>{t("translations:xlx_import")}</span>
                </button>
              </>
            }
          />
          <div className="translations-table-wrapper">
            <div className="translations-table">
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell width="30%">{t("translations:key")}</TableCell>
                    <TableCell width="30%">{t("translations:english_label")}</TableCell>
                    <TableCell width="30%">{t("translations:label")}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {paginatedTranslations?.map((translation) => (
                    <TableRow key={translation._id}>
                      <TableCell>{translation.namespace + ":" + translation.key}</TableCell>
                      <TableCell>{translation.label}</TableCell>
                      <TableCell>
                        {translations.find((t) => t.namespace === translation.namespace && t.key === translation.key)?.label ?? "-"}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </div>
          <PaginationButtons onPageChange={changePagination} pageCount={Math.ceil(englishTranslations.length / 100)} />
        </main>
      </Wrapper>
    </Page>
  );
};
