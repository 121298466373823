import { Formik } from "formik";
import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { AppState, setSelectedLocation, showAppModal, showSnackbar } from "../../../AppState";
import { IDevice, IFirmware, IGateway } from "../../../Managers/Types";
import classNames from "classnames";
import { mySearch } from "../../../Managers";
import "./ManageFirmware.scss";
import {
  getGatewaysByLocationId,
  IDeviceFirmwareUpdateRequest,
  IGatewayFirmwareUpdateRequest,
  postDeviceFirmwareUpdate,
  postGatewayFirmwareUpdate,
} from "../../../Managers/API";
import { action } from "mobx";
import { FirmwareType } from "../../../Enums";
import { useTranslation } from "react-i18next";
import { Column, Row } from "../../../Components";
import { fetchDashboardDevices } from "../../../Managers/LocationService";

interface IManageFirmware {
  latestFirmwares: IFirmware[] | null;
  selectedDevice: IDevice | null;
}

const TABS = {
  GATEWAY: 0,
  DEVICE: 1,
};

export const ManageFirmware: React.FC<IManageFirmware> = observer(({ latestFirmwares, selectedDevice }) => {
  const choseTabToInitiallyLoad = () => {
    if (selectedDevice) {
      return TABS.DEVICE;
    } else {
      return TABS.GATEWAY;
    }
  };

  const [searchString_Gateway, setSearchString_Gateway] = useState("");
  const [searchString_Device, setSearchString_Device] = useState(selectedDevice?.name ? selectedDevice.name : "");
  const [isSaving, _setIsSaving] = useState(false);
  const [updateAll, _setUpdateAll] = useState(false);
  const [gatewayFirmwaresToUpdate_UC, setGatewayFirmwaresToUpdate_UC] = useState<string[]>([]);
  const [gatewayFirmwaresToUpdate_BLE, setGatewayFirmwaresToUpdate_BLE] = useState<string[]>([]);
  const [deviceFirmwaresToUpdate, setDeviceFirmwaresToUpdate] = useState<string[]>([]);
  const [selectedTab, setSelectedTab] = useState(choseTabToInitiallyLoad());
  const [isUpdating, _setIsUpdating] = useState(true);
  const [devices, setDevices] = useState<Array<IDevice>>([]);
  const [gateways, setGateways] = useState<Array<IGateway>>([]);
  const [selectAllBLE, setSelectAllBLE] = useState<boolean>(false);
  const [selectAllUC, setSelectAllUC] = useState<boolean>(false);
  const [selectAllDevices, setSelectAllDevices] = useState<boolean>(false);

  const { t } = useTranslation(["dashboard", "common"]);

  const handleUpdateFirmware = () => {
    switch (selectedTab) {
      case TABS.GATEWAY:
        handleUpdateGatewayFirmware();
        break;
      case TABS.DEVICE:
        handleUpdateDeviceFirmware();
        break;
      default:
        break;
    }
  };

  const getAllDevicesLength = useCallback(() => {
    return AppState.selectedLocation?.Gateways?.reduce((a, b) => a + b.Devices.count, 0) ?? 0;
  }, [gateways]);

  useEffect(() => {
    const locationId = AppState.selectedLocation?._id || 0;
    fetchDashboardDevices(locationId, undefined, undefined, undefined, 0, getAllDevicesLength())
      .then(
        action((response) => {
          setDevices(response?.rows ?? []);
        }),
      )
      .catch((e) => console.warn("[APPSTATE] Unable to load more devices", e));
  }, [getAllDevicesLength]);

  useEffect(() => {
    const locationId = AppState.selectedLocation?._id || 0;
    getGatewaysByLocationId(locationId)
      .then(action((response) => setGateways(response)))
      .catch((e) => console.warn("[APPSTATE] Unable to load gateways", e));
  }, []);

  const handleUpdateGatewayFirmware = () => {
    if (gatewayFirmwaresToUpdate_BLE.length > 0 || gatewayFirmwaresToUpdate_UC.length > 0) {
      let gatewayList: any[] = [];

      gatewayFirmwaresToUpdate_BLE.map((gatewaySerial) => {
        gatewayList.push({
          serial_number: gatewaySerial,
          firmware_type: {
            name: "Gateway BLE",
          },
        });
      });

      gatewayFirmwaresToUpdate_UC.map((gatewaySerial) => {
        gatewayList.push({
          serial_number: gatewaySerial,
          firmware_type: {
            name: "Gateway UC",
          },
        });
      });

      const payload: IGatewayFirmwareUpdateRequest = {
        update_all: updateAll,
        gateways: gatewayList,
        is_updating: isUpdating,
      };

      postGatewayFirmwareUpdate(AppState.user?.AccountId, payload)
        .then((r) => {
          showSnackbar(t("dashboard:manage_firmware.update_success"), "success");
          console.log("Firmware update result", r);
          if (r.data && r.data.gateways.length > 0 && r.data.isUpdating !== undefined) {
            r.data.gateways.forEach((updatedGatewaySerialKey: string) => {
              gateways?.map((gateway: IGateway) => {
                if (gateway.serial_number === updatedGatewaySerialKey) {
                  gateway.is_updating = r.data.isUpdating;
                }
              });
            });
          }
        })
        .catch((e) => {
          const errorMessage = t("dashboard:manage_firmware.update_error");
          showSnackbar(errorMessage, "error");
          console.log(errorMessage, e);
        })
        .finally(() => showAppModal(null));
    }
  };

  const handleUpdateDeviceFirmware = () => {
    if (deviceFirmwaresToUpdate.length > 0) {
      let deviceList: any[] = [];

      deviceFirmwaresToUpdate.map((deviceSerial) => {
        deviceList.push({ serial_number: deviceSerial });
      });

      const payload: IDeviceFirmwareUpdateRequest = {
        devices: deviceList,
        is_updating: isUpdating,
      };

      postDeviceFirmwareUpdate(payload)
        .then((r) => {
          showSnackbar(t("dashboard:manage_firmware.update_success"), "success");
          console.log("Firmware update result", r);

          if (AppState.selectedLocation) {
            setSelectedLocation(AppState.selectedLocation);
          }
        })
        .catch((e) => {
          const errorMessage = t("dashboard:manage_firmware.update_error");
          showSnackbar(errorMessage, "error");
          console.log(errorMessage, e);
        })
        .finally(() => showAppModal(null));
    }
  };

  const handleCheckboxOnChangeEvent_UC = (_e: ChangeEvent<HTMLInputElement>, gatewaySerial: string) => {
    const addToList = !gatewayFirmwaresToUpdate_UC.includes(gatewaySerial);

    if (addToList) {
      return setGatewayFirmwaresToUpdate_UC([...gatewayFirmwaresToUpdate_UC, gatewaySerial]);
    } else {
      return setGatewayFirmwaresToUpdate_UC(
        gatewayFirmwaresToUpdate_UC.filter(function (serial) {
          return serial !== gatewaySerial;
        }),
      );
    }
  };
  const handleCheckboxOnChangeEvent_BLE = (e: ChangeEvent<HTMLInputElement>, gatewaySerial: string) => {
    const addToList = !gatewayFirmwaresToUpdate_BLE.includes(gatewaySerial);

    if (addToList) {
      return setGatewayFirmwaresToUpdate_BLE([...gatewayFirmwaresToUpdate_BLE, gatewaySerial]);
    } else {
      return setGatewayFirmwaresToUpdate_BLE(
        gatewayFirmwaresToUpdate_BLE.filter((serial) => {
          return serial !== gatewaySerial;
        }),
      );
    }
  };
  const handleCheckboxOnChangeEvent_Device = (_e: ChangeEvent<HTMLInputElement>, deviceSerial: string) => {
    const addToList = !deviceFirmwaresToUpdate.includes(deviceSerial);

    if (addToList) {
      return setDeviceFirmwaresToUpdate([...deviceFirmwaresToUpdate, deviceSerial]);
    } else {
      return setDeviceFirmwaresToUpdate(
        deviceFirmwaresToUpdate.filter((serial) => {
          return serial !== deviceSerial;
        }),
      );
    }
  };

  const handleSelectAll_Device = (e: React.MouseEvent<HTMLButtonElement | HTMLDivElement>) => {
    e.preventDefault();

    let devicesToSelect: string[] = [];
    devices?.forEach((device) => {
      if (!disableDeviceFirmwareCheckbox(device) && !deviceFirmwaresToUpdate.includes(device.serial_number)) {
        devicesToSelect.push(device.serial_number);
      }
    });
    selectAllDevices ? setDeviceFirmwaresToUpdate([]) : setDeviceFirmwaresToUpdate([...deviceFirmwaresToUpdate, ...devicesToSelect]);
    setSelectAllDevices(!selectAllDevices);
  };

  const handleSelectAll_Gateway = (e: React.MouseEvent<HTMLButtonElement | HTMLDivElement>, firmwareType: string) => {
    e.preventDefault();

    let alreadySelectedGateways: string[] = [];

    switch (firmwareType) {
      case "UC":
        alreadySelectedGateways = gatewayFirmwaresToUpdate_UC;
        break;
      case "BLE":
        alreadySelectedGateways = gatewayFirmwaresToUpdate_BLE;
        break;
      default:
        return;
    }

    gateways?.forEach((gateway) => {
      if (!disableGatewayFirmwareCheckbox(gateway, firmwareType) && !alreadySelectedGateways.includes(gateway.serial_number)) {
        alreadySelectedGateways.push(gateway.serial_number);
      }
    });

    switch (firmwareType) {
      case "UC":
        selectAllUC ? setGatewayFirmwaresToUpdate_UC([]) : setGatewayFirmwaresToUpdate_UC([...alreadySelectedGateways]);
        setSelectAllUC(!selectAllUC);
        break;
      case "BLE":
        selectAllBLE ? setGatewayFirmwaresToUpdate_BLE([]) : setGatewayFirmwaresToUpdate_BLE([...alreadySelectedGateways]);
        setSelectAllBLE(!selectAllBLE);
        break;
    }
    return;
  };

  const disableGatewayFirmwareCheckbox = (gateway: IGateway, firmwareType: string) => {
    let isLatestVersion;
    let isLatestBLEVersion = gateway.Firmwares?.find((entry) => entry.FirmwareTypeId === FirmwareType.BLE)?.isLatest;
    switch (firmwareType) {
      case "UC":
        isLatestVersion = !isLatestBLEVersion || gateway.Firmwares?.find((entry) => entry.FirmwareTypeId === FirmwareType.UC)?.isLatest;
        break;
      case "BLE":
        isLatestVersion = isLatestBLEVersion;
        break;
      default:
        break;
    }
    console.log("Gateway " + gateway.name + ` ${firmwareType} type`, isLatestVersion, gateway.Firmwares);
    return isSaving || isLatestVersion || !gateway.is_online || !gateway.is_active || gateway.is_updating;
  };

  const disableDeviceFirmwareCheckbox = (device: IDevice) => {
    const isLatestVersion = device.Firmware?.isLatest ? device.Firmware.isLatest : false;

    return isSaving || isLatestVersion || device.update_queued || !device.is_online || !device.is_active;
  };

  const disableUpdateButton = () => {
    switch (selectedTab) {
      case TABS.GATEWAY:
        if (gatewayFirmwaresToUpdate_UC.length > 0 || gatewayFirmwaresToUpdate_BLE.length > 0) {
          let result = gateways
            ?.filter((gateway) => {
              return (
                gatewayFirmwaresToUpdate_UC.includes(gateway.serial_number) || gatewayFirmwaresToUpdate_BLE.includes(gateway.serial_number)
              );
            })
            .map((gateway) => gateway.is_updating);
          if (result && result.length > 0 && !result.includes(true)) {
            return false;
          }
        }
        return true;
      case TABS.DEVICE:
        return !(deviceFirmwaresToUpdate.length > 0);
      default:
        return true;
    }
  };

  const displayGatewayFirmwareVersion = (gateway: IGateway, firmwareType: string) => {
    if (gateway?.is_updating) {
      return t("dashboard:manage_firmware.update_in_progress");
    } else {
      let firmwareVersion;
      switch (firmwareType) {
        case "UC":
          firmwareVersion = gateway.Firmwares?.find((entry) => entry.FirmwareTypeId === FirmwareType.UC)?.version;
          break;
        case "BLE":
          firmwareVersion = gateway.Firmwares?.find((entry) => entry.FirmwareTypeId === FirmwareType.BLE)?.version;
          break;
        default:
          return "";
      }
      return firmwareVersion ? `v${firmwareVersion}` : "";
    }
  };

  const getLatestFirmware = (type: FirmwareType) => {
    const firmwareArea = type === FirmwareType.FOOD_PROBE || type === FirmwareType.SENSOR_NODE ? "devices" : "gateways";
    const context = Object.entries(FirmwareType).find(([_key, value]) => value === type)?.[0];

    return latestFirmwares
      ?.filter((firmware) => firmware.FirmwareTypeId === type)
      .map((firmware) => {
        return (
          <h3 key={firmware._id} className="latest-firmware-version">
            {t(`dashboard:manage_firmware.${firmwareArea}.latest_version`, {
              context: context?.toLowerCase(),
              version: firmware.version,
            })}
          </h3>
        );
      });
  };

  const getLatestMobileFirmwares = () => {
    const uc = latestFirmwares?.find((firmware) => firmware.FirmwareTypeId === FirmwareType.UC);
    const ble = latestFirmwares?.find((firmware) => firmware.FirmwareTypeId === FirmwareType.BLE);
    return (
      <Row className="latest-firmware-version">
        <div>{t("dashboard:manage_firmware.latest_version")}:</div>
        <div>{`${t("dashboard:manage_firmware.ble")}: v${ble?.version}`}</div>
        <div>{`${t("dashboard:manage_firmware.uc")}: v${uc?.version}`}</div>
      </Row>
    );
  };

  const getLatestMobileDeviceFirmwares = () => {
    const sensor = latestFirmwares?.find((firmware) => firmware.FirmwareTypeId === FirmwareType.SENSOR_NODE);

    const probe = latestFirmwares?.find((firmware) => firmware.FirmwareTypeId === FirmwareType.FOOD_PROBE);
    return (
      <Row className="latest-firmware-version">
        <div>{t("dashboard:manage_firmware.latest_version")}:</div>
        <div>{`${t("dashboard:manage_firmware.food_probe")}: v${sensor?.version}`}</div>
        <div>{`${t("dashboard:manage_firmware.sensor_node")}: v${probe?.version}`}</div>
      </Row>
    );
  };

  const displayDeviceFirmwareVersion = (device: IDevice) => {
    if (device?.update_queued) {
      return t("dashboard:manage_firmware.update_queued");
    } else {
      return device.Firmware?.version ? `v${device.Firmware.version}` : "";
    }
  };

  const gatewayTab = (
    <Formik initialValues={[]} onSubmit={handleUpdateFirmware}>
      {({ submitForm }) => {
        return (
          <form>
            <div className={classNames("tab-pane", { active: selectedTab === TABS.GATEWAY })}>
              <div className="row">
                <div className="col-sm-12">
                  <div className="modal-body-header">
                    <div className="modal-body-header-latest-firmware-versions u-mobile-hide">
                      {getLatestFirmware(FirmwareType.UC)}
                      {getLatestFirmware(FirmwareType.BLE)}
                    </div>
                    <div className="modal-body-header-latest-firmware-versions u-mobile-only">{getLatestMobileFirmwares()}</div>
                    <div className="gateway-tab-select-all-buttons u-mobile-hide">
                      <button className="btn btn-default btn-select-all" onClick={(e) => handleSelectAll_Gateway(e, "BLE")}>
                        {t("dashboard:manage_firmware.gateways.select_all_ble")}
                      </button>
                      <button className="btn btn-default btn-select-all" onClick={(e) => handleSelectAll_Gateway(e, "UC")}>
                        {t("dashboard:manage_firmware.gateways.select_all_uc")}
                      </button>
                    </div>
                  </div>
                  <p className="input-label u-mobile-hide">{t("dashboard:manage_firmware.gateways.title")}</p>
                  <div className="u-flex-center">
                    <div className="input-holder mobile-search">
                      <input
                        type="text"
                        className="input input-default"
                        placeholder={t("dashboard:manage_firmware.gateways.find_by_name")}
                        onChange={(e) => setSearchString_Gateway(e.target.value)}
                        disabled={!gateways?.length}
                        value={searchString_Gateway}
                      />
                      <i className="fa fa-search input-holder-icon" />
                    </div>
                  </div>
                  <ul className="select-group">
                    <li className="u-mobile-hide select-group-item header">
                      <div className="row u-full-width u-mobile-hide">
                        <div className="col-xs-5">{t("dashboard:manage_firmware.gateways.name")}</div>
                        <div className="col-xs-3">{t("dashboard:manage_firmware.gateways.serial_number")}</div>
                        <div className="col-xs-2 centered-header">{t("dashboard:manage_firmware.gateways.ble_version")}</div>
                        <div className="col-xs-2 centered-header">{t("dashboard:manage_firmware.gateways.uc_version")}</div>
                      </div>
                    </li>
                    <li className="u-mobile-only select-group-item header">
                      <Row>
                        <div className={"input-label"}>{t("dashboard:manage_firmware.details")}</div>
                        <Row className="mobile-header-row">
                          <div className="checkbox" onClick={(e) => handleSelectAll_Gateway(e, "BLE")}>
                            <input type="checkbox" className="input-checkbox" name="ble-toggle" id={"ble-toggle"} checked={selectAllBLE} />
                            <span />
                            {t("dashboard:manage_firmware.gateways.select_all_ble")}
                          </div>

                          <div className="checkbox" onClick={(e) => handleSelectAll_Gateway(e, "UC")}>
                            <input type="checkbox" className="input-checkbox" name="uc-toggle" id={"uc-toggle"} checked={selectAllUC} />
                            <span />
                            {t("dashboard:manage_firmware.gateways.select_all_uc")}
                          </div>
                        </Row>
                      </Row>
                    </li>
                    {gateways?.length ? (
                      mySearch<IGateway>(gateways, searchString_Gateway).map((g) => (
                        <>
                          <li className={classNames(["select-group-item", "u-mobile-hide"], { isSaving })} key={g._id}>
                            <div className="row u-full-width">
                              <div className="col-xs-5">{g.name}</div>
                              <div className="col-xs-3">{g.serial_number}</div>
                              <div className="col-xs-2 checkbox-cell">
                                <label
                                  className={classNames({
                                    checkbox: { isSaving },
                                    "disabled-checkbox": disableGatewayFirmwareCheckbox(g, "BLE"),
                                  })}
                                  title={g.name}>
                                  <div>{displayGatewayFirmwareVersion(g, "BLE")}</div>
                                  <input
                                    type="checkbox"
                                    className="input-checkbox"
                                    name="gateways"
                                    id={"" + g._id}
                                    onChange={(e) => handleCheckboxOnChangeEvent_BLE(e, g.serial_number)}
                                    checked={gatewayFirmwaresToUpdate_BLE.includes(g.serial_number)}
                                    disabled={disableGatewayFirmwareCheckbox(g, "BLE")}
                                  />
                                  <span />
                                </label>
                              </div>
                              <div className="col-xs-2 checkbox-cell">
                                <label
                                  className={classNames({
                                    checkbox: { isSaving },
                                    "disabled-checkbox": disableGatewayFirmwareCheckbox(g, "UC"),
                                  })}
                                  title={g.name}>
                                  <div>{displayGatewayFirmwareVersion(g, "UC")}</div>
                                  <input
                                    type="checkbox"
                                    className="input-checkbox"
                                    name="gateways"
                                    id={"" + g._id}
                                    onChange={(e) => handleCheckboxOnChangeEvent_UC(e, g.serial_number)}
                                    checked={gatewayFirmwaresToUpdate_UC.includes(g.serial_number)}
                                    disabled={disableGatewayFirmwareCheckbox(g, "UC")}
                                  />
                                  <span />
                                </label>
                              </div>
                            </div>
                          </li>
                          {/* mobile version */}
                          <li
                            className={classNames(["select-group-item", "u-mobile-only", "mobile-list"], { isSaving })}
                            key={g._id + "-mobile"}>
                            <Column className="u-full-width">
                              <Row className="mobile-list-row">
                                <div className={"input-label"}>{t("dashboard:manage_firmware.gateways.name")}:</div>
                                <div>{g.name}</div>
                              </Row>
                              <Row className="mobile-list-row">
                                <div className={"input-label"}>{t("dashboard:manage_firmware.gateways.serial_number")}:</div>
                                <div>{g.serial_number}</div>
                              </Row>
                              <Row className="mobile-list-row">
                                <div className={"input-label"}>{t("dashboard:manage_firmware.gateways.ble_version")}:</div>
                                <div className="checkbox-cell">
                                  <label
                                    className={classNames({
                                      checkbox: { isSaving },
                                      "disabled-checkbox": disableGatewayFirmwareCheckbox(g, "BLE"),
                                    })}
                                    title={g.name}>
                                    <div>{displayGatewayFirmwareVersion(g, "BLE")}</div>
                                    <input
                                      type="checkbox"
                                      className="input-checkbox"
                                      name="gateways"
                                      id={"" + g._id + "mobile"}
                                      onChange={(e) => handleCheckboxOnChangeEvent_BLE(e, g.serial_number)}
                                      checked={gatewayFirmwaresToUpdate_BLE.includes(g.serial_number)}
                                      disabled={disableGatewayFirmwareCheckbox(g, "BLE")}
                                    />
                                    <span />
                                  </label>
                                </div>
                              </Row>
                              <Row className="mobile-list-row">
                                <div className={"input-label"}>{t("dashboard:manage_firmware.gateways.uc_version")}:</div>
                                <div className="checkbox-cell">
                                  <label
                                    className={classNames({
                                      checkbox: { isSaving },
                                      "disabled-checkbox": disableGatewayFirmwareCheckbox(g, "UC"),
                                    })}
                                    title={g.name}>
                                    <div>{displayGatewayFirmwareVersion(g, "UC")}</div>
                                    <input
                                      type="checkbox"
                                      className="input-checkbox"
                                      name="gateways"
                                      id={"" + g._id + "mobile"}
                                      onChange={(e) => handleCheckboxOnChangeEvent_UC(e, g.serial_number)}
                                      checked={gatewayFirmwaresToUpdate_UC.includes(g.serial_number)}
                                      disabled={disableGatewayFirmwareCheckbox(g, "UC")}
                                    />
                                    <span />
                                  </label>
                                </div>
                              </Row>
                            </Column>
                          </li>
                        </>
                      ))
                    ) : (
                      <p>{t("dashboard:manage_firmware.gateways.no_gateways")}</p>
                    )}
                  </ul>
                </div>
              </div>
              <div className="modal-footer">
                <button className="btn btn-info" onClick={() => showAppModal(null)}>
                  {t("common:close")}
                </button>
                <button
                  type="button"
                  disabled={disableUpdateButton()}
                  className={classNames({
                    "btn btn-primary": true,
                    "disabled-button": disableUpdateButton(),
                  })}
                  onClick={submitForm}>
                  {t("dashboard:manage_firmware.update_firmware")}
                </button>
              </div>
            </div>
          </form>
        );
      }}
    </Formik>
  );

  const deviceTab = (
    <Formik initialValues={[]} onSubmit={handleUpdateFirmware}>
      {({ submitForm }) => {
        return (
          <form>
            <div className={classNames("tab-pane", { active: selectedTab === TABS.DEVICE })}>
              <div className="row">
                <div className="col-sm-12">
                  <div className="modal-body-header">
                    <div className="modal-body-header-latest-firmware-versions u-mobile-hide">
                      {getLatestFirmware(FirmwareType.FOOD_PROBE)}
                      {getLatestFirmware(FirmwareType.SENSOR_NODE)}
                    </div>
                    <div className="modal-body-header-latest-firmware-versions u-mobile-only">{getLatestMobileDeviceFirmwares()}</div>
                    <button className="btn btn-default btn-select-all u-mobile-hide" onClick={(e) => handleSelectAll_Device(e)}>
                      {/*Close*/}
                      {t("dashboard:manage_firmware.devices.select_all_devices")}
                    </button>
                  </div>
                  <p className="input-label u-mobile-hide">{t("dashboard:manage_firmware.devices.title")}</p>
                  <div className="u-flex-center">
                    <div className="input-holder mobile-search">
                      <input
                        type="text"
                        className="input input-default"
                        placeholder={t("dashboard:manage_firmware.devices.find_by_name")}
                        onChange={(e) => setSearchString_Device(e.target.value)}
                        disabled={!devices?.length}
                        value={searchString_Device}
                      />
                      <i className="fa fa-search input-holder-icon" />
                    </div>
                  </div>
                  <ul className="select-group">
                    <li className="select-group-item header u-mobile-hide">
                      <div className="row u-full-width">
                        <div className="col-xs-5">{t("dashboard:manage_firmware.devices.name")}</div>
                        <div className="col-xs-4">{t("dashboard:manage_firmware.devices.serial_number")}</div>
                        <div className="col-xs-3 centered-header">
                          <div>{t("dashboard:manage_firmware.devices.version")}</div>
                        </div>
                      </div>
                    </li>
                    <li className="u-mobile-only select-group-item header">
                      <Row>
                        <div className={"input-label"}>{t("dashboard:manage_firmware.devices.title")}</div>
                        <Row className="mobile-header-row">
                          <div className="checkbox" onClick={(e: any) => handleSelectAll_Device(e)}>
                            <input
                              type="checkbox"
                              className="input-checkbox"
                              name="device-toggle"
                              id={"device-toggle"}
                              checked={selectAllDevices}
                            />
                            <span />
                            {t("dashboard:manage_firmware.devices.select_all_devices")}
                          </div>
                        </Row>
                      </Row>
                    </li>
                    {devices?.length ? (
                      mySearch<IDevice>(devices, searchString_Device).map((d) => (
                        <>
                          <li className={classNames(["select-group-item", "u-mobile-hide"], { isSaving })} key={d._id}>
                            <div className="row u-full-width">
                              <input
                                type="checkbox"
                                className="input-checkbox"
                                name="device-firmware"
                                id={"" + d._id + "-mobile"}
                                onChange={(e) => handleCheckboxOnChangeEvent_Device(e, d.serial_number)}
                                checked={deviceFirmwaresToUpdate.includes(d.serial_number)}
                                disabled={disableDeviceFirmwareCheckbox(d)}
                              />
                              <div className="col-xs-5">
                                {d.name} (
                                {d.Device_type?._id === 1
                                  ? d.Device_type.name
                                  : t("dashboard:manage_firmware.devices.sensor_node") ??
                                    t("dashboard:manage_firmware.devices.sensor_node")}
                                )
                              </div>
                              <div className="col-xs-4">{d.serial_number}</div>
                              <div className="col-xs-3 checkbox-cell">
                                <label
                                  className={classNames({
                                    checkbox: { isSaving },
                                    "disabled-checkbox": disableDeviceFirmwareCheckbox(d),
                                  })}
                                  title={d.name}>
                                  <div>{displayDeviceFirmwareVersion(d)}</div>

                                  <span />
                                </label>
                              </div>
                            </div>
                          </li>
                          <li
                            className={classNames(["select-group-item", "u-mobile-only", "mobile-list"], { isSaving })}
                            key={d._id + "-mobile"}>
                            <Column className="u-full-width">
                              <Row className="mobile-list-row">
                                <div className={"input-label"}>{t("dashboard:manage_firmware.devices.name")}:</div>
                                <div>
                                  {" "}
                                  {d.name} (
                                  {d.Device_type?._id === 1
                                    ? d.Device_type.name
                                    : t("dashboard:manage_firmware.devices.sensor_node") ??
                                      t("dashboard:manage_firmware.devices.sensor_node")}
                                  )
                                </div>
                              </Row>
                              <Row className="mobile-list-row">
                                <div className={"input-label"}>{t("dashboard:manage_firmware.devices.serial_number")}:</div>
                                <div>{d.serial_number}</div>
                              </Row>
                              <Row className="mobile-list-row">
                                <div className={"input-label"}>{t("dashboard:manage_firmware.devices.version")}:</div>
                                <div className="checkbox-cell">
                                  <label
                                    className={classNames({
                                      checkbox: { isSaving },
                                      "disabled-checkbox": disableDeviceFirmwareCheckbox(d),
                                    })}
                                    title={d.name}>
                                    <div>{displayDeviceFirmwareVersion(d)}</div>

                                    <span />
                                  </label>
                                </div>
                              </Row>
                            </Column>
                          </li>
                        </>
                      ))
                    ) : (
                      <p>{t("dashboard:manage_firmware.devices.no_devices")}</p>
                    )}
                  </ul>
                </div>
              </div>
              <div className="modal-footer">
                <button className="btn btn-info" onClick={() => showAppModal(null)}>
                  {t("common:close")}
                </button>
                {/*<button*/}
                {/*  type="button"*/}
                {/*  disabled={disableCancelButton()}*/}
                {/*  className={classNames({*/}
                {/*    "btn btn-primary": true,*/}
                {/*    "disabled-button": disableCancelButton(),*/}
                {/*  })}*/}
                {/*  onClick={async () => {*/}
                {/*    setIsUpdating(false);*/}
                {/*    await submitForm();*/}
                {/*  }}>*/}
                {/*  Cancel Firmware Update*/}
                {/*</button>*/}
                <button
                  type="button"
                  disabled={disableUpdateButton()}
                  className={classNames({
                    "btn btn-primary": true,
                    "disabled-button": disableUpdateButton(),
                  })}
                  onClick={submitForm}>
                  {t("dashboard:manage_firmware.update_firmware")}
                </button>
              </div>
            </div>
          </form>
        );
      }}
    </Formik>
  );

  return (
    <div className="modal fade in manage-firmware-modal" tabIndex={-1} role="dialog" style={{ display: "block", opacity: 1 }}>
      <div className={classNames("modal-dialog modal-firmware")}>
        <div className="modal-content">
          <div className="modal-header">
            <div className="modal-title">
              <span>{t("dashboard:manage_firmware.title")}</span>
            </div>
          </div>

          <div className="modal-body">
            <ul className="nav nav-tabs">
              {[
                { index: TABS.GATEWAY, name: t("dashboard:manage_firmware.gateways.title") },
                { index: TABS.DEVICE, name: t("dashboard:manage_firmware.devices.title") },
              ].map((tab) => (
                <li className="nav-item mobile-tab" key={tab.index}>
                  <a className={classNames("nav-link", { active: tab.index === selectedTab })} onClick={() => setSelectedTab(tab.index)}>
                    {tab.name}
                  </a>
                </li>
              ))}
            </ul>
            {gatewayTab}
            {deviceTab}
          </div>
        </div>
      </div>
    </div>
  );
});
