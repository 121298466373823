import { createTheme } from "@mui/material";

export const Colors = {
  black: "#4d4d4d",
  white: "#eeeeee",

  blkBlue: "#001321",
  bodyBlue: "#00243f",
  darkBlue: "#001a2e",
  cardBlue: "#033c70",
  marineBlue: "#002e51",
  royalBlue: "#005795",
  skyBlue: "#308dd9",
  babyBlue: "#80c2ff",

  lightTeal: "#94e7db",
  teal: "#00d1a4",

  alert: "#e51b00",
  warning: "#f8b700",
  info: "#00d1a4",

  success: "#1dab00",

  linkColor: "#eeeeee",

  componentActiveColor: "#fff",

  lightGray: "#ababab",
};

export const Vars = {
  primaryFontFamily: "Roboto, Helvetica, 'sans-serif'",

  blackFontWeight: 900,
  boldFontWeight: 700,
  semiBoldFontWeight: 500,
  regularFontWeight: 400,

  h1Size: "calc(36 / 14 * 1em)",
  h2Size: "calc(28 / 14 * 1em)",
  h3Size: "calc(22 / 14 * 1em)",
  h4Size: "calc(18 / 14 * 1em)",
  h5Size: "calc(16 / 14 * 1em)",
  pSize: "1em",
  smallSize: "calc(12 / 14 * 1em)",

  paddingBaseVertical: "6px",
  paddingBaseHorizontal: "12px",

  paddingLargeVertical: "10px",
  paddingLargeHorizontal: "16px",

  paddingSmallHorizontal: "10px",

  paddingXsVertical: "1px",
  paddingXsHorizontal: "5px",

  lineHeightBase: "1.428571429", // 20/14
  lineHeightLarge: "1.3333333", // extra decimals for Win 8.1 Chrome
  lineHeightSmall: "1.5",

  borderRadiusBase: "4px",
  borderRadiusLarge: "6px",
  borderRadiusSmall: "3px",

  btnBorderRadiusBase: "4px",
  btnBorderRadiusLarge: "6px",
  btnBorderRadiusSmall: "3px",

  gridGutterDefault: "15px",
};

export const wamTheme = createTheme({
  palette: {
    primary: {
      main: "#04cca0",
    },
    secondary: {
      main: "#cccccc",
    },
    warning: {
      main: Colors.warning,
    },
  },
  typography: {
    fontFamily: ["Roboto", "Helvetica", "sans-serif"].join(","),
    h1: {
      fontSize: "1.5rem",
      fontWeight: 500,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: "14px",
        },
        containedPrimary: {
          background: "#04cca0",
          color: "#001321",
          padding: "3px 8px 4px 8px",
          border: "1px solid #04cca0",
          textTransform: "none",
          fontWeight: 700,
          fontSize: "14px",
          letterSpacing: "0.8px",
          boxShadow: "0 1px 2px 0 rgb(0 0 0 / 30%), inset 0 1px 0 0 hsl(0deg 0% 100% / 50%)",

          ":disabled": {
            color: "rgba(0,19,33,.3)",
            cursor: "not-allowed",
            pointerEvents: "none",
            backgroundColor: "#03b38d",
          },
        },

        textPrimary: {
          fontSize: "13px",
          textTransform: "none",
          padding: "10px 0 0 0",
          marginRight: "18px",
          letterSpacing: "0.5px",
        },
      },
    },

    MuiAutocomplete: {
      styleOverrides: {
        hasClearIcon: {
          padding: 0,
          ".MuiOutlinedInput-notchedOutline": {
            border: `1px solid ${Colors.teal}`,
          },
        },
        hasPopupIcon: {
          padding: 0,
        },
        inputRoot: {
          padding: 0,
          paddingTop: "3px",
          borderBottom: "none",
        },
        clearIndicator: {
          display: "none",
        },
        popper: {
          whiteSpace: "nowrap",
          paddingTop: 1,
        },
        paper: {
          width: "100%",
          backgroundColor: Colors.blkBlue,
          border: `1px solid ${Colors.royalBlue}`,
          borderRadius: 0,
        },
        listbox: {
          borderRadius: 0,
          color: Colors.white,
          fontSize: Vars.h4Size,
          width: "100%",

          "> *:not(:first-of-type)": {
            borderTop: `1px solid ${Colors.marineBlue}`,
          },
        },
        option: {
          fontSize: Vars.pSize,
        },
        popupIndicator: {
          ".MuiSvgIcon-root": {
            color: Colors.teal,
            zIndex: 1,
          },
        },
        root: {
          ".MuiInputLabel-root": {
            fontSize: Vars.pSize,
          },

          ".labeled.dark .MuiOutlinedInput-notchedOutline > legend": {
            display: "flex",
            fontSize: 10,
            marginBottom: 0,
          },

          "&.dark .MuiOutlinedInput-notchedOutline": {
            backgroundColor: Colors.blkBlue,
          },

          "&.dark.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderStyle: "solid",
          },
        },
      },
    },

    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          padding: 4,
          border: "none",

          "> legend": {
            display: "none",
          },
        },
        root: {
          backgroundColor: "transparent",
          border: "none",
          borderRadius: 0,
          borderBottom: "1px solid rgba(238, 238, 238, 0.4)",
          color: Colors.white,
          fontSize: Vars.pSize,
          margin: 0,

          "&.labeled.dark .MuiOutlinedInput-notchedOutline > legend": {
            display: "block",
            marginBottom: 0,
          },

          "&.Mui-focused": {
            borderBottomColor: Colors.white,
          },

          ".MuiSvgIcon-root": {
            color: Colors.white,
          },

          ".MuiSelect-icon": {
            bottom: "8px",
          },

          "&.dark .MuiOutlinedInput-notchedOutline": {
            backgroundColor: Colors.blkBlue,
          },

          "&.dark.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderStyle: "solid",
          },
        },
        input: {
          padding: 4,
          margin: 0,
        },
      },
    },

    MuiSelect: {
      styleOverrides: {
        icon: {
          zIndex: 10,
        },
        select: {
          border: "1px solid transparent",
          padding: "2px 4px 4px",
          zIndex: 10,

          "& +.dark": {
            backgroundColor: Colors.blkBlue,
          },

          "& +.black": {
            backgroundColor: "#000",
          },

          "& +.marine": {
            backgroundColor: Colors.marineBlue,
          },

          ".MuiOutlinedInput-notchedOutline": {
            border: `1px solid ${Colors.teal}`,
            borderRadius: 0,
          },
        },
      },
    },

    MuiMenu: {
      styleOverrides: {
        root: {
          "&.MuiPopover-root": {
            zIndex: 1500,
          },
        },
        paper: {
          "&.MuiPaper-root": {
            backgroundColor: Colors.bodyBlue,
            borderRadius: 0,
            borderLeft: `1px solid ${Colors.royalBlue}`,
            borderRight: `1px solid ${Colors.royalBlue}`,
            borderBottom: `1px solid ${Colors.royalBlue}`,
            color: Colors.white,
          },

          ".MuiList-root": {
            padding: 0,
          },

          ".MuiMenuItem-root": {
            fontSize: Vars.pSize,
          },

          "& .dark": {
            backgroundColor: Colors.blkBlue,
          },

          "& .dark:not(:first-of-type)": {
            borderTop: `1px solid ${Colors.marineBlue}`,
          },
        },
      },
    },

    MuiFormControl: {
      styleOverrides: {
        root: {
          marginBottom: "12px",
          width: "100%",
        },
      },
    },

    MuiInputBase: {
      styleOverrides: {
        input: {
          "&.dark": {
            zIndex: 1,

            "& .MuiSelect-icon": {
              color: Colors.teal,
            },
          },

          "&.black": {
            backgroundColor: "#000",
            borderBottom: "none",

            "& .MuiSelect-icon": {
              color: Colors.teal,
            },
          },

          "&.marine": {
            backgroundColor: Colors.marineBlue,
            borderBottom: "none",

            "& .MuiSelect-icon": {
              color: Colors.teal,
            },
          },
        },
      },
    },

    MuiFormLabel: {
      styleOverrides: {
        filled: { top: 0 },
      },
    },

    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: Colors.white,

          "&:not(.Mui-focused):not(.MuiFormLabel-filled)": {
            top: "-1em",
          },

          "&.Mui-focused": {
            top: 0,
          },
        },
        filled: {
          display: "none",
        },
        shrink: {
          color: Colors.teal,
        },
      },
    },

    MuiInput: {
      styleOverrides: {
        root: {
          borderBottom: "1px solid  rgba(237, 237, 237, 0.4)",
          padding: "0 1px 6px 1px",
          color: "white",

          "&:before": {
            borderBottom: "none",
          },

          "&.Mui-error": {
            borderBottom: "1px solid red",
          },
        },

        input: {
          fontSize: "15px",
          letterSpacing: "0.5px",
          fontWeight: 500,
          padding: "10px 0 0 0",
        },
      },
    },

    MuiFormHelperText: {
      styleOverrides: {
        root: {
          bottom: "-16px",
          marginTop: 1,
          marginBottom: 0,
          lineHeight: "20px",
          position: "absolute",

          "&.Mui-error": {
            color: "#f8b700",
            fontWeight: 500,
          },
        },
      },
    },

    MuiBadge: {
      styleOverrides: {
        badge: {
          fontSize: "1rem",
        },
      },
    },

    MuiSlider: {
      styleOverrides: {
        root: {
          color: "#f8b700",
          marginTop: 3,
          marginRight: 10,
          marginLeft: 5,
        },
        thumb: {
          borderRadius: 0,
          width: "14px",
          height: "20px",
        },
        track: {
          backgroundColor: "#677cba",
          borderColor: "#354f64",
          height: "7px",
        },
        rail: {
          backgroundColor: "#f8b700",
          height: "7px",
          opacity: 1,
        },
      },
    },

    MuiTab: {
      styleOverrides: {
        root: {
          borderBottom: "1px solid rgba(4, 204, 160, 0.60)",
          color: "rgba(4, 204, 160, 0.60)",
          fontSize: Vars.pSize,
          maxWidth: "unset",

          "&.tab-error": {
            color: Colors.alert,
          },
        },
      },
    },

    MuiTabs: {
      styleOverrides: {
        root: {
          overflow: "unset",
        },
        flexContainerVertical: {
          height: "100%",
        },
        vertical: {
          backgroundColor: Colors.darkBlue,

          ".MuiTabs-indicator": {
            backgroundColor: Colors.royalBlue,
            left: 0,
            width: "8px",
          },

          ".MuiTab-root": {
            paddingLeft: "32px",
            alignItems: "flex-start",
            whiteSpace: "nowrap",
          },

          ".Mui-selected": {
            color: Colors.white,
          },
        },
      },
    },

    MuiTable: {
      styleOverrides: {
        root: {
          backgroundColor: Colors.darkBlue,
        },
      },
    },

    MuiTableRow: {
      styleOverrides: {
        root: {
          ".MuiTableRow-draggable": {
            fontWeight: 800,
            "&:hover": {
              cursor: "grab",
            },
          },
        },
      },
    },

    MuiTableBody: {
      styleOverrides: {
        root: {
          backgroundColor: Colors.cardBlue,
          borderBottom: `4px solid ${Colors.skyBlue}`,
        },
      },
    },

    MuiTableCell: {
      styleOverrides: {
        root: {
          borderColor: Colors.royalBlue,
          color: Colors.white,
          fontSize: Vars.pSize,
        },
        body: {
          padding: "0 16px",
        },
      },
    },

    MuiTableHead: {
      styleOverrides: {
        root: {
          position: "sticky",
          top: 0,
          zIndex: 10,

          ".MuiTableCell-root": {
            backgroundColor: Colors.marineBlue,
            color: Colors.babyBlue,
            textTransform: "uppercase",
          },
        },
      },
    },

    MuiChip: {
      styleOverrides: {
        sizeSmall: {
          fontSize: "0.7em",
        },
      },
    },

    MuiSwitch: {
      styleOverrides: {
        thumb: {
          backgroundColor: Colors.white,
          height: "12px",
          width: "12px",
        },

        switchBase: {
          padding: 13,

          "+ .MuiSwitch-track": {
            backgroundColor: Colors.white,
          },

          ".Mui-checked + .MuiSwitch-track": {
            backgroundColor: Colors.teal,
            opacity: 1,
          },
        },
      },
    },

    MuiListItem: {
      styleOverrides: {
        root: {
          padding: "4px 0 4px 8px",
          width: "100%",

          "&.selectable": {
            backgroundColor: Colors.marineBlue,
            padding: "8px 16px",

            "&:not(:first-of-type)": {
              borderTop: "1px solid " + Colors.royalBlue,
            },

            "&.selected": {
              backgroundColor: Colors.blkBlue,
            },
          },
        },
      },
    },

    MuiToggleButton: {
      styleOverrides: {
        root: {
          "&.MuiToggleButton-primary": {
            border: "1px solid " + Colors.teal,
            color: Colors.white,
            fontSize: Vars.smallSize,
            "&.Mui-selected": {
              backgroundColor: Colors.teal,
              color: Colors.blkBlue,
            },
          },
        },
      },
    },

    MuiPaginationItem: {
      styleOverrides: {
        root: {
          color: Colors.white,
          "&.Mui-selected": {
            color: Colors.teal,
            backgroundColor: "unset",
            fontWeight: Vars.boldFontWeight,

            "&:hover": {
              backgroundColor: "unset",
            },
          },
        },
      },
    },

    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: Colors.blkBlue,
          color: Colors.white,
          justifyContent: "space-between",
        },
      },
    },

    MuiSnackbar: {
      styleOverrides: {
        root: {
          zIndex: 2000,
        },
      },
    },

    MuiTextField: {
      styleOverrides: {
        root: {
          margin: 0,

          "&.dark > .MuiInputBase-root": {
            backgroundColor: Colors.blkBlue,
          },
        },
      },
    },
  },
});
