export const URLS = {
  Login: "/auth",
  ForgotPassword: "/auth/forget-password",
  Register: "/auth/register",
  RegisterConfirm: "/register-confirmed",
  Dashboard: "/",
  Users: "/users",
  ExportUsers: "/users/export",
  Alerts: "/alerts",
  Accounts: "/accounts",
  SubscriptionManagement: "/subscription-management",
  SubscriptionIsExpired: "/subscription-expired",
  TermsAndPolicy: "/tos-and-policy",
  LegalManagement: "/legal-management",
  TermsOfService: "/terms-of-service/:country",
  PrivacyPolicy: "/privacy-policy/:country",
  SupplementalPrivacyPolicy: "/supplemental-privacy-policy/:country",
  AlertsHistory: "/alerts-history",
  Translations: "/translations",
};
