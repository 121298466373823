import React from "react";
import { useField, useFormikContext } from "formik";
import classNames from "classnames";
import { Checkbox, MenuItem, Select } from "@mui/material";
import { InputLabel } from "./InputLabel";
import { InputError } from "./InputError";
import { SelectProps } from "@mui/material/Select/Select";

export interface ISelectOption {
  value: any;
  label: string | React.ReactNode;
  key?: string;
  onClick?: () => void;
  selected?: boolean;
}

export interface IFormFieldSelectProps extends SelectProps {
  inputClassName?: string;
  layout?: "vertical" | "horizontal";
  options?: ISelectOption[];
  onChange?: (value: any) => void;
  name?: string;
  dark?: boolean;
  displayError?: boolean;
  labelTooltip?: string;
  menuItemClass?: string;
  inputPlaceholder?: string;
}

export interface ISelectInputProps extends IFormFieldSelectProps {
  labelClassName?: string;
  setTouched?: () => void;
  hasError?: string;
  initialValue?: any;
  menuItemClass?: string;
  inputPlaceholder?: string;
}

export const SelectInput: React.FC<ISelectInputProps> = ({
  className = "",
  inputClassName = "",
  layout = "vertical",
  options,
  name = "",
  label,
  onChange,
  required,
  displayError,
  labelClassName,
  hasError,
  value,
  initialValue,
  menuItemClass = "",
  style,
  children,
  inputPlaceholder,
  ...props
}) => {
  // We may have many check boxes with the same field name. They need separate IDs.
  const id = `${name}_${Math.random().toString(36).substring(2, 9)}`;
  return (
    <div style={style} className={classNames("form-group input-select", className, layout)}>
      {label ? <InputLabel name={name} label={label} required={required} tooltip={props.labelTooltip} /> : null}
      <div className={classNames("input-holder mod-block", labelClassName)}>
        <Select
          {...props}
          id={id}
          value={value ?? initialValue ?? ""}
          className={classNames("form-control", "select", inputClassName)}
          sx={{
            "& .MuiSelect-select .notranslate::after": inputPlaceholder
              ? {
                  content: `"${inputPlaceholder}"`,
                  opacity: 0.68,
                }
              : {},
          }}
          onChange={(e) => onChange?.(e.target.value)}>
          {options?.map((option, i) => (
            <MenuItem onClick={option.onClick} className={menuItemClass} key={option.key ?? i} value={option.value}>
              {props.multiple ? <Checkbox checked={option.selected} onChange={option.onClick} /> : null}
              {option.label}
            </MenuItem>
          ))}
          {children}
        </Select>
        <InputError error={hasError} displayError={displayError} touched={true} />
      </div>
    </div>
  );
};

export const FormFieldSelect: React.FC<IFormFieldSelectProps> = (props) => {
  const [field, meta] = useField(props.name ?? "");
  const { setFieldValue } = useFormikContext();

  return (
    <SelectInput
      {...props}
      onChange={(value) => {
        props.onChange && props.onChange(value);
        return setFieldValue(props.name ?? "", value);
      }}
      hasError={meta.error}
      setTouched={() => (meta.touched = true)}
      value={field.value}
      labelClassName={meta.touched && Boolean(meta.error) ? "error" : ""}
    />
  );
};
