import React, { useEffect, useState } from "react";
import { AppState } from "../AppState";
import { closeMaintenanceNotification } from "../Managers/MaintenanceNotificationService";
import { Snackbar } from "@mui/material";
import { Colors } from "../Theme";
import "./MaintenanceSnackbar.scss";

const MaintenanceNotificationSnackbar = () => {
  const [isMaintenanceNotificationClosed, setIsMaintenanceNotificationClosed] = useState(false);

  useEffect(() => {
    const fetchMaintenanceNotificationClosedStatus = async () => {
      try {
        const isClosed = AppState.user?.is_maintenance_notification_closed ?? true;
        setIsMaintenanceNotificationClosed(isClosed);
      } catch (error) {
        console.error("Error fetching maintenance notification closed status:", error);
      }
    };

    if (AppState.user) {
      fetchMaintenanceNotificationClosedStatus();
    }
  }, [AppState.user]);

  const handleSnackbarMaintenanceNotificationClose = () => {
    if (AppState.user && !isMaintenanceNotificationClosed) {
      closeMaintenanceNotification(AppState.user._id)
        .then(() => {
          console.log("Maintenance notification closed by user");
          setIsMaintenanceNotificationClosed(true);
          if (AppState.user) {
            AppState.user.is_maintenance_notification_closed = true;
          }
        })
        .catch((error: any) => {
          console.error("Error closing maintenance notification:", error);
        });
    }
  };
  return (
    <Snackbar
      color="error"
      className="maintenance-notification-snackbar"
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      ContentProps={{ sx: { background: Colors.alert, fontSize: 15 } }}
      open={!isMaintenanceNotificationClosed && !!AppState.maintenanceNotification.message && AppState.maintenanceNotification.active}
      message={AppState.maintenanceNotification.message}
      action={
        <button className="close-button" onClick={handleSnackbarMaintenanceNotificationClose}>
          x
        </button>
      }
    />
  );
};

export default MaintenanceNotificationSnackbar;
