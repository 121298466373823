import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import * as AlertConditions from "../../../Managers/AlertConditionService";
import * as DeviceAlerts from "../../../Managers/DeviceAlertService";
import { useAlertConfigs } from "../../../Managers/AlertService";
import { IAlertConfig } from "../../../Managers/Alert.model";
import { useDevice } from "../../../Managers/DeviceService";
import { AppState, showAppModal, showSnackbar } from "../../../AppState";
import { DeviceDetailModal } from "./DeviceDetailModal";
import { Modal } from "../../../Components/Modal";
import { useTranslation } from "react-i18next";
import { CheckboxInput } from "../../../Components";

interface IAddAlertsProps {
  alerts?: IAlertConfig[];
  deviceId: number;
  nextStep?: any;
  sensorTypeId?: number;
  tabIndex?: number;
}

export const AddAlertModal: React.FC<IAddAlertsProps> = observer(({ tabIndex, deviceId, alerts, sensorTypeId }) => {
  const [isSaving, setIsSaving] = useState(false);
  const [possibleAlerts, setPossibleAlerts] = useState<IAlertConfig[]>([]);
  const [addedAlerts, setAddedAlerts] = useState<IAlertConfig[]>([]);

  const { t } = useTranslation(["dashboard", "common"]);

  const alertConfigs = useAlertConfigs();
  const deviceQuery = useDevice(deviceId);
  const device = deviceQuery.data;

  const sensorTypes = device?.Sensors.map((sensor) => sensor.Sensor_type);
  const isSelected = Boolean(sensorTypeId);

  const filterPossibleAlerts = (id = sensorTypeId) => {
    const deviceAlerts = device?.Sensors.filter((x) => x.SensorTypeId == id).flatMap((x) => x.Alerts);
    const deviceAlertIds = deviceAlerts?.map((x) => x._id) ?? [];
    return alertConfigs.data?.filter((x) => !deviceAlertIds.includes(x._id) && x.SensorTypeId === id) ?? [];
  };

  useEffect(() => {
    if (alertConfigs.data) {
      setPossibleAlerts(filterPossibleAlerts());
    }
  }, [alertConfigs.status]);

  const save = () => {
    setIsSaving(true);
    DeviceAlerts.addAlertsToDevice(deviceId, addedAlerts)
      .then(() => {
        showSnackbar(t("dashboard:add_alert.add_success"), "success");
        alerts != undefined ? showAppModal(<DeviceDetailModal deviceId={deviceId} sensorIndex={tabIndex} />) : showAppModal(null);
      })
      .catch((e) => {
        console.log("Error saving alerts", e);
        showSnackbar(t("dashboard:add_alert.add_error"), "error");
      })
      .finally(() => setIsSaving(false));
  };

  const getAlerts = (s: string) => {
    const id = Number(s);
    const possibleAlerts = filterPossibleAlerts(id);
    setPossibleAlerts(possibleAlerts);
  };

  const toggleAlert = (e: any, alert: IAlertConfig) => {
    e.preventDefault();
    console.log("toggling alert", alert);
    if (!addedAlerts.includes(alert)) {
      setAddedAlerts([...addedAlerts, alert]);
    } else {
      setAddedAlerts(addedAlerts.filter((a) => a._id !== alert._id));
    }
  };

  return (
    <Modal
      className=""
      title={t("dashboard:add_alert.title")}
      buttons={
        <>
          <button
            className="btn btn-info"
            disabled={isSaving}
            onClick={() =>
              alerts != undefined ? showAppModal(<DeviceDetailModal deviceId={deviceId} sensorIndex={tabIndex} />) : showAppModal(null)
            }>
            {t("common:cancel")}
          </button>
          <button type="button" className="btn btn-primary" disabled={isSaving || (addedAlerts && !addedAlerts.length)} onClick={save}>
            {isSaving ? <i className="fa fa-circle-o-notch fa-spin" /> : <></>}
            {t("common:save")}
          </button>
        </>
      }>
      {!sensorTypeId && (
        <div className="form-group">
          <label htmlFor="sensor-type" className="input-label u-display-block">
            {t("dashboard:add_alert.sensor_type")}
          </label>
          <div className="input-holder select">
            <select name="sensor-type" className="input input-select" onChange={(e) => getAlerts(e.target.value)}>
              <option value="">-- {t("dashboard:add_alert.select")} --</option>
              {sensorTypes ? sensorTypes.map((sensorType) => <option value={sensorType._id}>{sensorType.name}</option>) : <></>}
            </select>
          </div>
        </div>
      )}
      <div className="form-group">
        {isSelected && !possibleAlerts.length ? (
          <p>
            {t("dashboard:add_alert.no_applicable_alerts")}{" "}
            <a className="u-text-teal" href="/alerts">
              {t("dashboard:add_alert.go_to_alerts_page")}
            </a>
          </p>
        ) : null}
        {possibleAlerts.length ? (
          <ul className="select-group">
            <li className="select-group-item header u-desktop-only">
              <div className="row u-full-width">
                <div className="col-xs-5 first-col">{t("dashboard:add_alert.alert_name")}</div>
                <div className="col-xs-7">{t("dashboard:add_alert.condition")}</div>
              </div>
            </li>

            {possibleAlerts.map((alert) => (
              <li className="select-group-item" onClick={(e) => toggleAlert(e, alert)}>
                <div className="row u-full-width u-text-small" style={{ display: "flex", alignItems: "center" }}>
                  <CheckboxInput
                    className="col-xs-2 col-lg-5"
                    label={AppState.mode === "desktop" ? alert.name : undefined}
                    checked={addedAlerts.some((a) => a._id == alert._id)}
                    name="selected"
                  />
                  <div className="col-xs-10 col-lg-7">
                    <div className="u-desktop-hide">
                      <div className="input-label">{t("dashboard:add_alert.alert_name")}</div>
                      <div>{alert.name}</div>
                    </div>
                    <div>
                      <div className="input-label u-desktop-hide">{t("dashboard:add_alert.condition")}</div>
                      <div>{AlertConditions.transform(alert)}</div>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        ) : null}
      </div>
    </Modal>
  );
});
