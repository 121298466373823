import classNames from "classnames";
import React, { useEffect, useState } from "react";
import moment from "moment";
import { observer } from "mobx-react-lite";
import { updateNotification, useLatestNotifications, useLatestSystemNotifications } from "../../Managers/NotificationService";
import { alertConditionTransform } from "../../Managers/AlertConditionService";
import { measurementTransform } from "../../Managers/MeasurementService";
import { unitsTransform } from "../../Managers/UnitsService";
import { AppState, refreshAppState, showAppModal, showSnackbar } from "../../AppState";
import { AlertResolveModal } from "./AlertResolveModal";
import { useUsers } from "../../Managers/UsersService";
import { INotification, ISystemNotification } from "../../Managers/Types";
import { AlertLogModal } from "./AlertLogModal";
import "./NotificationDropdown.scss";
import { Badge, Button } from "@mui/material";
import { Tab, TabContext, TabList, TabPanel } from "../../Components";
import { NotificationTypes } from "../../Enums/NotificationTypes";
import { markNotificationAsRead } from "../../Managers/AccountsService";
import { useTranslation } from "react-i18next";

interface IAlertsDropdownProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onClose: () => void;
}

export const NotificationDropdown: React.FC<IAlertsDropdownProps> = observer(({ isOpen, setIsOpen, onClose }) => {
  const [isButtonActive, setIsButtonActive] = useState(false);
  const [isBtnActive, setIsBtnActive] = useState(false);
  const [notificationsToResolve, setNotificationsToResolve] = useState<INotification[]>([]);
  const [resolving, setResolving] = useState(false);
  const [currentTab, setCurrentTab] = useState("alerts");
  const [isScrollAtBottom, setScrollAtBottom] = useState({ notification: true, alert: true });
  const [alerts, setAlerts] = useState<INotification[]>([]);

  const scrollableAlertListRef = React.createRef<HTMLUListElement>();
  const scrollableNotificationListRef = React.createRef<HTMLUListElement>();

  const usersQuery = useUsers();
  const alertsQuery = useLatestNotifications();
  const notifications = useLatestSystemNotifications();

  useEffect(() => {
    if (alertsQuery.data) {
      setAlerts(alertsQuery.data);
    }
  }, [alertsQuery.dataUpdatedAt]);

  const { t } = useTranslation("notification_dropdown");

  const openAlertLog = () => {
    showAppModal(<AlertLogModal />);
    onClose();
  };

  const openAlertDetail = (notification: INotification) => {
    showAppModal(<AlertResolveModal notification={notification} />);
    onClose();
  };

  // TODO: Consider doing a code review pass and moving all formatters/helpers like this to a set of utility modules? There are inconsistencies.
  const getUserName = (id: number) => {
    const user = AppState.user?._id === id ? AppState.user : (usersQuery.data || []).find((user) => user._id === id);
    return user ? (user.first_name ? user.first_name + " " : "") + (user.last_name ? user.last_name : "") : "";
  };

  const checkBoxChange = (notification: INotification) => {
    let newList = [...notificationsToResolve];
    const checkedIds = newList.map((n) => n._id);

    if (!checkedIds.includes(notification._id)) {
      newList.push(notification);
    } else {
      newList = newList.filter((l) => l._id !== notification._id);
    }

    setNotificationsToResolve(newList);
    setIsButtonActive(newList.length > 0);
  };

  // TODO: There seems to be mixed use of Alert and Notification as terms in the code base.
  const resolveAlerts = async (all: boolean = false) => {
    // TODO: These are just hard-coded?
    let identifier = "";
    let password = "";

    setResolving(true);

    const toResolve = all ? alerts : notificationsToResolve;

    await Promise.allSettled<INotification>(
      toResolve.map((entry) =>
        updateNotification(
          {
            ...entry,
            is_resolved: true,
            resolved_notes: "",
            UserId: AppState.user?._id || 0,
          },
          identifier,
          password,
        ),
      ),
    )
      .then((response) => {
        response.forEach((r) => {
          if (r.status === "fulfilled") {
            console.log("Resolved notification", r.value._id, r.value);
          } else {
            console.log("Error resolving notification", r);
          }
          setNotificationsToResolve([]);
          showSnackbar(t("notification_dropdown:resolve_alert_success"), "success");
        });
      })
      .catch((e) => {
        console.log("Error resolving notifications", e);
        showSnackbar(t("notification_dropdown:resolve_alert_error"), "error");
      });

    setResolving(false);
    setIsBtnActive(false);
  };

  const markAsRead = async (notificationType: NotificationTypes) => {
    await markNotificationAsRead([notificationType]).then(() => {
      refreshAppState();
    });
  };

  const markAllAsRead = async (notifications: ISystemNotification[]) =>
    markNotificationAsRead(notifications.filter((n) => !n.read).map((n) => n.type)).then(() => refreshAppState());

  const handleListScroll = (e: React.UIEvent<HTMLUListElement, UIEvent>, scrollType: keyof typeof isScrollAtBottom) => {
    const atBottom = Math.abs(e.currentTarget.scrollHeight - (e.currentTarget.scrollTop + e.currentTarget.clientHeight)) <= 20;
    if (atBottom !== isScrollAtBottom[scrollType]) setScrollAtBottom({ ...isScrollAtBottom, [scrollType]: atBottom });
  };

  useEffect(() => {
    if (isOpen) {
      scrollableAlertListRef.current?.scrollBy(0, 1);
      scrollableAlertListRef.current?.scrollBy(0, -1);
    }
    if (isOpen) {
      scrollableNotificationListRef.current?.scrollBy(0, 1);
      scrollableNotificationListRef.current?.scrollBy(0, -1);
    }
  }, [isOpen, scrollableAlertListRef, scrollableNotificationListRef, currentTab]);

  return (
    <div className="dropdown-menu dropdown-menu-right alerts-dropdown-menu">
      <TabContext value={currentTab}>
        <TabList onChange={(_e, val) => setCurrentTab(val)}>
          <Tab
            label={
              <Badge color="warning" badgeContent={alerts?.length}>
                {t("notification_dropdown:alerts")}
              </Badge>
            }
            className="col-sm-6 dropdown-tab"
            value="alerts"
          />
          <Tab
            label={
              <Badge color="warning" badgeContent={notifications?.filter((r) => !r.read).length}>
                {t("notification_dropdown:notifications")}
              </Badge>
            }
            className="col-sm-6 dropdown-tab"
            value="notifications"
          />
        </TabList>
        <TabPanel value="alerts">
          <li className="select-group-item header" key={`alert-header`}>
            <div className="col-xs-3 u-text-teal row">{t("notification_dropdown:status")}</div>
            <div className="col-xs-6 u-text-teal">{t("notification_dropdown:info")}</div>
            {/* u-desktop-only */}
            <div className="col-xs-3 u-text-teal row">{t("notification_dropdown:resolve")}</div>
          </li>
          <ul
            className="select-group fix-height alert-group-list"
            key={`alert-list`}
            role="menu"
            onScroll={(e) => handleListScroll(e, "alert")}
            ref={scrollableAlertListRef}>
            {!alerts.length ? <li className="select-group-item">{t("notification_dropdown:no_alerts")}</li> : <></>}

            {alerts.map((notification) => (
              <li className="select-group-item" key={`alert-${notification._id}`} onClick={() => openAlertDetail(notification)}>
                <div className="col-xs-3 row">
                  {/* CHANGED - removed if */}
                  <div>
                    {notification.Sensor != null ? (
                      <>
                        <p className="alert-log-heading">
                          {measurementTransform(notification.value.value, [
                            notification.Sensor.default_unit,
                            notification.Sensor.Device?.is_empirical,
                            notification.Sensor.Sensor_type.type,
                          ])}
                          <span className="u-degree">
                            {unitsTransform(notification.Sensor.default_unit, [
                              notification.Sensor.default_unit,
                              notification.Sensor.Device?.is_empirical,
                              notification.Sensor.Sensor_type.type,
                            ])}
                          </span>
                        </p>
                        <p className="alert-log-time">{moment(notification.updatedAt).format("M/D/YYYY hh:mm A")}</p>
                      </>
                    ) : null}
                  </div>
                </div>
                <div className="col-xs-6">
                  <p className="notification-info notification-info-header">
                    {!notification.is_snoozed ? <i className="icon icon-alarm" /> : <></>}
                    {notification.is_snoozed ? <i className="icon icon-alarm_no" /> : <></>}
                    {notification.Alert.name.indexOf("not-transmitting-alert") < 0 && notification.Alert.name}
                  </p>
                  <p className="notification-info">{alertConditionTransform(notification.Alert)}</p>

                  {notification.Sensor?.Device ? (
                    <p className="notification-info">
                      <span className="input-label">{t("notification_dropdown:device")}: </span>
                      <span>{notification.Sensor?.Device?.name || ""}</span>
                    </p>
                  ) : null}

                  {!notification.is_resolved && notification.Sensor?.Device ? (
                    <p className="notification-info">
                      <span className="input-label">{t("notification_dropdown:placement")}: </span>
                      <span>{notification.Sensor?.Device?.location_note || "--"}</span>
                    </p>
                  ) : null}

                  {notification.is_resolved && notification.UserId ? (
                    <p className="notification-info">
                      <span className="input-label">{t("notification_dropdown:user")}: </span>
                      {/*	TODO: I think this was misspelled in the old code? */}
                      {/*<span className="u-text-small">{notification.user}</span>*/}
                      <span className="u-text-small">{getUserName(notification.UserId)}</span>
                    </p>
                  ) : null}
                </div>
                <div className="col-xs-3 row resolved-column" style={{ zIndex: 99999 }}>
                  {/* SHOW CHECK MARK IF RESOLVED */}
                  {notification.is_resolved ? (
                    <div>
                      <p className="alert-log-heading">
                        <i className="fa fa-check-circle" />
                      </p>
                      <p className="alert-log-subheading">{t("notification_dropdown:resolved")}</p>
                      <p className="alert-log-time">{moment(notification.updatedAt).format("M/D/YYYY hh:mm A")}</p>
                    </div>
                  ) : null}

                  {/* CHECK BOX TO RESOLVE MULTIPLE ALERTS */}
                  {!notification.is_resolved ? (
                    <div>
                      <p className="alert-log-heading">
                        <input
                          type="checkbox"
                          name="resolveAlert"
                          checked={notificationsToResolve.some((n) => n._id === notification._id)}
                          style={{ width: "20px", height: "20px" }}
                          onClick={(e) => {
                            e.stopPropagation();
                            checkBoxChange(notification);
                          }}
                        />
                      </p>
                    </div>
                  ) : null}
                </div>
              </li>
            ))}
          </ul>
          {resolving ? (
            <div className="resolve-loader">
              <i className="fa fa-spin fa-circle-o-notch" />
            </div>
          ) : null}
          <div className="alert-control-wrapper">
            <div className={classNames("background-gradient", { "hide-gradient": isScrollAtBottom.alert })} />
            <button
              id="resolve-all-alerts-button"
              className={classNames("btn btn-link btn-sm pull-right", { "u-text-teal": isBtnActive })}
              onClick={() => resolveAlerts(true)}>
              {t("notification_dropdown:resolve_all_alerts")}
            </button>
            <button
              id="resolve-checked-alerts"
              className={classNames("btn btn-link btn-sm pull-right tablet-dekstop-only", { "u-text-teal": isButtonActive })}
              onClick={() => resolveAlerts()}>
              {t("notification_dropdown:resolve_checked_alerts")}
            </button>
            <div id="resolve-checked-close-buttons" className="alert-control-small-last-container">
              <button
                id="resolve-checked-alerts"
                className={classNames("btn btn-link btn-sm pull-right", { "u-text-teal": isButtonActive })}
                onClick={() => resolveAlerts()}>
                {t("notification_dropdown:resolve_checked_alerts")}
              </button>
              <button
                id="close-button-mobile-small"
                className="btn btn-link btn-sm pull-right close-button"
                type="button"
                onClick={() => setIsOpen(false)}>
                {t("common:close")}
              </button>
            </div>
            <button id="alert-log-button" className="btn btn-link btn-sm pull-right" type="button" onClick={openAlertLog}>
              <i className="fa fa-table u-desktop-only" /> {t("notification_dropdown:alert_log")}
            </button>
            <button
              id="close-button-tablet"
              className="btn btn-link btn-sm pull-right close-button"
              type="button"
              onClick={() => setIsOpen(false)}>
              {t("common:close")}
            </button>
          </div>
        </TabPanel>
        <TabPanel value="notifications">
          <li className="select-group-item header" key={`notifications-header`}>
            <div className="col-xs-1 row" style={{ display: "flex", padding: 0 }} />
            <div className="col-xs-8 u-text-teal">{t("notification_dropdown:info")}</div>
            <div className="col-xs-3 u-text-teal row">{t("notification_dropdown:action")}</div>
          </li>
          <ul
            className="select-group fix-height"
            key={`notifications-list`}
            role="menu"
            style={{ maxHeight: "60vh" }}
            onScroll={(e) => handleListScroll(e, "notification")}
            ref={scrollableNotificationListRef}>
            {!notifications || notifications?.length === 0 ? (
              <li className="select-group-item" key={`no-notifications`}>
                {t("notification_dropdown:no_notifications")}
              </li>
            ) : null}
            {notifications.map((notification) => (
              <li
                className={`select-group-item ${notification.read ? "read" : ""}`}
                key={`notification-${Math.floor(Math.random() * 10000)}`}>
                <div className="col-xs-1" style={{ display: "flex", padding: 0 }}>
                  <button
                    style={{
                      zIndex: 2,
                      cursor: "pointer",
                      background: "transparent",
                      border: 0,
                      textAlign: "center",
                      width: 20,
                      height: 50,
                      color: notification.read ? "#ffffff" : "#04cca0",
                      fontSize: 30,
                    }}
                    onClick={() => markAsRead(notification.type)}
                    title={t("notification_dropdown:mark_as_read")}>
                    •
                  </button>
                </div>
                <div className="col-xs-8 notification-message" style={{ whiteSpace: "break-spaces" }}>
                  {notification.message}
                </div>
                {notification.button ? (
                  <div className="col-xs-3">
                    <Button style={{ zIndex: 2 }} onClick={notification.button.action} variant="text">
                      {notification.button.label}
                    </Button>
                  </div>
                ) : (
                  <div className="col-xs-3"></div>
                )}
              </li>
            ))}
          </ul>
          {notifications?.filter((n) => !n.read).length ? (
            <div className="notification-control-wrapper">
              <div className={classNames("background-gradient", { "hide-gradient": isScrollAtBottom.notification })} />
              <button
                className={classNames("btn btn-link btn-sm pull-right", { "u-text-teal": isBtnActive })}
                onClick={() => markAllAsRead(notifications).catch(() => {})}>
                {t("notification_dropdown:mark_all_as_read")}
              </button>
              <button className="btn btn-link btn-sm pull-right close-button" type="button" onClick={() => setIsOpen(false)}>
                {t("common:close")}
              </button>
            </div>
          ) : null}
        </TabPanel>
      </TabContext>
    </div>
  );
});
