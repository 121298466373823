import React from "react";
import { observer } from "mobx-react-lite";
import { AppState, showAppModal } from "../AppState";
import { isUserPermissionAllowed, isUserRoleAllowed, PermissionEnum, UserRoles } from "../Enums";
import {CurrenciesModal, MaintenanceNotificationModal, ManageHelpModal} from "../Modals";
import { DefaultAlertValues } from "../Views/Alerts/DefaultAlertValues/DefaultAlertValues";
import { URLS } from "../Views/URLS";
import { createSearchParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const ManagementMenu: React.FC = observer(() => {
  const navigate = useNavigate();
  const { t } = useTranslation(["common", "management_menu"]);

  const menuItems: Array<{ action?: () => void; title: string; hide?: boolean }> = [
    {
      title: t("common:accounts"),
      action: () => navigate({ pathname: URLS.Accounts }),
    },
    {
      title: t("common:subscription_management"),
      action: () => navigate({ pathname: URLS.SubscriptionManagement }),
    },
    {
      title: t("management_menu:manage_maintenance_notification"),
      action: () => showAppModal(<MaintenanceNotificationModal />),
      hide: !isUserRoleAllowed(UserRoles.APP_ADMIN),
    },
    {
      title: t("management_menu:default_alarm_values"),
      action: () => showAppModal(<DefaultAlertValues />),
      hide: !isUserRoleAllowed(UserRoles.APP_ADMIN),
    },
    {
      title: t("management_menu:manage_help_tabs"),
      action: () => showAppModal(<ManageHelpModal />),
      hide: !isUserRoleAllowed(UserRoles.APP_ADMIN),
    },
    {
      title: t("management_menu:manage_translations"),
      action: () => navigate(URLS.Translations),
    },
    {
      title: t("management_menu:manage_currencies"),
      action: () => showAppModal(<CurrenciesModal />),
      hide: !isUserRoleAllowed(UserRoles.APP_ADMIN),
    },
    {
      title: t("management_menu:manage_legals"),
      action: () =>
        navigate({
          pathname: URLS.LegalManagement,
          search: createSearchParams({
            country: AppState.user?.country_code ?? "US",
          }).toString(),
        })
    },
  ];

  return (
    <section role="menu" className="dropdown-menu dropdown-menu-right">
      <ul style={{ paddingLeft: 0 }}>
        {menuItems.map((item) =>
          item.hide ? null : (
            <li key={item.title} role="menuitem" className={`dropdown-menu-item ${item.action ? "" : "non-click"}`} onClick={item.action}>
              {item.title}
            </li>
          ),
        )}
      </ul>
    </section>
  );
});
