import React from "react";
import "./LeftRail.scss";
import classNames from "classnames";

interface ILeftRailProps {
  header: JSX.Element;
  body: JSX.Element;
  onClick?: () => void;
  className?: string;
}

export const LeftRail: React.FC<ILeftRailProps> = ({ header, body, onClick, className }) => {
  return (
    <aside className={classNames("left-rail", className)} onClick={onClick}>
      <header className="left-rail-header">{header}</header>
      <section className="left-rail-body">{body}</section>
    </aside>
  );
};
