import * as yup from "yup";
import { Formik } from "formik";
import React from "react";
import { observer } from "mobx-react-lite";
import { FormFieldSelect, FormFieldText } from "../../../Components";
import { addGateway, editGateway, removeGateway } from "../../../Managers/GatewayService";
import { AppState, setSelectedLocation, showAppModal, showSnackbar } from "../../../AppState";
import { useLocations } from "../../../Managers/API";
import { IGateway } from "../../../Managers/Types";
import { useTranslation } from "react-i18next";
import { Modal } from "../../../Components/Modal";

interface IEditGatewayProps {
  gateway: IGateway | null;
  mode: "new" | "edit" | "delete" | "finalize_replace" | "online" | "offline";
  setGateway?: (gateway: IGateway | null) => void;
}

const validationSchemaNew = yup.object({
  serial_number: yup.string().required().min(8).max(12),
  LocationId: yup.string().notOneOf(["-1"]).required(),
});

const validationSchemaFinalize = yup.object({
  replacement_serial_number: yup.string().required().min(8).max(12),
  replaceAll: yup.boolean(),
});

const validationSchemaOther = yup.object({
  name: yup.string().required(),
  LocationId: yup.string().notOneOf(["-1"]).required(),
});

export const EditGatewayModal: React.FC<IEditGatewayProps> = observer(({ gateway, mode, setGateway }) => {
  const locations = useLocations();
  const { t } = useTranslation(["dashboard", "common"]);

  const handleAddGateway = (values: IGateway) => {
    if (gateway == null) {
      const gateway: IGateway = {
        _id: 0,
        name: "",
        is_active: true,
        is_online: true,
        is_updating: false,
        last_report_time: "",
        info: null,
        createdAt: "",
        updatedAt: "",
        AccountId: 0,
        Devices: { count: 0, rows: [] },
        Firmwares: [],
        serial_number: values.serial_number,
        LocationId: values.LocationId,
      };

      return addGateway(gateway)
        .then((r) => {
          showSnackbar(t("dashboard:edit_gateway_modal.create_success"), "success");
          if (AppState.selectedLocation) {
            setSelectedLocation(AppState.selectedLocation);
          }
          console.log("Update result", r);
        })
        .catch((e) => {
          showSnackbar(t("dashboard:edit_gateway_modal.create_error"), "error");
          console.log("Update error", e);
        })
        .finally(() => showAppModal(null));
    }
  };

  const updateSettings = (e: React.MouseEvent, name: string, LocationId: number) => {
    e.preventDefault();
    if (gateway) {
      return editGateway({ ...gateway, name, LocationId })
        .then((r) => {
          showSnackbar(t("dashboard:edit_gateway_modal.update_success"), "success");
          setGateway && setGateway({ ...gateway, name, LocationId });
          console.log("Update result", r);
        })
        .catch((e) => {
          showSnackbar(t("dashboard:edit_gateway_modal.update_error"), "error");
          console.log("Update error", e);
        })
        .finally(() => showAppModal(null));
    }
  };

  const toggle = (e: React.MouseEvent) => {
    e.preventDefault();
    const is_online = mode === "online";
    if (gateway) {
      return editGateway({ ...gateway, is_online })
        .then((r) => {
          showSnackbar(t("dashboard:edit_gateway_modal.toggle_success", { context: mode }), "success");
          setGateway && setGateway({ ...gateway, is_online });
          console.log("Toggle result", r);
        })
        .catch((e) => {
          showSnackbar(t("dashboard:edit_gateway_modal.toggle_error", { context: mode }), "error");
          console.log("Toggle error", e);
        })
        .finally(() => showAppModal(null));
    }
  };

  const handleRemove = (e: any) => {
    e.preventDefault();
    if (gateway) {
      return removeGateway(gateway)
        .then((r) => {
          showSnackbar(t("dashboard:edit_gateway_modal.remove_success"), "success");
          setGateway && setGateway(null);
          console.log("Remove result", r);
        })
        .catch((e) => {
          showSnackbar(t("dashboard:edit_gateway_modal.remove_error"), "error");
          console.log("Remove error", e);
        })
        .finally(() => showAppModal(null));
    }
  };

  const validationSchema =
    mode === "new" ? validationSchemaNew : mode === "finalize_replace" ? validationSchemaFinalize : validationSchemaOther;

  const initialValues = {
    name: gateway?.name || "",
    serial_number: "",
    LocationId: AppState.selectedLocation?._id || 0,
  } as IGateway;

  const locationOptions = (locations.data || []).map((location) => ({ value: location._id, label: location.name }));
  locationOptions.unshift({ value: -1, label: `-- ${t("common:select")} --` });

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleAddGateway}>
      {({ values, isSubmitting, isValid, submitForm }) => {
        return (
          <Modal
            className="modal-sm"
            title={t(`dashboard:edit_gateway_modal.title_${mode}`)}
            buttons={
              <>
                <button type="button" className="btn btn-info" disabled={isSubmitting} onClick={() => showAppModal(null)}>
                  {t("common:cancel")}
                </button>

                {mode === "delete" ? (
                  <button type="button" className="btn btn-primary" disabled={isSubmitting} onClick={handleRemove}>
                    {t("dashboard:edit_gateway_modal.remove_button")}
                  </button>
                ) : null}

                {mode === "offline" || mode === "online" ? (
                  <button type="button" className="btn btn-primary" disabled={isSubmitting} onClick={toggle}>
                    {t(`dashboard:edit_gateway_modal.toggle_${mode}_button`)}
                  </button>
                ) : null}

                {mode === "new" ? (
                  <button type="button" className="btn btn-primary" disabled={isSubmitting || !isValid} onClick={submitForm}>
                    {isSubmitting ? <i className="fa fa-circle-o-notch fa-spin" /> : <></>}
                    <span>{t("dashboard:edit_gateway_modal.add")}</span>
                  </button>
                ) : null}

                {mode === "edit" ? (
                  <button
                    type="button"
                    className="btn btn-primary"
                    disabled={isSubmitting || !isValid}
                    onClick={(e) => updateSettings(e, values.name, values.LocationId)}>
                    {isSubmitting ? <i className="fa fa-circle-o-notch fa-spin" /> : <></>}
                    <span>{t("common:save_changes")}</span>
                  </button>
                ) : null}
              </>
            }>
            {mode === "new" ? (
              <FormFieldText name="serial_number" label={t("dashboard:edit_gateway_modal.serial_number")} placeholder="12304567" required />
            ) : null}

            {mode === "edit" ? (
              <>
                <FormFieldText
                  maxlength={32}
                  name="name"
                  label={t("dashboard:edit_gateway_modal.name")}
                  placeholder={t("dashboard:edit_gateway_modal.name_placeholder")}
                  required
                />
                <FormFieldSelect
                  style={{ margin: 0 }}
                  className="input-filter-holder"
                  menuItemClass="dark"
                  inputClassName="dark"
                  options={locationOptions}
                  name="LocationId"
                  label={t("dashboard:edit_gateway_modal.location")}
                />
              </>
            ) : null}

            {mode === "delete" ? <div>{t("dashboard:edit_gateway_modal.remove")}</div> : <></>}
            {mode === "offline" ? <div>{t("dashboard:edit_gateway_modal.toggle_offline")}</div> : <></>}
            {mode === "online" ? <div>{t("dashboard:edit_gateway_modal.toggle_online")}</div> : <></>}
            {mode === "edit" && gateway && values.LocationId !== gateway.LocationId ? (
              <p className="u-text-error">{t("dashboard:edit_gateway_modal.change_gateway_location_warning")}</p>
            ) : null}
          </Modal>
        );
      }}
    </Formik>
  );
});
