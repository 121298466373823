import { AppState } from "../AppState";
import { IAlertConfig } from "./Alert.model";
import * as UnitsService from "./UnitsService";
import * as MeasurementService from "./MeasurementService";
import { IAlert } from "./Types";
import { t } from "i18next";

export const transform = (value: IAlertConfig | IAlert | null): string => {
  if (!value) {
    // TODO: Discuss. I think Angular would have ended up blank
    return t("common:select_options.not_transmitting");
  }

  let condition: string = "";

  if (value.is_notify_on_non_transmitting) {
    condition = t("common:select_options.not_transmitting");
  } else {
    const Sensor_type = AppState.sensorTypes.find((type) => type._id === value?.SensorTypeId || 0);
    if (!Sensor_type) return "";

    if (Sensor_type.type === "RANGE") {
      let unit = UnitsService.transform(value.selected_units, []);

      if (value.min !== null) {
        let displayMin = MeasurementService.convertTemperature(+value.min, "celcius", value.selected_units);
        condition += t("common:value") + " < " + displayMin + " " + unit;
      }

      if (value.max !== null && value.min !== null) {
        condition += ` ${t("common:or")} `;
      }

      if (value.max !== null) {
        let displayMax = MeasurementService.convertTemperature(+value.max, "celcius", value.selected_units);
        condition += t("common:value") + " > " + displayMax + " " + unit;
      }
    } else if (Sensor_type.type === "BOOLEAN") {
      if (value.max !== null) {
        condition += Sensor_type.ranges[Sensor_type.units[0]][+value.max];
      } else if (value.min !== null) {
        condition += Sensor_type.ranges[Sensor_type.units[0]][+value.min];
      }
    }
  }

  return condition;
};

export const alertConditionTransform = (value: IAlertConfig | IAlert | null) => transform(value);
