import React from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

export const LinkDeviceToGateway: React.FC = () => {
  const { t } = useTranslation("dashboard");
  return (
    <div className={classNames("dashboard-content setup-container main-panel link-device-container")}>
      <h1 className="setup-title">{t("dashboard:gateway_link.up_and_running")}</h1>
      <div className="setup-instruction">
        <p>{t("dashboard:gateway_link.link_device")}:</p>
        <ul>
          <li>{t("dashboard:gateway_link.place_batteries")}</li>
          <li>{t("dashboard:gateway_link.device_power_up_info")}</li>
        </ul>
      </div>
    </div>
  );
};
