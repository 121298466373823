import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import HttpBackend, { HttpBackendOptions, RequestResponse } from "i18next-http-backend";
import { Endpoint } from "./Managers/API";

// In IntelliJ the best way to handle the react-i18next lib is the i18n Ally plugin, which finds
// most of the missing translations in a file and also enables both way navigation between namespace and file

i18next
  .use(HttpBackend)
  .use(initReactI18next)
  .init<HttpBackendOptions>({
    fallbackLng: "en",
    ns: ["common"],
    backend: {
      loadPath: "{{lng}}|{{ns}}",
      request: async (_options, url, _payload, callback) => {
        try {
          const [lng, ns] = url.split("|");
          Endpoint.get(`api/translations/${lng}/${ns}`)
            .then((response) => callback(null, response))
            .catch((error) => console.log(error.response));
        } catch (e) {
          callback(null, { status: 500 } as RequestResponse);
        }
      },
    },
  });
