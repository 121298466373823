import Moment from "moment";
import * as Measurements from "./MeasurementService";
import { IDevice, IDeviceBond, IDeviceTypeGroup, ISensor } from "./Types";
import * as Units from "./UnitsService";
import { Endpoint, invalidateQuery } from "./API";
import { useQuery } from "react-query";
import moment from "moment/moment";

const API_PATH = "api/devices";
const QK_DEVICES = ["DEVICES"];
const QK_SENSORS = ["SENSORS"];
const QK_DEVICE_TYPE_GROUPS = ["DEVICE_TYPE_GROUPS"];
const QK_DEVICE_BONDS = ["DEVICE_BONDS"];

// NOTE: To understand react-query a bit see https://tkdodo.eu/blog/effective-react-query-keys.
// It uses 'query keys' to identify queries to clear, eliminating most data store caching work.
// If you use an array as a query key e.g. ['DEVICES', 3] you can selectively update or invalidate
// queries via the hierarchy. So you can "get" ['DEVICES'] and it will cache the whole list. Then
// you can "get" ['DEVICES', 3] and it will immediately return device 3 while revalidating that
// piece of data if needed. You can then deliberately invalidate ['DEVICES', 3] to mark just that
// one device stale, or ['DEVICES'] to re-query them all.

export const getDevices = (sensors: boolean, alerts: boolean) =>
  Endpoint.get<IDevice[]>(`${API_PATH}?sensors=${sensors}&alerts=${alerts}`).then((r) => r.data);

export const useDevices = (sensors: boolean, alerts: boolean) =>
  useQuery([...QK_DEVICES, { sensors, alerts }], () => getDevices(sensors, alerts), { refetchInterval: 60000 });

export const useDevicesWithProps = (props: Array<keyof IDevice>, sensors: boolean = false) =>
  useQuery([...QK_DEVICES, props, sensors], () =>
    Endpoint.get<IDevice[]>(`${API_PATH}?props=${props}&sensors=${sensors}`).then((r) => r.data),
  );

export const getDevice = (id: number) => Endpoint.get<IDevice>(`${API_PATH}/${id}?sensors=true&alerts=true`).then((r) => r.data);

export const useDevice = (id: number) => useQuery([...QK_DEVICES, id], () => getDevice(id));

export const createDevice = (device: IDevice) =>
  Endpoint.post(`${API_PATH}`, device).then((r) => {
    invalidateQuery(QK_DEVICES);
    return r.data;
  });

export const updateDevice = (device: IDevice) =>
  Endpoint.put(`${API_PATH}/${device._id}`, device).then((r) => {
    invalidateQuery(QK_DEVICES);
    return r.data;
  });

export const updateDevices = (devices: IDevice[]) =>
  Endpoint.put(`${API_PATH}`, devices).then((r) => {
    invalidateQuery(QK_DEVICES);
    return r.data;
  });

export const removeDevice = (device: IDevice) =>
  Endpoint.delete(`${API_PATH}/${device._id}`).then((r) => {
    invalidateQuery(QK_DEVICES);
    return r.data;
  });

export const updateSensor = (device: IDevice, sensor: ISensor) =>
  Endpoint.put(`${API_PATH}/${device._id}/sensors/${sensor._id}`, sensor).then((r) => {
    invalidateQuery(QK_DEVICES);
    invalidateQuery(QK_SENSORS);
    return r.data;
  });

export const getDeviceTypeGroups = () =>
  Endpoint.get<IDeviceTypeGroup[]>("api/device_type_groups").then((r) => {
    invalidateQuery(QK_DEVICES);
    invalidateQuery(QK_DEVICE_TYPE_GROUPS);
    return r.data;
  });

export const getDeviceBonds = (deviceId: number) =>
  Endpoint.get<IDeviceBond[]>(`/api/gateway_devices/device/${deviceId}`).then((r) => {
    invalidateQuery(QK_DEVICE_BONDS);
    return r.data;
  });

export const deleteDeviceBond = (deviceBond: IDeviceBond) =>
  Endpoint.delete("/api/gateway_devices/", { data: deviceBond }).then((r) => {
    invalidateQuery(QK_DEVICE_BONDS);
    return r.data;
  });

// TODO: Invalidate this query when creating or editing groups
export const useDeviceTypeGroups = () => useQuery("QK_DEVICE_TYPE_GROUPS", getDeviceTypeGroups);

export const getSensorData = (sensor: ISensor, startDate: any, endDate: any) => {
  let min, max;
  let data: any[] = [];
  let sensorType = sensor.Sensor_type;

  let range = sensorType.ranges as any;

  range = range[Object.keys(range)[0]];
  min = range[0];
  max = range[1];

  let value;

  startDate = Moment(startDate);
  endDate = Moment(endDate);

  if (sensorType && sensorType.type === "RANGE") {
    while (startDate < endDate) {
      value = Math.random() * (max - min) + min;
      data.push({
        // * This used to be "updatedAt"
        createdAt: startDate.toISOString(),
        value,
      });

      startDate.add(5, "minutes");
    }
  }

  return data;
};

export interface ISensorDatapoint {
  _id: string; // "1"
  SensorId: number; // 2875
  createdAt: string; // "2022-03-02T02:50:00.000Z"
  value: {
    value: number; // 2.78
  };
}

export interface ISensorDatapointStaleHistory extends ISensorDatapoint {
  updatedAt: string;
  is_finished: boolean;
}

export const getDeviceSensorData = (sensor: ISensor, startDate: string, endDate: string) => {
  return Promise.all([
    Endpoint.get<ISensorDatapoint[]>(
      `api/sensors/${sensor._id}/data?startDate=${startDate}&endDate=${endDate}&deviceId=${sensor.DeviceId}`,
    ),
    Endpoint.get<ISensorDatapointStaleHistory[]>(
      `api/sensor_stale_histories/sensorStaleHistory/${sensor._id}?startDate=${startDate}&endDate=${endDate}`,
    ),
  ]).then((r) => {


    for(let stalePointIdx in r[1].data) {
      let stalePoint = r[1].data[stalePointIdx];
      let readingInTheStale = r[0].data.filter(p => {
        return (moment(p.createdAt).isAfter(moment(stalePoint.createdAt)) && moment(p.createdAt).isBefore(moment(stalePoint.updatedAt)))
      });
      if (readingInTheStale.length) {
        stalePoint.updatedAt = readingInTheStale[0].createdAt;
        r[1].data[stalePointIdx] = stalePoint;
      }
    }

    let filteredData = r[0].data.filter(
        (point) =>
            !r[1].data.some(
                (staleHistory) =>
                    new Date(staleHistory.createdAt) <= new Date(point.createdAt) && new Date(point.createdAt) <= new Date(staleHistory.updatedAt),
            ),
    );
    // if sensor is still not sending data
    const stillStale = r[1].data.find((sh) => !sh.is_finished);
    if (!!stillStale) filteredData = filteredData.filter((point) => new Date(point.createdAt) < new Date(stillStale.createdAt));
    return filteredData.concat(r[1].data.map((x) => ({ createdAt: x.createdAt } as ISensorDatapoint)));
  });
};

export interface ISensorReading {
  value: string;
  createdAt: Date;
  unit: string;
}

export const transformSensorData = (data: any[], sensor: any): ISensorReading[] => {
  if (!sensor.Sensor_type) {
    return data;
  }
  let unit = Units.transform(sensor.default_unit, [sensor.default_unit, sensor.Sensor_type.type]);

  // hard override of the unit if it's not the default
  if (sensor.default_unit !== sensor.display_unit) {
    unit = sensor.display_unit;
  }

  return data.map((sensorData) => {
    return {
      value: Measurements.transform(sensorData.value.value, [sensor.default_unit]),
      // * This used to be "updatedAt"
      createdAt: new Date(sensorData.createdAt),
      unit,
    };
  });
};

export const calcBatteryStrength = (battery: number, base?: number): number => {
  let maxNum = 3.6;
  let minNum = 2.5;

  base = base ? base : 4;
  let percentage = Math.round(((battery - minNum) / (maxNum - minNum)) * base);

  if (!percentage || percentage < 0) {
    percentage = 0;
  }

  return Math.min((percentage * 100) / base, 100);
};

export const calcSignalStrength = (strength: number, base?: number): number => {
  let maxNum = -10;
  let minNum = -95;
  base = base ? base : 5;
  let percentage = Math.round(((strength - minNum) / (maxNum - minNum)) * base);

  if (!percentage || percentage < 0) {
    percentage = 0;
  }

  return Math.min((percentage * 100) / base, 100);
};
