export enum DeviceSort {
  Name = "name",
  Updated = "updatedAt",
}

export enum OrderBy {
  ASC = "ASC",
  DESC = "DESC",
}

export interface ISort {
  prop: string;
  dir: OrderBy;
}

export interface IMenuSort extends ISort {
  label: string;
}
