import { observer } from "mobx-react-lite";
import { IMenuSort, ISort, OrderBy } from "../Enums/SortingTypes";
import classNames from "classnames";
import React, { useState } from "react";
import ClickAwayListener from "react-click-away-listener";

export const SortMenu: React.FC<{
  className?: string;
  iconClassName?: string;
  options?: IMenuSort[];
  sort: ISort;
  sortChange: (sort: ISort) => void;
}> = observer(({ iconClassName, className, options, sort, sortChange }) => {
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <ClickAwayListener onClickAway={() => setMenuOpen(false)}>
      <button
        className={classNames("btn btn-icon u-text-teal bapi-header-nav-item dropdown u-text-teal", { open: menuOpen }, className)}
        onClick={() =>
          options
            ? setMenuOpen(!menuOpen)
            : sortChange({
                dir: sort.dir === OrderBy.ASC ? OrderBy.DESC : OrderBy.ASC,
                prop: sort.prop,
              })
        }>
        <i
          className={classNames("fa fa-sort-amount-asc", iconClassName)}
          style={{ transform: sort.dir === OrderBy.DESC ? "scale(1,-1)" : "unset" }}
        />
        <section role="menu" className="dropdown-menu dropdown-menu-right">
          <ul style={{ paddingLeft: 0 }}>
            {options?.map((item, index) => (
              <li
                key={item.prop + item.dir}
                role="menuitem"
                className={classNames("dropdown-menu-item", { separated: index !== 0 })}
                onClick={() => sortChange(item)}>
                {item.label}
              </li>
            ))}
          </ul>
        </section>
      </button>
    </ClickAwayListener>
  );
});
