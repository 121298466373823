import React from 'react';
import {observer} from 'mobx-react-lite';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './DatepickerDropdown.scss';
import {format} from 'date-fns';

// TODO: The old interface had two dropdowns. I went with the one that seemed more common and adjusted the forms to all use it. If we
// want styling adjustments it would be better to have style overrides for certain cases than two separate components.

// Seehttps://date-fns.org/v2.28.0/docs/format and https://github.com/date-fns/date-fns/blob/master/docs/unicodeTokens.md
// for details on formatting

interface IDatepickerDropdownProps {
  name?: string;
  minDate?: Date;
  maxDate?: Date;
  isClearable?: boolean;
  selectedDate?: Date;
  dateDisabled?: Date[];
  hasTimeSelector?: boolean;
  onSelectDate: (date: Date) => void;
  onSelectionDone?: () => void;
}

export const DatepickerDropdown: React.FC<IDatepickerDropdownProps> = observer(
  ({
    name,
    minDate,
    maxDate = new Date(),
    selectedDate,
    dateDisabled,
    isClearable,
    hasTimeSelector = false,
    onSelectDate,
    onSelectionDone,
  }) => {
    return (
      <DatePicker
        name={name}
        isClearable={isClearable}
        selected={selectedDate}
        onChange={onSelectDate}
        dateFormat={'MM/dd/yyyy'}
        customInput={
          <div className="input-holder datepicker mod-block">
            <input
              id={name}
              type="text"
              className="input input-default"
              placeholder="mm/dd/yyyy"
              readOnly
              value={selectedDate ? format(selectedDate, 'MM/dd/yyyy') : ''}
            />
            <div className="input-holder-icon" />
          </div>
        }
      />
    );
  },
);
