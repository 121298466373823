import { IDevice, ILocation, ILocationDetail, IUser } from "./Types";
import { Endpoint, invalidateQuery } from "./API";
import { useQuery } from "react-query";
import { ISort } from "../Enums/SortingTypes";

const API_PATH = "api/locations";
export const QK_LOCATIONS = ["LOCATIONS"];
const QK_LOCATION_DEVICES = QK_LOCATIONS[0] + "_DEVICES";

export const addLocation = (location: ILocation | ILocationDetail) =>
  Endpoint.post(`${API_PATH}`, location).then((r) => {
    invalidateQuery(QK_LOCATIONS);
    return r.data;
  });

export const deleteLocation = (location: ILocation | ILocationDetail) =>
  Endpoint.delete(`${API_PATH}/${location._id}`).then((r) => {
    invalidateQuery(QK_LOCATIONS);
    return r.data;
  });

export const updateLocation = (location: ILocation | ILocationDetail) =>
  Endpoint.put(`${API_PATH}/${location._id}`, location).then((r) => {
    invalidateQuery(QK_LOCATIONS);
    return r.data;
  });

export const updateLocationUsers = (location: ILocationDetail) =>
  Endpoint.put(`${API_PATH}/${location._id}/users`, location.Users).then((r) => {
    invalidateQuery(QK_LOCATIONS);
    return r.data;
  });

export const getAllLocations = () =>
  Endpoint.get<ILocationDetail[]>(`${API_PATH}/all`).then((r) => {
    invalidateQuery(QK_LOCATIONS);
    return r.data;
  });

export const getLocations = () =>
  Endpoint.get<ILocationDetail[]>(`${API_PATH}`).then((r) => {
    invalidateQuery(QK_LOCATIONS);
    return r.data;
  });

export const getLocationsUnderAccount = (accountId: number) =>
  Endpoint.get<ILocationDetail[]>(`${API_PATH}/account/${accountId}`).then((r) => {
    invalidateQuery(QK_LOCATIONS);
    return r.data;
  });

export const getLocation = (location: any) =>
  Endpoint.get<ILocationDetail>(`${API_PATH}/${location._id}`).then((r) => {
    invalidateQuery(QK_LOCATIONS);
    return r.data;
  });

export const getLocationWithUsers = (location: any) =>
  Endpoint.get<ILocation>(`${API_PATH}/${location._id}?users=true`).then((r) => {
    invalidateQuery(QK_LOCATIONS);
    return r.data;
  });

// TODO: This dupes a call in DeviceService. We also nuked a lot of post-processing that we probably have a better way to do now
export const getLocationDeep = (locationId: number) =>
  Endpoint.get<ILocationDetail>(`${API_PATH}/${locationId}?devices=true&device_groups=true&sensors=true&alerts=true`).then((r) => r.data);

export const createLocationSharedUser = (location: ILocation | ILocationDetail, user: IUser) =>
  Endpoint.post(`${API_PATH}/${location._id}/shared_user`, user).then((r) => {
    invalidateQuery(QK_LOCATIONS);
    return r.data;
  });

export const updateLocationSharedUser = (location: ILocation | ILocationDetail, user: IUser) =>
  Endpoint.put(`${API_PATH}/${location._id}/shared_user`, user).then((r) => {
    invalidateQuery(QK_LOCATIONS);
    return r.data;
  });

const getFetchDevicesQuery = (gatewayId?: number, groupId?: number, order?: ISort, page = 0, limit = 10) => {
  let offset = page * limit;
  let url = "";
  if (gatewayId !== undefined) {
    url += `&gatewayId=${gatewayId}`;
  }
  if (groupId) {
    url += `&groupId=${groupId}`;
  }
  if (order) {
    url += `&orderBy=${order.dir}&sort=${order.prop}`;
  }
  url += `&offset=${offset}&limit=${limit}`;
  return url;
};

export const fetchDashboardDevices = async (
  locationId: number,
  gatewayId?: number,
  groupId?: number,
  order?: ISort,
  page = 0,
  limit = 10,
) => {
  const promises = [];

  for (let i = 0; i <= page; i++) {
    const url = `${API_PATH}/${locationId}/fetch?${getFetchDevicesQuery(gatewayId, groupId, order, i, limit)}`;
    promises.push(Endpoint.get<{ count: number; rows: IDevice[] }>(url));
  }

  const data = await Promise.all(promises).then((responses) => responses.map((response) => response.data));
  const rows: IDevice[] = data
    .map((r) => r?.rows)
    .flat()
    .filter((d) => !!d);
  const count = data[0]?.count || 0;

  return { rows, count };
};

export const useDashboardDevices = (locationId: number, gatewayId?: number, groupId?: number, order?: ISort, page = 0, limit = 10) =>
  useQuery(QK_LOCATION_DEVICES + locationId + getFetchDevicesQuery(gatewayId, groupId, order, page, limit), () =>
    fetchDashboardDevices(locationId, gatewayId, groupId, order, page, limit),
  );
