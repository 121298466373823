import { Endpoint } from "./API";
import {
  CancellationDetails,
  ILinkResponse,
  IMessageResponse,
  ISelectedPlanOption,
  ISubscriptionModel,
  IUserCreatingFromTemporary,
} from "./Types";
import { useQuery } from "react-query";

export const getAccountSubscription = (accountId: number) =>
  Endpoint.get<ISubscriptionModel>(`api/subscription/${accountId}`).then((r) => r.data);
export const cancelSubscription = (accountId: undefined | string, comment: undefined | string, feedback: CancellationDetails.Feedback) =>
  Endpoint.post<IMessageResponse>(`api/subscription/${accountId}`, { comment, feedback }).then((r) => r.data);
export const resumeCancelledSubscription = (accountId: undefined | string) =>
  Endpoint.post<IMessageResponse>(`api/subscription/resume/${accountId}`).then((r) => r.data);
export const getPaymentLink = () => Endpoint.get<ILinkResponse>(`api/subscription/paymentLink`).then((r) => r.data);
export const getFirstPaymentLink = (
  user: IUserCreatingFromTemporary,
  selectedPlanOptions: ISelectedPlanOption[],
  redirectToDashboard?: boolean,
) =>
  Endpoint.post<string>(`api/subscription/firstStripePayment`, {
    user,
    selectedPlans: selectedPlanOptions,
    redirectToDashboard,
  }).then((r) => r.data);
export const updateSubscription = (subscriptionId: undefined | string, selectedPlanOptions: ISelectedPlanOption[]) =>
  Endpoint.put<IMessageResponse>(`api/subscription/${subscriptionId}`, { selectedPlanOptions }).then((r) => r.data);

export const useAccountSubscriptionById = (accountId: number) =>
  useQuery(["SUBSCRIPTIONINFO", accountId], () => (accountId ? getAccountSubscription(accountId) : null));
