import React, { useEffect, useRef, useState } from "react";
import { createSearchParams, useBlocker, useNavigate, useSearchParams } from "react-router-dom";
import { RichEditor } from "../../Components/RichEditor";
import { CountrySelect, Page, Tab } from "../../Components";
import "./PolicyWindow.scss";
import { countries, createLegal, getDefaultCountry, ICountry, updateLegal, useLegal } from "../../Managers";
import { Button, Tabs } from "@mui/material";
import { URLS } from "../URLS";
import { LegalType } from "../../Enums";
import { showAppModal, showSnackbar } from "../../AppState";
import { ConfirmModal } from "../../Modals";
import { useTranslation } from "react-i18next";

export const LegalManagement: React.FC<{ type?: LegalType }> = (props) => {
  const [type, setType] = useState<LegalType>(props.type ?? "terms");
  const [searchParams, setSearchParams] = useSearchParams();
  const [lastChange, setLastChange] = useState<string>();
  const [selectedCountry, setSelectedCountry] = useState<ICountry>(
    searchParams.get("country")
      ? countries.find((country) => country.code === searchParams.get("country")) ?? getDefaultCountry()
      : getDefaultCountry(),
  );

  const { t } = useTranslation(["common", "legal"]);

  const quillRef = useRef();

  const navigate = useNavigate();

  const legal = useLegal(selectedCountry.code, type as LegalType);

  useEffect(() => {
    const searchType = searchParams.get("type");
    if (searchType && ["terms", "privacy", "supplemental_policy"].includes(searchType)) {
      setType(searchType as LegalType);
    }
  }, [searchParams]);

  let blocker = useBlocker(({ currentLocation, nextLocation }) => {
    const locationIsChanging = currentLocation.pathname !== nextLocation.pathname;

    return hasUnsavedChanges() && locationIsChanging;
  });

  useEffect(() => {
    if (blocker?.state === "blocked") {
      blockNavigation();
    }
  }, [blocker?.state]);

  const changeLegalPage = (_event: React.SyntheticEvent, type: LegalType) => {
    if (type === searchParams.get("type")) {
      return;
    }

    const search = createSearchParams({ country: selectedCountry.code, type }).toString();
    switch (type) {
      case "privacy":
        return navigate({ pathname: URLS.LegalManagement, search });
      case "supplemental_policy":
        return navigate({ pathname: URLS.LegalManagement, search });
      case "terms":
        return navigate({ pathname: URLS.LegalManagement, search });
    }
  };

  const savePolicyItem = () => {
    if (lastChange && !legal.data?._id) {
      createLegal({ country: selectedCountry.code, type, content: lastChange })
        .then(() => showSnackbar(t("legal:save_success", { context: props.type }), "success"))
        .catch(() => showSnackbar(t("legal:save_error", { context: props.type }), "error"));
    } else if (lastChange) {
      updateLegal({ ...legal.data, country: selectedCountry.code, type, content: lastChange })
        .then(() => showSnackbar(t("legal:save_success", { context: props.type }), "success"))
        .catch(() => showSnackbar(t("legal:save_error", { context: props.type }), "error"));
    }
  };

  const changeCountry = (c: ICountry) => {
    if (hasUnsavedChanges()) {
      blockNavigation(() => {
        setSelectedCountry(c);
        setSearchParams({ country: c.code });
      });
    } else {
      setSelectedCountry(c);
      setSearchParams({ country: c.code });
    }
  };

  const blockNavigation = (callback?: () => void) => {
    showAppModal(
      <ConfirmModal
        header={t("common:unsaved_changes")}
        onCancel={() => {
          showAppModal(null);
          blocker.reset && blocker.reset();
        }}
        onConfirm={() => {
          callback && callback();
          showAppModal(null);
          return !!blocker.proceed && blocker?.proceed();
        }}>
        {t("legal:unsaved_changes", { context: props.type })}
      </ConfirmModal>,
    );
  };

  const hasUnsavedChanges = () => {
    const isEmptyEditor = lastChange === "<p></p>";
    const contentsAreDifferent = lastChange !== legal.data?.content;
    return isEmptyEditor ? legal.data?.content !== undefined : contentsAreDifferent;
  };

  return (
    <Page>
      <main className="bapi-main policy-window">
        <Tabs value={type} onChange={changeLegalPage}>
          <Tab value="terms" label={t("legal:terms")}></Tab>
          <Tab value="privacy" label={t("legal:privacy")}></Tab>
          <Tab value="supplemental_policy" label={t("legal:supplemental_policy")}></Tab>
        </Tabs>
        <div className="main-panel">
          <div className="country-select input-filter-holder">
            <label className="input-label u-display-block">{t("legal:country")}</label>
            <CountrySelect value={selectedCountry} selectedCountryLabel="name" formikControl={false} onChange={changeCountry} />
          </div>
          <RichEditor
            defaultValue={legal.data?.content}
            className="text-editor"
            ref={quillRef}
            readonly={false}
            onTextChange={setLastChange}
          />
          <div className="button-row">
            <Button disabled={lastChange === "<p></p>"} variant="contained" onClick={() => savePolicyItem()}>
              {t("common:save")}
            </Button>
          </div>
        </div>
      </main>
    </Page>
  );
};
