import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { IGateway, IUser } from "../../Managers/Types";
import { useUsers } from "../../Managers/UsersService";
import "./AddRecipientModal.scss";
import { IAlertConfig, IUserAlert } from "../../Managers/Alert.model";
import { showAppModal, showSnackbar } from "../../AppState";
import { XIcon } from "@heroicons/react/solid";
import { addRecipients } from "../../Managers/AlertService";
import { Modal } from "../../Components/Modal";
import { useTranslation } from "react-i18next";
import { mySearch } from "../../Managers";

interface IAddRecipientModal {
  alert: IAlertConfig;
  onAdd: () => void;
}

export const AddRecipientModal: React.FC<IAddRecipientModal> = observer(({ alert, onAdd }) => {
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [selectedUsers, setSelectedUsers] = useState<number[]>([]);
  const [searchString_Recipient, setSearchString_Recipient] = useState("");

  const users = useUsers();

  const { t } = useTranslation(["alerts", "common"]);

  useEffect(() => {
    if (users.data?.length && alert.Users) {
      setSelectedUsers(alert.Users.map((x) => x._id));
    }
  }, [alert.Users, users.data?.length]);

  const toggleUser = (_e: any, user: IUser) => {
    if (selectedUsers.includes(user._id)) {
      setSelectedUsers([...selectedUsers.filter((id) => id !== user._id)]);
    } else {
      setSelectedUsers([...selectedUsers, user._id]);
    }
  };

  const addUsers = () => {
    setIsSaving(true);

    const newAlertUsers: IUserAlert[] = [];
    const existingAlertUsers = alert.Users?.map((x) => x.User_alert);

    selectedUsers.forEach((u) => {
      if (!existingAlertUsers?.map((x) => x._id).includes(u)) {
        const alertUser: IUserAlert = {
          _id: 0,
          email_alert: false,
          sms_alert: false,
          phone_alert: false,
          UserId: u,
          AlertId: alert._id,
          createdAt: "",
          updatedAt: "",
        };

        newAlertUsers.push(alertUser);
      }
    });

    addRecipients(alert, newAlertUsers)
      .then(() => {
        showSnackbar(t("alerts:add_recipient.add_recipient_success", { count: newAlertUsers.length }), "success");
        setIsSaving(false);
        onAdd();
        showAppModal(null);
      })
      .catch((e) => {
        setIsSaving(false);
        showSnackbar(t("alerts:add_recipient.add_recipient_error", { count: newAlertUsers.length }), "error");
        console.error(e);
      });
  };

  return (
    <Modal
      title={
        <div style={{ display: "flex", flexDirection: "row" }}>
          <h4 className="modal-title">{t("alerts:add_recipient.title")}</h4>
          <div style={{ flex: 1 }} />
          <button onClick={() => showAppModal(null)} style={{ background: "none", color: "white", border: "none" }}>
            <XIcon style={{ width: "18px", marginBottom: "-4px" }} /> {t("common:close")}
          </button>
        </div>
      }
      buttons={
        <>
          <button className="btn btn-info" disabled={isSaving} onClick={() => showAppModal(null)}>
            {t("common:cancel")}
          </button>
          <button className={classNames("btn btn-primary", { disabled: isSaving })} onClick={addUsers}>
            {isSaving ? <i className="fa fa-circle-o-notch fa-spin" /> : <></>}
            {t("alerts:add_recipient.add_to_alert")}
          </button>
        </>
      }>
      <>
        <div className="select-group-item header mobile-header-row">
          <div className="u-flex-center">
            <div className="input-holder u-mobile-only mobile-search-input-holder">
              <input
                type="text"
                className="input input-default"
                placeholder={t("alerts:add_recipient.find_by_name")}
                onChange={(e) => setSearchString_Recipient(e.target.value)}
                disabled={!users.data?.length}
                value={searchString_Recipient}
              />
              <i className="fa fa-search input-holder-icon" />
            </div>
          </div>

          <div className="row u-full-width">
            <div className="col-xs-5">{t("alerts:add_recipient.user_name")}</div>
          </div>
        </div>

        <ul className="select-group">
          {mySearch<IUser>(users.data ?? [], searchString_Recipient).map((user) => (
            <li className="select-group-item">
              <div className="row u-full-width">
                <div className="col-xs-12 col-lg-5 alert-name">
                  <label className={classNames("checkbox", { isSaving })} title={user.email}>
                    <input
                      type="checkbox"
                      className="input-checkbox"
                      name="alerts"
                      id={"" + user._id}
                      onChange={(e) => toggleUser(e, user)}
                      checked={selectedUsers.includes(user._id)}
                      disabled={isSaving}
                    />
                    <span />
                    {`${user.first_name} ${user.last_name}`}
                  </label>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </>
    </Modal>
  );
});
