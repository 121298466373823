import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import ClickAwayListener from "react-click-away-listener";
import { DevicePropertiesModal } from "./Modals/DevicePropertiesModal";
import {
  AppState,
  deselectAllDevices,
  getSelectedDevices,
  setDevices,
  setSelectedDevices as setGloballySelectedDevices,
  showAppModal,
  showSnackbar,
} from "../../AppState";
import { DropdownMenu, MenuButton, PermissionBlocker, SectionTitle } from "../../Components";
import { deleteDeviceAlerts } from "../../Managers/DeviceAlertService";
import { updateDevices } from "../../Managers/DeviceService";
import { AddToGroupModal } from "./Modals/AddToGroupModal";
import { AddAlertModal } from "./Modals/AddAlertModal";
import { ConfirmModal } from "../../Modals";
import { IAlert, IDevice } from "../../Managers/Types";
import { PermissionEnum } from "../../Enums";
import { useTranslation } from "react-i18next";

interface IActionMenuProps {
  onClose: () => void;
  open: boolean;
  refresh: () => void;
}

export const ActionMenu: React.FC<IActionMenuProps> = observer(({ onClose, open, refresh }) => {
  const [selectedDevices, setSelectedDevices] = useState<IDevice[]>([]);
  const [selectedAlerts, setSelectedAlerts] = useState<IAlert[]>([]);
  const [currentSubmenu, setCurrentSubmenu] = useState<"measurement" | "status">();

  const { t } = useTranslation("dashboard");

  useEffect(() => {
    const { devices, alerts } = getSelectedDevices();
    setSelectedDevices(devices);
    setSelectedAlerts(alerts);
  }, [AppState.selectedDeviceIds, AppState.selectedDevices]);

  if (!open) {
    return <></>;
  }

  const showAddAlertModal = () => showAppModal(<AddAlertModal deviceId={selectedDevices[0]._id} />);

  const handleRemoveAllAlerts = () =>
    Promise.all(selectedDevices.map((device) => deleteDeviceAlerts(device._id)))
      .then(() => showSnackbar(t("dashboard:action_menu.remove_alerts_success", { count: selectedAlerts.length }), "success"))
      .catch((e) => {
        const errorMessage = t("dashboard:action_menu.remove_alerts_error", { count: selectedAlerts.length });
        showSnackbar(errorMessage, "error");
        console.warn(errorMessage, e);
      })
      .finally(() => {
        setGloballySelectedDevices([]);
        showAppModal(null);
        onClose();
      });

  const showRemoveModal = () =>
    showAppModal(
      <ConfirmModal
        header={t("dashboard:action_menu.remove_alerts", { count: selectedAlerts.length })}
        children={<p>{t("dashboard:action_menu.remove_alerts_confirmation_message", { count: selectedAlerts.length })}</p>}
        confirmText={t("dashboard:action_menu.remove_alerts", { count: selectedAlerts.length })}
        onConfirm={handleRemoveAllAlerts}
      />,
    );

  const handleSetImperial = (imperial: boolean) => {
    const shouldUpdate = selectedDevices.some((d) => (imperial ? !d.is_empirical : d.is_empirical));

    if (!shouldUpdate) {
      return;
    }

    updateDevices(selectedDevices.map((device) => ({ ...device, is_empirical: imperial })))
      .then((r) => {
        const selectedDevices = r as IDevice[];
        setDevices(selectedDevices);
        deselectAllDevices();
        setCurrentSubmenu(undefined);
        onClose();
        refresh();
        showSnackbar(t("dashboard:action_menu.measurement_set_to_success", { context: imperial ? "imperial" : "metric" }), "success");
      })
      .catch((e) => {
        showSnackbar(t("dashboard:action_menu.measurement_set_to_error"), "error");
        console.log("Update device error", e);
      });
  };

  const handleSetOnline = (online: boolean) => {
    const shouldUpdate = selectedDevices.some((d) => (online ? !d.is_online : d.is_online));

    if (!shouldUpdate) {
      return;
    }

    updateDevices(selectedDevices.map((device) => ({ ...device, is_online: online })))
      .then((r) => {
        const selectedDevices = r as IDevice[];
        setDevices(selectedDevices);
        deselectAllDevices();
        setCurrentSubmenu(undefined);
        onClose();
        refresh();

        showSnackbar(t("dashboard:action_menu.status_set_to_success", { context: online ? "online" : "offline" }), "success");
      })
      .catch((e) => {
        showSnackbar(t("dashboard:action_menu.status_set_to_error", { context: online ? "online" : "offline" }), "error");
        console.log("Update device error", e);
      });
  };

  const showDevicePropertiesModal = () => {
    showAppModal(<DevicePropertiesModal device={selectedDevices[0]} refresh={refresh} />);
    onClose();
  };

  const showAddToGroupModal = () => {
    showAppModal(<AddToGroupModal devices={selectedDevices} title={t("dashboard:device.place_in_group")} />);
    onClose();
  };

  const showRemoveFromGroupModal = () => {
    showAppModal(<AddToGroupModal devices={selectedDevices} title={t("dashboard:device.remove_from_group")} remove={true} />);
    onClose();
  };

  return (
    <ClickAwayListener
      onClickAway={() => {
        onClose();
        setCurrentSubmenu(undefined);
      }}>
      <DropdownMenu className={classNames({ open })}>
        <PermissionBlocker permission={PermissionEnum.EDIT_ALERTS}>
          <SectionTitle>{t("dashboard:action_menu.alert_settings")}:</SectionTitle>

          <MenuButton label={t("dashboard:action_menu.add_alert")} onClick={showAddAlertModal} disabled={selectedDevices.length !== 1} />
          {/*    */}
          {/* TODO: For review, the old system did not disable this for any reason. But I think it should be in this case... */}
          <MenuButton
            label={t("dashboard:action_menu.remove_alerts", { count: selectedAlerts.filter((a) => !a.hidden).length })}
            onClick={showRemoveModal}
            disabled={!selectedAlerts.filter((a) => !a.hidden).length}
          />
        </PermissionBlocker>

        <SectionTitle>{t("dashboard:action_menu.device_settings")}:</SectionTitle>

        {/* TODO: This was called "Empirical" in the old system but there's no such thing, I think it should be Imperial? */}
        <div className="dropdown">
          <MenuButton
            className={currentSubmenu === "measurement" ? "active" : ""}
            label={t("dashboard:action_menu.set_measurement")}
            onClick={() => setCurrentSubmenu(currentSubmenu === "measurement" ? undefined : "measurement")}
            disabled={selectedDevices.length < 1}
          />
          <ul className={classNames("dropdown-menu dropdown-menu-left-side", { open: currentSubmenu === "measurement" })}>
            <li role="menuitem" style={{ display: "flex" }}>
              <MenuButton
                selected={selectedDevices.filter((d) => d.is_empirical).length === selectedDevices.length}
                label={t("dashboard:action_menu.imperial")}
                onClick={() => handleSetImperial(true)}
              />
            </li>
            <li role="menuitem" style={{ display: "flex" }}>
              <MenuButton
                selected={selectedDevices.filter((d) => !d.is_empirical).length === selectedDevices.length}
                label={t("dashboard:action_menu.metric")}
                onClick={() => handleSetImperial(false)}
              />
            </li>
          </ul>
        </div>

        <div className="dropdown">
          <MenuButton
            className={currentSubmenu === "status" ? "active" : ""}
            label={t("dashboard:action_menu.set_status")}
            onClick={() => setCurrentSubmenu(currentSubmenu === "status" ? undefined : "status")}
            disabled={selectedDevices.length < 1}
          />
          <ul className={classNames("dropdown-menu dropdown-menu-left-side", { open: currentSubmenu === "status" })}>
            <li role="menuitem" style={{ display: "flex" }}>
              <MenuButton
                selected={selectedDevices.filter((d) => d.is_online).length === selectedDevices.length}
                label={t("dashboard:action_menu.online")}
                onClick={() => handleSetOnline(true)}
              />
            </li>
            <li role="menuitem" style={{ display: "flex" }}>
              <MenuButton
                selected={selectedDevices.filter((d) => !d.is_online).length === selectedDevices.length}
                label={t("dashboard:action_menu.offline")}
                onClick={() => handleSetOnline(false)}
              />
            </li>
          </ul>
        </div>

        <PermissionBlocker permission={PermissionEnum.UPDATE_DEVICE_SETTINGS}>
          <>
            <SectionTitle>{t("dashboard:action_menu.device_actions")}:</SectionTitle>

            <MenuButton
              label={t("dashboard:action_menu.edit_properties")}
              onClick={showDevicePropertiesModal}
              disabled={selectedDevices.length !== 1}
            />
            {selectedDevices.some((d) => d.DeviceGroupId > 0) ? (
              <MenuButton
                label={t("dashboard:action_menu.remove_group")}
                onClick={showRemoveFromGroupModal}
                disabled={selectedDevices.length < 1}
              />
            ) : (
              <MenuButton
                label={t("dashboard:action_menu.add_to_group")}
                onClick={showAddToGroupModal}
                disabled={selectedDevices.length < 1}
              />
            )}
          </>
        </PermissionBlocker>
      </DropdownMenu>
    </ClickAwayListener>
  );
});
