import React from "react";
import { Button } from "@mui/material";
import { AppState, showAppModal } from "../AppState";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

export interface IModalProps {
  buttons?: React.ReactNode;
  bodyClassName?: string;
  children?: React.ReactNode;
  className?: string;
  title?: string | React.ReactNode;
  titleCloseButton?: React.ReactNode;
}

export const Modal: React.FC<IModalProps> = ({
  buttons,
  bodyClassName = "",
  children,
  className = AppState.mode !== "mobile" ? "modal-lg" : "",
  title,
  titleCloseButton,
}) => {
  const { t } = useTranslation("common");

  return (
    <div className={classNames("modal fade in")} tabIndex={-1} role="dialog">
      <div className={classNames("modal-dialog", className)}>
        <div className={classNames("modal-content")}>
          {(title || titleCloseButton) ? (
            <div className={classNames("modal-header")} style={{display: "flex"}}>
              <h4 className="modal-title">{title}</h4>
              {titleCloseButton}
            </div>
          ) : null}
          <div className={classNames("modal-body", bodyClassName)}>{children}</div>
          <div className={classNames("modal-footer")}>
            {buttons ?? (
              <Button variant="contained" onClick={() => showAppModal(null)}>
                {t("common:ok")}
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
