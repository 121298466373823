import React, { useState } from "react";
import { Collapse } from "react-collapse";
import { observer } from "mobx-react-lite";
import styled from "@emotion/styled/macro";
import { DeviceDetailModal } from "./Modals/DeviceDetailModal";
import { checkForWai418Devices, mySearch } from "../../Managers";
import { IDevice } from "../../Managers/Types";
import { showAppModal } from "../../AppState";
import { Colors, Vars } from "../../Theme";
import { DeviceCard } from "./DeviceCard";
import "./DeviceGroup.scss";
import { ICON_TOOLTIP_DEFAULT_DELAY, StyledTooltip } from "../../Components";
import { useTranslation } from "react-i18next";

const Wrapper = styled.div`
  width: 100%;
  overflow: visible;
  height: auto;
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  font-weight: ${Vars.regularFontWeight};
  align-items: center;
  margin-bottom: 10px;
  color: ${Colors.lightGray};
`;

const Body = styled.div({
  display: "flex",
  "flex-wrap": "wrap",
  gap: 15,
});

const Title = styled.span`
  text-transform: capitalize;
  font-size: ${Vars.smallSize};
  margin: 0;
`;

const Info = styled.p`
  margin: 0 0 0 0.6em;
  font-size: 12px;
`;

const ShowHide = styled.span`
  color: ${Colors.teal};
  font-size: ${Vars.smallSize};

  .btn {
    padding: 0 8px;
  }
`;

interface IDeviceGroupProps {
  title: string;
  devicesInGroup: IDevice[];
  searchString?: string;
  refresh: () => void;
}

export const DeviceGroup: React.FC<IDeviceGroupProps> = observer(({ refresh, title, devicesInGroup, searchString = "" }) => {
  const [collapsed, setCollapsed] = useState(false);
  const { t } = useTranslation("dashboard");

  if (devicesInGroup?.length < 1) return <></>;

  const handleCheckDetail = (device: IDevice) => {
    let isWai = checkForWai418Devices(device.serial_number);

    showAppModal(<DeviceDetailModal deviceId={device._id} isWai418={isWai} />);
  };

  return (
    <Wrapper className="device-group">
      <Header>
        <StyledTooltip
          title={t("dashboard:device_group.device", { context: collapsed ? "show" : "hide" })}
          enterDelay={ICON_TOOLTIP_DEFAULT_DELAY}>
          <button className="btn btn-plain u-desktop-only" onClick={() => setCollapsed(!collapsed)}>
            {!collapsed ? <i className="fa fa-minus-square" /> : <i className="fa fa-plus-square" />}
          </button>
        </StyledTooltip>
        <Title>{title}</Title>
        <Info>({devicesInGroup.length})</Info>
        <ShowHide className="u-desktop-hide">
          <button className="btn btn-plain" onClick={() => setCollapsed(!collapsed)}>
            {t("dashboard:device_group.device", { context: collapsed ? "show" : "hide" })}{" "}
            {!collapsed ? <i className="fa fa-minus" /> : <i className="fa fa-plus" />}
          </button>
        </ShowHide>
      </Header>
      <Collapse isOpened={!collapsed}>
        <Body>
          {mySearch<IDevice>(devicesInGroup, searchString).map((device, index) => {
            return (
              <DeviceCard
                key={`${device._id}-${index}`}
                isWai418={checkForWai418Devices(device.serial_number)}
                cardDevice={device}
                onCheckDetail={handleCheckDetail}
                refresh={refresh}
              />
            );
          })}
        </Body>
      </Collapse>
    </Wrapper>
  );
});
