import React from "react";
import { observer } from "mobx-react-lite";
import { AppState, showAppModal } from "../../AppState";
import moment from "moment";
import { ConfirmEndSubscription } from "./ConfirmEndSubscription";
import { Colors } from "../../Theme";
import { ConfirmModal } from "../index";
import { useTranslation } from "react-i18next";

export const CancelSubscription: React.FC = observer(() => {
  const { t } = useTranslation("billing_info");
  const leftToUseTheApp = () => {
    const expDate = new Date(AppState.user?.Account?.expiration_date ?? "now");
    let expirationDate = moment(expDate);
    let timeStampDifference = moment.duration(expirationDate.diff(new Date()));
    return Math.ceil(timeStampDifference.asDays());
  };

  const expirationDate = () => {
    let expirationDate = AppState.user?.Account?.expiration_date;
    if (!expirationDate) {
      return <></>;
    }
    const date = new Date(expirationDate);
    return date.toLocaleDateString("en-US", { dateStyle: "long" });
  };

  return (
    <ConfirmModal
      header={t("billing_info:cancel_subscription.header")}
      onConfirm={() => showAppModal(<ConfirmEndSubscription />)}
      confirmButtonType="btn-danger"
      confirmText={t("billing_info:cancel_subscription.confirm")}>
      <>
        {t("billing_info:cancel_subscription.warning_info_1")}{" "}
        <span style={{ color: Colors.alert, fontWeight: "bold" }}>{leftToUseTheApp()}</span>{" "}
        {t("billing_info:cancel_subscription.warning_info_2")}{" "}
        <span style={{ color: Colors.teal, fontWeight: "bold" }}>{expirationDate()}</span>
        <br />
        <br />
        {t("billing_info:cancel_subscription.warning_info_3")}
        <br />
        <br />
        {t("billing_info:cancel_subscription.warning_info_4")}
      </>
    </ConfirmModal>
  );
});
