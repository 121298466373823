import React from "react";
import Tooltip from "@mui/material/Tooltip";

export const InputLabel: React.FC<{
  name: string;
  label?: React.ReactNode;
  tooltip?: string;
  required?: boolean;
  className?: string;
  style?: React.CSSProperties;
}> = ({ name, label, required = false, className, style, tooltip }) => {
  return (
    <Tooltip title={tooltip ?? ""} arrow placement="top">
      <label htmlFor={name} className={className ?? "input-label u-display-block"} style={style}>
        {label}
        {required ? <span className="u-color-alert"> *</span> : ""}
      </label>
    </Tooltip>
  );
};
