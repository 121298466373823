import moment from "moment";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import html2canvas from "html2canvas";
import { Formik } from "formik";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { FormFieldDate, FormFieldSelect, FormFieldText, ILineChartValue, ISetpoint, TimeSeriesLineChart } from "../../../Components";
import { AppState, getSelectedDevices, showAppModal, showSnackbar } from "../../../AppState";
import { useLocations } from "../../../Managers/API";
import { getDeviceSensorData } from "../../../Managers/DeviceService";
import { prepareChartDataSet } from "../../../Managers/ReportManager";
import { unitsTransform } from "../../../Managers/UnitsService";
import {
  checkForWai418Devices,
  checkForWai418HumidityDevice,
  checkForWai418TemperatureDevice,
  exportToXLSX,
  formatDateCustom,
} from "../../../Managers";
import { IDevice, ISensor } from "../../../Managers/Types";
import "./ReportModal.scss";
import { CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Modal } from "../../../Components/Modal";
import { getNotificationsHistory } from "../../../Managers/NotificationService";
import { measurementTransform } from "../../../Managers/MeasurementService";
import { alertConditionTransform } from "../../../Managers/AlertConditionService";

const TARGET_POINT_MAX = 10;

export const ReportModal: React.FC<{ selected?: IDevice }> = observer(({ selected }) => {
  const locations = useLocations();
  const [sensors, setSensors] = useState<any[]>([]);
  const [selectedSensor, setSelectedSensor] = useState<ISensor | null>(null);
  const [isGraphLoading, setIsGraphLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [chartData, setChartData] = useState<ILineChartValue[]>([]);
  const [imgData, setImgData] = useState<HTMLCanvasElement | undefined>();
  const [minDate, setMinDate] = useState<Date | null>(null);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [totalElapsedTime, setTotalElapsedTime] = useState("");
  const [selectedExportData, setSelectedExportData] = useState("sensorReading");

  // Setpoints
  const [, setAddNewPoint] = useState(false);
  const [setPointsError, setSetPointsError] = useState("");
  const [setPoints, setSetPoints] = useState<ISetpoint[]>([]);
  const [, setHoveredPoint] = useState<number | null>(null);

  const [filteredData, setFilteredData] = useState<ILineChartValue[]>([]);

  // This does not appear to be used for anything
  // const [anchorTag, setAnchorTag] = useState<any | null>(null);

  const { devices } = getSelectedDevices();
  const device = devices[0] ?? selected;

  const { t } = useTranslation(["report_modal", "common"]);

  const totalSteps = 3;

  let convertToRh = false;
  let convertToTemp = false;

  const isWai418 = checkForWai418Devices(device.serial_number);

  if (isWai418 && device?.serial_number) {
    if (checkForWai418TemperatureDevice(device.serial_number)) {
      console.log("setting convert to temp to true");
      convertToTemp = true;
    } else if (checkForWai418HumidityDevice(device.serial_number)) {
      console.log("setting convert to humidity to true");
      convertToRh = true;
    } else {
      console.log("not a wai418 device - 0");
    }
  } else {
    console.log("not a wai418 device - 1");
  }

  useEffect(() => {
    if (device) {
      console.log("Chose device, updating min date", device);
      const newMinDate = new Date(device.createdAt);
      setMinDate(newMinDate);

      const newStartDate = new Date(new Date().getTime() - 86400 * 1000);
      setStartDate(new Date(Math.max(newStartDate.getTime(), newMinDate.getTime())));

      _getSensors();
    }
  }, [device?.createdAt]);

  const save = (_values: any) => {};

  const clearPoints = () => {
    setSetPoints([]);
    setSetPointsError("");
  };

  const handleExport = (_imageData: any) => {
    //setImgData(imageData);
  };

  const nextStep = (e: any) => {
    e.preventDefault();

    // TODO: Is math.min the right thing here? If steps is 3 but we go 0..2 it would seem wrong...
    const newCurrentStep = Math.min(currentStep + 1, totalSteps);
    setCurrentStep(newCurrentStep);

    if (!isGraphLoading) {
      const graph = document.getElementById("line-chart");
      if (graph) {
        html2canvas(graph, { backgroundColor: null }).then((imageData) => {
          setImgData(imageData);
        });
      }
    }

    if (setPoints.length) {
      setSelectedExportData("targetPoints");
    } else {
      setSelectedExportData("sensorReading");
    }

    if (currentStep === 2) {
      _getSensorData(startDate, endDate, selectedSensor, false);
    } else if (currentStep === totalSteps && setPoints.length) {
      setSelectedExportData("targetPoints");
    }
  };

  // TODO: We receive start/end as params rather than accessing startDate/endDate directly to allow this to be efficiently called while a
  // setState to adjust one of those values is pending (triggered by a date picker update). We could make patterns like this more efficient
  // by refactoring them to useEffect() and similar techniques, but I tried to keep the logic flow close to the old app during the
  // transition phase to ease debugging / cross-comparing to the old app's logic.
  const _getSensorData = (start: Date, end: Date, sensor: ISensor | null, isUpdate: boolean) => {
    if (!sensor) {
      return;
    }

    console.log("Getting sensor data", { start, end, sensor, isUpdate });
    setIsGraphLoading(true);

    setSetPointsError("");
    setAddNewPoint(false);

    getDeviceSensorData(sensor, start.toISOString(), end.toISOString())
      .then((r) => {
        const processed = prepareChartDataSet(r, sensor, device.is_empirical, convertToRh, convertToTemp);
        setChartData(processed);

        // const fsp = findChartSetPoints(setPointTargets, processed);
        // setSetPoints(fsp);

        if (r && r.length) {
          // let sensorData = transformSensorData(r, sensor);
          // setSensorReading(sensorData);

          if (isUpdate) {
            _relocateSetPoints();
          }
        }
      })
      .catch((e) => {
        const errorMessage = t("report_modal:sensor_data_loading_error");
        showSnackbar(errorMessage, "error");
        console.log(errorMessage, e);
      })
      .finally(() => setIsGraphLoading(false));
  };

  const backStep = () => {
    setCurrentStep(Math.max(currentStep - 1, 0));
  };

  // Show/hide the "add new point" row in the setpoints list
  // const toggleAddNewPoint = (e: any, isTrue: boolean) => {
  //   e.preventDefault();
  //   setSetPointsError("");
  //   if (isTrue && setPoints.length < 10) {
  //     setAddNewPoint(isTrue);
  //   } else if (!isTrue) {
  //     setAddNewPoint(isTrue);
  //   }
  // };

  // Checks to see if the number entered matches the direction of previously entered values. There is a lot of code here to do what appears
  // to be just a check that once the direction of setpoints is known (first two values are ascending or descending) any further points must
  // match that direction. This could probably be refactored but I left it in place for now.
  // const validateNumberInOrder = (arr: ISetpoint[], newValue: number) => {
  //   if (arr.length > TARGET_POINT_MAX) {
  //     return false;
  //   }
  //
  //   let count = arr.length === 0 ? 0 : arr.length - 1;
  //   // Reset when starting over
  //   if (count === 0) {
  //     setAsc(false);
  //     setDesc(false);
  //   }
  //
  //   // If there is only 1 item in the array compare with newValue and set asc or decs variables
  //   if (arr.length === 1) {
  //     if (arr[count].target === newValue) {
  //       return false; //values should not be the same
  //     } else if (arr[count].target > newValue) {
  //       setDesc(true);
  //     } else {
  //       setAsc(true);
  //     }
  //     return true;
  //   } else {
  //     let descending = arr[count].target > newValue && desc;
  //     let ascending = arr[count].target < newValue && asc;
  //
  //     return ascending ? ascending : descending;
  //   }
  // };

  const changeHoverSetPoint = ($event: any, point: any) => {
    setHoveredPoint(point ? point._id : null);
  };

  const deleteNewPoint = (e: any, point: ISetpoint, i: number) => {
    console.log("delete setpoint", setPoints[i]);
    e.preventDefault();

    setSetPointsError("");

    setPoints.splice(i, 1);

    _relocateSetPoints();
  };

  function readingUOMString() {
    return selectedSensor
      ? " (" +
          unitsTransform(selectedSensor?.default_unit, [
            selectedSensor?.default_unit,
            device?.is_empirical,
            selectedSensor?.Sensor_type.type,
            convertToRh,
            convertToTemp,
          ]) +
          ")"
      : "";
  }

  const prepareHistoricalData = async () => {
    const historicalData = await getNotificationsHistory(
      moment(startDate).startOf("day").toISOString(),
      moment(endDate).endOf("day").toISOString(),
    );

    let headers = [
      { header: t("dashboard:report_modal:value"), dataKey: "value" },
      { header: t("dashboard:report_modal:name"), dataKey: "name" },
      { header: t("dashboard:report_modal:condition"), dataKey: "condition" },
      { header: t("dashboard:report_modal:device"), dataKey: "device" },
      { header: t("dashboard:report_modal:placement"), dataKey: "placement" },
      { header: t("dashboard:report_modal:serial"), dataKey: "serial" },
      { header: t("dashboard:report_modal:last_updated"), dataKey: "lastUpdateAt" },
      { header: t("dashboard:report_modal:resolved"), dataKey: "resolved" },
    ];

    console.log("historicalData");
    console.log(historicalData);
    const parsedHistoricalData = historicalData
      .filter((alert) => alert.Sensor?.DeviceId === selectedSensor?.DeviceId)
      .map((alert) => {
        let value =
          String(
            measurementTransform(alert.value.value, [
              alert.Sensor?.default_unit || "degF",
              alert.Sensor?.Device?.is_empirical,
              alert.Sensor?.Sensor_type.type,
            ]),
          ) +
          unitsTransform(alert.Sensor?.default_unit || "degF", [
            alert.Sensor?.default_unit || "degF",
            alert.Sensor?.Device?.is_empirical,
            alert.Sensor?.Sensor_type.type,
          ]);

        const alertName = alert.Alert ? alert.Alert.name + " - " + alertConditionTransform(alert.Alert) : "";
        const alertCondition = alert.Alert ? alertConditionTransform(alert.Alert) : "";
        const deviceName = alert.Sensor?.Device?.name || "";
        const placement = alert.Sensor?.Device?.location_note || "--";
        const serialNumber = alert.Sensor?.Device?.serial_number || "";
        const lastUpdateAt = moment(alert.updatedAt).format("M/D/YYYY hh:mm A");
        const isResolved = alert.is_resolved ? "yes" : "no";

        return [value, alertName, alertCondition, deviceName, placement, serialNumber, lastUpdateAt, isResolved];
      });

    return { historicalHeaders: headers, historicalData: parsedHistoricalData };
  };

  const saveReport = async (values: any) => {
    const fileFormat = values.fileFormat;
    console.log(values.selectedExportData);
    let columns = [t("report_modal:reading") + readingUOMString(), t("report_modal:date_time")];
    // let readings = sensorReading;
    let data;
    let lastReading: any = false;
    let total = 0;

    const dateFormat = "D/MM/YYYY h:mm";

    const formatTime = function (num: number) {
      if (num < 10) {
        return "0" + num;
      } else {
        return num;
      }
    };

    function formatDuration(ms: number) {
      const s1 = Math.floor(ms / 1000);
      const s2 = s1 % 60;
      const m1 = Math.floor(s1 / 60);
      const m2 = m1 % 60;
      const h1 = Math.floor(m1 / 60);
      return formatTime(h1) + "h " + formatTime(m2) + "m " + formatTime(s2) + "s";
    }

    if (selectedExportData === "sensorReading") {
      let dataToFilter = chartData;
      if (filteredData.length > 0) {
        dataToFilter = filteredData;
      }
      data = dataToFilter.map((datapoint) => {
        // * read.createdAt below used to be reading.updatedAt
        return [datapoint.y, moment(datapoint.x).format(dateFormat)];
      });
    } else if (selectedExportData === "targetPoints" || selectedExportData === "targetPointsAndHistorical") {
      columns = [
        t("report_modal:target_point"),
        t("report_modal:reading") + readingUOMString(),
        t("report_modal:date_time"),
        t("report_modal:elapsed_time"),
      ];

      data = setPoints.map((reading: ISetpoint) => {
        let duration = 0;
        if (lastReading) {
          // * this used to be reading.updatedAt
          duration = (reading.createdAt || new Date()).getTime() - lastReading;
          total += duration;
        }
        // * this used to be reading.updatedAt
        lastReading = reading.createdAt;

        if (reading.value) {
          return [
            reading.value,
            reading.value,
            // * this used to be reading.updatedAt
            moment(reading.createdAt).format(dateFormat),
            duration !== 0 ? formatDuration(duration) : "n/a",
          ];
        } else {
          return [
            reading.target,
            reading.value,
            // * this used to be reading.updatedAt
            moment(reading.createdAt).format(dateFormat),
            duration !== 0 ? formatDuration(duration) : "n/a",
          ];
        }
      });
      data.push([]);
      data.push(["", "", t("report_modal:total_elapsed_time"), formatDuration(total)]);
    } else {
      // Prevent 'data' from being used un-initialized
      return;
    }

    const { historicalHeaders, historicalData } = await prepareHistoricalData();

    if (fileFormat === "pdf" || !fileFormat) {
      _saveAsPDF(values.filename, values.lotCode, values.productCode, columns, data, historicalHeaders, historicalData);
    } else {
      // Add information about device;
      data.unshift(columns);
      data.unshift([
        t("report_modal:sensor_type"),
        sensorType() || selectedSensor?.display_name || selectedSensor?.Sensor_type.name || "Unknown",
      ]);
      data.unshift([t("report_modal:serial_number"), device.serial_number]);
      data.unshift([t("report_modal:device_name"), device.name]);
      data.unshift([t("report_modal:lot_code"), values.lotCode ?? ""]);
      data.unshift([t("report_modal:product_code"), values.productCode ?? ""]);
      data.unshift([t("report_modal:device_location"), device.location_note || ""]);
      data.unshift([t("report_modal:location"), AppState.selectedLocation?.address || ""]);
      data.unshift([t("report_modal:location_name"), AppState.selectedLocation?.name ?? ""]);

      const combinedData = [...data, []];
      combinedData.push([t("report_modal:historical")]);
      combinedData.push(historicalHeaders.map((header) => header.header));
      combinedData.push(...historicalData);

      const records = combinedData.map((d) => {
        const rec: Record<string, number | string | null | undefined> = {};
        d.forEach((entry, index) => {
          rec[index] = entry;
        });
        return rec;
      });

      exportToXLSX(values.filename || t("common:untitled"), records);
    }
    showAppModal(null);
  };

  const handleSetStartDate = (date: Date) => {
    //console.log(date);
    setStartDate(date);
    _getSensorData(date, endDate, selectedSensor, true);
  };

  const handleSetEndDate = (date: Date) => {
    setEndDate(date);
    _getSensorData(startDate, date, selectedSensor, true);
  };

  const handleSetSensorId = (value: string) => {
    const sensor = sensors.find((s) => s._id === +value);
    setSelectedSensor(sensor);
    _getSensorData(startDate, endDate, sensor, true);
  };

  const _getSensors = () => {
    if (device.Sensors.length) {
      setSensors(
        device.Sensors.filter((sensor) => {
          return sensor.Sensor_type && sensor.Sensor_type.name !== "Battery Voltage" && sensor.Sensor_type.name !== "Signal Strength";
        }),
      );
    } else {
      setSensors([]);
    }

    if (sensors.length === 1) {
      setSelectedSensor(sensors[0]);
    } else {
      setSelectedSensor(null);
    }
  };

  const _relocateSetPoints = () => {
    const localSetpoints: any[] = [];
    if (setPoints.length) {
      let targets = [...setPoints];
      //setSetPoints([]);

      if (targets.length === 1 && setPoints.length === 1) {
        targets[0].elapsed = "";
        setTotalElapsedTime("");
        setSetPoints(targets);
        //localSetpoints.push(newSetPoint);
      }

      targets.forEach((target, i) => {
        // If cannot find the new setPoint in the updated dataset
        localSetpoints.push({
          _id: target.id,
          id: target.id,
          createdAt: target.createdAt,
          elapsed: i > 0 ? _displayElapseTime(moment(targets[targets.length - 1].createdAt).diff(moment(localSetpoints[0].createdAt))) : "",
          target: target.target,
          unit: unitsTransform(target.unit, [
            selectedSensor?.default_unit,
            device?.is_empirical,
            selectedSensor?.Sensor_type.type,
            convertToRh,
            convertToTemp,
          ]),
          // error: 'could not find ' + target.target + ' in selected time frame.',
          value: target.value,
        });
      });

      if (localSetpoints.length) {
        setSetPoints(localSetpoints);
        setTotalElapsedTime(
          _displayElapseTime(moment(localSetpoints[localSetpoints.length - 1].createdAt).diff(moment(localSetpoints[0].createdAt))),
        );
      } else {
        setTotalElapsedTime("");
      }
    }
  };

  // NOTE: This was another block of code I didn't fully understand. I felt it could probably be simplified a lot but was out of time
  // THere was a lot of usage of any-typed values in the old code...
  // find the first interset behinds the target value
  // i.e. in the ascending slope, find the first number that is bigger than the target value,
  // while in the desending slope, find the first number that is smaller than the target value
  const findSetPointForTarget = (target: ISetpoint): ISetpoint | null => {
    let lastSetPoint = setPoints.length ? setPoints[setPoints.length - 1] : null;
    const firstSetpoint = setPoints.length > 0 ? setPoints[0] : null;

    console.log("trying to set this target", target);

    if (setPoints.length === 0 && target) {
      setSetPoints([...setPoints, target]);
    } else if (target && lastSetPoint && target.id > lastSetPoint.id) {
      target.elapsed = _displayElapseTime(moment(target.createdAt).diff(moment(lastSetPoint.createdAt)));

      if (firstSetpoint) {
        setTotalElapsedTime(_displayElapseTime(moment(target.createdAt).diff(moment(firstSetpoint.createdAt))));
      }

      setSetPoints([...setPoints, target]);
    } else if (target.id <= (lastSetPoint?.id ?? 0)) {
      setSetPointsError("Please select a valid target point on the graph that is in descending/ascending order.");
    }

    console.log("done finding setpoint for target");
    console.log(setPoints);

    return target || null;
  };

  const _displayElapseTime = (millisecond: number) => {
    if (!millisecond || millisecond < 0) {
      return "00:00";
    }

    let hour, min, sec;
    let totalSecond = Math.round(Math.abs(millisecond) / 1000);

    if (totalSecond >= 3600) {
      hour = Math.floor(totalSecond / 3600);
      totalSecond = totalSecond % 3600;
    }

    min = Math.floor(totalSecond / 60);
    totalSecond = totalSecond % 60;
    sec = totalSecond;

    if (min < 10) {
      min = "0" + min;
    }

    if (sec < 10) {
      sec = "0" + sec;
    }

    return hour ? hour + ":" + min + ":" + sec : min + ":" + sec;
  };

  const renderHeaderFooterPDF = (doc: any, lotCode: string, productCode: string) => {
    doc.setFontSize(14);
    doc.setTextColor(0, 0, 0);
    // TODO: The old system did repeat this field 3x
    doc.text(AppState.selectedLocation?.name || "", 40, 50);
    doc.text(AppState.selectedLocation?.name || "", 40, 50);
    doc.text(AppState.selectedLocation?.name || "", 40, 50);
    doc.setFontSize(8);

    let subtitle = [];
    let address = AppState.selectedLocation?.address !== "please enter an address" ? AppState.selectedLocation?.address : null;
    let deviceLocation =
      address &&
      AppState.selectedLocation?.city &&
      AppState.selectedLocation?.state &&
      AppState.selectedLocation?.country &&
      AppState.selectedLocation?.zip
        ? address +
          ", " +
          AppState.selectedLocation?.city +
          ", " +
          AppState.selectedLocation?.state +
          ", " +
          AppState.selectedLocation?.country +
          " " +
          AppState.selectedLocation?.zip
        : null;
    if (deviceLocation) {
      subtitle.push(deviceLocation);
    }
    let devicePhone = AppState.selectedLocation?.phone !== "please enter a phone number" ? AppState.selectedLocation?.phone : null;
    if (devicePhone) {
      subtitle.push(devicePhone);
    }
    doc.text(subtitle.join(" | "), 40, 63);

    doc.setDrawColor(240, 240, 240);
    doc.setLineWidth(2);
    doc.line(40, 68, 550, 68);
    doc.setLineWidth(0.01);
    doc.line(40, 95, 550, 95);

    //devices
    doc.setFontSize(7);
    doc.setTextColor(180, 180, 180);
    doc.text(t("report_modal:device_name"), 40, 78);
    doc.text(t("report_modal:product_code"), 210, 78);
    doc.text(t("report_modal:serial_number"), 300, 78);
    doc.text(t("report_modal:sensor_type"), 360, 78);
    doc.text(t("report_modal:lot_code"), 475, 78);

    doc.setFontSize(9);
    doc.setTextColor(100, 100, 100);
    doc.text(device.name || "n/a", 40, 90);
    doc.text(productCode || "n/a", 210, 90);
    doc.text(device.serial_number || "n/a", 300, 90);
    doc.text(sensorType() || selectedSensor?.display_name || selectedSensor?.Sensor_type.name || "n/a", 360, 90);
    doc.text(lotCode || "n/a", 475, 90);

    doc.text(new Date().toLocaleDateString(), doc.internal.pageSize.width - 100, 40);

    //footer
    doc.setFontSize(12);
    doc.setTextColor(160, 160, 160);
    doc.text(t("report_modal:wireless_monitoring"), 40, doc.internal.pageSize.height - 50);
    doc.setFontSize(9);
    doc.setTextColor(200, 200, 200);
    doc.text(t("report_modal:footer"), 40, doc.internal.pageSize.height - 40);
  };

  // SAVING TO PDF
  const _saveAsPDF = (
    filename: any,
    lotCode: string,
    productCode: string,
    columns: any,
    data: any,
    historicalHeaders: any[],
    historicalData: any[],
  ) => {
    let doc = new jsPDF("p", "pt");

    if (imgData) {
      doc.addImage({ imageData: imgData.toDataURL(), format: "PNG", x: 20, y: 100, width: 560, height: 250 });
    }

    autoTable(doc, {
      head: [columns],
      body: data,
      startY: 380,
      margin: {
        top: 100,
        bottom: 70,
        left: 35,
      },
      showFoot: "everyPage",
      didDrawPage: () => {
        renderHeaderFooterPDF(doc, lotCode, productCode);
      },
    });

    if (historicalData && historicalData.length > 0) {
      doc.addPage();
      doc.setFontSize(18);
      doc.text(t("report_modal:historical"), 40, 40);
      autoTable(doc, {
        head: historicalHeaders,
        body: historicalData,
        startY: 70,
        margin: {
          top: 60,
          bottom: 70,
          left: 35,
        },
      });
    }

    doc.save((filename || t("common:untitled")) + ".pdf");
  };

  const sensorType = () => {
    if (convertToTemp) {
      return t("report_modal:temperature");
    } else if (convertToRh) {
      return t("report_modal:humidity");
    }
    return null;
  };

  const sensorOptions = [
    { value: 0, label: `-- ${t("common:select")} --` },
    ...sensors.map((sensor) => ({
      value: sensor._id,
      label: sensorType() || sensor.display_name || sensor.Sensor_type.name,
    })),
  ];

  const initialValues = {
    startDate,
    endDate,
    sensorId: 0,
    filename: "",
    productCode: "",
    lotCode: "",
    fileFormat: "csv",
    selectedExportData: "targetPoints",
  };

  // TODO: Review visuals, date fields here are darker due to standardization of the picker

  return (
    <Formik initialValues={initialValues} onSubmit={save}>
      {({ values, isSubmitting }) => {
        const locationOptions = [{ value: 0, label: `-- ${t("common:select")} --` }];
        (locations.data || []).forEach((location) => {
          locationOptions.push({ value: location._id, label: location.name });
        });

        return (
          <Modal
            buttons={
              <>
                <button type="button" className="btn btn-info" disabled={isSubmitting} onClick={() => showAppModal(null)}>
                  {t("common:cancel")}
                </button>

                {currentStep > 1 ? (
                  <button type="button" className="btn btn-info" disabled={isSubmitting} onClick={backStep}>
                    {t("common:back")}
                  </button>
                ) : null}

                {currentStep < totalSteps ? (
                  <button type="button" className="btn btn-primary" disabled={isSubmitting || !values.sensorId} onClick={nextStep}>
                    {t("common:next")}
                  </button>
                ) : null}

                {currentStep === totalSteps ? (
                  <button type="button" className="btn btn-primary" onClick={() => saveReport(values)}>
                    {t("common:save")}
                  </button>
                ) : null}
              </>
            }
            title={
              <>
                <h4 className="pull-left modal-title">{t("report_modal:title", { context: currentStep })}</h4>
                <p className="pull-right u-text-small">
                  {t("report_modal:step_of", {
                    currentStep,
                    totalSteps,
                  })}
                </p>
              </>
            }>
            {currentStep === 1 ? (
              <div id="step1">
                <div className="form-group">
                  <div className="row">
                    <FormFieldDate
                      name="startDate"
                      minDate={minDate}
                      maxDate={endDate}
                      hasTimeSelector={true}
                      className="col-xs-6"
                      onChange={handleSetStartDate}
                      label={t("common:from")}
                    />
                    <FormFieldDate
                      name="endDate"
                      minDate={startDate}
                      maxDate={new Date()}
                      hasTimeSelector={true}
                      className="col-xs-6"
                      onChange={handleSetEndDate}
                      label={t("common:to")}
                    />
                  </div>
                </div>

                <br />

                <FormFieldSelect
                  menuItemClass="dark"
                  inputClassName="dark"
                  options={sensorOptions}
                  name="sensorId"
                  label={t("report_modal:sensor_type")}
                  onChange={handleSetSensorId}
                />
              </div>
            ) : null}

            {currentStep === 2 ? (
              <div id="step2">
                <div className="row">
                  <div className="col-xs-3">
                    <div>
                      <p className="input-label">{t("report_modal:device_name")}:</p>
                      <p>{device.name}</p>
                    </div>
                    <div>
                      <p className="input-label">{t("report_modal:serial_number")}:</p>
                      <p>{device.serial_number}</p>
                    </div>
                    <div>
                      <p className="input-label">{t("report_modal:placement")}:</p>
                      <p>{device.location_note || "-"}</p>
                    </div>
                  </div>

                  <div className="col-xs-9">
                    <div className="form-group" style={{ marginBottom: 0 }}>
                      {selectedSensor && selectedSensor.Sensor_type && selectedSensor.Sensor_type.type !== "BOOLEAN" ? (
                        <div className="row">
                          <FormFieldDate
                            name="startDate"
                            minDate={minDate}
                            maxDate={endDate}
                            hasTimeSelector={true}
                            className="col-xs-6"
                            onChange={handleSetStartDate}
                            label={t("common:from")}
                          />
                          <FormFieldDate
                            name="endDate"
                            minDate={startDate}
                            maxDate={new Date()}
                            hasTimeSelector={true}
                            className="col-xs-6"
                            onChange={handleSetEndDate}
                            label={t("common:to")}
                          />
                        </div>
                      ) : (
                        <>{t("report_modal:nothing")}</>
                      )}
                    </div>

                    <div className="graph-holder">
                      {isGraphLoading ? (
                        <CircularProgress />
                      ) : (
                        <TimeSeriesLineChart
                          data={chartData}
                          setPoints={setPoints}
                          valueName={sensorType() || selectedSensor?.Sensor_type.name}
                          setFilteredDataHook={setFilteredData}
                          onSetpointClick={(d) => findSetPointForTarget(d)}
                          onExport={handleExport}
                        />
                      )}
                    </div>
                  </div>
                </div>

                <br />

                {selectedSensor && selectedSensor.Sensor_type && selectedSensor.Sensor_type.type !== "BOOLEAN" ? (
                  <div>
                    <div className="row">
                      <div>
                        <i className="fa fa-trash" />
                        <span className="sr-only">{t("report_modal:remove_all_targets")}</span>
                        <button type="button" className="btn btn-plain" onClick={() => clearPoints()}>
                          {t("report_modal:clear_all_targets")}
                        </button>
                      </div>
                    </div>

                    <div className="select-group-item header">
                      <div className="row u-full-width">
                        <div className="col-xs-2">{t("report_modal:target_point")}</div>
                        <div className="col-xs-3">{t("report_modal:reading")}</div>
                        <div className="col-xs-4">{t("report_modal:date_time")}</div>
                        <div className="col-xs-2">{t("report_modal:elapsed_time")}</div>
                        <div className="col-xs-1">{t("report_modal:remove")}</div>
                      </div>
                    </div>

                    <ul className="select-group" onMouseLeave={(e) => changeHoverSetPoint(e, null)}>
                      {setPoints.map((setpoint, i) => (
                        <li className="select-group-item" onMouseEnter={(e) => changeHoverSetPoint(e, setpoint)} key={i}>
                          <div className="row u-full-width">
                            <div className="col-xs-2">
                              {setpoint.target}{" "}
                              {unitsTransform(selectedSensor.default_unit, [
                                selectedSensor?.default_unit,
                                device?.is_empirical,
                                selectedSensor?.Sensor_type.type,
                                convertToRh,
                                convertToTemp,
                              ])}
                            </div>
                            <div className="col-xs-3">{setpoint.target}</div>
                            <div className="col-xs-4">
                              {setpoint.createdAt ? formatDateCustom(setpoint.createdAt, "MM/DD/YYYY hh:mm:ss a") : "--"}
                            </div>
                            <div className="col-xs-2">{setpoint.elapsed || "--:--"}</div>
                            <div className="col-xs-1 u-text-center">
                              <button type="button" className="btn btn-plain" onClick={(e) => deleteNewPoint(e, setpoint, i)}>
                                <i className="fa fa-times-circle" />
                                <span className="sr-only">{t("report_modal:remove_target")}</span>
                              </button>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>

                    {setPoints.length ? (
                      <p>
                        <span className="pull-right u-text-blue">
                          {t("report_modal:total_elapsed_time")}: {totalElapsedTime}
                        </span>
                      </p>
                    ) : null}

                    {!setPoints.length ? (
                      <p className="u-text-small u-opacity-fade col-xs-12">
                        {t("report_modal:point_click_info", { count: TARGET_POINT_MAX })}
                      </p>
                    ) : null}

                    <p className="u-text-error">{setPointsError}</p>
                  </div>
                ) : null}
              </div>
            ) : null}

            {currentStep === 3 ? (
              <div id="step3">
                <div className="form-group">
                  <FormFieldText
                    name="filename"
                    label={t("report_modal:file_name")}
                    placeholder={t("report_modal:file_name_placeholder")}
                  />
                  <FormFieldText
                    name="productCode"
                    label={t("report_modal:product_code")}
                    placeholder={t("report_modal:product_code_placeholder")}
                  />
                  <FormFieldText name="lotCode" label={t("report_modal:lot_code")} placeholder={t("report_modal:lot_code_placeholder")} />
                </div>

                <FormFieldSelect options={locationOptions} name="location" />

                <FormFieldSelect
                  options={[
                    { value: "pdf", label: "PDF" },
                    { value: "xlsx", label: "XLSX" },
                  ]}
                  name="fileFormat"
                  label={t("report_modal:file_format")}
                />

                {setPoints.length ? (
                  <FormFieldSelect
                    options={[
                      { value: "targetPoints", label: t("report_modal:option_only_target_points") },
                      {
                        value: "sensorReading",
                        label: t("report_modal:option_only_sensor_data"),
                      },
                    ]}
                    onChange={setSelectedExportData}
                    name="selectedExportData"
                    label={t("report_modal:export_to_report")}
                  />
                ) : (
                  <FormFieldSelect
                    options={[
                      {
                        value: "sensorReading",
                        label: t("report_modal:option_only_sensor_data"),
                      },
                    ]}
                    onChange={setSelectedExportData}
                    name="selectedExportData"
                    label={t("report_modal:export_to_report")}
                  />
                )}
              </div>
            ) : null}
          </Modal>
        );
      }}
    </Formik>
  );
});
