import classNames from "classnames";
import React, { useCallback, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import {
  deleteAlertConfig,
  deleteRecipient,
  editRecipient,
  getDisplayAlertType,
  selectAlertConfig,
  useAlertConfigs,
  useSensorTypes,
} from "../../Managers/AlertService";
import { convertUnitTemperature } from "../../Managers/MeasurementService";
import {
  DropdownMenu,
  HeaderRow,
  ICON_TOOLTIP_DEFAULT_DELAY,
  InfoMenuItem,
  InputRangeSlider,
  LeftRail,
  Page,
  PermissionBlocker,
  SelectInput,
  StyledTooltip,
  Wrapper,
} from "../../Components";
import { ConfirmModal } from "../../Modals";
import * as AlertConditionService from "../../Managers/AlertConditionService";
import { IAlertConfig, IAlertConfigUser } from "../../Managers/Alert.model";
import { AppState, setSelectedAlertConfigId, showAppModal, showSnackbar } from "../../AppState";
import * as UnitsService from "../../Managers/UnitsService";
import { fullUnitName } from "../../Managers/UnitsService";
import { capitalize, getNameSlug, mySearch } from "../../Managers";
import { AlertConfigModal } from "./AlertConfigModal";
import { AddRecipientModal } from "./AddRecipientModal";
import ClickAwayListener from "react-click-away-listener";
import { PermissionEnum } from "../../Enums";
import { useTranslation } from "react-i18next";
import "./Alerts.scss";
import { Button, Drawer } from "@mui/material";
import { LeftRailToggleIcon } from "../../icon";
import styled from "@emotion/styled/macro";

export const Alerts: React.FC = observer(() => {
  // TODO: This little sort widget is on several screens. Make it a component?
  const [order, setOrder] = useState<"asc" | "desc">("asc");
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [searchAlertString, setSearchAlertString] = useState("");
  const [isSaving, setIsSaving] = useState("");
  const [devicesOpen, setDevicesOpen] = useState(false);
  const [selectedAlert, setSelectedAlert] = useState<IAlertConfig>();
  const sensorTypes = useSensorTypes();

  const { t } = useTranslation(["alerts", "common", "sensor_types"]);

  const MobileHeaderRow = styled.div({
    display: "flex",
    gap: 16,
  });

  const MobileButtons = styled.div({
    display: "flex",
    margin: "auto",
    position: "relative",
    top: 5,
    gap: 16,
  });

  const alertConfigs = useAlertConfigs();
  const sortedAlerts = mySearch<IAlertConfig>([...(alertConfigs.data || [])], searchAlertString);
  sortedAlerts.sort((a, b) =>
    order === "asc" ? a.name.toLowerCase().localeCompare(b.name.toLowerCase()) : b.name.toLowerCase().localeCompare(a.name.toLowerCase()),
  );

  const setupSelectedAlert = useCallback(
    (alertConfig?: IAlertConfig) => {
      if (alertConfig && alertConfig._id !== selectedAlert?._id) {
        refreshViewAlert(alertConfig);
      }
    },
    [selectedAlert],
  );

  useEffect(() => {
    if (!selectedAlert) {
      // TODO: Unlike other views, alerts seems to always get the detail for a selected alert rather than using the list. Maybe it's to
      // always have the freshest data? But since we already had this pattern established I viewed it as an easy thing to refactor to get
      // boilerplate out of the main controllers, so I used the same pattern here for consistency's sake. We get fresh data from react-query
      // automatically so that one detail doesn't hold us back.
      setupSelectedAlert(sortedAlerts.find((cfg) => cfg._id === AppState.selectedAlertConfigId) ?? undefined);
      if (!AppState.selectedAlertConfigId && alertConfigs.isSuccess && sortedAlerts.length > 0) {
        console.log("No alert config selected yet, pre-selecting", sortedAlerts[0]);
        setupSelectedAlert(sortedAlerts[0]);
      }
    } else {
      setSelectedAlert(sortedAlerts?.find((a) => a._id === selectedAlert?._id));
    }
  }, [alertConfigs, selectedAlert, setupSelectedAlert, sortedAlerts]);

  const refreshViewAlert = (alertConfig: IAlertConfig) => {
    setSelectedAlertConfigId(alertConfig._id);
    setSelectedAlert(alertConfig);
    selectAlertConfig(alertConfig).then((res) => setSelectedAlert(res));
  };

  const alertType = getDisplayAlertType(selectedAlert);

  let range = [0, 100];
  let displayThreshold = 50;
  let sliderType = "range";
  if (selectedAlert?.Sensor_type) {
    const sensorType = sensorTypes.data?.find((t) => t._id === selectedAlert.SensorTypeId);

    if (sensorType?.type === "RANGE" && !selectedAlert.is_notify_on_non_transmitting) {
      let unit = selectedAlert.selected_units || selectedAlert?.Sensor_type.units[0] || "";
      range = sensorType?.ranges?.[fullUnitName(unit)] || [0, 100];
    }

    // Because everything on server is stored as metric value, we need to convert them back to its selected unit value
    if (selectedAlert.min !== null && selectedAlert.max === null) {
      sliderType = "min";
    }

    if (selectedAlert.max !== null && selectedAlert.min === null) {
      sliderType = "max";
    }

    if (selectedAlert.threshold !== null) {
      displayThreshold = convertUnitTemperature(+selectedAlert.threshold, "celcius", selectedAlert.selected_units);
    }
  }

  const deleteAlert = () => {
    setIsSaving("alert-" + (selectedAlert?._id || 0));
    selectedAlert &&
      deleteAlertConfig(selectedAlert?._id || 0)
        .then((r) => {
          console.log("deleted", r);
          showSnackbar(t("alerts:list.alert_delete_success"), "success");
          showAppModal(null);
        })
        .catch((e) => {
          showSnackbar(t("alerts:list.alert_delete_error", { name: selectedAlert?.name || "" }), "error");
          console.log("delete error", e);
        });
  };

  const removeRecipient = (recipient: IAlertConfigUser) => {
    setIsSaving("recipient" + recipient._id);
    deleteRecipient(selectedAlert?._id || 0, recipient._id)
      .then((r) => {
        console.log("deleted", r);
        showSnackbar(t("alerts:list.recipient_removal_success"), "success");
        refreshList();
      })
      .catch((e) => {
        console.log("delete error", e);
        showSnackbar(t("alerts:list.recipient_removal_error", { name: recipient.first_name + " " + recipient.last_name }), "error");
      })
      .finally(() => setIsSaving(""));
  };

  // TODO: The system has a phone alert option but no UI to control it?
  const updateRecipient = (isChecked: boolean, recipient: IAlertConfigUser, alertSetting: "email_alert" | "sms_alert" | "phone_alert") => {
    setIsSaving("recipient" + recipient._id);

    if (!recipient.User_alert) {
      return;
    }

    console.log(alertSetting);

    const newSettings = { ...recipient.User_alert, [alertSetting]: isChecked };
    console.log("new settings", newSettings);

    editRecipient(selectedAlert?._id || 0, newSettings)
      .then((r) => {
        console.log("updated", r);
        showSnackbar(t("alerts:list.recipient_update_success"), "success");
        refreshList();
      })
      .catch((e) => {
        console.log("update error", e);
        showSnackbar(t("alerts:list.recipient_update_error", { name: recipient.first_name + " " + recipient.last_name }), "error");
      })
      .finally(() => setIsSaving(""));
  };

  const refreshList = () => {
    let alertConfig = sortedAlerts.find((cfg) => cfg._id === AppState.selectedAlertConfigId);
    if (alertConfig) {
      refreshViewAlert(alertConfig);
    }
  };

  return (
    <Page>
      <Wrapper>
        <Drawer
          variant={AppState.mode === "desktop" ? "permanent" : "temporary"}
          hideBackdrop={AppState.mode === "desktop"}
          onBackdropClick={() => AppState.mode === "tablet" && setDrawerOpen(false)}
          open={drawerOpen || AppState.mode === "desktop"}>
          <LeftRail
            header={
              <div className="input-holder u-full-width">
                <input
                  type="text"
                  className="input input-default"
                  placeholder={t("alerts:list.find_alert")}
                  value={searchAlertString}
                  onChange={(e) => setSearchAlertString(e.target.value)}
                />
                <i className="fa fa-search input-holder-icon" />
              </div>
            }
            body={
              <nav className="left-rail-nav">
                <div className="left-rail-nav-header">
                  <h2 className="left-rail-nav-header-title mod-with-btn">
                    <StyledTooltip
                      title={t("common:sort_by_name", { context: order === "asc" ? "desc" : "asc" })}
                      enterDelay={ICON_TOOLTIP_DEFAULT_DELAY}>
                      <button className="btn btn-plain" onClick={() => setOrder(order === "asc" ? "desc" : "asc")}>
                        {order === "asc" ? <i className="fa fa-sort-amount-desc" /> : <i className="fa fa-sort-amount-asc" />}
                      </button>
                    </StyledTooltip>{" "}
                    {t("alerts:list.title")}
                  </h2>
                  <PermissionBlocker permission={PermissionEnum.EDIT_ALERTS}>
                    <button className="btn btn-circle btn-primary" onClick={() => showAppModal(<AlertConfigModal alert={undefined} />)}>
                      <i className="fa fa-plus" />
                      <span className="sr-only">{t("alerts:list.add_alert")}</span>
                    </button>
                  </PermissionBlocker>
                </div>
                <ul className="left-rail-nav-group">
                  {sortedAlerts.length < 1 ? <li className="left-rail-nav-item">{t("alerts:list.no_alerts")}</li> : <></>}
                  {sortedAlerts.map((alert) => (
                    <li className={classNames("left-rail-nav-item", { active: alert._id === selectedAlert?._id })}>
                      <div onClick={() => setupSelectedAlert(alert)} title={alert.name} className="u-text-eclipse">
                        {alert.name}
                      </div>
                    </li>
                  ))}
                </ul>
              </nav>
            }
          />
        </Drawer>

        {sortedAlerts.length < 1 || !selectedAlert ? (
          <main className="bapi-main mod-dashboard bapi-no-alerts-dashboard">
            <HeaderRow info={{ name: "" }} />
            <div className={classNames("main-panel no-alerts-panel u-text-center", { isLoading: alertConfigs.isLoading })}>
              <p>{t("alerts:list.no_alerts")}</p>
              <PermissionBlocker permission={PermissionEnum.EDIT_ALERTS}>
                <StyledTooltip title={t("alerts:list.create_alert")} enterDelay={ICON_TOOLTIP_DEFAULT_DELAY}>
                  <button className="btn btn-primary" onClick={() => showAppModal(<AlertConfigModal alert={selectedAlert} />)}>
                    {t("alerts:list.create_alert_button")}
                  </button>
                </StyledTooltip>
              </PermissionBlocker>
            </div>
          </main>
        ) : (
          <main className="bapi-main mod-dashboard bapi-alerts-dashboard">
            <HeaderRow
              className="u-mobile-hide"
              infoLeftSideControl={
                <div className="left-rail-toggle-wrapper alerts-left-rail-icon-wrapper u-tablet-only">
                  <button className="btn btn-icon" style={{ display: "flex" }} onClick={() => setDrawerOpen(!drawerOpen)}>
                    <LeftRailToggleIcon />
                  </button>
                </div>
              }
              info={{ name: selectedAlert?.name }}
              fullWidth={false}
              infoControl={
                <PermissionBlocker permission={PermissionEnum.EDIT_ALERTS}>
                  <>
                    <div className="header-row-info-control">
                      <StyledTooltip title={t("alerts:details.edit_alert")} enterDelay={ICON_TOOLTIP_DEFAULT_DELAY}>
                        <button
                          className="btn btn-icon"
                          onClick={() => {
                            showAppModal(<AlertConfigModal alert={selectedAlert} />);
                          }}>
                          <i className="fa fa-edit u-text-teal mobile-tablet-icon" />
                          <span className="sr-only">{t("common:edit")}</span>
                        </button>
                      </StyledTooltip>

                      <StyledTooltip title={t("alerts:details.delete")} enterDelay={ICON_TOOLTIP_DEFAULT_DELAY}>
                        <button
                          className="btn btn-icon"
                          onClick={() => {
                            showAppModal(
                              <ConfirmModal
                                header={t("alerts:details.remove")}
                                children={<p>{t("alerts:details.confirm_delete", { name: selectedAlert?.name })}</p>}
                                confirmText={t("alerts:details.remove")}
                                onConfirm={deleteAlert}
                              />,
                            );
                          }}>
                          <i className="fa fa-trash u-text-teal mobile-tablet-icon" />
                          <span className="sr-only">{t("common:delete")}</span>
                        </button>
                      </StyledTooltip>
                    </div>
                  </>
                </PermissionBlocker>
              }
              control={
                selectedAlert?._id && selectedAlert?.Sensors ? (
                  <div className={classNames("dropdown header-row-section last large", { open: devicesOpen })}>
                    <div
                      className={classNames({ "dropdown-trigger": selectedAlert?.Sensors?.length > 0 })}
                      onClick={() => selectedAlert?.Sensors && selectedAlert?.Sensors.length > 0 && setDevicesOpen(true)}>
                      <i className="fa fa-cubes" />
                      <span>{t("alerts:details.alert_usage", { count: selectedAlert.Sensors.length })}</span>
                    </div>
                    {selectedAlert?.Sensors && selectedAlert?.Sensors.length > 0 && devicesOpen && (
                      <ClickAwayListener onClickAway={() => setDevicesOpen(false)}>
                        <DropdownMenu customStyles={{ right: "50px" }}>
                          {selectedAlert.Sensors.map((s) => (
                            <InfoMenuItem key={s.Device?._id}>{s.Device?.name}</InfoMenuItem>
                          ))}
                        </DropdownMenu>
                      </ClickAwayListener>
                    )}
                  </div>
                ) : undefined
              }
            />

            <MobileHeaderRow className="header-row-alerts-mobile-only u-mobile-only">
              <SelectInput
                label={t("alerts:alert_list")}
                className="input-filter-holder"
                style={{ flex: 1 }}
                displayEmpty={true}
                menuItemClass="dark"
                inputClassName="dark"
                value={selectedAlert ? selectedAlert._id : ""}
                onChange={(alertId) => {
                  const alert = sortedAlerts.find((alert) => alert._id === alertId);
                  if (alert) {
                    setSelectedAlert(alert as IAlertConfig);
                  }
                }}
                options={sortedAlerts.map((alert) => ({
                  value: alert._id,
                  label: alert.name,
                  key: alert._id.toString(),
                }))}
              />
              <MobileButtons>
                <PermissionBlocker permission={PermissionEnum.EDIT_ALERTS}>
                  <button
                    onClick={() => showAppModal(<AlertConfigModal alert={undefined} />)}
                    className="btn btn-circle btn-primary mobile-button">
                    <i className="fa fa-plus" />
                  </button>
                </PermissionBlocker>
              </MobileButtons>
            </MobileHeaderRow>

            <section className="alerts-mobile-first-section u-mobile-only">
              <div>
                <div className="alert-name">{selectedAlert?.name}</div>
                <div className="devices-usage">{t("alerts:details.alert_usage", { count: selectedAlert?.Sensors?.length })}</div>
              </div>

              <PermissionBlocker permission={PermissionEnum.EDIT_ALERTS}>
                <>
                  <div className="header-row-info-control u-mobile-only">
                    <StyledTooltip title={t("alerts:details.edit_alert")} enterDelay={ICON_TOOLTIP_DEFAULT_DELAY}>
                      <button className="btn btn-icon" onClick={() => showAppModal(<AlertConfigModal alert={selectedAlert} />)}>
                        <i className="fa fa-edit u-text-teal mobile-tablet-icon" />
                        <span className="sr-only">{t("common:edit")}</span>
                      </button>
                    </StyledTooltip>

                    <StyledTooltip title={t("alerts:details.delete")} enterDelay={ICON_TOOLTIP_DEFAULT_DELAY}>
                      <button
                        className="btn btn-icon"
                        onClick={() => {
                          showAppModal(
                            <ConfirmModal
                              header={"Remove Alert"}
                              children={<p>{t("alerts:details.confirm_delete", { name: selectedAlert?.name })}</p>}
                              confirmText={"Remove Alert"}
                              onConfirm={deleteAlert}
                            />,
                          );
                        }}>
                        <i className="fa fa-trash u-text-teal mobile-tablet-icon" />
                        <span className="sr-only">{t("common:delete")}</span>
                      </button>
                    </StyledTooltip>
                  </div>
                </>
              </PermissionBlocker>
            </section>

            <section className={classNames("main-panel main-panel-alerts", { isLoading: alertConfigs.isLoading })}>
              <div className="row u-mobile-hide u-tablet-hide">
                <div className="col-sm-6">
                  <p className="input-label">{t("alerts:details.sensor_type")}</p>
                  {selectedAlert?.Sensor_type ? (
                    <p className="type-large-regular">{t(`sensor_types:${getNameSlug(selectedAlert?.Sensor_type.name)}`)}</p>
                  ) : (
                    <></>
                  )}
                </div>

                <div className="col-sm-6">
                  <p className="input-label">{t("alerts:details.alert_type")}</p>
                  {alertType ? <p className="type-large-regular">{capitalize(alertType.label)}</p> : <></>}
                </div>
              </div>

              <div className="u-desktop-hide mobile-alert-types-row">
                <div className="col-sm-6">
                  <p className="input-label">{t("alerts:details.sensor_type")}</p>
                  {selectedAlert?.Sensor_type ? (
                    <p className="type-large-regular">{t(`sensor_types:${getNameSlug(selectedAlert?.Sensor_type.name)}`)}</p>
                  ) : (
                    <></>
                  )}
                </div>

                <div className="col-sm-6">
                  <p className="input-label">{t("alerts:details.alert_type")}</p>
                  {alertType ? <p className="type-large-regular">{capitalize(alertType.label)}</p> : <></>}
                </div>
              </div>

              <br />

              {/* TODO: This was buggy in the old system. It checked alertType directly rather than alertType.type. Explore what might happen now?  */}
              {range &&
              alertType &&
              selectedAlert &&
              alertType.type !== "transmitting" &&
              selectedAlert?.Sensor_type?.type !== "BOOLEAN" ? (
                <div className="row">
                  <div className="col-sm-6">
                    <p className="input-label mobile-alert-condition u-mobile-only">
                      {t("alerts:details.alert_condition")}{" "}
                      <div className="u-text-small u-opacity-fade mobile-alert-condition-row">
                        <div className="mobile-alert-condition-value">{AlertConditionService.transform(selectedAlert)}</div>

                        <button className="btn btn-primary readonly u-desktop-hide" style={{ marginRight: "1em" }}>
                          {UnitsService.transform(selectedAlert.selected_units)}
                        </button>
                      </div>
                    </p>

                    <p className="input-label u-desktop-only">
                      {t("alerts:details.alert_condition")}:{" "}
                      <div className="u-text-small u-opacity-fade">
                        <div style={{ color: "white" }}>{AlertConditionService.transform(selectedAlert)}</div>
                      </div>
                    </p>

                    <div className="u-flex-center">
                      <button className="btn btn-primary readonly u-mobile-hide u-tablet-hide" style={{ marginRight: "1em" }}>
                        {UnitsService.transform(selectedAlert.selected_units)}
                      </button>
                      <InputRangeSlider
                        readonly={true}
                        type={sliderType}
                        valueMin={selectedAlert.min}
                        valueMax={selectedAlert.max}
                        min={+range[0]}
                        max={+range[1]}
                      />
                    </div>
                  </div>

                  <div className="row u-tablet-only">
                    <div className="col-sm-6">
                      <p className="input-label alerts-label-mobile-tablet">{t("alerts:details.alert_protocol")}</p>
                      <br />
                      <p className="type-large-regular mobile-protocol">{selectedAlert?.protocol || "-"}</p>
                      <br />
                    </div>
                  </div>

                  <div className="col-sm-6">
                    <p className="input-label alerts-label-mobile-tablet">{t("alerts:details.approaching_threshold_warning")}</p>
                    <p className="u-text-small u-opacity-fade u-desktop-hide explanation">
                      {t("alerts:details.approaching_threshold_warning_explanation")}
                    </p>
                    <p className="input-holder">
                      <span className="type-large-regular">{displayThreshold}</span>
                      <span className="u-text-uppercase u-text-small"> {UnitsService.transform(selectedAlert?.selected_units)}</span>
                    </p>
                    <p className="u-text-small u-opacity-fade u-desktop-only">
                      {t("alerts:details.approaching_threshold_warning_explanation")}
                    </p>
                  </div>
                  <div className="col-sm-6 u-tablet-only">
                    <p className="input-label alerts-label-mobile-tablet">{t("alerts:details.initial_alert_delay")}</p>
                    <p className="u-text-small u-opacity-fade u-desktop-hide explanation">
                      {t("alerts:details.initial_delay_explanation")}
                    </p>
                    <p>
                      <span className="type-large-regular"> {Math.round(selectedAlert.delay_seconds / 60)} </span>
                      <span className="u-text-uppercase u-text-small">
                        {" "}
                        {t("alerts:details.minutes", { count: Math.round(selectedAlert.delay_seconds / 60) })}
                      </span>
                    </p>
                    <p className="u-text-small u-opacity-fade u-desktop-only">{t("alerts:details.initial_delay_explanation")}</p>
                  </div>
                </div>
              ) : (
                <></>
              )}

              <br />

              <div className="row">
                <div className="col-sm-6 col-lg-4 u-tablet-hide">
                  <p className="input-label alerts-label-mobile-tablet">{t("alerts:details.initial_alert_delay")}</p>
                  <p className="u-text-small u-opacity-fade u-desktop-hide explanation">{t("alerts:details.initial_delay_explanation")}</p>
                  <p>
                    <span className="type-large-regular"> {Math.round(selectedAlert.delay_seconds / 60)} </span>
                    <span className="u-text-uppercase u-text-small">
                      {" "}
                      {t("alerts:details.minutes", { count: Math.round(selectedAlert.delay_seconds / 60) })}
                    </span>
                  </p>
                  <p className="u-text-small u-opacity-fade u-desktop-only">{t("alerts:details.initial_delay_explanation")}</p>
                </div>
                <div className="col-sm-6 col-lg-4">
                  <p className="input-label alerts-label-mobile-tablet">{t("alerts:details.resend_alert_after")}</p>
                  <p className="u-text-small u-opacity-fade u-desktop-hide explanation">{t("alerts:details.resend_explanation")}</p>
                  <p>
                    <span className="type-large-regular">{Math.round((selectedAlert?.interval_seconds ?? 0) / 60)} </span>
                    <span className="u-text-uppercase u-text-small">
                      {" "}
                      {t("alerts:details.minutes", { count: Math.round((selectedAlert?.interval_seconds ?? 0) / 60) })}
                    </span>
                  </p>
                  <p className="u-text-small u-opacity-fade u-desktop-only">{t("alerts:details.resend_explanation")}</p>
                </div>
                <div className="col-sm-6 col-lg-4">
                  <p className="input-label alerts-label-mobile-tablet">{t("alerts:details.alert_snooze_time")}</p>
                  <p className="u-text-small u-opacity-fade u-desktop-hide explanation">{t("alerts:details.snooze_time_explanation")}</p>
                  <p>
                    <span className="type-large-regular">{Math.round((selectedAlert?.snooze_seconds ?? 0) / 60)}</span>
                    <span className="u-text-uppercase u-text-small">
                      {" "}
                      {t("alerts:details.minutes", { count: Math.round((selectedAlert?.snooze_seconds ?? 0) / 60) })}
                    </span>
                  </p>
                  <p className="u-text-small u-opacity-fade u-desktop-only">{t("alerts:details.snooze_time_explanation")}</p>
                </div>
              </div>
              <br />
              <div className="row u-tablet-hide">
                <div className="col-sm-12">
                  <p className="input-label alerts-label-mobile-tablet">{t("alerts:details.alert_protocol")}</p>
                  <br />
                  <p className="type-large-regular mobile-protocol">{selectedAlert?.protocol || "-"}</p>
                  <br />
                </div>
              </div>
              <br />
              <div className="row u-desktop-only">
                <div className="col-sm-12">
                  <p className="input-label">{t("alerts:details.alert_recipients")}:</p>
                  <ul className="select-group">
                    <li className="select-group-item header">
                      <div className="row u-full-width">
                        <div className="col-xs-2">{t("alerts:details.recipient.last_name")}</div>
                        <div className="col-xs-2">{t("alerts:details.recipient.first_name")}</div>
                        <div className="col-xs-2">{t("alerts:details.recipient.title")}</div>
                        <div className="col-xs-2">{t("alerts:details.recipient.email_alert")}</div>
                        <div className="col-xs-2">{t("alerts:details.recipient.sms_alert")}</div>
                        <div className="col-xs-1"></div>
                      </div>
                    </li>
                    {(selectedAlert?.Users || []).map((recipient) => (
                      <li className={classNames("select-group-item", { isSaving: isSaving === "recipient" + recipient._id })}>
                        <div className="row u-full-width">
                          <div className="col-xs-2">
                            <span> {recipient.last_name || "--"}</span>
                          </div>
                          <div className="col-xs-2">{recipient.first_name || "--"}</div>
                          <div className="col-xs-2">{recipient.title || "--"}</div>
                          <div className="col-xs-2">
                            <input
                              type="checkbox"
                              className="input-checkbox"
                              id={`email-${recipient._id}`}
                              checked={recipient.User_alert.email_alert}
                              onChange={(e) => {
                                updateRecipient(e.target.checked, recipient, "email_alert");
                              }}
                            />
                            <label htmlFor={`email-${recipient._id}`} className="input-label-checkbox input-label-white" />
                          </div>
                          <div className="col-xs-2">
                            <input
                              type="checkbox"
                              className="input-checkbox"
                              id={`sms-${recipient._id}`}
                              checked={recipient.User_alert.sms_alert}
                              onChange={(e) => {
                                updateRecipient(e.target.checked, recipient, "sms_alert");
                              }}
                            />
                            <label htmlFor={`sms-${recipient._id}`} className="input-label-checkbox input-label-white" />
                          </div>
                          <div className="col-xs-1">
                            <StyledTooltip title={t("alerts:details.remove_recipient")} enterDelay={ICON_TOOLTIP_DEFAULT_DELAY}>
                              <button
                                className={classNames("btn btn-circle", { "u-invisible": isSaving === "recipient" + recipient._id })}
                                onClick={() => removeRecipient(recipient)}>
                                <i className="fa fa-close" />
                                <span className="sr-only">{t("alerts:details.remove_recipient")}</span>
                              </button>
                            </StyledTooltip>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                  <br />
                  <button
                    className="btn btn-primary"
                    onClick={() => showAppModal(<AddRecipientModal onAdd={() => refreshList()} alert={selectedAlert} />)}>
                    {t("alerts:details.add_recipient")}
                  </button>
                </div>
              </div>
              <div className="row u-desktop-hide alert-recipients-mobile-tablet">
                <div className="col-sm-12">
                  <p className="input-label">{t("alerts:details.alert_recipients")}</p>
                  <ul className="select-group">
                    {(selectedAlert?.Users || []).map((recipient) => (
                      <li className={classNames("select-group-item", { isSaving: isSaving === "recipient" + recipient._id })}>
                        <div className="u-full-width alert-recipient-row">
                          <div className="alert-field">
                            <span className="input-label alert-label">{t("alerts:details.recipient.last_name")}</span>
                            <span>{recipient.last_name || "--"}</span>
                          </div>
                          <div className="u-tablet-only"></div>
                          <div className="alert-field">
                            <span className="input-label alert-label">{t("alerts:details.recipient.first_name")}</span>
                            <span>{recipient.first_name || "--"}</span>
                          </div>

                          <div className="alert-field delete-alert u-tablet-hide">
                            <StyledTooltip title={t("alerts:details.remove_recipient")} enterDelay={ICON_TOOLTIP_DEFAULT_DELAY}>
                              <button
                                className={classNames("btn btn-circle", { "u-invisible": isSaving === "recipient" + recipient._id })}
                                onClick={() => removeRecipient(recipient)}>
                                <i className="fa fa-close" />
                                <span className="sr-only">{t("alerts:details.remove_recipient")}</span>
                              </button>
                            </StyledTooltip>
                          </div>
                          <div className="u-tablet-only"></div>
                          <div className="alert-field">
                            <span className="input-label alert-label">{t("alerts:details.recipient.title")}</span>
                            <span>{recipient.title || "--"}</span>
                          </div>
                          <div className="u-mobile-only"></div>
                          <div className="u-mobile-only"></div>
                          <span className="input-label alert-label">{t("alerts:details.recipient.alert_type")}</span>
                          <div></div>
                          <div></div>
                          <div className="u-tablet-only"></div>
                          <div className="u-tablet-only"></div>
                          <div className="alert-field alert-field-checkbox">
                            <input
                              type="checkbox"
                              className="input-checkbox"
                              id={`email-${recipient._id}`}
                              checked={recipient.User_alert.email_alert}
                              onChange={(e) => {
                                updateRecipient(e.target.checked, recipient, "email_alert");
                              }}
                            />
                            <label htmlFor={`email-${recipient._id}`} className="input-label-checkbox input-label-white">
                              {t("alerts:details.recipient.email")}
                            </label>
                          </div>
                          <div className="alert-field alert-field-checkbox">
                            <input
                              type="checkbox"
                              className="input-checkbox"
                              id={`sms-${recipient._id}`}
                              checked={recipient.User_alert.sms_alert}
                              onChange={(e) => {
                                updateRecipient(e.target.checked, recipient, "sms_alert");
                              }}
                            />
                            <label htmlFor={`sms-${recipient._id}`} className="input-label-checkbox input-label-white">
                              {t("alerts:details.recipient.sms")}
                            </label>
                          </div>
                          <div className="u-tablet-only"></div>
                          <div className="u-tablet-only"></div>
                          <div className="alert-field delete-alert u-tablet-only">
                            <Button className="" variant="text" style={{ margin: "5px" }} onClick={() => removeRecipient(recipient)}>
                              <i className="fa fa-close remove-alert-recipient-icon" />
                              {t("alerts:details.remove_recipient_remove")}
                            </Button>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                  <br />
                  <div className="row" style={{ margin: "auto", textAlign: "center" }}>
                    <button
                      className="btn btn-primary"
                      style={{ padding: "8px 32px" }}
                      onClick={() => showAppModal(<AddRecipientModal onAdd={() => refreshList()} alert={selectedAlert} />)}>
                      {t("alerts:details.add_recipient")}
                    </button>
                  </div>
                </div>
              </div>
            </section>
          </main>
        )}
      </Wrapper>
    </Page>
  );
});
