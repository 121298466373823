import React, { forwardRef, useEffect, useRef, useState } from "react";
import Quill from "quill";
import "quill/dist/quill.snow.css";
import "./RichEditor.scss";
import { useTranslation } from "react-i18next";

interface IRichEditorProps {
  className?: string;
  readonly: boolean;
  defaultValue?: string;
  onTextChange: (text: string) => void;
}

// Editor is an uncontrolled React component
export const RichEditor = forwardRef<HTMLDivElement | undefined, IRichEditorProps>(
  ({ readonly, defaultValue, onTextChange, className = "" }, ref) => {
    const containerRef = useRef(null);
    const [quill, setQuill] = useState<Quill>();
    const { t } = useTranslation("common");

    useEffect(() => {
      if (quill) {
        quill.setContents(quill.clipboard.convert({ html: defaultValue }));
      }
    }, [defaultValue, quill]);

    useEffect(() => {
      (ref as any)?.current?.enable(!readonly);
    }, [ref, readonly]);

    useEffect(() => {
      const container = containerRef.current;

      if (container) {
        const editorContainer = (container as any).appendChild((container as any).ownerDocument.createElement("div"));
        const q = new Quill(editorContainer, {
          theme: "snow",
          modules: {
            toolbar: "#toolbar",
          },
        });

        (ref as any).current = q;

        q.on(Quill.events.TEXT_CHANGE, () => onTextChange(q.getSemanticHTML()));

        setQuill(q);
      }

      return () => {
        (ref as any).current = null;
        (container as any).innerHTML = "";
      };
    }, [ref]);

    return (
      <>
        <div id="toolbar" className="ql-toolbar ql-snow">
          <select className="ql-header" defaultValue="false">
            <option value="false">{t("common:heading", { context: "normal" })}</option>
            <option value="1">{t("common:heading", { context: 1 })}</option>
            <option value="2">{t("common:heading", { context: 2 })}</option>
            <option value="3" className="h3">
              {t("common:heading", { context: 3 })}
            </option>
          </select>
          <span className="ql-formats">
            <button className="ql-bold" />
            <button className="ql-italic" />
            <button className="ql-underline" />
          </span>
          <span className="ql-formats">
            <button className="ql-list" value="ordered" />
            <button className="ql-list" value="bullet" />
            <button className="ql-indent" value="-1" />
            <button className="ql-indent" value="+1" />
          </span>
          <span className="ql-formats">
            <select className="ql-align" />
            <select className="ql-color" />
            <select className="ql-background" />
          </span>
          <span className="ql-formats">
            <button className="ql-script" value="super" />
            <button className="ql-script" value="sub" />
          </span>
          <span className="ql-formats">
            <button className="ql-link" />
          </span>
          <span className="ql-formats">
            <button className="ql-code-block" />
            <button className="ql-clean" />
          </span>
        </div>
        <div className={className} ref={containerRef}></div>
      </>
    );
  },
);
