import * as yup from "yup";
import { Formik } from "formik";
import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { AppState, fetchMaintenanceNotificationData, refreshAppState, showAppModal, showSnackbar } from "../AppState";
import { FormFieldCheckbox, FormFieldTextArea } from "../Components";
import { isUserRoleAllowed, UserRoles, ValidationMessages } from "../Enums";
import { Modal } from "../Components/Modal";
import { IManageMaintenanceNotification } from "../Managers/Types";
import {
  resetUsersMaintenanceNotificationClosedFlag,
  sendMaintenanceNotificationEmails,
  updateMaintenanceNotification,
} from "../Managers/MaintenanceNotificationService";
import "./MaintenanceNotificationModal.scss";
import { ConfirmModal } from "./ConfirmModal";
import { useTranslation } from "react-i18next";

const validationSchema = yup.object({
  message: yup.string().required(ValidationMessages.REQUIRED),
  active: yup.boolean(),
});

export const MaintenanceNotificationModal: React.FC = observer(() => {
  const [initialValues] = useState<IManageMaintenanceNotification>({
    message: AppState.maintenanceNotification.message ?? "Test message",
    active: AppState.maintenanceNotification.active ?? false,
  });
  const [isSendingEmails, setIsSendingEmails] = useState(false);
  const { t } = useTranslation(["common", "maintenance_notification"]);

  if (!AppState.user) {
    return <></>;
  }

  const handleManageMaintenanceNotificationUpdate = (values: IManageMaintenanceNotification) => {
    if (!AppState.user || !isUserRoleAllowed(UserRoles.APP_ADMIN)) {
      return;
    }

    updateMaintenanceNotification({ ...AppState.maintenanceNotification, ...values })
      .then((r) => {
        resetUsersMaintenanceNotificationClosed();
        fetchMaintenanceNotificationData();
        console.log("updateMaintenanceNotification result", r);
        showAppModal(null);
        showSnackbar(t("maintenance_notification:update_success"), "success");
        return refreshAppState();
      })
      .catch((error) => {
        console.log("Update Maintenance Notification error", error);
        showSnackbar(t("maintenance_notification:update_error"), "error");
      });
  };

  const handleSendMaintenanceNotificationMails = () => {
    setIsSendingEmails(true);
    sendMaintenanceNotificationEmails()
      .then((r) => {
        console.log("Maintenance notification emails result", r);
        showAppModal(null);
        showSnackbar(t("maintenance_notification:emails_sent_success"), "success");
      })
      .catch((e) => {
        console.log("Error sending maintenance notification emails", e);
        showSnackbar(t("maintenance_notification:emails_sent_error"), "error");
      })
      .finally(() => setIsSendingEmails(false));
  };

  const resetUsersMaintenanceNotificationClosed = () => {
    resetUsersMaintenanceNotificationClosedFlag()
      .then((r) => console.log("resetUsersMaintenanceNotificationClosed", r))
      .catch((e) => console.log("Error while resetting UsersMaintenanceNotificationClosed", e));
  };

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleManageMaintenanceNotificationUpdate}>
      {({ isSubmitting, isValid, values, submitForm, initialValues, setFieldValue, setFieldTouched }) => {
        return (
          <Modal
            title={t("maintenance_notification:title")}
            buttons={
              <>
                <button className="btn btn-info" onClick={() => showAppModal(null)} disabled={isSubmitting}>
                  {t("common:cancel")}
                </button>
                <button className="btn btn-primary" disabled={!isValid || isSubmitting} onClick={submitForm}>
                  {isSubmitting ? <i className="fa fa-circle-o-notch fa-spin" /> : <></>}
                  {t("common:save_changes")}
                </button>
              </>
            }>
            <form>
              <div className="row">
                <FormFieldTextArea
                  required={true}
                  className="message-notification"
                  name="message"
                  label={t("maintenance_notification:message")}
                  placeholder={t("maintenance_notification:message")}
                  initialValue={initialValues.message}
                />
              </div>

              <div className="row">
                <FormFieldCheckbox
                  required={true}
                  name="active"
                  checked={values.active}
                  label={t("maintenance_notification:active_label")}
                  onChange={(e) => {
                    setFieldTouched("active", true);
                    setFieldValue("active", e.target.checked);
                  }}
                />
              </div>

              <div className="row" style={{ marginTop: 20 }}>
                <p className="input-label">{t("maintenance_notification:send_notification")}</p>
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    showAppModal(
                      <ConfirmModal
                        header={t("maintenance_notification:send_notification")}
                        children={<p>{t("maintenance_notification:send_notification_body")}</p>}
                        confirmText={t("maintenance_notification:send_notification_confirm")}
                        onConfirm={handleSendMaintenanceNotificationMails}
                      />,
                    );
                  }}
                  disabled={isSendingEmails}>
                  {isSendingEmails ? <i className="fa fa-spin fa-circle-o-notch" /> : <></>}
                  {t("maintenance_notification:send_notification_confirm").toUpperCase()}
                </button>
                <br />
              </div>
            </form>
          </Modal>
        );
      }}
    </Formik>
  );
});
