import React, { Suspense, useEffect, useState } from "react";
import { CircularProgress, ThemeProvider } from "@mui/material";
import { QueryClientProvider } from "react-query";
import { Content, PageWrapper } from "./Components";
import { MainRouter } from "./Views/MainRouter";
import { ModalServiceProvider } from "./Modals";
import { queryClient } from "./Managers/API";
import { wamTheme } from "./Theme";
import "./Styles/styles.scss";
import "./Main.scss";
import { checkSession, updateAppMode } from "./AppState";

function App() {
  const [checkingSession, setCheckingSession] = useState(true);

  const handleWindowSizeChange = () => updateAppMode(window.innerWidth);

  useEffect(() => {
    handleWindowSizeChange();
    checkSession().finally(() => setCheckingSession(false));
    window.addEventListener("resize", handleWindowSizeChange);

    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  if (checkingSession) {
    return (
      <PageWrapper>
        <Content style={{ alignItems: "center", justifyContent: "center" }}>
          <CircularProgress />
        </Content>
      </PageWrapper>
    );
  }

  return (
    <Suspense fallback={<></>}>
      <ThemeProvider theme={wamTheme}>
        <QueryClientProvider client={queryClient}>
          <ModalServiceProvider>
            <MainRouter />
          </ModalServiceProvider>
        </QueryClientProvider>
      </ThemeProvider>
    </Suspense>
  );
}

export default App;
