import { getUsers } from "../../Managers/UsersService";
import { Table, TableBody, TableCell, TableHead, TableRow } from "../../Components/Table";
import React, { useEffect, useState } from "react";
import { IUser } from "../../Managers/Types";
import { countries, exportToXLSX, getMainCountries, ICountry } from "../../Managers";
import { Flag, HeaderRow, LeftRail, Page, SelectInput, Wrapper } from "../../Components";
import "./ExportUsers.scss";
import { UserNav } from "./UsersNav";
import { useTranslation } from "react-i18next";
import { MobileUserHeader } from "./MobileUserHeader";
import { AppState } from "../../AppState";
import { Drawer } from "@mui/material";
import { DrawerIcon } from "../../icon";

export const ExportUsers: React.FC = () => {
  const [selectedUsers, setSelectedUsers] = useState<IUser[]>([]);
  const [selectedCountry, setSelectedCountry] = useState<ICountry | undefined>(countries.find((c) => c.code === "US")!);
  const [selectedCompany, setSelectedCompany] = useState<string | undefined>();
  const [selectedMarketingFilter, setSelectedMarketingFilter] = useState<string | boolean>("all");
  const [filteredUsers, setFilteredUsers] = useState<IUser[]>([]);
  const [companies, setCompanies] = useState<string[]>([]);
  const [users, setUsers] = useState<IUser[]>([]);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { t } = useTranslation("users");

  useEffect(() => {
    getUsers(true).then((res) => setUsers(res));
  }, []);

  useEffect(() => {
    setFilteredUsers(
      users
        .filter((u) => selectedCountry?.code === "all" || u.country_code === selectedCountry?.code)
        .filter((u) => selectedCompany === "all" || u.Account?.name === selectedCompany)
        .filter((u) => selectedMarketingFilter === "all" || u.is_marketing_accepted === selectedMarketingFilter),
    );

    if (companies.length === 0 && users) {
      const companiesList = users.filter((u) => u.Account).map((u) => u.Account!.name) ?? [];
      const companiesSet = new Set<string>(companiesList);
      setCompanies(Array.from(companiesSet));
    }

    if (!selectedCompany && users) {
      setSelectedCompany(users.find((u) => u.Account)?.Account?.name);
    }
  }, [selectedCountry, selectedCompany, users, companies.length, selectedMarketingFilter]);

  const handleMarketingChange = (marketing: string | boolean) => {
    if (marketing !== "all") {
      setSelectedUsers([]);
    }
    setSelectedMarketingFilter(marketing);
  };

  const selectUsers = (users: IUser[], select = true) => {
    const selected = [...selectedUsers];

    users.forEach((user) => {
      const selectedUserIndex = selected.findIndex((u) => u._id === user._id);
      if (select && selectedUserIndex === -1) {
        selected.push(user);
      } else if (!select) {
        selected.splice(selectedUserIndex, 1);
      }
    });

    setSelectedUsers(selected);
  };

  const exportToCSV = () => {
    const exportData = selectedUsers.map((u) => {
      const { first_name, last_name, title, Account, email, phone_code, phone, country_code, is_marketing_accepted } = u;

      return {
        first_name,
        last_name,
        title,
        company: Account?.name,
        email,
        phone: (phone_code ?? "") + (phone ?? ""),
        country_code,
        is_marketing_accepted: is_marketing_accepted ? "YES" : "NO",
      };
    });

    exportToXLSX(`${t("export.export_title")} ${new Date().toDateString()}`, exportData, [
      t("export.first_name"),
      t("export.last_name"),
      t("export.company"),
      t("export.title"),
      t("export.email"),
      t("export.phone"),
      t("export.country"),
    ]);
  };

  const handleCountryChange = (code: string | ICountry) => {
    if (typeof code === "string" && code === "all") {
      setSelectedCountry({ code: "all" } as ICountry);
    } else if (typeof code === "string") {
      setSelectedUsers([]);
      setSelectedCountry(countries.find((c) => c.code === code)!);
    }
  };

  const handleCompanyChange = (company: string) => {
    if (company !== "all") {
      setSelectedUsers([]);
    }

    setSelectedCompany(company);
  };

  return (
    <Page>
      <Wrapper>
        <Drawer
          variant={AppState.mode === "desktop" ? "permanent" : "temporary"}
          hideBackdrop={AppState.mode === "desktop"}
          onBackdropClick={() => AppState.mode === "tablet" && setDrawerOpen(false)}
          open={drawerOpen || AppState.mode === "desktop"}>
          <LeftRail header={<UserNav value="export" />} body={<></>} />
        </Drawer>
        <main className="bapi-main export-users mod-dashboard">
          <MobileUserHeader currentPage={"export"} />
          <HeaderRow
            info={{
              name: (
                <>
                  <button onClick={() => setDrawerOpen(!drawerOpen)} className="btn btn-icon u-tablet-only">
                    <DrawerIcon />
                  </button>
                  {t("users:export.page_title")}
                </>
              ),
            }}
            infoControl={
              <div className="header-row-info-control" style={{ gap: "2em" }}>
                <div className="col-sm-4">
                  <SelectInput
                    value={selectedCountry?.code}
                    label={t("export.country")}
                    onChange={(c) => handleCountryChange(c)}
                    options={[
                      { value: "all", label: t("common:all") },
                      ...getMainCountries().map((c) => ({
                        label: (
                          <>
                            <Flag countryCode={c.code} />
                            {c.name}
                          </>
                        ),
                        value: c.code,
                      })),
                    ]}
                  />
                </div>
                <div className="col-sm-4">
                  <SelectInput
                    value={selectedCompany}
                    label={t("users:export.company")}
                    onChange={(c) => handleCompanyChange(c)}
                    options={[
                      { value: "all", label: t("common:all") },
                      ...companies.map((c) => ({
                        value: c,
                        label: c,
                      })),
                    ]}
                  />
                </div>
                <div className="col-sm-4">
                  <SelectInput
                    value={selectedMarketingFilter}
                    label={t("users:export.marketing_info")}
                    onChange={(c) => handleMarketingChange(c)}
                    options={[
                      { value: "all", label: t("common:all") },
                      { value: true, label: t("common:yes") },
                      { value: false, label: t("common:no") },
                    ]}
                  />
                </div>
              </div>
            }
            mainRight={
              <button className="btn btn-primary u-desktop-only" disabled={selectedUsers.length === 0} onClick={exportToCSV}>
                <span>{t("export.xlx_export")}</span>
              </button>
            }
          />
          <section className="main-panel export-users-panel">
            <div className="user-table-wrapper">
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell width={20}>
                      <label className="checkbox">
                        <input
                          type="checkbox"
                          className="input-checkbox"
                          id="users"
                          name="users"
                          onChange={(e) => selectUsers(filteredUsers ?? [], e.target.checked)}
                          checked={filteredUsers.length === selectedUsers.length}
                        />
                        <span />
                      </label>
                    </TableCell>
                    <TableCell width="20%">{t("users:export.name")}</TableCell>
                    <TableCell width="20%">{t("users:export.company")}</TableCell>
                    <TableCell width="15%">{t("users:export.title")}</TableCell>
                    <TableCell>{t("users:export.email")}</TableCell>
                    <TableCell width={170}>{t("users:export.phone")}</TableCell>
                    <TableCell width={50}>{t("users:export.country")}</TableCell>
                    <TableCell width={50}>{t("users:export.marketing_info")}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredUsers.map((user) => (
                    <TableRow key={user._id}>
                      <TableCell>
                        <label className="checkbox">
                          <input
                            type="checkbox"
                            className="input-checkbox"
                            id="users"
                            name="users"
                            onChange={(e) => selectUsers([user], e.target.checked)}
                            checked={selectedUsers.some((u) => user._id === u._id)}
                          />
                          <span />
                        </label>
                      </TableCell>
                      <TableCell>
                        {user.first_name} {user.last_name}
                      </TableCell>
                      <TableCell>{user.Account?.name}</TableCell>
                      <TableCell>{user.title}</TableCell>
                      <TableCell>{user.email}</TableCell>
                      <TableCell>
                        {user.phone_code} {user.phone}
                      </TableCell>
                      <TableCell>
                        {user.country_code ? <Flag countryCode={user.country_code} /> : null}
                        {user.country_code}
                      </TableCell>
                      <TableCell>{user.is_marketing_accepted ? t("common:yes") : t("common:no")}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <div style={{ textAlign: "center" }}>{filteredUsers.length === 0 ? t("users:export.no_users_found") : null}</div>
            </div>
            <div className="main-panel-footer">
              <button className="btn btn-primary u-desktop-hide" disabled={selectedUsers.length === 0} onClick={exportToCSV}>
                <span>{t("export.xlx_export")}</span>
              </button>
            </div>
          </section>
        </main>
      </Wrapper>
    </Page>
  );
};
