import * as React from "react";
import { styled, TooltipProps } from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

export const ICON_TOOLTIP_DEFAULT_DELAY = 500;
// See https://mui.com/components/tooltips/#customization
export const StyledTooltip: React.FC<TooltipProps> = styled(({ className, children, ...props }: any) => (
  <Tooltip {...props} arrow classes={{ popper: className }}>
    {children}
  </Tooltip>
))(({ theme }: { theme: any }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    fontSize: 12,
    backgroundColor: theme.palette.common.black,
  },
}));
