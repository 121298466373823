import React from "react";
import { ErrorMessage, useField } from "formik";
import classNames from "classnames";
import { IStandardInput } from "./Types";
import { InputLabel } from "./InputLabel";

export interface IFormFieldCheckboxProps extends IStandardInput {
  checked: boolean;
  displayError?: boolean;
}

export interface ICheckboxInput extends IFormFieldCheckboxProps {
  fieldOnChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  setTouched?: () => void;
  value?: boolean;
  labelClassName?: string;
}

export const CheckboxInput: React.FC<ICheckboxInput> = ({
  className = "",
  checked,
  displayError = false,
  readOnly = false,
  name,
  label,
  onChange,
  disabled,
  required,
  fieldOnChange,
  labelClassName = "",
  setTouched,
}) => {
  // We may have many check boxes with the same field name. They need separate IDs.
  const id = `${name}_${Math.random().toString(36).substring(2, 9)}`;

  // TODO: This does not have form-group, if anything needs it applied, it should pass it in
  // TODO: Checkboxes had a readonly pattern that applied a class rather than the HTML readonly attribute. See if this applies elsewhere.
  return (
    <div className={className}>
      <input
        type="checkbox"
        className="input-checkbox"
        id={id}
        disabled={disabled}
        checked={checked}
        onChange={(e) => {
          fieldOnChange && fieldOnChange(e);
          setTouched && setTouched();
          onChange && onChange(e);
        }}
      />
      <InputLabel
        name={id}
        className={classNames(
          "input-label-checkbox",
          label ? "" : "checkbox-hidden-label",
          {
            readonly: readOnly,
          },
          labelClassName,
        )}
        label={label}
        required={required}
      />
      {displayError ? (
        <div className="checkbox-input-error">
          <ErrorMessage name={name} />
        </div>
      ) : null}
    </div>
  );
};

export const FormFieldCheckbox: React.FC<IFormFieldCheckboxProps> = (props) => {
  const [field, meta] = useField(props.name ?? "");

  return (
    <CheckboxInput
      {...props}
      setTouched={() => (meta.touched = true)}
      value={field.value}
      labelClassName={meta.touched && Boolean(meta.error) ? "error" : ""}
    />
  );
};
