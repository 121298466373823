import bb, { bubble, Chart, DataItem, line, zoom } from "billboard.js";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import "billboard.js/dist/billboard.css";
import "./TimeSeriesLineChart.scss";
import { ErrorBoundary } from "./ErrorBoundary";
import { useTranslation } from "react-i18next";
import { Colors } from "../Theme";
import classNames from "classnames";
import { AppState } from "../AppState";

// NOTE: This is the "new" component. For reference to old funtionality see OldLineChart.tsx

// TODO: We could clean up a lot of code if ISetpoint and ILineChartValue shared a common structure
export interface ISetpoint {
  createdAt: Date;
  id: number;
  target: number;
  unit: string;
  value: string | number | null;
  _id: number;
  elapsed?: string;
}

export interface ILineChartValue {
  x: Date;
  y: number | string | null;
  unit: string;
  triggeredTarget?: number;
}

export interface ILineChartProps {
  className?: string;
  data: ILineChartValue[];
  setPoints: ISetpoint[];
  valueName?: string;
  setFilteredDataHook?: React.Dispatch<React.SetStateAction<ILineChartValue[]>>;
  onSetpointClick?: (data: ISetpoint) => void;
  onExport?: (imgData: any) => void;
  bindToSuffix?: string;
  onRendered?: () => void;
  // hover?: number | null;
  // sensorType: any;
  // onClearPoints?: () => void;
  // onChartChanges?: (data: any) => void;
}

export const TimeSeriesLineChart: React.FC<ILineChartProps> = ({
  data,
  setPoints,
  className,
  valueName = "Value",
  setFilteredDataHook = null,
  onSetpointClick = null,
  onExport = null,
  bindToSuffix = "",
  onRendered,
}) => {
  const originalZoomDomain = useMemo(() => {
    return data.length > 0 && data[0].x ? [data[0].x.toISOString(), data[data.length - 1].x.toISOString()] : ["", ""];
  }, [data]);

  const isContact = data?.[0]?.unit === "off/on" ?? false;

  const [chartComponent, setChartComponent] = useState<Chart | null>(null);
  const [zoomDomain, setZoomDomain] = useState<string[]>(originalZoomDomain);
  const [filteredData, setFilteredData] = useState<ILineChartValue[]>(data);
  const [showResetZoom, setShowResetZoom] = useState(false);
  const [chartClass, setChartClass] = useState("");

  const { t } = useTranslation("common");

  const handleResetZoom = () => {
    setFilteredData(data);
    setZoomDomain(originalZoomDomain);
    setShowResetZoom(false);
  };

  const x1 = filteredData.map((entry) => entry.x);
  const values = filteredData.map((entry) => (entry.y === null ? null : Number(entry.y === "ON" ? 1 : entry.y === "OFF" ? 0 : entry.y)));
  const x2 = setPoints.map((entry) => entry.createdAt);
  const setpoints = setPoints.map((entry) => Number(entry?.value));
  const setpoint_line = setPoints.map((entry) => Number(entry?.value));

  const chart = bb.generate({
    bindto: `#line-chart${bindToSuffix}`,
    transition: {
      duration: 0,
    },
    onrendered: function () {
      onRendered?.();
    },
    data: {
      onover(this, data, _element?: SVGElement) {
        if (!chartClass && AppState.mode !== "desktop") {
          setChartClass("show-tooltip");
          this.tooltip.show({ x: 1, data });
        }
      },
      onout(this, _data: DataItem, _element?: SVGElement) {
        if (chartClass && AppState.mode !== "desktop") {
          setChartClass("");
          this.tooltip.hide();
        }
      },
      onclick(this, d, _element) {
        if (onSetpointClick != null) {
          const date: any = d.x;
          const originalIndex = data.findIndex((value) => value.x.getTime() === new Date(date).getTime());
          const id = originalIndex ?? 0;
          if (id > 0) {
            const lineChartValue: ILineChartValue = data[id];
            const setpoint: ISetpoint = {
              _id: id,
              id: id,
              value: d.value,
              target: d.value,
              unit: lineChartValue.unit,
              createdAt: lineChartValue.x,
            };

            onSetpointClick?.(setpoint);
          }
        }
      },
      xs: {
        values: "x1",
        setpoints: "x2",
        setpoint_line: "x2",
      },
      type: line(),
      types: {
        setpoints: bubble(),
        setpoint_line: line(),
      },
      colors: {
        values: "#55cdcd",
        setpoints: Colors.warning,
        setpoint_line: "",
      },
      // Should look like: [['series1', 30, 200, 100, 400, 150, 250], ...]
      columns: [
        ["x1", ...x1],
        ["values", ...values],
        ["x2", ...x2],
        ["setpoints", ...setpoints],
        ["setpoint_line", ...setpoint_line],
      ],
    },
    // @see https://naver.github.io/billboard.js/release/latest/doc/Options.html#.line
    line: {
      // This is the default but I force-set it here in case that ever changes. We do not want 'null' entries "connected".
      connectNull: false,
      classes: ["values", "setpoints"],
      point: ["values", "setpoints", "setpoint_line"],
    },
    point: {
      r: 1,
    },
    bubble: {
      maxR: 8,
    },
    zoom: {
      enabled: zoom(),
      type: "drag",
      //rescale: true,
      onzoomend(this, domain) {
        setZoomDomain(domain);
        filterValues(domain);
      },
      resetButton: false,
      // {
      //   onclick(this, button) {
      //     setShowResetZoom(false);
      //     setFilteredData(data);
      //     setZoomDomain(originalZoomDomain);
      //   },
      // }
    },
    legend: {
      show: false,
    },
    tooltip: {
      format: {
        title: function (entry) {
          return entry.toLocaleString(); //d3.timeFormat('%Y-%m-%d')(x);
        },
        name: function (name: string) {
          return name === "values" ? valueName : t("common:setpoint");
        },
        value: (entry) => {
          return `${isContact ? (entry === 0 ? t("common:off").toUpperCase() : t("common:on").toUpperCase()) : entry} ${
            data?.[0]?.unit || ""
          }`;
        },
      },
      grouped: false,
      doNotHide: AppState.mode !== "desktop",
    },
    axis: {
      x: {
        // TODO: Set tick values manually to even intervals through the data set: noon/midnight for a few days, hourly for a day, etc.
        // https://naver.github.io/billboard.js/release/latest/doc/Options.html#.axis%25E2%2580%25A4x%25E2%2580%25A4tick%25E2%2580%25A4values
        // Discuss vert line per day
        tick: {
          fit: false,
          // format: function (x: Date | number) {
          //   return typeof x === 'number' ? format(new Date(x), 'KK:mm aaa \n LL/dd/yyyy') : x.getFullYear();
          // },
          // multiline: true,
          count: 5,
          // culling: {
          //   max: 5,
          // },
        },
        type: "timeseries",
      },
      y: {
        tick: {
          format(x: number) {
            //const v = Number(x);
            //return v === 0 ? "OFF" : v === 1 ? "ON" : v;
            //console.log(x);
            return isContact ? (x === 0 ? t("common:off").toUpperCase() : x === 1 ? t("common:on").toUpperCase() : "") : x.toString();
          },
        },
      },
      // y: {
      //   tick: {
      //     count: isContact ? 2 : 10,
      //     values(this) {
      //       return isContact ? [0, 1] : [0, Number(data?.[data.length - 1]?.y) + 5 ?? 100]
      //     },
      //   }
      // },
    },
    grid: {
      y: {
        show: true,
        ticks: 8,
      },
    },
  });

  if (isContact) {
    // get all y values, filter to see if there are ON values, see if every value is null
    // if it's null, that means there aren't any ON values in this result set
    const allContactOff = data
      .map((x) => x.y)
      .filter((d) => d !== "OFF")
      .every((v) => v === null);
    if (allContactOff) {
      chart.config("axis.y.tick.count", 1, true);
      chart.config("axis.y.tick.values", [0], true);
    } else {
      chart.config("axis.y.tick.count", 3, true);
      chart.config("axis.y.tick.values", [0, 1], true);
    }
  }

  const filterValues = useCallback(
    (zoomDomain: string[]) => {
      const filtered = data.filter((d) => d.x >= new Date(zoomDomain[0]) && d.x <= new Date(zoomDomain[1]));
      setFilteredData(filtered);
      if (setFilteredDataHook !== null) {
        setFilteredDataHook(filtered);
      }
    },
    [data, setFilteredDataHook],
  );

  if (onExport != null) {
    chartComponent?.export(
      {
        mimeType: "image/png",
      },
      (dataUrl) => {
        onExport?.(dataUrl);
      },
    );
  }

  // first render show graph only
  useEffect(() => {
    setChartComponent(chart);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    console.log(filteredData.length);
  }, [data, filteredData.length]);

  // when zoom changes
  useEffect(() => {
    if (zoomDomain !== originalZoomDomain) {
      setShowResetZoom(true);
      filterValues(zoomDomain);
      chart.zoom(zoomDomain);
    }

    if (zoomDomain === originalZoomDomain) {
      console.log("reset clicked");
      setShowResetZoom(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zoomDomain]);

  useEffect(() => {
    chart.config("tooltip.doNotHide", AppState.mode !== "desktop", true);
  }, [AppState.mode]);

  return (
    <ErrorBoundary>
      <div className={classNames("line-chart-holder", chartClass)}>
        <div
          className="bb-button"
          id="btn-reset-zoom"
          onClick={() => handleResetZoom()}
          style={{ visibility: showResetZoom ? "inherit" : "hidden" }}>
          <span className="bb-zoom-reset">{t("common:reset_zoom")}</span>
        </div>
        <div id={`line-chart${bindToSuffix}`} className={className}></div>
      </div>
    </ErrorBoundary>
  );
};
