import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { AppState, setLoggedIn, showAppModal, showSnackbar } from "../../AppState";
import { Column, PermissionBlocker, PlanDetails, Row } from "../../Components";
import { getAccountSubscription, getPaymentLink, getSupportEmail, resumeCancelledSubscription } from "../../Managers";
import { IInvoice, IPlanProps, ISubscription } from "../../Managers/Types";
import { InvoicesTable } from "./InvoicesTable";
import { CancelSubscription } from "./CancelSubscription";
import { UpdatePlanModal } from "../UpdatePlanModal";
import { CalculatePrice } from "../../Managers/PaymentOptionsService";
import { PermissionEnum } from "../../Enums";
import { Modal } from "../../Components/Modal";
import "./BillingInfo.scss";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { URLS } from "../../Views/URLS";
import { useNavigate } from "react-router-dom";

interface SelfProps {
  expiredMode?: boolean;
}

export const BillingInfo: React.FC<SelfProps> = observer(({ expiredMode }) => {
  const user = AppState.user;
  const mailTo = getSupportEmail();
  const navigate = useNavigate();

  const [accountSubscription, setAccountSubscription] = useState<ISubscription[]>();
  const [accountInvoices, setAccountInvoices] = useState<IInvoice[]>();
  useEffect(() => {
    getAccountSubscription(user?.AccountId ?? 0).then((data) => {
      setAccountSubscription(data.subscription);
      setAccountInvoices(data.invoices);
    });
  }, [user?.AccountId]);

  const { t } = useTranslation("billing_info");

  const calculatePrice = (values: IPlanProps) =>
    CalculatePrice(values, AppState.user?.country_code ?? "US", AppState.user?.Account?.Currency?.iso_code ?? "USD").then((value) => {
      return value;
    });

  const showCancellationButton = (): boolean => {
    return !user?.Account?.blocked && (expiredMode === true || (!user?.Account?.cancel_at_period_end ?? false));
  };

  const showResumeButton = (): boolean => {
    return !user?.Account?.blocked && expiredMode !== true && (user?.Account?.cancel_at_period_end ?? false);
  };

  const isAccountInDemoMode = user?.Account?.is_demo || false;

  const resumeSubscription = () => {
    resumeCancelledSubscription(AppState.user?.Account?.stripe_subscription_id)
      .then(() => {
        showSnackbar(t("billing_info:subscription.resume_success"), "error");
        if (AppState.user && AppState.user.Account) {
          AppState.user.Account.cancel_at_period_end = false;
        }
        showAppModal(null);
      })
      .catch(() => {
        showSnackbar(t("billing_info:subscription.resume_error"), "error");
      });
  };

  const updatePaymentMethod = async () => {
    getPaymentLink().then((link) => {
      if (link.link.url) {
        window.location.href = link.link.url;
      }
    });
  };

  const contact = (
    <>
      {t("billing_info:subscription.contact_info")}{" "}
      <div className={"link"} onClick={() => (window.location.href = "mailto:" + mailTo)} style={{ display: "inline" }}>
        {mailTo}
      </div>
    </>
  );

  const logOut = () => {
    showAppModal(null);
    setLoggedIn(false, null, null);
    navigate({ pathname: URLS.Dashboard });
  }

  return (
    <Modal
      className={AppState.mode !== "mobile" ? "modal-lg" : ""}
      title={t("billing_info:subscription.title")}
      buttons={
        <div className="billing-modal-footer">
          <button className="btn btn-primary" onClick={() => (expiredMode ? logOut() : showAppModal(null))}>
            {expiredMode ? t("billing_info:log_out") : t("common:close")}
          </button>
          <div className="u-mobile-only">{contact}</div>
        </div>
      }>
      <PlanDetails
        updatePaymentMethod={updatePaymentMethod}
        displayUpdatePaymentButton={true}
        accountSubscription={accountSubscription}
        account={user?.Account}
        expiredMode={expiredMode}
      />
      <div className="billing-buttons">
        <PermissionBlocker permission={PermissionEnum.SUBSCRIPTION_ACCESS}>
          <button
            className="text-right btn btn-primary"
            onClick={() => {
              showAppModal(
                <UpdatePlanModal
                  currentDevicesCount={accountSubscription?.find((sub) => sub.plan === "numberOfDevices")?.used}
                  currentUsersCount={accountSubscription?.find((sub) => sub.plan === "numberOfUsers")?.used}
                  calculateTotal={calculatePrice}
                />,
              );
            }}>
            {isAccountInDemoMode ? t("billing_info:subscription.update_to_regular") : t("billing_info:subscription.update_plan")}
          </button>
        </PermissionBlocker>
        <PermissionBlocker permission={PermissionEnum.BILLING_ACCESS}>
          <button className="u-mobile-hide text-right btn btn-primary" onClick={() => updatePaymentMethod()}>
            {t("billing_info:subscription.update_payment_method")}
          </button>
        </PermissionBlocker>

        {showCancellationButton() && (
          <PermissionBlocker permission={PermissionEnum.SUBSCRIPTION_ACCESS}>
            <button
              className={classNames("text-right btn ", AppState.mode === "mobile" ? "btn-plain u-color-warning" : "btn-warning")}
              onClick={() => showAppModal(<CancelSubscription />)}>
              {t("billing_info:subscription.cancel_subscription")}
            </button>
          </PermissionBlocker>
        )}

        {showResumeButton() && (
          <PermissionBlocker permission={PermissionEnum.SUBSCRIPTION_ACCESS}>
            <button
              className={classNames("text-right btn", AppState.mode === "mobile" ? "btn-plain u-color-warning" : "btn-warning")}
              onClick={() => resumeSubscription()}>
              {t("billing_info:subscription.resume_subscription")}
            </button>
          </PermissionBlocker>
        )}
      </div>

      {accountInvoices && (
        <Column style={{ marginTop: 35 }}>
          <Row>
            <InvoicesTable invoices={accountInvoices} />
          </Row>
        </Column>
      )}

      <div className="type-small-regular email-link u-mobile-hide">{contact}</div>
    </Modal>
  );
});
