import classNames from "classnames";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { CircularProgress, Drawer } from "@mui/material";
import { DashboardLeftRail, LinkDeviceToGateway, WelcomeInstructions } from "./Widgets";
import { useDeviceTypeGroups } from "../../Managers/API";
import { AppState, setFetchedDevices, setGlobalSelectedGateway, setSelectedDeviceGroup, setSelectedLocation } from "../../AppState";
import { NoResults, Page, Wrapper } from "../../Components";
import { IDevice, IDeviceGroup, IGateway } from "../../Managers/Types";
import { myKeys, mySearch } from "../../Managers";
import { DeviceGroup } from "./DeviceGroup";
import "./Dashboard.scss";
import { DeviceSort, OrderBy } from "../../Enums/SortingTypes";
import { useTranslation } from "react-i18next";
import { DashboardHeaderRow } from "./DashboardHeaderRow";
import { MobileDashboardHeader } from "./MobileDashboardHeader";
import { useDashboardDevices } from "../../Managers/LocationService";

type TDeviceGroupBy = "Device_type" | "location_note";

export const Dashboard: React.FC = observer(() => {
  const [selectedGateway, setSelectedGateway] = useState<IGateway | undefined | null>(null);
  const [selectedGroup, setSelectedGroup] = useState<IDeviceGroup | undefined | null>(null);
  const [searchString, setSearchString] = useState("");
  const [deviceList, setDeviceList] = useState<Array<IDevice>>([]);
  const [showLoadButton, setShowLoadButton] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [sortBy, setSortBy] = useState<DeviceSort>(DeviceSort.Updated);
  const [orderBy, setOrderBy] = useState<OrderBy>(OrderBy.DESC);
  const [mode, setMode] = useState<"gateways" | "groups">("gateways");
  const [drawerOpen, setDrawerOpen] = useState(false);

  const { t } = useTranslation(["dashboard", "common"]);

  const deviceTypeGroups = useDeviceTypeGroups();
  const groupBy: TDeviceGroupBy = "Device_type";
  const gateways = AppState.selectedLocation?.Gateways || [];

  const sortOptions = Object.entries(DeviceSort)
    .map(([key, value]) => [
      {
        label: t("dashboard:sort_by." + key.toLowerCase(), { context: OrderBy.ASC.toLowerCase() }),
        prop: value,
        dir: OrderBy.ASC,
      },
      {
        label: t("dashboard:sort_by." + key.toLowerCase(), { context: OrderBy.DESC.toLowerCase() }),
        prop: value,
        dir: OrderBy.DESC,
      },
    ])
    .flat();

  const dashboardDevicesQuery = useDashboardDevices(
    AppState.selectedLocation?._id || 0,
    selectedGateway?._id,
    selectedGroup?._id,
    {
      prop: sortBy,
      dir: orderBy,
    },
    currentPage,
    AppState.deviceLimit,
  );

  useEffect(() => {
    setSelectedGateway(mode === "gateways" ? AppState.selectedLocation?.Gateways[0] : null);
    setSelectedGroup(mode === "groups" ? AppState.selectedLocation?.Device_groups[0] : null);
  }, [AppState.selectedLocation?._id]);

  // This is done automatically by AppState post-login or session-load. There's nothing for us to do until it completes, so we exit early.
  if (deviceTypeGroups.isLoading) {
    console.log("[DASHBOARD] Awaiting full session load...");
    return (
      <Page>
        <Wrapper>
          <CircularProgress />
        </Wrapper>
      </Page>
    );
  }

  useEffect(() => {
    dashboardDevicesQuery.refetch();
  }, [selectedGateway, selectedGroup, AppState.selectedLocation, currentPage]);

  useEffect(() => {
    if (dashboardDevicesQuery.data) {
      setFetchedDeviceList(dashboardDevicesQuery.data.rows);
    }
  }, [dashboardDevicesQuery.dataUpdatedAt]);

  useEffect(() => {
    const locationInterval = setInterval(() => {
      forceRefreshDevices(orderBy, sortBy);
    }, 30000);

    return () => clearInterval(locationInterval);
  }, [selectedGateway, selectedGroup, currentPage, orderBy, sortBy]);

  useEffect(() => {
    const locationInterval = setInterval(() => {
      setSelectedLocation(AppState.selectedLocation!, false);
    }, 120000);

    return () => clearInterval(locationInterval);
  }, [selectedGateway, selectedGroup, currentPage]);

  const groups = useMemo(() => {
    const groupsToDisplay = {} as Record<string, IDevice[]>;
    if (!groupBy) {
      groupsToDisplay.all = deviceList;
    } else {
      deviceList.forEach((device) => {
        let attr;
        const deviceGroupBy = device[groupBy];

        if (deviceGroupBy) {
          if (deviceGroupBy.Device_type_group) {
            attr = deviceGroupBy.Device_type_group.name;
          } else if (deviceGroupBy.DeviceTypeGroupId) {
            const filtered =
              deviceTypeGroups.data?.filter((deviceTypeGroup) => deviceTypeGroup._id === device[groupBy].DeviceTypeGroupId) ?? [];

            attr = filtered.length ? filtered[0].name : "";
          }
        }

        if (!attr) {
          attr = "other";
        } else {
          attr = attr.toLowerCase();
        }

        if (!groupsToDisplay[attr]) {
          groupsToDisplay[attr] = [device];
        } else {
          groupsToDisplay[attr].push(device);
        }
      });
    }
    return groupsToDisplay;
  }, [deviceList]);

  const getSelectedCount = () => {
    if (selectedGroup) {
      return selectedGroup.Devices.length;
    } else if (selectedGateway) {
      return selectedGateway.Devices.count;
    }
    return gateways.reduce((a, b) => a + b.Devices.count, 0);
  };

  useEffect(() => {
    setShowLoadButton((currentPage + 1) * AppState.deviceLimit < getSelectedCount());
  }, [selectedGateway, selectedGroup, currentPage, getSelectedCount]);

  const getSelectedName = useCallback(() => {
    if (selectedGroup && selectedGroup.name) {
      return selectedGroup.name;
    } else if (selectedGateway) {
      return selectedGateway.name || t("dashboard:gateway_name") + " " + selectedGateway._id;
    }
    return t("dashboard:all_devices");
  }, [selectedGroup, selectedGateway, AppState.selectedLocation]);

  const forceRefreshDevices = useCallback((order?: OrderBy, sort?: string) => {
    setSortBy((sort as DeviceSort) ?? sortBy);
    setOrderBy(order ?? orderBy);
    dashboardDevicesQuery.refetch();
  }, []);

  const setFetchedDeviceList = useCallback((devices: Array<IDevice>) => {
    setFetchedDevices(devices);
    setDeviceList(devices);
  }, []);

  const loadMoreDevices = useCallback(() => {
    setCurrentPage(currentPage + 1);
    setOrderBy(orderBy);
    setSortBy(sortBy);
  }, [deviceList, currentPage, orderBy, sortBy]);

  const selectGroup = useCallback(
    (group: IDeviceGroup | null) => {
      setCurrentPage(0);
      setSelectedDeviceGroup(group);
      setSelectedGroup(group);
    },
    [currentPage, deviceList],
  );

  const selectGateway = useCallback(
    (gateway: IGateway | null) => {
      setCurrentPage(0);
      setGlobalSelectedGateway(gateway);
      setSelectedGateway(gateway);
    },
    [currentPage, deviceList],
  );

  return (
    <Page>
      <Wrapper>
        {AppState.mode === "desktop" ? (
          <DashboardLeftRail
            gateways={AppState.selectedLocation?.Gateways || []}
            deviceGroups={AppState.selectedLocation?.Device_groups || []}
            onSelectGateway={selectGateway}
            onSelectGroup={selectGroup}
          />
        ) : null}
        {AppState.mode === "tablet" ? (
          <Drawer onBackdropClick={() => setDrawerOpen(false)} open={drawerOpen}>
            <DashboardLeftRail
              gateways={AppState.selectedLocation?.Gateways || []}
              deviceGroups={AppState.selectedLocation?.Device_groups || []}
              onSelectGateway={selectGateway}
              onSelectGroup={selectGroup}
            />
          </Drawer>
        ) : null}

        <main className="bapi-main mod-dashboard">
          {AppState.mode !== "mobile" ? (
            <DashboardHeaderRow
              setSortBy={setSortBy}
              orderBy={orderBy}
              sortBy={sortBy}
              setOrderBy={setOrderBy}
              name={getSelectedName()}
              forceRefreshDevices={forceRefreshDevices}
              deviceList={deviceList}
              selectGateway={selectGateway}
              selectedGateway={selectedGateway}
              selectGroup={selectGroup}
              selectedGroup={selectedGroup}
              count={getSelectedCount()}
              searchString={searchString}
              setSearchString={setSearchString}
              setDrawerOpen={setDrawerOpen}
              sortOptions={sortOptions}
            />
          ) : (
            <MobileDashboardHeader
              sortOptions={sortOptions}
              tab={mode}
              setTab={setMode}
              gateways={AppState.selectedLocation?.Gateways || []}
              selectedGateway={selectedGateway}
              selectGateway={selectGateway}
              selectGroup={selectGroup}
              selectedGroup={selectedGroup}
              groups={AppState.selectedLocation?.Device_groups || []}
              searchString={searchString}
              setSearchString={setSearchString}
              sort={{ dir: orderBy, prop: sortBy }}
              setSort={(sort) => {
                setOrderBy(sort.dir);
                setSortBy(sort.prop as DeviceSort);
              }}
            />
          )}
          {AppState.selectedLocationId === -1 ? (
            <i className="fa fa-spin fa-spinner" />
          ) : (
            <>
              {!gateways.length && mode === "gateways" ? <WelcomeInstructions /> : null}
              {mode === "groups" && !AppState.selectedLocation?.Device_groups.length ? (
                <div className="dashboard-content setup-container main-panel">{t("dashboard:no_groups")}</div>
              ) : null}

              {gateways.length && !deviceList.length && !selectedGroup && mode === "gateways" ? <LinkDeviceToGateway /> : <></>}

              <div className={classNames("main-panel")}>
                {deviceList?.length > 0 && searchString && mySearch<IDevice>(deviceList, searchString)?.length === 0 ? (
                  <NoResults />
                ) : (
                  myKeys(groups, ["sort"]).map((key) => (
                    <DeviceGroup
                      refresh={forceRefreshDevices}
                      key={key}
                      title={key}
                      devicesInGroup={groups[key]}
                      searchString={searchString}
                    />
                  ))
                )}

                {showLoadButton && (
                  <div className="load-more-button">
                    <button className="btn btn-primary load-more-button" onClick={() => loadMoreDevices()}>
                      {t("dashboard:load_more")}
                    </button>
                  </div>
                )}
              </div>
            </>
          )}
        </main>
      </Wrapper>
    </Page>
  );
});
