import React from "react";
import classNames from "classnames";
import { observer } from "mobx-react-lite";
import ClickAwayListener from "react-click-away-listener";
import { getSelectedDevices, showAppModal } from "../../../AppState";
import { ReportModal } from "./ReportModal";
import { DropdownMenu } from "../../../Components";
import { useTranslation } from "react-i18next";

interface IReportMenuProps {
  onClose: () => void;
  open: boolean;
}

export const ReportMenu: React.FC<IReportMenuProps> = observer(({ onClose, open }) => {
  const { t } = useTranslation("dashboard");
  const { devices } = getSelectedDevices();

  if (!open) {
    return <></>;
  }

  return (
    <ClickAwayListener onClickAway={onClose}>
      <DropdownMenu className={classNames({ open })}>
        <p className="dropdown-menu-title">{t("dashboard:report_menu.create")}</p>
        <div className={classNames("dropdown-menu-item", { disabled: devices.length !== 1 })} onClick={() => showAppModal(<ReportModal />)}>
          {t("dashboard:report_menu.on_selected", { count: devices.length })}
        </div>
      </DropdownMenu>
    </ClickAwayListener>
  );
});
