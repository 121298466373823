import classNames from "classnames";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { Autocomplete, Button, Chip, Drawer } from "@mui/material";
import {
  changeAccountDemo,
  integrateAccountWithStripe,
  stringToDate,
  useAccountInfoById,
  useAccounts,
} from "../../Managers/AccountsService";
import { formatDateCustom, mySearch, useAccountSubscriptionById, usePermissions } from "../../Managers";
import { AppState, setSelectedAccount, showAppModal, showSnackbar } from "../../AppState";
import {
  Column,
  HeaderRow,
  ICON_TOOLTIP_DEFAULT_DELAY,
  LeftRail,
  Page,
  PlanDetails,
  Row,
  StyledTooltip,
  TextInput,
  Wrapper,
} from "../../Components";
import { useLocations } from "../../Managers/API";
import { IAccount } from "./Types";
import { useNavigate } from "react-router-dom";
import { isUserRoleAllowed, UserRoles } from "../../Enums";
import { ConfirmModal } from "../../Modals";
import { useTranslation } from "react-i18next";
import { DrawerIcon } from "../../icon";
import "./Accounts.scss";
import { Colors } from "../../Theme";

export const Accounts: React.FC = observer(() => {
  const [searchUserString, setSearchUserString] = useState("");
  const [order, setOrder] = useState<"asc" | "desc">("asc");
  const [changeDemoInProgress, setChangeDemoInProgress] = useState<boolean>(false);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const navigate = useNavigate();

  const accounts = useAccounts();
  const locations = useLocations();
  const permissions = usePermissions();
  const accountInfo = useAccountInfoById(AppState.selectedAccount?.AdminId || 0);
  const accountSubscription = useAccountSubscriptionById(AppState.selectedAccount?._id || 0);

  const { t } = useTranslation("accounts");

  useEffect(() => {
    if (!isUserRoleAllowed(UserRoles.APP_ADMIN)) {
      navigate("/");
    }

    if (!AppState.selectedAccount && accounts.data?.length) {
      console.log("Preselecting account", accounts.data[0]);
      setSelectedAccount(accounts.data[0]);
    } else {
      setSelectedAccount(AppState.selectedAccount);
    }
  }, [accounts.data, navigate, accountInfo.isLoading]);

  useEffect(() => {
    if (drawerOpen) {
      setDrawerOpen(false);
    }
  }, [AppState.mode]);

  if (accounts.isLoading || !accounts.data || locations.isLoading || !locations.data || permissions.isLoading || !permissions.data) {
    return <></>;
  }

  const sortedAccounts = [...(accounts.data || [])].sort((a, b) =>
    order === "desc" ? b.name.localeCompare(a.name) : a.name.localeCompare(b.name),
  );

  const clickedIntegrateWithStripeButton = (selectedAccount: IAccount | null) => {
    if (selectedAccount) {
      integrateAccountWithStripe(selectedAccount._id)
        .then((r) => {
          selectedAccount.stripe_id = r.Account.stripe_id;
          const updatedAccounts = accounts.data.map((account) => {
            if (account._id === selectedAccount._id) {
              account.stripe_id = selectedAccount.stripe_id;
            }
            return account;
          });
          Object.assign(accounts.data, updatedAccounts);
          showSnackbar(t("accounts:stripe_integration_success"), "success");
        })
        .catch(() => showSnackbar(t("accounts:stripe_integration_error"), "error"));
    }
  };

  const showDemoChangeConfirmModal = () => {
    const isDemoMode = AppState.selectedAccount?.is_demo === true || false;
    showAppModal(
      <ConfirmModal
        header={t("accounts:change_type", { context: isDemoMode ? "regular" : "demo" })}
        confirmText={t("common:confirm")}
        onConfirm={() => changeAccountDemoCallback(!isDemoMode)}>
        <>
          <p>{t("accounts:confirm_change_type", { context: isDemoMode ? "regular" : "demo" })}</p>
          <p className={"bordered-info-text bordered-info-text--warning"}>
            {t("accounts:info_change_mode_to", { context: isDemoMode ? "regular" : "demo" })}
          </p>
          {changeDemoInProgress ? <i className="fa fa-spin fa-spinner" /> : <></>}
        </>
      </ConfirmModal>,
    );
  };

  const changeAccountDemoCallback = (newAccountTypeValue: boolean) => {
    setChangeDemoInProgress(true);
    if (AppState.selectedAccount?._id !== undefined) {
      changeAccountDemo(AppState.selectedAccount?._id, newAccountTypeValue)
        .then((r) => {
          if (r.is_demo === newAccountTypeValue) {
            const index = accounts.data.findIndex((account) => account._id === AppState.selectedAccount?._id);
            if (index !== -1) {
              accounts.data[index].is_demo = r.is_demo;
              AppState.selectedAccount = r;
              showSnackbar(t("accounts:change_mode_success"), "success");
              showAppModal(null);
              setChangeDemoInProgress(false);
            } else {
              console.error("Account not found in accounts.data");
            }
          } else {
            showSnackbar(t("accounts:change_mode_error"), "error");
            setChangeDemoInProgress(false);
          }
        })
        .catch(() => {
          showSnackbar(t("accounts:change_mode_error"), "error");
          setChangeDemoInProgress(false);
        });
    } else {
      console.error("AppState.selectedAccount._id is undefined");
    }
  };

  const HeadInfo = () => (
    <>
      <Chip
        className="account-chip"
        color={AppState.selectedAccount?.is_demo ? "warning" : "success"}
        label={t("accounts:label", {
          context: AppState.selectedAccount?.is_demo ? "demo" : "regular",
        }).toUpperCase()}
        size="small"
      />
      <div className="mobile-name">
        <div>{AppState.selectedAccount?.name || ""}</div>
        <div className="u-text-small" style={{ color: Colors.lightGray }}>
          {t("accounts:stripe_id", { id: AppState.selectedAccount?.stripe_id ?? t("accounts:stripe_id_missing") })}
        </div>
      </div>
      {isUserRoleAllowed(UserRoles.APP_ADMIN) && (
        <span className="u-text-small">
          {!AppState.selectedAccount?.stripe_id && (
            <Button onClick={() => clickedIntegrateWithStripeButton(AppState.selectedAccount)} variant="contained">
              {t("accounts:integrate_with_stripe")}
            </Button>
          )}
        </span>
      )}
    </>
  );

  return (
    <Page>
      <Wrapper>
        <Drawer
          variant={AppState.mode === "desktop" ? "permanent" : "temporary"}
          hideBackdrop={AppState.mode === "desktop"}
          onBackdropClick={() => AppState.mode === "tablet" && setDrawerOpen(false)}
          open={drawerOpen || AppState.mode === "desktop"}>
          <LeftRail
            header={
              <div className="input-holder u-full-width">
                <input
                  type="text"
                  className="input input-default"
                  placeholder={t("accounts:find_account")}
                  value={searchUserString}
                  onChange={(e) => setSearchUserString(e.target.value)}
                />
                <i className="fa fa-search input-holder-icon" />
              </div>
            }
            body={
              <nav className="left-rail-nav">
                <div className="left-rail-nav-header">
                  <h2 className="left-rail-nav-header-title mod-with-btn">
                    <StyledTooltip
                      title={t("common:sort_by_name", { context: order === "asc" ? "desc" : "asc" })}
                      enterDelay={ICON_TOOLTIP_DEFAULT_DELAY}>
                      <button className="btn btn-plain" onClick={() => setOrder(order === "desc" ? "asc" : "desc")}>
                        <i className={classNames("fa fa-sort-amount-asc", { "u-display-none": order === "asc" })} />
                        <i className={classNames("fa fa-sort-amount-desc", { "u-display-none": order !== "asc" })} />
                      </button>
                    </StyledTooltip>{" "}
                    {t("accounts:account_list")}
                  </h2>
                </div>

                <ul className="left-rail-nav-group">
                  {mySearch<IAccount>(sortedAccounts, searchUserString).map((account) => (
                    <li
                      className={classNames("left-rail-nav-item", { active: AppState.selectedAccount?._id === account._id })}
                      key={account._id}>
                      <div onClick={() => setSelectedAccount(account)}>{account.name}</div>
                    </li>
                  ))}
                </ul>
              </nav>
            }
          />
        </Drawer>

        <main className="bapi-main account-header mod-dashboard">
          <HeaderRow
            className="account-header"
            info={{
              width: AppState.mode === "mobile" ? "100%" : "",
              name: (
                <>
                  <button onClick={() => setDrawerOpen(true)} className="btn btn-icon u-tablet-only">
                    <DrawerIcon />
                  </button>
                  {AppState.mode !== "mobile" ? (
                    <HeadInfo />
                  ) : (
                    <Autocomplete
                      onChange={(_e, val) => setSelectedAccount(val)}
                      className="autocomplete-account u-full-width dark"
                      options={sortedAccounts}
                      getOptionLabel={(o: IAccount) => o.name}
                      getOptionKey={(o: IAccount) => o._id}
                      renderInput={(params) => (
                        <TextInput
                          label={t("accounts:choose_account")}
                          displayLabelInside
                          className="account-search-input u-full-width dark"
                          inputClass="dark"
                          onChange={(e) => setSearchUserString(e.target.value)}
                          value={searchUserString}
                          name={params.id}
                          {...params}
                        />
                      )}
                    />
                  )}
                </>
              ),
            }}
          />

          <section className={classNames("main-panel users-main", { isLoading: false })}>
            {AppState.mode === "mobile" ? <HeadInfo /> : null}
            <Row>
              <Column>
                <div className="row main-panel-row full-width">
                  <div className="col-xs-6">
                    <p className="input-label">{t("accounts:account_name")}</p>
                    <p className="type-large-regular">{AppState.selectedAccount?.name}</p>
                  </div>
                  <div className="col-xs-6">
                    <p className="input-label">{t("accounts:account_language")}</p>
                    <p className="type-large-regular">{t(`common:language.${AppState.selectedAccount?.language}`)}</p>
                  </div>
                </div>
                <div className="row main-panel-row full-width">
                  <div className="col-xs-6">
                    <p className="input-label">{t("accounts:expiration_date")}</p>
                    {/* TODO: The old system added a time zone */}
                    <p className="type-large-regular">
                      {formatDateCustom(
                        stringToDate(accountInfo.data?.expiration_date ?? accountInfo.data?.app_metadata?.expiration_date),
                        "MM/DD/YYYY",
                      )}
                    </p>
                  </div>
                  <div className="col-xs-6">
                    <p className="input-label">{t("accounts:purchase_date")}</p>
                    {/* TODO: The old system added a time zone */}
                    <p className="type-large-regular">
                      {formatDateCustom(
                        stringToDate(accountInfo.data?.purchase_date ?? accountInfo.data?.app_metadata?.purchase_date),
                        "MM/DD/YYYY",
                      )}
                    </p>
                  </div>
                </div>
              </Column>
            </Row>

            {accountSubscription.isLoading ? <i style={{ fontSize: "50px" }} className="fa fa-spinner fa-spin" /> : null}
            {accountSubscription.data ? (
              <PlanDetails accountSubscription={accountSubscription.data?.subscription} account={AppState.selectedAccount} />
            ) : null}

            {isUserRoleAllowed(UserRoles.APP_ADMIN) ? (
              <Column className="switch-account-type-button">
                <Button onClick={() => showDemoChangeConfirmModal()} variant="contained">
                  {t("accounts:switch_type_to", { context: AppState.selectedAccount?.is_demo ? "regular" : "demo" }).toUpperCase()}
                </Button>
              </Column>
            ) : null}
          </section>
        </main>
      </Wrapper>
    </Page>
  );
});
