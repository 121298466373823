import { Endpoint, invalidateQuery } from "./API";
import { IActualPaymentOptions, IPaymentOptions, IPlanProps } from "./Types";
import { countries, ICountry } from "./CountryService";
import { ICurrencyExtended, QK_CURRENCIES } from "./CurrencyService";
const API_PATH = "payment_options";

export const GetPaymentOptions = (userCountryCode?: string, currencyCode?: string) =>
  Endpoint.get<IPaymentOptions>(`${API_PATH}/getAvailable?country_code=${userCountryCode}&currency_code=${currencyCode}`);

export const GetSelectedAndAvailablePaymentOptions = () => {
  return Endpoint.get<{ availableOptions: IPaymentOptions; selectedOptions: IActualPaymentOptions }>(
    `${API_PATH}/getAvailableWithActualSelected`,
  ).then((response) => {
    return response.data;
  });
};
export const CalculatePrice = (values: IPlanProps, userCountryCode: string, currencyCode: string) =>
  Endpoint.post<number>(`${API_PATH}/calculateTotal?country_code=${userCountryCode}&currency_code=${currencyCode}`, values).then(
    (res) => res.data,
  );

export const getCurrenciesForDropdown = (onlyActive = false) =>
  Endpoint.get<ICurrencyExtended[]>(`${API_PATH}/getAvailableCurrencies?onlyActive=${onlyActive}`).then((res) => {
    res.data.forEach((currency) => {
      currency.country = countries.find((c: ICountry) => c.code === currency.country_code);
    });
    const result = res.data.map((currency) => Object.assign({}, currency));
    return result as ICurrencyExtended[];
  });
