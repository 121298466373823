import { Formik } from "formik";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import {
  FormFieldCheckbox,
  FormFieldDate,
  FormFieldSelect,
  FormFieldText,
  ILineChartValue,
  SelectInput,
  TimeSeriesLineChart,
} from "../../../Components";
import { AppState, showAppModal, showSnackbar } from "../../../AppState";
import { useLocations } from "../../../Managers/API";
import { IDevice, ISensor } from "../../../Managers/Types";
import "./ReportMassExportModal.scss";
import { unitsTransform } from "../../../Managers/UnitsService";
import { getDeviceSensorData } from "../../../Managers/DeviceService";
import { prepareChartDataSet } from "../../../Managers/ReportManager";
import moment from "moment";
import {
  checkForWai418Devices,
  checkForWai418HumidityDevice,
  checkForWai418TemperatureDevice,
  exportToXLSX,
  getNameSlug,
} from "../../../Managers";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { getNotificationsHistory } from "../../../Managers/NotificationService";
import { measurementTransform } from "../../../Managers/MeasurementService";
import { alertConditionTransform } from "../../../Managers/AlertConditionService";
import * as htmlToImage from "html-to-image";
import { useTranslation } from "react-i18next";
import { Modal } from "../../../Components/Modal";

export const ReportMassExportModal: React.FC = observer(() => {
  const totalSteps = 3;
  const [currentStep, setCurrentStep] = useState(1);
  const [selectedDevices, setSelectedDevices] = useState<IDevice[]>([]);
  const [selectedSensors, setSelectedSensors] = useState<ISensor[]>([]);
  const [minDate, setMinDate] = useState<Date | null>(null);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [doGenerateHiddenCharts, setDoGenerateHiddenCharts] = useState<boolean>(false);
  const [finalValues, setFinalValues] = useState<any>({});
  const [chartsData, setChartsData] = useState<any[]>([]);
  const [showLoader, setShowLoader] = useState<boolean>(false);

  const { t } = useTranslation(["report_modal", "common", "sensor_types"]);

  const locations = useLocations();

  const gateways = AppState.selectedLocation?.Gateways;

  const nextStep = (e: any) => {
    e.preventDefault();

    const newCurrentStep = Math.min(currentStep + 1, totalSteps);
    setCurrentStep(newCurrentStep);
    if (newCurrentStep === totalSteps) {
      _getSensorData(startDate, endDate, selectedSensors, selectedDevices);
    }
  };

  const backStep = () => {
    setCurrentStep(Math.max(currentStep - 1, 0));
  };

  const getConverts = (device: IDevice) => {
    const isWai418 = checkForWai418Devices(device.serial_number);

    if (isWai418 && device?.serial_number) {
      if (checkForWai418TemperatureDevice(device.serial_number)) {
        return { convertToTemp: true, convertToRh: false };
      } else if (checkForWai418HumidityDevice(device.serial_number)) {
        return { convertToTemp: false, convertToRh: true };
      } else {
        return { convertToTemp: false, convertToRh: false };
      }
    }
    return { convertToTemp: false, convertToRh: false };
  };

  const updateSelectedDevices = (device: IDevice, checked: boolean) => {
    let newSelectedDevices = [...(selectedDevices || [])];
    if (checked) {
      newSelectedDevices.push(device);
    } else {
      newSelectedDevices = newSelectedDevices.filter((u) => u._id !== device._id);
    }
    setSelectedDevices(newSelectedDevices);

    const newSelectedAllDevices = newSelectedDevices.map(getFilteredSensors).flat();
    setSelectedSensors(newSelectedAllDevices);

    const newMinDate = new Date(
      Math.min.apply(
        null,
        newSelectedDevices.map((d) => new Date(d.createdAt).getTime()),
      ),
    );
    const newStartDate = new Date(new Date().getTime() - 86400 * 1000);
    setMinDate(newMinDate);
    setStartDate(new Date(Math.max(newStartDate.getTime(), newMinDate.getTime())));
    return newSelectedDevices;
  };

  const updateSelectedSensors = (sensor: ISensor) => {
    const checked = !isSensorSelected(sensor);
    let newSelectedSensors = [...(selectedSensors || [])];

    if (checked) {
      newSelectedSensors.push(sensor);
    } else {
      newSelectedSensors = newSelectedSensors.filter((u) => u._id !== sensor._id);
    }

    setSelectedSensors(newSelectedSensors);
    return newSelectedSensors;
  };

  const isDeviceSelected = (user: IDevice) => selectedDevices.findIndex((u) => u._id === user._id) > -1;

  const isSensorSelected = (user: ISensor) => selectedSensors.findIndex((u) => u._id === user._id) > -1;

  function readingUOMString(device: IDevice, sensor: ISensor) {
    const { convertToTemp, convertToRh } = getConverts(device);

    return sensor
      ? " (" +
          unitsTransform(sensor?.default_unit, [
            sensor?.default_unit,
            device?.is_empirical,
            sensor?.Sensor_type.type,
            convertToRh,
            convertToTemp,
          ]) +
          ")"
      : "";
  }

  const _getSensorData = (start: Date, end: Date, sensors: ISensor[] | null, devices: IDevice[]) => {
    if (!sensors) {
      return;
    }

    const promises: Promise<any>[] = [];
    setChartsData([]);
    sensors.forEach((sensor) => {
      const device = devices.find((d) => d._id === sensor.DeviceId);
      if (!sensor || !device) {
        return;
      }
      const { convertToRh, convertToTemp } = getConverts(device);
      promises.push(
        getDeviceSensorData(sensor, start.toISOString(), end.toISOString()).then((r) => {
          const processed = prepareChartDataSet(r, sensor, device.is_empirical, convertToRh, convertToTemp);
          return { sensor: sensor, device: device, data: processed };
        }),
      );
    });
    Promise.all(promises)
      .then((result) => {
        setChartsData(result.filter((d) => !!d.data));
      })
      .catch((e) => {
        const errorMessage = t("report_modal:load_error");
        showSnackbar(errorMessage, "error");
        console.log(errorMessage, e);
      });
  };

  const sensorType = (device: IDevice) => {
    const { convertToRh, convertToTemp } = getConverts(device);
    if (convertToTemp) {
      return t("report_modal:temperature");
    } else if (convertToRh) {
      return t("report_modal:humidity");
    }
    return null;
  };

  const renderHeaderFooterPDF = (doc: any, device: IDevice, sensor: ISensor, lotCode: string, productCode: string) => {
    doc.setFontSize(14);
    doc.setTextColor(0, 0, 0);
    // TODO: The old system did repeat this field 3x
    doc.text(AppState.selectedLocation?.name || "", 40, 50);
    doc.text(AppState.selectedLocation?.name || "", 40, 50);
    doc.text(AppState.selectedLocation?.name || "", 40, 50);
    doc.setFontSize(8);

    let subtitle: string[] = [];
    let address = AppState.selectedLocation?.address !== "please enter an address" ? AppState.selectedLocation?.address : null;
    let deviceLocation =
      address &&
      AppState.selectedLocation?.city &&
      AppState.selectedLocation?.state &&
      AppState.selectedLocation?.country &&
      AppState.selectedLocation?.zip
        ? address +
          ", " +
          AppState.selectedLocation?.city +
          ", " +
          AppState.selectedLocation?.state +
          ", " +
          AppState.selectedLocation?.country +
          " " +
          AppState.selectedLocation?.zip
        : null;
    if (deviceLocation) {
      subtitle.push(deviceLocation);
    }
    let devicePhone = AppState.selectedLocation?.phone !== "please enter a phone number" ? AppState.selectedLocation?.phone : null;
    if (devicePhone) {
      subtitle.push(devicePhone);
    }
    doc.text(subtitle.join(" | "), 40, 63);

    doc.setDrawColor(240, 240, 240);
    doc.setLineWidth(2);
    doc.line(40, 68, 550, 68);
    doc.setLineWidth(0.01);
    doc.line(40, 95, 550, 95);

    //devices
    doc.setFontSize(7);
    doc.setTextColor(180, 180, 180);
    doc.text(t("report_modal:device_name"), 40, 78);
    doc.text(t("report_modal:product_code"), 210, 78);
    doc.text(t("report_modal:serial_number"), 300, 78);
    doc.text(t("report_modal:sensor_type"), 360, 78);
    doc.text(t("report_modal:lot_code"), 475, 78);

    doc.setFontSize(9);
    doc.setTextColor(100, 100, 100);
    doc.text(device.name || "n/a", 40, 90);
    doc.text(productCode || "n/a", 210, 90);
    doc.text(device.serial_number || "n/a", 300, 90);
    doc.text(sensorType(device) || sensor?.display_name || sensor?.Sensor_type.name || "n/a", 360, 90);
    doc.text(lotCode || "n/a", 475, 90);

    doc.text(new Date().toLocaleDateString(), doc.internal.pageSize.width - 100, 40);

    //footer
    doc.setFontSize(12);
    doc.setTextColor(160, 160, 160);
    doc.text(t("report_modal:wireless_monitoring"), 40, doc.internal.pageSize.height - 50);
    doc.setFontSize(9);
    doc.setTextColor(200, 200, 200);
    doc.text(t("report_modal:footer"), 40, doc.internal.pageSize.height - 40);
  };

  const prepareHistoricalData = async (sensor: ISensor) => {
    const historicalData = await getNotificationsHistory(
      moment(startDate).startOf("day").toISOString(),
      moment(endDate).endOf("day").toISOString(),
    );

    const headers = [
      { header: "Value", dataKey: "value" },
      { header: "Name", dataKey: "name" },
      { header: "Condition", dataKey: "condition" },
      { header: "Device", dataKey: "device" },
      { header: "Placement", dataKey: "placement" },
      { header: "Serial", dataKey: "serial" },
      { header: "Updated at", dataKey: "lastUpdateAt" },
      { header: "Resolved", dataKey: "resolved" },
    ];

    const parsedHistoricalData = historicalData
      .filter((alert) => alert.Sensor?.DeviceId === sensor?.DeviceId)
      .map((alert) => {
        let value =
          String(
            measurementTransform(alert.value.value, [
              alert.Sensor?.default_unit || "degF",
              alert.Sensor?.Device?.is_empirical,
              alert.Sensor?.Sensor_type.type,
            ]),
          ) +
          unitsTransform(alert.Sensor?.default_unit || "degF", [
            alert.Sensor?.default_unit || "degF",
            alert.Sensor?.Device?.is_empirical,
            alert.Sensor?.Sensor_type.type,
          ]);

        const alertName = alert.Alert ? alert.Alert.name + " - " + alertConditionTransform(alert.Alert) : "";
        const alertCondition = alert.Alert ? alertConditionTransform(alert.Alert) : "";
        const deviceName = alert.Sensor?.Device?.name || "";
        const placement = alert.Sensor?.Device?.location_note || "--";
        const serialNumber = alert.Sensor?.Device?.serial_number || "";
        const lastUpdateAt = moment(alert.updatedAt).format("M/D/YYYY hh:mm A");
        const isResolved = alert.is_resolved ? "yes" : "no";

        return [value, alertName, alertCondition, deviceName, placement, serialNumber, lastUpdateAt, isResolved];
      });

    return { historicalHeaders: headers, historicalData: parsedHistoricalData };
  };

  const saveReports = async (values: any) => {
    if (values.fileFormat === "pdf" && values.doGeneratePdfCharts) {
      setDoGenerateHiddenCharts(true);
      setFinalValues(values);
    } else {
      await _saveReports(values);
    }
  };

  const _saveReports = async (values: any) => {
    if (!AppState.appModal) {
      return;
    }
    const contentArray: Record<string, string | number | null | undefined>[] = [];
    let doc = new jsPDF("p", "pt");
    const isPdf = () => values.fileFormat === "pdf" || !values.fileFormat;

    for (let index = 0; index < chartsData.length; index++) {
      let data = chartsData[index];
      let imageData: any = null;

      if (isPdf() && values.doGeneratePdfCharts) {
        const graph = document.getElementById(`line-chart${index}`);
        if (graph) {
          imageData = await htmlToImage.toPng(graph);
        }
      }

      const content = await _generateReportForSensor(values, data.device, data.sensor, data.data, imageData, doc);

      if (isPdf() && index !== chartsData.length - 1) {
        doc.addPage();
      } else if (content && Array.isArray(content)) {
        contentArray.push(...content, { [content.length]: "\n" }, { [content.length + 1]: "\n" });
      }
    }

    if (isPdf()) {
      doc.save((values.filename || t("common:untitled")) + ".pdf");
    } else {
      exportToXLSX(values.filename || t("common:untitled"), contentArray);
    }
    setDoGenerateHiddenCharts(false);
    setChartsData([]);
    setCurrentStep(0);
    setShowLoader(false);
    showAppModal(null);
  };

  const _generateReportForSensor = async (
    values: any,
    device: IDevice,
    sensor: ISensor,
    chartData: ILineChartValue[],
    imgData: any,
    doc: jsPDF,
  ) => {
    const fileFormat = values.fileFormat;
    let columns = [t("report_modal:reading") + readingUOMString(device, sensor), t("report_modal:date_time")];
    let data;

    const dateFormat = "D/MM/YYYY h:mm";

    data = chartData.map((datapoint) => {
      // * read.createdAt below used to be reading.updatedAt
      return [datapoint.y, moment(datapoint.x).format(dateFormat)];
    });

    const { historicalHeaders, historicalData } = await prepareHistoricalData(sensor);

    if (fileFormat === "pdf" || !fileFormat) {
      const lotCode = values.lotCode ?? "";
      const productCode = values.productCode ?? "";

      if (imgData) {
        doc.addImage({ imageData: imgData, format: "PNG", x: 20, y: 100, width: 560, height: 250 });
      }

      autoTable(doc, {
        head: [columns],
        body: data,
        startY: imgData ? 380 : 130,
        margin: {
          top: 100,
          bottom: 70,
          left: 35,
        },
        showFoot: "everyPage",
        didDrawPage: () => {
          renderHeaderFooterPDF(doc, device, sensor, lotCode, productCode);
        },
      });

      if (historicalData && historicalData.length > 0) {
        doc.addPage();
        doc.setFontSize(18);
        doc.text(t("report_modal:historical"), 40, 40);
        autoTable(doc, {
          head: historicalHeaders,
          body: historicalData,
          startY: 70,
          margin: {
            top: 60,
            bottom: 70,
            left: 35,
          },
        });
      }

      return doc;
    } else {
      // Add information about device;
      data.unshift(columns);
      data.unshift([
        t("dashboard:report_modal.sensor_type"),
        sensorType(device) || sensor?.display_name || sensor?.Sensor_type.name || "Unknown",
      ]);
      data.unshift([t("dashboard:report_modal.serial_number"), device.serial_number]);
      data.unshift([t("dashboard:report_modal.device_name"), device.name]);
      data.unshift([t("dashboard:report_modal.lot_code"), values.lotCode ?? ""]);
      data.unshift([t("dashboard:report_modal.product_code"), values.productCode ?? ""]);
      data.unshift([t("dashboard:report_modal.device_location"), device.location_note || ""]);
      data.unshift([t("dashboard:report_modal.location"), AppState.selectedLocation?.address || ""]);
      data.unshift([t("dashboard:report_modal.location_name"), AppState.selectedLocation?.name ?? ""]);

      const combinedData = [...data, []];
      combinedData.push([t("report_modal:historical")]);
      combinedData.push(historicalHeaders.map((header) => header.header));
      combinedData.push(...historicalData);

      return combinedData.map((d) => {
        const rec: Record<string, number | string | null | undefined> = {};
        d.forEach((entry, index) => {
          rec[index] = entry;
        });
        return rec;
      });
    }
  };

  const generatePdfHiddenCharts = () => {
    if (!AppState.appModal) {
      return null;
    }
    return !doGenerateHiddenCharts ? null : (
      <div>
        {chartsData.map((chart, i) => (
          <div style={{ position: "fixed", width: "620px", height: "350px", left: -9000 - 400 * i }}>
            <div className="graph-holder">
              <TimeSeriesLineChart
                data={chart.data}
                setPoints={[]}
                valueName={""}
                setFilteredDataHook={() => {}}
                onSetpointClick={() => {}}
                bindToSuffix={`${i}`}
                onRendered={async () => {
                  if (i === chartsData.length - 1) {
                    await _saveReports(finalValues);
                  }
                }}
              />
            </div>
          </div>
        ))}
      </div>
    );
  };

  const getSensorDropdownText = (device: IDevice) => {
    const selectedSensors = getFilteredSensors(device).filter((s) => isSensorSelected(s));
    if (isAllSensorsSelectedForDevice(device)) {
      return t("report_modal:all_sensors_selected");
    } else if (selectedSensors.length === 0) {
      return t("report_modal:no_sensors_selected");
    }
    return selectedSensors.map((s) => s.Sensor_type.name).join(", ");
  };

  const isAllSensorsSelectedForDevice = (device: IDevice) => {
    const sensors = getFilteredSensors(device);
    return sensors.filter((s) => isSensorSelected(s)).length === sensors.length;
  };

  const updateSelectedAll = (device: IDevice) => {
    const checked = !isAllSensorsSelectedForDevice(device);
    let newSelectedAllSensors = [...(selectedSensors || [])];

    if (checked) {
      for (const sensor of getFilteredSensors(device).filter((s) => !isSensorSelected(s))) {
        newSelectedAllSensors.push(sensor);
      }
    } else {
      newSelectedAllSensors = newSelectedAllSensors.filter((s) => !device.Sensors.includes(s));
    }

    setSelectedSensors(newSelectedAllSensors);
  };

  const getFilteredSensors = (device: IDevice) =>
    device.Sensors.filter(
      (sensor) => sensor.Sensor_type && sensor.Sensor_type.name !== "Battery Voltage" && sensor.Sensor_type.name !== "Signal Strength",
    );

  const initialValues = {
    startDate,
    endDate,
    filename: "",
    productCode: "",
    lotCode: "",
    fileFormat: "xlsx",
    doGeneratePdfCharts: false,
    selectedDevices,
    selectedSensors,
    location: -1,
  };

  return (
    <Formik initialValues={initialValues} onSubmit={() => {}} enableReinitialize={true}>
      {({ values, isSubmitting, submitForm, setFieldValue }) => {
        const locationOptions = [{ value: -1, label: t("common:select") }];
        (locations.data || []).forEach((location) => {
          locationOptions.push({ value: location._id, label: location.name });
        });

        return (
          <Modal
            buttons={
              <div className="report-footer-buttons">
                <div className="report-footer-buttons-main">
                  {currentStep === 1 || AppState.mode !== "mobile" ? (
                    <button type="button" className="btn btn-info" disabled={isSubmitting} onClick={() => showAppModal(null)}>
                      {t("common:cancel")}
                    </button>
                  ) : null}

                  {currentStep > 1 ? (
                    <button type="button" className="btn btn-info" disabled={isSubmitting} onClick={backStep}>
                      {t("common:back")}
                    </button>
                  ) : null}

                  {currentStep < totalSteps ? (
                    <button
                      type="button"
                      className="btn btn-primary"
                      disabled={
                        isSubmitting ||
                        (currentStep === 1 && !values.selectedDevices.length) ||
                        (currentStep === 2 && !values.selectedSensors.length)
                      }
                      onClick={nextStep}>
                      {t("common:next")}
                    </button>
                  ) : null}

                  {currentStep === totalSteps ? (
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => {
                        setShowLoader(true);
                        submitForm();
                        saveReports(values);
                      }}
                      disabled={isSubmitting}>
                      {t("common:save")}
                    </button>
                  ) : null}
                </div>
                {currentStep > 1 ? (
                  <button
                    type="button"
                    className="btn btn-info btn-plain u-text-teal u-mobile-only"
                    disabled={isSubmitting}
                    onClick={() => showAppModal(null)}>
                    {t("common:cancel")}
                  </button>
                ) : null}
              </div>
            }
            title={
              <>
                <span className="pull-left modal-title">{t("report_modal:mass_title", { context: currentStep })}</span>
                <p className="pull-right">
                  {t("report_modal:step_of", {
                    currentStep,
                    totalSteps,
                  })}
                </p>
              </>
            }>
            <form onSubmit={submitForm}>
              {currentStep === 1 ? (
                <div id="step1">
                  {gateways?.map((gateway) => (
                    <div key={gateway._id}>
                      {gateway.name}
                      <div>
                        <ul>
                          {gateway.Devices.rows.map((device) => (
                            <li key={device._id} className="select-group-item">
                              <FormFieldCheckbox
                                onChange={(e) => setFieldValue("selectedDevices", updateSelectedDevices(device, e.target.checked))}
                                className="input-holder"
                                checked={isDeviceSelected(device)}
                                name="selectedDevices"
                                label={device.name}
                              />
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  ))}
                </div>
              ) : null}

              {currentStep === 2 ? (
                <div id="step2">
                  <div className="form-group">
                    <div className="row">
                      <FormFieldDate
                        name="startDate"
                        minDate={minDate}
                        maxDate={endDate}
                        hasTimeSelector={true}
                        className="col-xs-6"
                        onChange={setStartDate}
                        label={t("common:from")}
                      />
                      <FormFieldDate
                        name="endDate"
                        minDate={startDate}
                        maxDate={new Date()}
                        hasTimeSelector={true}
                        className="col-xs-6"
                        onChange={setEndDate}
                        label={t("common:to")}
                      />
                    </div>
                  </div>

                  {selectedDevices.map((device) => (
                    <div className="select-group-item device-item">
                      <div>{device.name}</div>
                      <SelectInput
                        className="input-filter-holder"
                        renderValue={() => getSensorDropdownText(device)}
                        inputClassName="dark"
                        multiple
                        options={[
                          {
                            label: t("report_modal:select_all"),
                            onClick: () => updateSelectedAll(device),
                            selected: isAllSensorsSelectedForDevice(device),
                            value: undefined,
                          },
                          ...getFilteredSensors(device).map((sensor) => ({
                            label: t(`sensor_types:${getNameSlug(sensor.Sensor_type.name)}`),
                            onClick: () => setFieldValue("selectedSensors", updateSelectedSensors(sensor)),
                            selected: isSensorSelected(sensor),
                            value: sensor,
                          })),
                        ]}
                        value={selectedSensors}
                      />
                    </div>
                  ))}
                </div>
              ) : null}

              {currentStep === 3 ? (
                <div id="step3">
                  <div className="form-group">
                    <FormFieldText
                      name="filename"
                      label={t("report_modal:file_name")}
                      placeholder={t("report_modal:file_name_placeholder")}
                    />
                    <FormFieldText
                      name="productCode"
                      label={t("report_modal:product_code")}
                      placeholder={t("report_modal:product_code_placeholder")}
                    />
                    <FormFieldText name="lotCode" label={t("report_modal:lot_code")} placeholder={t("report_modal:lot_code_placeholder")} />
                  </div>

                  <FormFieldSelect label={t("report_modal:location")} options={locationOptions} name="location" />

                  <FormFieldSelect
                    options={[
                      { value: "pdf", label: "PDF" },
                      { value: "xlsx", label: "XLSX" },
                    ]}
                    name="fileFormat"
                    label={t("report_modal:file_format")}
                  />

                  {values.fileFormat === "pdf" ? (
                    <FormFieldCheckbox
                      onChange={(e) => setFieldValue("doGeneratePdfCharts", e.target.checked)}
                      className="input-holder u-mobile-hide"
                      checked={values.doGeneratePdfCharts}
                      name="doGeneratePdfCharts"
                      label={t("report_modal:generate_charts")}
                      disabled={!chartsData || chartsData.length === 0}
                    />
                  ) : null}
                  <div>{generatePdfHiddenCharts()}</div>
                </div>
              ) : null}
              {showLoader ? (
                <div className="generate-loader">
                  <i className="fa fa-spin fa-circle-o-notch" />
                </div>
              ) : null}
            </form>
          </Modal>
        );
      }}
    </Formik>
  );
});
