import React, { useCallback, useEffect, useState } from "react";
import classNames from "classnames";
import { LocationDropDown } from "../LocationDropdown";
import { ICON_TOOLTIP_DEFAULT_DELAY, LeftRail, PermissionBlocker, StyledTooltip } from "../../../Components";
import { IDeviceGroup, IFirmware, IGateway } from "../../../Managers/Types";
import { AppState, refreshLocationData, setSelectedAllDevices, showAppModal } from "../../../AppState";
import { EditGatewayModal } from "../Modals/EditGatewayModal";
import { ManageFirmware } from "../Modals/ManageFirmware";
import { AddDeviceGroupModal } from "../Modals/AddDeviceGroup";
import { observer } from "mobx-react-lite";
import { getLatestFirmwares } from "../../../Managers/API";
import "./DashboardLeftRail.scss";
import { isUserPermissionAllowed, PermissionEnum } from "../../../Enums";
import { FirmwareChangelogModal } from "../Modals/FirmwareChangelogModal";
import { useTranslation } from "react-i18next";

export interface IDashboardLeftRailProps {
  gateways: IGateway[];
  deviceGroups: IDeviceGroup[];
  onSelectGateway: (gateway: IGateway | null) => void;
  onSelectGroup: (group: IDeviceGroup | null) => void;
}

export const DashboardLeftRail: React.FC<IDashboardLeftRailProps> = observer(
  ({ gateways, deviceGroups, onSelectGateway, onSelectGroup }) => {
    const [showAllGateways, setShowAllGateways] = useState(false);
    const [selectedGateway, setSelectedGateway] = useState<IGateway | null>(null);
    const [selectedGroup, setSelectedGroup] = useState<IDeviceGroup | null>(null);
    const [shouldShowFirmwareUpdateBadge, setShouldShowFirmwareUpdateBadge] = useState<boolean>(false);

    const { t } = useTranslation(["dashboard", "common"]);

    const handleSelectGateway = useCallback(
      (gateway: IGateway | null) => {
        setSelectedGateway(gateway);
        onSelectGateway(gateway);

        setSelectedGroup(null);
        onSelectGroup(null);
      },
      [onSelectGateway],
    );

    useEffect(() => {
      if (isUserPermissionAllowed(PermissionEnum.UPDATE_FIRMWARE)) {
        const outdatedFirmware: IFirmware[] = [];
        const show = gateways?.some((g) => {
          outdatedFirmware.push(...(g.Firmwares?.filter((f) => !f.isLatest) ?? []));
          const hasOldFirmware = g.Firmwares?.some((f) => !f.isLatest);
          const hasMissingFirmware = (g.Firmwares ?? []).length < 2;
          const isOnline = g.is_online && g.is_active;
          return (hasOldFirmware || hasMissingFirmware) && isOnline;
        });

        setShouldShowFirmwareUpdateBadge(show);

        if (
          outdatedFirmware?.length > 0 &&
          !AppState.user?.Account?.firmware_changes_read &&
          isUserPermissionAllowed(PermissionEnum.UPDATE_FIRMWARE)
        ) {
          const outdatedFirmwareIds = outdatedFirmware.map((f) => f.FirmwareTypeId);
          getLatestFirmwares().then((latest) => {
            const firmwareToShowUpdatesFor = latest.filter((l) => outdatedFirmwareIds.includes(l.FirmwareTypeId) && !!l.changelog);

            if (firmwareToShowUpdatesFor?.length > 0) {
              showAppModal(<FirmwareChangelogModal firmwares={firmwareToShowUpdatesFor} />);
            }
          });
        }
      }

      if (AppState.selectedAllDevices || AppState.selectedDeviceGroup) {
        return;
      }

      const gatewaysWithDevices = gateways.filter((el) => el.Devices.count);
      const previouslySelectedGateway = AppState.selectedGateway && gateways.find((g) => AppState.selectedGateway?._id === g._id);
      handleSelectGateway(previouslySelectedGateway ?? gatewaysWithDevices[0]);
    }, [gateways]);

    const getAllDevicesLength = useCallback(() => {
      return gateways.reduce((a, b) => a + b.Devices.count, 0);
    }, [gateways]);

    const handleSelectGroup = useCallback(
      (group: IDeviceGroup | null) => {
        setSelectedGateway(null);
        onSelectGateway(null);

        setSelectedGroup(group);
        onSelectGroup(group);
      },
      [onSelectGroup],
    );

    const numberOfLowBatteries = AppState.selectedLocation?.lowBatteryCount || 0;

    const deviceGroupNotificationCounter = {} as Record<number, any>;

    return (
      <LeftRail
        header={
          <div style={{ width: "100%" }}>
            <LocationDropDown />
          </div>
        }
        body={
          <nav className="left-rail-nav">
            <div className="left-rail-nav-header">
              <h2 className="left-rail-nav-header-title">{t("dashboard:left_rail.gateways.title")}</h2>

              <div id="left-rail-actions">
                <StyledTooltip title={t("dashboard:left_rail.gateways.add_new")} enterDelay={ICON_TOOLTIP_DEFAULT_DELAY}>
                  <button
                    id="left-rail-action"
                    className="btn btn-circle btn-primary"
                    onClick={() => showAppModal(<EditGatewayModal gateway={null} mode="new" />)}>
                    <i className="fa fa-plus" />
                  </button>
                </StyledTooltip>

                <PermissionBlocker permission={PermissionEnum.UPDATE_FIRMWARE}>
                  <StyledTooltip title={t("dashboard:left_rail.gateways.manage_firmware")} enterDelay={ICON_TOOLTIP_DEFAULT_DELAY}>
                    <button
                      id="left-rail-action"
                      className="btn btn-circle btn-primary"
                      onClick={async () => {
                        refreshLocationData();
                        showAppModal(<ManageFirmware latestFirmwares={await getLatestFirmwares()} selectedDevice={null} />);
                      }}>
                      <i id="gateway-settings" className="fa fa-cloud-upload" />
                      {shouldShowFirmwareUpdateBadge ? (
                        <span className={classNames("gateway-firmware-alert-badge badge btn-badge btn-badge-sm btn-badge-alert")} />
                      ) : null}
                    </button>
                  </StyledTooltip>
                </PermissionBlocker>
                <StyledTooltip
                  title={t("dashboard:gateways", { context: showAllGateways ? "hide" : "show" })}
                  enterDelay={ICON_TOOLTIP_DEFAULT_DELAY}>
                  <button className="btn btn-circle btn-primary" onClick={() => setShowAllGateways(!showAllGateways)}>
                    <i className={"fa " + (showAllGateways ? "fa-eye-slash" : "fa-eye")} />
                  </button>
                </StyledTooltip>
              </div>
            </div>

            <ul className="left-rail-nav-group">
              {gateways.map((gateway) => (
                <li
                  key={gateway._id}
                  className={classNames("left-rail-nav-item", {
                    offline: !gateway.is_online,
                    active: selectedGateway?._id === gateway._id,
                  })}>
                  {(gateway.Devices?.rows && gateway.Devices.rows.length > 0) || showAllGateways ? (
                    <a onClick={() => handleSelectGateway(gateway)} title={gateway.name || "Gateway " + gateway._id}>
                      <div className={classNames("left-rail-nav-item-main", { full: !gateway.new })}>
                        <span className="left-rail-nav-item-title">{gateway.name || "Gateway " + gateway._id}</span>
                        <span className={classNames("left-rail-nav-item-number", { warning: gateway.Devices.count >= 50 })}>
                          {" "}
                          ({gateway.Devices.count})
                        </span>
                      </div>

                      {gateway.new ? (
                        <span className="left-rail-nav-item-icon">
                          {gateway.new}
                          <span className="u-text-small">{t("dashboard:left_rail.gateways.new")}</span>
                        </span>
                      ) : null}
                    </a>
                  ) : null}
                </li>
              ))}

              <li className={classNames("left-rail-nav-item last", { active: !selectedGroup && !selectedGateway })}>
                <a
                  onClick={() => {
                    handleSelectGateway(null);
                    setSelectedAllDevices(true);
                  }}>
                  <div className="left-rail-nav-item-main">
                    <span className="left-rail-nav-item-title">{t("dashboard:left_rail.gateways.all_devices")}</span>
                    <span className="left-rail-nav-item-number"> ({getAllDevicesLength()})</span>
                  </div>

                  {numberOfLowBatteries ? (
                    <span className="left-rail-nav-item-icon">
                      {numberOfLowBatteries}{" "}
                      <StyledTooltip
                        title={t("dashboard:left_rail.gateways.battery_tooltip", { count: numberOfLowBatteries })}
                        enterDelay={ICON_TOOLTIP_DEFAULT_DELAY}>
                        <i className="icon-sprite icon-sprite-battery_0" />
                      </StyledTooltip>
                    </span>
                  ) : null}
                </a>
              </li>
            </ul>

            <div className="left-rail-nav-header">
              <h2 className="left-rail-nav-header-title">{t("dashboard:left_rail.device_groups.title")}</h2>
              <PermissionBlocker permission={PermissionEnum.DEVICE_GROUPS_MANAGEMENT}>
                <StyledTooltip title={t("dashboard:left_rail.device_groups.add_new")} enterDelay={ICON_TOOLTIP_DEFAULT_DELAY}>
                  <button className="btn btn-circle btn-primary" onClick={() => showAppModal(<AddDeviceGroupModal />)}>
                    <>
                      <i className="fa fa-plus" />
                      <span className="sr-only">{t("dashboard:left_rail.device_groups.add_new")} </span>
                    </>
                  </button>
                </StyledTooltip>
              </PermissionBlocker>
            </div>

            <ul className="left-rail-nav-group">
              {!deviceGroups.length ? <li className="left-rail-nav-item"> {t("dashboard:no_groups")} </li> : <></>}

              {deviceGroups.map((group) => (
                <li
                  className={classNames("left-rail-nav-item", { active: selectedGroup && selectedGroup._id === group._id })}
                  key={group._id}>
                  <a onClick={() => handleSelectGroup(group)} title={group.name || ""}>
                    <div className={classNames("left-rail-nav-item-main", { full: !deviceGroupNotificationCounter[group._id] })}>
                      <span className="left-rail-nav-item-title">{group.name}</span>
                      <span className="left-rail-nav-item-number"> ({group.Devices?.length})</span>
                    </div>

                    {deviceGroupNotificationCounter[group._id] ? (
                      <span className="left-rail-nav-item-icon">
                        {deviceGroupNotificationCounter[group._id]}
                        <i className="icon icon-alarm" />
                      </span>
                    ) : null}
                  </a>
                </li>
              ))}
            </ul>
          </nav>
        }
      />
    );
  },
);
