import { Column, Row } from "./PageLayout";
import { List, ListItem } from "./List";
import React from "react";
import { ISubscription } from "../Managers/Types";
import { Colors } from "../Theme";
import { IAccount } from "../Views/Accounts/Types";
import { Trans, useTranslation } from "react-i18next";
import { getCurrencySign } from "../AppState";
import { PermissionBlocker } from "./PermissionBlocker";
import { PermissionEnum } from "../Enums";

interface IPlanDetailsProps {
  account?: IAccount | null;
  accountSubscription?: ISubscription[];
  expiredMode?: boolean;
  displayUpdatePaymentButton?: boolean;
  updatePaymentMethod?: () => void;
}

export const PlanDetails: React.FC<IPlanDetailsProps> = ({
  account,
  accountSubscription,
  displayUpdatePaymentButton,
  expiredMode,
  updatePaymentMethod,
}) => {
  const { t } = useTranslation(["plan_details", "common"]);

  const formatPrice = (value: number | string | undefined) => {
    if (value === undefined) {
      return "";
    }

    if (typeof value === "string") {
      value = Number.parseFloat(value);
    }

    return `${getCurrencySign()}${value.toFixed(2)}`;
  };

  const nextBillingAttempt = () => {
    let expirationDate = account?.expiration_date;
    if (!expirationDate || account?.blocked) {
      return <></>;
    }
    const date = new Date(expirationDate);
    const formattedDate = date.toLocaleDateString("en-US", { dateStyle: "long" });
    if (account?.cancel_at_period_end) {
      return (
        <span style={{ fontWeight: "bold", color: Colors.alert }}>
          {t("plan_details:subscription_end_info")}
          <br />
          {formattedDate}
        </span>
      );
    }
    return (
      <span>
        {t("plan_details:renews_on")} {formattedDate}
      </span>
    );
  };

  const getSubscriptionOption = (key: string): ISubscription | undefined => {
    return accountSubscription?.find((i) => i.plan === key);
  };

  const colorNumber = (used: number | undefined, limit: number | undefined) => {
    if (limit === undefined || used === undefined) {
      return <></>;
    }
    const color = used !== 0 && used === limit ? Colors.alert : Colors.teal;

    return <span style={{ fontWeight: "bold", fontSize: 16, color }} />;
  };

  return (
    <Column>
      {expiredMode && (
        <div className={"bordered-info-text bordered-info-text--error"} style={{ fontSize: 16 }}>
          {t("plan_details:no_valid_subscription")}
        </div>
      )}
      <div className="type-large-regular register-form-label" style={{ paddingTop: "16px" }}>
        {t("plan_details:title")}
      </div>
      <Row className="confirmation-step-second-row-double-width">
        <Column>
          <p className="input-label">{t("plan_details:subscription_price")}</p>
          <p>
            {formatPrice(account?.cost)}/{t("common:monthly")}
          </p>
          {<p>{nextBillingAttempt()}</p>}

          {displayUpdatePaymentButton ? (
            <PermissionBlocker permission={PermissionEnum.BILLING_ACCESS}>
              <button
                style={{ padding: 0, marginBottom: "3em" }}
                className="u-mobile-only text-right btn btn-plain u-text-teal"
                onClick={() => updatePaymentMethod && updatePaymentMethod()}>
                {t("billing_info:subscription.update_payment_method")}
              </button>
            </PermissionBlocker>
          ) : null}
        </Column>
        <Column>
          <p className="input-label">{t("plan_details:includes")}</p>
          <List listStyle="disc" className="container-full confirmation-step-list">
            <ListItem>
              <div className="confirmation-step-list-item-content">
                <span>{t("plan_details:base_price")}</span>
                <span>{formatPrice(getSubscriptionOption("basePrice")?.price)}</span>
              </div>
            </ListItem>
            <ListItem>
              <div className="confirmation-step-list-item-content">
                <span>
                  <Trans
                    t={t}
                    count={getSubscriptionOption("numberOfDevices")?.limit}
                    values={{
                      used: getSubscriptionOption("numberOfDevices")?.used,
                      limit: getSubscriptionOption("numberOfDevices")?.limit,
                    }}
                    components={{
                      number: colorNumber(getSubscriptionOption("numberOfDevices")?.used, getSubscriptionOption("numberOfDevices")?.limit),
                    }}
                    i18nKey="used_devices"
                    ns="plan_details"
                  />
                </span>
                <span>{formatPrice(getSubscriptionOption("numberOfDevices")?.price)}</span>
              </div>
            </ListItem>
            <ListItem>
              <div className="confirmation-step-list-item-content">
                <span>
                  <Trans
                    t={t}
                    count={getSubscriptionOption("numberOfUsers")?.limit}
                    values={{
                      used: getSubscriptionOption("numberOfUsers")?.used,
                      limit: getSubscriptionOption("numberOfUsers")?.limit,
                    }}
                    components={{
                      number: colorNumber(getSubscriptionOption("numberOfUsers")?.used, getSubscriptionOption("numberOfUsers")?.limit),
                    }}
                    i18nKey="used_users"
                    ns="plan_details"
                  />
                </span>
                <span>{formatPrice(getSubscriptionOption("numberOfUsers")?.price)}</span>
              </div>
            </ListItem>
            <ListItem>
              <div className="confirmation-step-list-item-content">
                <span>
                  {t("plan_details:transmission_rate_up_to")} {getSubscriptionOption("transmissionRate")?.name}
                </span>
                <span>{formatPrice(getSubscriptionOption("transmissionRate")?.price)}</span>
              </div>
            </ListItem>
            <ListItem>
              <div className="confirmation-step-list-item-content">
                <span>{t("plan_details:unlimited_email_notifications")}</span>
                <span>{t("plan_details:free")}</span>
              </div>
            </ListItem>
            {getSubscriptionOption("smsNotifications") ? (
              <ListItem>
                <div className="confirmation-step-list-item-content">
                  <span>
                    <Trans
                      t={t}
                      values={{
                        used: getSubscriptionOption("smsNotifications")?.used,
                        limit: getSubscriptionOption("smsNotifications")?.limit,
                      }}
                      components={{
                        number: colorNumber(getSubscriptionOption("smsNotifications")?.used, getSubscriptionOption("numberOfUsers")?.limit),
                      }}
                      i18nKey="used_sms"
                      ns="plan_details"
                    />
                  </span>
                  <span>{formatPrice(getSubscriptionOption("smsNotifications")?.price)}</span>
                </div>
              </ListItem>
            ) : null}
          </List>
        </Column>
      </Row>
    </Column>
  );
};
