import React, { useEffect, useState } from "react";
import moment from "moment";
import { INotification } from "../../Managers/Types";
import { Modal } from "../../Components/Modal";
import { showAppModal, showSnackbar } from "../../AppState";
import { measurementTransform } from "../../Managers/MeasurementService";
import { alertConditionTransform } from "../../Managers/AlertConditionService";
import { unitsTransform } from "../../Managers/UnitsService";
import { TimeSeriesLineChart } from "../../Components";
import { getDeviceSensorData } from "../../Managers/DeviceService";
import { prepareChartDataSet } from "../../Managers/ReportManager";
import "./AlertsHistory.scss";
import { useTranslation } from "react-i18next";

interface Props {
  selectedAlert: INotification;
}

const AlertsHistoryDetailsModal: React.FC<Props> = ({ selectedAlert }) => {
  const [graphData, setGraphData] = useState<any[]>([]);
  const [isGraphLoading, setIsGraphLoading] = useState(true);
  const [setPoints, setSetPoints] = useState<any[]>([]);

  const { t } = useTranslation(["alert_history", "common"]);

  useEffect(() => {
    initData(selectedAlert);
  }, [selectedAlert]);

  const initData = (selectedAlert: INotification) => {
    if (selectedAlert) {
      const startDate = moment(selectedAlert.createdAt).subtract(5, "days").toDate();
      const endDate = moment(selectedAlert.createdAt).add(5, "days").toDate();
      _getSensorData(selectedAlert, startDate, endDate);
    }
  };

  const _getSensorData = (selectedAlert: INotification, startDate: Date, endDate: Date) => {
    setIsGraphLoading(true);
    setGraphData([]);
    setSetPoints([]);

    if (!selectedAlert?.Sensor) {
      return;
    }

    getDeviceSensorData(selectedAlert.Sensor, startDate.toISOString(), endDate.toISOString())
      .then((r) => {
        console.log("getDeviceSensorData");
        console.log(r);
        if (r && r.length) {
          let sensorData = prepareChartDataSet(r, selectedAlert.Sensor!, selectedAlert.Sensor?.Device?.is_empirical);
          setGraphData([...sensorData]);

          const point = [
            {
              createdAt: new Date(selectedAlert.createdAt),
              value: measurementTransform(selectedAlert.value.value, [
                selectedAlert.Sensor?.default_unit,
                selectedAlert.Sensor?.Device?.is_empirical,
                selectedAlert.Sensor?.Sensor_type.type,
              ]),
              _id: 1,
            },
          ];

          setSetPoints(point);
        } else {
          setGraphData(r);
        }
      })
      .catch((e) => {
        const errorMessage = t("alert_history:data_load_error");
        showSnackbar(errorMessage, "error");
        console.log(errorMessage, e);
      })
      .finally(() => setIsGraphLoading(false));
  };

  return (
    <Modal
      bodyClassName={"alert-details-body"}
      buttons={
        <>
          <button className="btn btn-info alerts-details-button" onClick={() => showAppModal(null)}>
            {t("common:cancel")}
          </button>
        </>
      }>
      <div className="alert-history-details-modal">
        <div className="top-wrapper">
          <div className="top-wrapper-left">
            {t("alert_history:device")} {selectedAlert?.Sensor?.Device?.name || ""}
          </div>
          <div className="top-wrapper-right">
            <div className="u-mobile-hide">
              {t("alert_history:placement")}: {selectedAlert?.Sensor?.Device?.location_note || "--"}
            </div>
            <div className="u-mobile-hide">
              {t("alert_history:serial_number")}: {selectedAlert?.Sensor?.Device?.serial_number || ""}
            </div>
          </div>
        </div>

        <div className="alert-details">
          <div className="alert-detail-column u-desktop-hide">
            <div className="input-label">{t("alert_history:placement")}</div>
            <div>{selectedAlert?.Sensor?.Device?.location_note || "--"}</div>
          </div>

          <div className="alert-detail-column u-desktop-hide">
            <div className="input-label">{t("alert_history:serial_number")}</div>
            <div>{selectedAlert?.Sensor?.Device?.serial_number || ""}</div>
          </div>

          <div className="alert-detail-column date-column">
            <div className="input-label">{t("alert_history:date")}</div>
            <div>{moment(selectedAlert?.createdAt).format("M/D/YYYY hh:mm A")}</div>
          </div>

          <div className="alert-detail-column">
            <div className="input-label">{t("alert_history:sensor_type")}</div>
            <div>{selectedAlert.Sensor?.Sensor_type?.name}</div>
          </div>

          <div className="alert-detail-column alert-value-column">
            <div className="input-label">{t("alert_history:alert_value")}</div>
            <span>
              {measurementTransform(selectedAlert.value.value, [
                selectedAlert.Sensor?.default_unit || "degF",
                selectedAlert.Sensor?.Device?.is_empirical,
                selectedAlert.Sensor?.Sensor_type.type || "RANGE",
              ])}
              {selectedAlert.Sensor?.Sensor_type.type !== "BOOLEAN" && (
                <span>
                  {" "}
                  <span className="u-degree-history">
                    {unitsTransform(selectedAlert.Sensor?.default_unit || "degF", [
                      selectedAlert.Sensor?.default_unit || "degF",
                      selectedAlert.Sensor?.Device?.is_empirical,
                      selectedAlert.Sensor?.Sensor_type.type || "RANGE",
                    ])}
                  </span>{" "}
                </span>
              )}
            </span>
          </div>

          <div className="alert-detail-column condition-column u-mobile-hide">
            <div className="input-label">{t("alert_history:alert_condition")}</div>
            <div>{alertConditionTransform(selectedAlert.Alert)}</div>
          </div>

          <div className="alert-detail-column">
            <div className="input-label">{t("alert_history:alert_type")}</div>
            <div>{selectedAlert.Sensor?.Sensor_type?.type}</div>
          </div>
        </div>

        <div className="alert-detail-column condition-column u-desktop-hide" style={{ padding: "0px 12px 15px 12px" }}>
          <div className="input-label">{t("alert_history:alert_condition")}</div>
          <div>{alertConditionTransform(selectedAlert.Alert)}</div>
        </div>

        {isGraphLoading ? (
          <div className="no-graph-data">{t("alert_history:loading_graph_data")}</div>
        ) : (
          <>
            {graphData.length > 0 ? (
              <TimeSeriesLineChart data={graphData} setPoints={setPoints} />
            ) : (
              <div className="no-graph-data">{t("alert_history:no_data_available")}</div>
            )}
          </>
        )}
      </div>
    </Modal>
  );
};

export default AlertsHistoryDetailsModal;
