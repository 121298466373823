import React, { useCallback } from "react";
import * as yup from "yup";
import { Formik } from "formik";
import { observer } from "mobx-react-lite";
import { FormFieldText, InputLabel } from "../../../Components";

import "./DefaultAlertValues.scss";
import { ValidationMessages } from "../../../Enums";
import { AppState, setStateDefaultAlertValues, showAppModal, showSnackbar } from "../../../AppState";
import { setDefaultAlertValues } from "../../../Managers/AlertService";
import { Modal } from "../../../Components/Modal";
import { useTranslation } from "react-i18next";
import { IStoredValue } from "../../../Managers/Types";

export const DefaultAlertValues: React.FC = observer(({}) => {
  const userValues = AppState.user?.defaultAlertValues;
  const values = () => {
    return userValues?.map((el) => {
      let val = Number(el.value);
      if (el.slug !== "battery-voltage") val = val / 60;
      return [el.slug, val];
    });
  };
  const initialValues = Object.fromEntries(values() ?? []);
  const { t } = useTranslation(["default_alert_values", "common", "alerts"]);

  const validationSchema = yup.object({
    "battery-voltage": yup.number().moreThan(-1).not([-1], t(ValidationMessages.REQUIRED)),
    "battery-initial-alert-delay": yup.number().moreThan(-1).not([-1], t(ValidationMessages.REQUIRED)),
    "battery-resend-alert-after": yup.number().moreThan(-1).not([-1], t(ValidationMessages.REQUIRED)),
    "battery-alert-snooze": yup.number().moreThan(-1).not([-1], t(ValidationMessages.REQUIRED)),
    "not-transmitting-initial-alert-delay": yup.number().moreThan(-1).not([-1], t(ValidationMessages.REQUIRED)),
    "not-transmitting-resend-alert-after": yup.number().moreThan(-1).not([-1], t(ValidationMessages.REQUIRED)),
    "not-transmitting-alert-snooze": yup.number().moreThan(-1).not([-1], t(ValidationMessages.REQUIRED)),
  });

  const handleSubmit = useCallback((values, { setSubmitting }) => {
    const models: IStoredValue[] = [];
    for (const [slug, value] of Object.entries(values)) {
      let val = value;
      if (slug !== "battery-voltage") {
        val = (val as number) * 60;
      }

      models.push({ ...userValues?.find((el) => el.slug === slug), value: val } as IStoredValue);
    }

    setDefaultAlertValues(models)
      .then(() => {
        setStateDefaultAlertValues(models);
        showSnackbar(t("default_alert_values:save_success"), "success");
        showAppModal(null);
      })
      .catch((error) => {
        console.log("Update user error", error);
        showSnackbar(t("default_alert_values:save_error"), "error");
      })
      .finally(() => setSubmitting(false));
  }, []);

  return (
    <Formik enableReinitialize={true} initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
      {({ isSubmitting, submitForm }) => {
        return (
          <Modal
            title={t("default_alert_values:modal_title")}
            buttons={
              <>
                <button type="button" className="btn btn-info" onClick={() => showAppModal(null)} disabled={isSubmitting}>
                  {t("common:cancel")}
                </button>
                <button type="button" className="btn btn-primary" onClick={submitForm} disabled={isSubmitting}>
                  {isSubmitting ? <i className="fa fa-circle-o-notch fa-spin" /> : <></>}
                  {t("common:save")}
                </button>
              </>
            }>
            <form className="alert-config-wrap">
              <div className="alert-config-wrap-row">
                <InputLabel name={"battery"} label={t("default_alert_values:battery")} />
                <div className="col-xs-3">
                  <FormFieldText dark={true} name="battery-voltage" type="number" label={t("default_alert_values:voltage")} />
                  <p>V</p>
                </div>
                <div className="col-xs-3">
                  <FormFieldText
                    dark={true}
                    name="battery-initial-alert-delay"
                    type="number"
                    label={t("default_alert_values:initial_alert_delay")}
                  />
                  <p>{t("alerts:alert_config.minutes")}</p>
                  <p>{t("default_alert_values:initial_alert_delay_hint")}</p>
                </div>
                <div className="col-xs-3">
                  <FormFieldText
                    dark={true}
                    name="battery-resend-alert-after"
                    type="number"
                    label={t("default_alert_values:resend_after")}
                  />
                  <p>{t("alerts:alert_config.minutes")}</p>
                  <p>{t("default_alert_values:resend_after_hint")}</p>
                </div>
                <div className="col-xs-3">
                  <FormFieldText dark={true} name="battery-alert-snooze" type="number" label={t("default_alert_values:snooze")} />
                  <p>{t("alerts:alert_config.minutes")}</p>
                  <p>{t("default_alert_values:snooze_hint")}</p>
                </div>
              </div>
              <div className="alert-config-wrap-row">
                <InputLabel name={"not-transmitting"} label={t("default_alert_values:not_transmitting")} />
                <div className="col-xs-4">
                  <FormFieldText
                    dark={true}
                    name="not-transmitting-initial-alert-delay"
                    type="number"
                    label={t("default_alert_values:initial_alert_delay")}
                  />
                  <p>{t("alerts:alert_config.minutes")}</p>
                  <p>{t("default_alert_values:initial_alert_delay_hint")}</p>
                </div>
                <div className="col-xs-4">
                  <FormFieldText
                    dark={true}
                    name="not-transmitting-resend-alert-after"
                    type="number"
                    label={t("default_alert_values:resend_after")}
                  />
                  <p>{t("alerts:alert_config.minutes")}</p>
                  <p>{t("default_alert_values:resend_after_hint")}</p>
                </div>
                <div className="col-xs-4">
                  <FormFieldText dark={true} name="not-transmitting-alert-snooze" type="number" label={t("default_alert_values:snooze")} />
                  <p>{t("alerts:alert_config.minutes")}</p>
                  <p>{t("default_alert_values:snooze_hint")}</p>
                </div>
              </div>
            </form>
          </Modal>
        );
      }}
    </Formik>
  );
});
