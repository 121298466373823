import moment from "moment";
import jsPDF from "jspdf";
import "jspdf-autotable";
import classNames from "classnames";
import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { getDeviceSensorData } from "../../Managers/DeviceService";
import { alertConditionTransform } from "../../Managers/AlertConditionService";
import { measurementTransform } from "../../Managers/MeasurementService";
import { useNotifications } from "../../Managers/NotificationService";
import { DatepickerDropdown, TimeSeriesLineChart } from "../../Components";
import { unitsTransform } from "../../Managers/UnitsService";
import { AppState, showAppModal, showSnackbar } from "../../AppState";
import { useUsers } from "../../Managers/UsersService";
import { INotification } from "../../Managers/Types";
import "./AlertLogModal.scss";
import { prepareChartDataSet } from "../../Managers/ReportManager";
import { useTranslation } from "react-i18next";

export const AlertLogModal: React.FC = observer(() => {
  const [startDate, setStartDate] = useState<Date>(moment().subtract(1, "weeks").toDate());
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [selectedAlertId, setSelectedAlertId] = useState(0);
  const [graphData, setGraphData] = useState<any[]>([]);
  const [isGraphLoading, setIsGraphLoading] = useState(true);
  const [setPoints, setSetPoints] = useState<any[]>([]);

  const usersQuery = useUsers();
  const notificationsQuery = useNotifications(moment(startDate).startOf("day").toISOString(), moment(endDate).endOf("day").toISOString());

  const { t } = useTranslation(["alert_log", "common"]);

  // TODO: More naming inconsistencies
  const sortedNotifications = (notificationsQuery.data || []).sort((note1: any, note2: any) =>
    note2.createdAt.localeCompare(note1.createdAt),
  );
  const selectedAlert = sortedNotifications.find((n) => n._id === selectedAlertId) || null;
  const statusMessage = sortedNotifications.length > 0 ? "" : t("alert_log:no_alerts_found");

  const selectAlert = (alert: INotification) => {
    setSelectedAlertId(alert._id);
    setStartDate(moment().subtract(1, "weeks").toDate());
    setEndDate(new Date());
    if (alert && alert._id) {
      notificationsQuery.refetch().then(() => {
        const selectedAlert = sortedNotifications.find((n) => n._id === alert._id);
        if (selectedAlert) {
          _getSensorData(selectedAlert);
        }
      });
    }
  };

  const generatePDF = () => {
    let doc = new jsPDF("p", "pt");
    const startingHeight = 30;
    const marginLeft = 40;

    let headers = [
      { header: t("alert_log:value"), dataKey: "value" },
      { header: t("alert_log:name"), dataKey: "name" },
      { header: t("alert_log:condition"), dataKey: "condition" },
      { header: t("alert_log:device"), dataKey: "device" },
      { header: t("alert_log:placement"), dataKey: "placement" },
      { header: t("alert_log:serial"), dataKey: "serial" },
      { header: t("alert_log:last_updated"), dataKey: "lastUpdateAt" },
      { header: t("alert_log:resolved"), dataKey: "resolved" },
    ];

    let data = sortedNotifications.map((alert) => {
      let value =
        String(
          measurementTransform(alert.value.value, [
            alert.Sensor?.default_unit || "degF",
            alert.Sensor?.Device?.is_empirical,
            alert.Sensor?.Sensor_type.type,
          ]),
        ) +
        unitsTransform(alert.Sensor?.default_unit || "degF", [
          alert.Sensor?.default_unit || "degF",
          alert.Sensor?.Device?.is_empirical,
          alert.Sensor?.Sensor_type.type,
        ]);

      let alertName = alert.Alert ? alert.Alert.name + " - " + alertConditionTransform(alert.Alert) : "";
      let alertCondition = alert.Alert ? alertConditionTransform(alert.Alert) : "";
      let deviceName = alert.Sensor?.Device?.name || "";
      let placement = alert.Sensor?.Device?.location_note || "--";
      let serialNumber = alert.Sensor?.Device?.serial_number || "";
      let lastUpdateAt = moment(alert.updatedAt).format("M/D/YYYY hh:mm A");
      let isResolved = alert.is_resolved ? "4" : "8";

      return [value, alertName, alertCondition, deviceName, placement, serialNumber, lastUpdateAt, isResolved];
    });

    (doc as any).autoTable({
      startY: startingHeight,
      margin: { top: startingHeight, left: marginLeft, right: marginLeft },
      columns: headers,
      body: data,
      styles: {
        fontSize: 6,
      },
      headStyles: {
        halign: "center",
      },
      columnStyles: {
        value: {
          halign: "center",
        },
        name: {
          halign: "center",
          overflow: "ellipsize",
        },
        condition: {
          halign: "center",
          overflow: "ellipsize",
        },
        device: {
          halign: "center",
        },
        placement: {
          halign: "center",
        },
        serial: {
          halign: "center",
        },
        lastUpdateAt: {
          halign: "center",
        },
        resolved: {
          halign: "center",
          font: "zapfdingbats",
        },
      },
    });

    doc.save(t("alert_log:filename") + moment(startDate).format("M/D/YYYY") + t("common:to") + moment(endDate).format("M/D/YYYY") + ".pdf");
  };

  const getUserName = (id: number) => {
    const user = AppState.user?._id === id ? AppState.user : (usersQuery.data || []).find((user) => user._id === id);
    return user ? (user.first_name ? user.first_name + " " : "") + (user.last_name ? user.last_name : "") : "";
  };
  // const _getUserName = (userid) => {
  // 	if (!userid) {return '';}
  //
  // 	let user;
  //
  // 	if (userid === user._id) {
  // 		user = user;
  // 	} else {
  // 		//	TODO: I don't see how this could have worked, it shadows the outer block's "let" so user would never have gotten set unless it was "me"
  // 		// 	let user = usersQuery.filter((u) => {
  // 		// 		return u._id === userid;
  // 		// 	})[0];
  // 		let user = users.filter((u) => {
  // 			return u._id === userid;
  // 		})[0];
  // 	}
  //
  // 	if (user) {
  // 		return (user.first_name ? user.first_name + ' ' : '') + (user.last_name ? user.last_name : '');
  // 	} else {
  // 		return '';
  // 	}
  // }

  const _getSensorData = (selectedAlert: INotification) => {
    setIsGraphLoading(true);
    setGraphData([]);
    setSetPoints([]);

    if (!selectedAlert?.Sensor) {
      return;
    }

    getDeviceSensorData(selectedAlert.Sensor, startDate.toISOString(), endDate.toISOString())
      .then((r) => {
        console.log("getDeviceSensorData");
        console.log(r);
        if (r && r.length) {
          let sensorData = prepareChartDataSet(r, selectedAlert.Sensor!, selectedAlert.Sensor?.Device?.is_empirical);
          setGraphData([...sensorData]);

          const point = [
            {
              createdAt: new Date(selectedAlert.createdAt),
              value: measurementTransform(selectedAlert.value.value, [
                selectedAlert.Sensor?.default_unit,
                selectedAlert.Sensor?.Device?.is_empirical,
                selectedAlert.Sensor?.Sensor_type.type,
              ]),
              _id: 1,
            },
          ];
          console.log("point");
          console.log(point);
          setSetPoints(point);
        } else {
          setGraphData(r);
        }
      })
      .catch((e) => {
        const errorMessage = t("alert_log:sensor_data_error");
        showSnackbar(errorMessage, "error");
        console.log(errorMessage, e);
      })
      .finally(() => setIsGraphLoading(false));
  };

  return (
    <div className="modal fade in alert-log-modal" tabIndex={-1} role="dialog" style={{ display: "block", opacity: 1 }}>
      <div className="modal-dialog modal-lg">
        <div className="modal-content" style={{ height: "90vh", overflowY: "hidden" }}>
          <div className="modal-header" style={{ height: "auto", minHeight: "40px" }}>
            <div className="row">
              <h4 className="modal-title col-xs-2">{t("alert_log:title")}</h4>
              <div className="col-xs-7" style={{ padding: 0, display: "flex", alignItems: "center", flexDirection: "row" }}>
                <div className="pull-left">
                  <DatepickerDropdown selectedDate={startDate} maxDate={endDate} onSelectDate={(date) => setStartDate(date)} />
                </div>

                <span className="pull-left to" style={{ padding: "0.5em" }}>
                  {t("common:to")}
                </span>
                <div className="pull-left">
                  <DatepickerDropdown selectedDate={endDate} minDate={startDate} onSelectDate={(date) => setEndDate(date)} />
                </div>
              </div>
              <div className="col-xs-3">
                <button className="btn btn-primary pull-right" onClick={() => showAppModal(null)}>
                  {t("common:close")}
                </button>
                <span className="to pull-right" style={{ padding: "0.5em" }} />
                <button className="btn btn-info pull-right" disabled={!sortedNotifications.length} onClick={generatePDF}>
                  {t("alert_log:export_pdf")}
                </button>
              </div>
            </div>
          </div>

          <div className="left-rail" style={{ top: "51px!important" }}>
            {notificationsQuery.isLoading ? (
              <li className="select-group-item">
                {" "}
                <i className="fa fa-spin fa-spinner" />{" "}
              </li>
            ) : (
              <></>
            )}

            <ul className="select-group" role="menu">
              {!sortedNotifications.length ? <li className="select-group-item">{t("alert_log:no_alerts_found")}</li> : <></>}
              {sortedNotifications.map((alert) => (
                <li
                  className={classNames("select-group-item", { active: alert._id === selectedAlert?._id })}
                  onClick={() => selectAlert(alert)}>
                  {!alert.is_resolved ? (
                    <div className="col-xs-5 row">
                      <p className="alert-log-heading">
                        {measurementTransform(alert.value.value, [
                          alert.Sensor?.default_unit || "degF",
                          alert.Sensor?.Device?.is_empirical,
                          alert.Sensor?.Sensor_type.type || "RANGE",
                        ])}
                        <span className="u-degree">
                          {unitsTransform(alert.Sensor?.default_unit || "degF", [
                            alert.Sensor?.default_unit || "degF",
                            alert.Sensor?.Device?.is_empirical,
                            alert.Sensor?.Sensor_type.type || "RANGE",
                          ])}
                        </span>
                      </p>
                      <p className="alert-log-time">{moment(alert.updatedAt).format("M/D/YYYY hh:mm A")}</p>
                    </div>
                  ) : (
                    <></>
                  )}

                  {alert.is_resolved ? (
                    <div className="col-xs-5 row">
                      <p className="alert-log-heading">
                        <i className="fa fa-check-circle" />
                      </p>
                      <p className="alert-log-subheading">{t("alert_log:resolved")}</p>
                      <p className="alert-log-time">{moment(alert.updatedAt).format("M/D/YYYY hh:mm A")}</p>
                    </div>
                  ) : (
                    <></>
                  )}

                  <div className="col-xs-7">
                    <p className="u-text-eclipse" title="{alert.Alert.name}">
                      <i className="fa fa-bell" />
                      {alert.Alert.name}
                    </p>
                    <p>{alertConditionTransform(alert.Alert)}</p>
                    <p>
                      <span className="input-label">{t("alert_log:device")}: </span>
                      <span className="u-text-small">{alert.Sensor?.Device?.name || ""}</span>
                    </p>
                    {!alert.is_resolved ? (
                      <p>
                        <span className="input-label">{t("alert_log:placement")}: </span>
                        <span className="u-text-small">{alert.Sensor?.Device?.location_note || "--"}</span>
                      </p>
                    ) : (
                      <></>
                    )}

                    {alert.is_resolved && alert.UserId ? (
                      <p>
                        <span className="input-label">{t("alert_log:user")}: </span>
                        <span className="u-text-small">{getUserName(alert.UserId)}</span>
                      </p>
                    ) : null}
                  </div>
                </li>
              ))}
            </ul>
          </div>

          <div className="main-panel" style={{ paddingLeft: "20.92857em", maxHeight: "100%", overflowY: "auto" }}>
            {notificationsQuery.isLoading ? (
              <p className="u-text-align-center status-indicator">
                <i className="fa fa-spin fa-spinner" />
              </p>
            ) : (
              <></>
            )}

            <p className="u-text-error status-indicator">{statusMessage}</p>

            {selectedAlert ? (
              <div>
                <div className="info">
                  <div className="info-alert">
                    <p>
                      <span className="alert-value">
                        {measurementTransform(selectedAlert.value.value, [
                          selectedAlert.Sensor?.default_unit || "degF",
                          selectedAlert.Sensor?.Device?.is_empirical,
                          selectedAlert.Sensor?.Sensor_type.type || "RANGE",
                        ])}
                        <span className="u-degree">
                          {unitsTransform(selectedAlert.Sensor?.default_unit || "degF", [
                            selectedAlert.Sensor?.default_unit || "degF",
                            selectedAlert.Sensor?.Device?.is_empirical,
                            selectedAlert.Sensor?.Sensor_type.type || "RANGE",
                          ])}
                        </span>
                      </span>
                      <span>
                        {!selectedAlert?.is_snoozed ? <i className="icon icon-alarm" /> : <></>}
                        {selectedAlert?.is_snoozed ? <i className="icon icon-alarm_no" /> : <></>} {selectedAlert.Alert.name.indexOf('not-transmitting-alert') < 0 && selectedAlert.Alert.name}
                      </span>
                    </p>
                    <p>{alertConditionTransform(selectedAlert?.Alert)}</p>
                    <p>{moment(selectedAlert?.updatedAt).format("M/D/YYYY hh:mm A")}</p>
                  </div>
                  <div className="info-device">
                    <div>
                      <label className="input-label">{t("alert_log:device")}: </label>
                      {selectedAlert?.Sensor?.Device?.name || ""}
                    </div>
                    <div>
                      <label className="input-label">{t("alert_log:placement")}: </label>
                      {selectedAlert?.Sensor?.Device?.location_note || "--"}
                    </div>
                    <div>
                      <label className="input-label">{t("alert_log:serial_num")}: </label>
                      {selectedAlert?.Sensor?.Device?.serial_number || ""}
                    </div>
                  </div>
                </div>
                {isGraphLoading ? (
                  <p>{t("alert_log:loading_graph_data")}</p>
                ) : (
                  <>
                    {graphData.length > 0 ? (
                      <TimeSeriesLineChart data={graphData} setPoints={setPoints} />
                    ) : (
                      <p>{t("alert_log:no_data_available")}</p>
                    )}
                  </>
                )}
                <br />
                {selectedAlert.is_resolved ? (
                  <div style={{ paddingLeft: "15px" }}>
                    <div className="u-flex-start">
                      <div className="">
                        <p className="alert-log-heading">
                          <i className="fa fa-check-circle" />
                        </p>
                        <p className="alert-log-subheading">{t("alert_log:resolved")}</p>
                      </div>
                      <div className="info-device">
                        <p className="u-text-small">
                          <span className="input-label">{t("alert_log:user")}: </span>
                          <span>{getUserName(selectedAlert?.UserId || 0)}</span>
                        </p>
                        <p className="u-text-small">
                          <span className="input-label">{t("alert_log:time")}: </span>
                          <span>{moment(selectedAlert.updatedAt).format("M/D/YYYY hh:mm A")}</span>
                        </p>
                      </div>
                    </div>
                    <br />
                    <label className="input-label">{t("alert_log:notes")}: </label>
                    <p>{selectedAlert.resolved_notes}</p>
                    <br />
                    <br />
                  </div>
                ) : (
                  <></>
                )}
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </div>
  );
});
