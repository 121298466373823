import React, { useCallback, useEffect, useState } from "react";
import * as yup from "yup";
import { Formik } from "formik";
import { observer } from "mobx-react-lite";
import { FormFieldText, FormFieldTextArea, IInputRangeSliderProps, InputRangeSlider, SelectInput } from "../../Components";
import * as UnitsService from "../../Managers/UnitsService";
import { fullUnitName } from "../../Managers/UnitsService";
import * as AlertConditionService from "../../Managers/AlertConditionService";
import { IAlertConfig, ISensorType } from "../../Managers/Alert.model";
import {
  createAlertConfig,
  getAlertTypesForSelect,
  getDefaultConfig,
  updateAlertConfig,
  useSensorTypes,
} from "../../Managers/AlertService";
import { showAppModal, showSnackbar } from "../../AppState";
import "./AlertConfigModal.scss";
import classNames from "classnames";
import { ValidationMessages } from "../../Enums";
import { Modal } from "../../Components/Modal";
import { useTranslation } from "react-i18next";
import { getNameSlug } from "../../Managers";

interface IAlertPropertiesModal {
  alert?: IAlertConfig;
}

interface IAlertFormValues {
  name: string;
  _id: number;
  SensorTypeId: number;
  alertType: string | number;
  delay_seconds: number;
  snooze_seconds: number;
  interval_seconds: number;
  protocol: string;
  min: number | null;
  max: number | null;
  threshold: number;
  selected_unit: string;
  is_notify_on_non_transmitting: boolean;
}

export const AlertConfigModal: React.FC<IAlertPropertiesModal> = observer(({ alert }) => {
  const [selectedSensorTypeId, setSelectedSensorTypeId] = useState<number | null>(null);
  const [selectedSensorType, setSelectedSensorType] = useState<ISensorType>();
  const [selectedAlertType, setSelectedAlertType] = useState<
    | {
        type: string | number;
        label: string;
      }
    | null
    | undefined
  >(null);
  const [alertCondition, setAlertCondition] = useState("");
  const [alertTypes, setAlertTypes] = useState<{ label: string; type: string }[]>([]);
  const [defaultMin, setDefaultMin] = useState(0);
  const [defaultMax, setDefaultMax] = useState(0);
  const [alertMin, setAlertMin] = useState<number | null>(null);
  const [alertMax, setAlertMax] = useState<number | null>(null);
  const [unit, setSelectedUnit] = useState("");
  const [keptSettings, setKeptSettings] = useState<IAlertFormValues>();
  const sensorTypes = useSensorTypes();

  const { t } = useTranslation(["alerts", "sensor_types", "common"]);

  const getUnit = useCallback(
    (type = selectedSensorType) =>
      UnitsService.unitsTransform(type?.gateway_unit || "degF", [
        type?.gateway_unit || "degF",
        type?.units && type.units.length > 1,
        type?.type,
      ]),
    [selectedSensorType],
  );

  const validationSchema = yup.object({
    name: yup.string().max(25).required(ValidationMessages.REQUIRED),
    SensorTypeId: yup.number().not([-1], ValidationMessages.REQUIRED),
    alertType: yup.string().not(["-"], ValidationMessages.REQUIRED),
    protocol: yup.string(),
    min: yup
      .string()
      .nullable(true)
      .test("required", () => {
        if (selectedAlertType?.type === "min" || selectedAlertType?.type === "range") {
          return alertMin !== null && (alertMax === null || alertMin < alertMax);
        }
        return true;
      }),
    max: yup
      .string()
      .nullable(true)
      .test("required", () => {
        if (selectedAlertType?.type === "max" || selectedAlertType?.type === "range") {
          return alertMax !== null && (alertMin === null || alertMin < alertMax);
        }
        return true;
      }),
  });

  useEffect(() => {
    if (alert) {
      setSelectedSensorTypeId(alert.SensorTypeId);
      if (alert.Sensor_type) {
        const types = getAlertTypesForSelect(alert.Sensor_type);
        const sensorType = sensorTypes.data?.find((t) => t._id === alert.SensorTypeId);
        setSelectedSensorType(sensorType);
        setAlertTypes(types ?? []);

        switch (alert.Sensor_type.type) {
          case "BOOLEAN":
            if (alert.min === 0) {
              let off = types?.find((x) => x.type === 0);
              setSelectedAlertType(off);
            } else if (alert.max === 1) {
              let on = types?.find((x) => x.type === 1);
              setSelectedAlertType(on);
            } else {
              setSelectedAlertType(types?.find((x) => x.type === "transmitting"));
            }
            break;
          case "RANGE":
            {
              setAlertMax(alert.max);
              setAlertMin(alert.min);
              const unit = fullUnitName(getUnit(sensorType));

              if (sensorType?.type === "RANGE") {
                const ranges = sensorType.ranges[unit];
                setDefaultMin(ranges?.[0]);
                setDefaultMax(ranges?.[1]);
              }

              if (alert.min !== null && alert.max === null) {
                setSelectedAlertType(types?.find((x) => x.type === "min"));
              } else if (alert.max !== null && alert.min === null) {
                setSelectedAlertType(types?.find((x) => x.type === "max"));
              } else if (alert.max !== null && alert.min !== null) {
                setSelectedAlertType(types?.find((x) => x.type === "range"));
              } else {
                setSelectedAlertType(types?.find((x) => x.type === "transmitting"));
              }
            }
            break;
        }
      }

      setSelectedUnit(alert.selected_units);
      setAlertCondition(AlertConditionService.transform(alert));
    }
  }, [alert, sensorTypes.data]);

  const getAlert = (values: any) => {
    let alertConfig = {
      ...values,
      selected_units: unit,
      min: alertMin,
      max: alertMax,
      SensorTypeId: selectedSensorTypeId,
    };

    if (selectedAlertType) {
      alertConfig.alertType = selectedAlertType;
      if (selectedAlertType.type === "min") {
        alertConfig.max = null;
      }

      if (selectedAlertType.type === "max") {
        alertConfig.min = null;
      }

      alertConfig.is_notify_on_non_transmitting = selectedAlertType.type === "transmitting";

      if (selectedAlertType.type === 0) {
        alertConfig.min = 0;
      }

      if (selectedAlertType.type === 1) {
        alertConfig.max = 1;
      }
    }

    return alertConfig;
  };

  const handleSubmit = (values: any) => {
    let alertConfig = getAlert(values);

    if (values._id > 0) {
      alertConfig.threshold = parseInt(alertConfig.threshold);
      alertConfig.delay_seconds = alertConfig.delay_seconds * 60;
      alertConfig.snooze_seconds = alertConfig.snooze_seconds * 60;
      alertConfig.interval_seconds = alertConfig.interval_seconds * 60;

      updateAlertConfig(alertConfig)
        .then(() => {
          showSnackbar(t("alerts:alert_config.update_success"), "success");
          showAppModal(null);
        })
        .catch(() => showSnackbar(t("alerts:alert_config.update_error"), "error"));
    } else {
      const defaultAlertConfig = getDefaultConfig();
      alertConfig = { ...defaultAlertConfig, ...alertConfig };
      delete alertConfig._id;

      alertConfig.threshold = parseInt(alertConfig.threshold);
      alertConfig.delay_seconds = alertConfig.delay_seconds * 60;
      alertConfig.snooze_seconds = alertConfig.snooze_seconds * 60;
      alertConfig.interval_seconds = alertConfig.interval_seconds * 60;

      createAlertConfig(alertConfig)
        .then(() => {
          showSnackbar(t("alerts:alert_config.create_success"), "success");
          showAppModal(null);
        })
        .catch(() => showSnackbar(t("alerts:alert_config.create_error"), "error"));
    }
  };

  const keepSettings = (values: IAlertFormValues) => {
    const { snooze_seconds, interval_seconds, delay_seconds, ...rest } = values;

    setKeptSettings({
      ...rest,
      delay_seconds: delay_seconds * 60,
      interval_seconds: interval_seconds * 60,
      snooze_seconds: snooze_seconds * 60,
    });
  };

  const handleSensorTypeChange = (e: number, values: IAlertFormValues) => {
    keepSettings(values);
    const newSensorType = sensorTypes.data?.find((x) => x._id === e);
    if (newSensorType) {
      setSelectedSensorTypeId(newSensorType._id);
      setSelectedSensorType(newSensorType);
      const types = getAlertTypesForSelect(newSensorType);
      setAlertTypes(types ?? []);
      const unit = getUnit(newSensorType);

      values.selected_unit = unit;
      setSelectedUnit(unit);

      const alertCondition = getAlertCondition(values);
      setAlertCondition(alertCondition);

      if (newSensorType.type !== "BOOLEAN") {
        handleRangeMinMax(newSensorType);

        if (typeof selectedAlertType?.type === "number") {
          setSelectedAlertType(types?.[0]);
        }
      } else {
        if (typeof selectedAlertType?.type !== "number") {
          setSelectedAlertType(types?.[0]);
        }

        handleBooleanMinMax();
      }
    }
  };

  const handleAlertTypeChange = (e: string, values: IAlertFormValues) => {
    keepSettings(values);
    const newAlertType = alertTypes.find((x) => x.type === e);
    setSelectedAlertType(newAlertType);

    if (selectedSensorType?.type === "BOOLEAN") {
      handleBooleanMinMax(newAlertType);
      values.is_notify_on_non_transmitting = newAlertType?.type === null;
    } else {
      handleRangeMinMax(selectedSensorType, newAlertType);
    }

    const alertCondition = getAlertCondition(values);
    setAlertCondition(alertCondition);
  };

  const handleBooleanMinMax = (alertType = selectedAlertType) => {
    if (selectedSensorType?.type !== "BOOLEAN") {
      return;
    }

    if (alertType?.type === 0) {
      setAlertMin(0);
      setAlertMax(null);
    } else if (alertType?.type === 1) {
      setAlertMax(1);
      setAlertMin(null);
    } else {
      setAlertMax(null);
      setAlertMin(null);
    }
  };

  const handleRangeMinMax = (sensorType = selectedSensorType, alertType = selectedAlertType) => {
    if (!sensorType || sensorType?.type === "BOOLEAN" || !alertType) {
      return;
    }

    const setMin = alertType.type === "min" || alertType.type === "range";
    const setMax = alertType.type === "max" || alertType.type === "range";

    const unitName = UnitsService.fullUnitName(unit);

    const ranges = sensorType.ranges[unitName] ?? Object.values(sensorType.ranges)[0];
    setDefaultMin(ranges[0]);
    setDefaultMax(ranges[1]);
    setAlertMin(setMin ? Number(ranges[0]) : null);
    setAlertMax(setMax ? Number(ranges[1]) : null);
  };

  const handleSelectedUnitChange = (values: IAlertFormValues) => {
    const alertCondition = getAlertCondition(values);
    setAlertCondition(alertCondition);

    const selectedSensorType = sensorTypes.data?.find((x) => x._id === selectedSensorTypeId);

    if (selectedSensorType) {
      const unitName = UnitsService.fullUnitName(unit);

      const newUnit = Object.keys(selectedSensorType.ranges).find((x) => x !== unitName);
      const transformedUnit = UnitsService.unitsTransform(newUnit);

      if (transformedUnit) {
        values.selected_unit = transformedUnit;
        setSelectedUnit(transformedUnit);

        if (newUnit) {
          const range = selectedSensorType.ranges[newUnit];
          if (selectedSensorType.type === "RANGE") {
            setAlertMin(Number(range[0]));
            setAlertMax(Number(range[1]));
            setDefaultMin(Number(range[0]));
            setDefaultMax(Number(range[1]));
          } else {
            setSelectedAlertType(alertTypes?.find((x) => x.type === "transmitting"));
          }
        }
      }
    }

    return unit;
  };

  const RenderAlertTypeSlider = (values: IAlertFormValues) => {
    const type = selectedAlertType?.type;
    if (type === "min" || type === "max" || type === "range") {
      const sliderProps: IInputRangeSliderProps = {
        type,
        valueMin: alertMin,
        valueMax: alertMax,
        min: defaultMin,
        max: defaultMax,
        readonly: false,
        onMinValueChange: (value) => {
          values.min = value || null;
          keepSettings(values);
          setAlertMin(value || null);
        },
        onMaxValueChange: (value) => {
          values.max = value || null;
          keepSettings(values);
          setAlertMax(value || null);
        },
      };

      values.is_notify_on_non_transmitting = false;

      if (type === "min") {
        values.min = null;
      } else if (type === "max") {
        values.max = null;
      }

      const condition = getAlertCondition(values);
      if (condition !== alertCondition) {
        setAlertCondition(condition);
      }

      return <InputRangeSlider {...sliderProps} />;
    }

    if (type === "transmitting") {
      if (alert && values) {
        values.is_notify_on_non_transmitting = true;
        const alertCondition = getAlertCondition(values);
        setAlertCondition(alertCondition);
      }
    }

    return <></>;
  };

  const getAlertCondition = (values: IAlertFormValues) => {
    const alert: IAlertConfig = getAlert(values);
    return AlertConditionService.transform(alert);
  };

  const initialValues: IAlertFormValues = {
    _id: alert?._id || 0,
    name: keptSettings?.name ?? alert?.name ?? "",
    SensorTypeId: selectedSensorType?._id || keptSettings?.SensorTypeId || alert?.SensorTypeId || -1,
    alertType: selectedAlertType?.type ?? keptSettings?.alertType ?? "-",
    delay_seconds: (keptSettings?.delay_seconds || alert?.delay_seconds || 120) / 60,
    snooze_seconds: (keptSettings?.snooze_seconds || alert?.snooze_seconds || 600) / 60,
    interval_seconds: (keptSettings?.interval_seconds || alert?.interval_seconds || 120) / 60,
    protocol: keptSettings?.protocol ?? alert?.protocol ?? "",
    min: keptSettings ? keptSettings.min : alert?.min ?? 0,
    max: keptSettings ? keptSettings.max : alert?.max ?? 0,
    threshold: keptSettings?.threshold || alert?.threshold || 0,
    selected_unit: alert?.selected_units || "",
    is_notify_on_non_transmitting: !!alert?.is_notify_on_non_transmitting,
  };

  return (
    <Formik enableReinitialize={true} initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
      {({ values, isSubmitting, setFieldValue, submitForm }) => {
        return (
          <Modal
            title={!alert ? t("alerts:alert_config.title_new") : t("alerts:alert_config.title_edit")}
            buttons={
              <>
                <button type="button" className="btn btn-info" onClick={() => showAppModal(null)} disabled={isSubmitting}>
                  {t("common:cancel")}
                </button>
                <button type="button" className="btn btn-primary" onClick={submitForm} disabled={isSubmitting}>
                  {isSubmitting ? <i className="fa fa-circle-o-notch fa-spin" /> : <></>}
                  {!!alert && alert._id > 0 ? t("common:save_changes") : t("alerts:alert_config.add")}
                </button>
              </>
            }>
            <form className="alert-config-modal">
              <div className="alert-config-modal-row"></div>
              <div className="alert-config-modal-row">
                <div className="col-xs-12 col-lg-4 desktop-tablet-column">
                  <FormFieldText
                    maxlength={32}
                    name="name"
                    label={t("alerts:alert_config.group_name")}
                    placeholder={t("alerts:alert_config.group_name_placeholder")}
                    required={true}
                    displayError={true}
                  />
                </div>
                <div className="col-xs-12 col-lg-4">
                  <SelectInput
                    required={true}
                    displayError={true}
                    className="input-holder u-full-width"
                    menuItemClass="marine"
                    inputClassName="marine"
                    label={t("alerts:alert_config.sensor_type")}
                    name="SensorTypeId"
                    onChange={(e: number) => handleSensorTypeChange(e, values)}
                    options={[
                      { value: -1, label: `-- ${t("common:select")} --` },
                      ...(sensorTypes.data?.map((type) => ({
                        key: type._id.toString(),
                        value: type._id,
                        label: t(`sensor_types:${getNameSlug(type.name)}`),
                      })) ?? []),
                    ]}
                    value={values.SensorTypeId}
                  />
                </div>
                <div className="col-xs-12 col-lg-4">
                  <SelectInput
                    required={true}
                    displayError={true}
                    className="input-holder u-full-width"
                    menuItemClass="marine"
                    inputClassName="marine"
                    label={t("alerts:alert_config.alert_type")}
                    name="alertType"
                    onChange={(e: string) => handleAlertTypeChange(e, values)}
                    options={[
                      { value: "-", label: `-- ${t("common:select")} --` },
                      ...alertTypes.map((type) => ({
                        key: type.type,
                        value: type.type,
                        label: type.label,
                      })),
                    ]}
                    value={values.alertType}
                  />
                </div>
              </div>
              {selectedSensorType &&
              selectedAlertType &&
              selectedAlertType.type !== "transmitting" &&
              selectedSensorType.type !== "BOOLEAN" ? (
                <div className="row alert-row">
                  <div className="col-xs-12 col-lg-7">
                    <label htmlFor="sensor_type_id" className="input-label u-display-block">
                      {t("alerts:alert_config.condition")}:{" "}
                      <span className="u-text-small u-opacity-fade u-mobile-hide">{alertCondition}</span>
                    </label>
                    <div className="alert-config-modal-flex-group">
                      <button type="button" className="btn btn-primary u-mobile-hide" onClick={() => handleSelectedUnitChange(values)}>
                        {unit}
                      </button>
                      {RenderAlertTypeSlider(values)}
                    </div>
                  </div>
                  <div className="col-xs-12 col-lg-5">
                    <div className="mobile-unit-threshold-row">
                      <div className="u-mobile-only">
                        <label htmlFor="unit" className="input-label u-display-block">
                          {t("alerts:alert_config.unit")}
                        </label>
                        <button type="button" className="btn btn-primary" onClick={() => handleSelectedUnitChange(values)}>
                          {unit}
                        </button>
                      </div>

                      <div>
                        <label htmlFor="approaching_threshold" className="input-label u-display-block">
                          {t("alerts:alert_config.approaching_threshold")}
                        </label>
                        <div className="flex-group threshold-wrapper">
                          <div className="plus-minus-container">
                            <span>+</span>
                            <span>-</span>
                          </div>
                          <div className="input-holder mod-block">
                            <input
                              type="number"
                              name="threshold"
                              className={classNames("input input-line input-threshold")}
                              data-lpignore="true"
                              value={values.threshold}
                              onChange={(e) => setFieldValue("threshold", e.target.value)}
                            />
                            {unit}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}

              <div className="alert-config-modal-row">
                <div className="col-sm-4 col-xs-12 minute-related-values">
                  <div className="value">
                    <FormFieldText dark={true} name="delay_seconds" type="number" label={t("alerts:alert_config.initial_alert_delay")} />
                    <p>{t("alerts:alert_config.minutes")}</p>
                  </div>
                  <p className="explanation">{t("alerts:alert_config.delay_minutes_explanation")} </p>
                </div>
                <div className="col-sm-4 col-xs-12 minute-related-values">
                  <div className="value">
                    <FormFieldText dark={true} name="interval_seconds" type="number" label={t("alerts:alert_config.resend_alert_after")} />
                    <p>{t("alerts:alert_config.minutes")}</p>
                  </div>
                  <p className="explanation">{t("alerts:alert_config.resend_minutes_explanation")} </p>
                </div>
                <div className="col-sm-4 col-xs-12 minute-related-values">
                  <div className="value">
                    <FormFieldText dark={true} name="snooze_seconds" type="number" label={t("alerts:alert_config.alert_snooze_time")} />
                    <p>{t("alerts:alert_config.minutes")}</p>
                  </div>
                  <p className="explanation">{t("alerts:alert_config.snooze_minutes_explanation")}</p>
                </div>
              </div>

              <div className="alert-config-modal-row mobile-protocol">
                <FormFieldTextArea name="protocol" label={t("alerts:alert_config.alert_protocol")} />
              </div>
            </form>
          </Modal>
        );
      }}
    </Formik>
  );
});
