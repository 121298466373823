import { FormFieldCheckbox, FormFieldSelect, FormFieldText, IFormStepProps, InlineButton, StepperActions } from "../../Components";
import { Formik, FormikErrors } from "formik";
import React, { useEffect, useState } from "react";
import * as yup from "yup";
import "./AccountStep.scss";
import { PhoneInput } from "../../Components/PhoneInput";
import { countries, ICountry } from "../../Managers";
import { showAppModal } from "../../AppState";
import { LegalModal } from "./Legal";
import { PasswordInput } from "../../Components/PasswordInput";
import { ICurrency, ICurrencyExtended } from "../../Managers/CurrencyService";
import { getCurrenciesForDropdown } from "../../Managers/PaymentOptionsService";
import { CurrencySelect } from "../../Components/CurrencySelect";
import { ValidationMessages } from "../../Enums";
import { useTranslation } from "react-i18next";
import { ILanguage } from "../../Managers/Types";
import { useLanguages } from "../../Managers/LanguageService";

export interface IAccountProps {
  country?: ICountry;
  firstName?: string;
  lastName?: string;
  title?: string;
  phone?: string;
  emailAddress?: string;
  companyName?: string;
  password?: string;
  confirmPassword?: string;
  termsChecked?: boolean;
  privacyChecked?: boolean;
  marketingChecked?: boolean;
  language: string;
  currency?: ICurrency;
}

interface IAccountStepProps extends IFormStepProps {
  accountDetails?: IAccountProps;
}

export const AccountStep: React.FC<IAccountStepProps> = ({ accountDetails, onClickBack, onSubmit }) => {
  const [submitAttempted, setSubmitAttempted] = useState(false);
  const [languages, setLanguages] = useState<ILanguage[]>([]);
  const [activeCurrencies, setActiveCurrencies] = useState<ICurrency[] | ICurrencyExtended[]>([]);
  const [selectedCurrency, setSelectedCurrency] = useState<ICurrency | ICurrencyExtended | null>(null);
  const [countryCode, setCountryCode] = useState<string>();

  const { t } = useTranslation(["register", "common"]);

  const languageQuery = useLanguages();

  useEffect(() => {
    setLanguages(languageQuery.data?.filter((l) => l.active) ?? []);
  }, [languageQuery.dataUpdatedAt]);

  useEffect(() => {
    const fetchCurrencies = async () => {
      try {
        const currencies = await getCurrenciesForDropdown(true);
        setActiveCurrencies(currencies);
        const initialCurrency = currencies.find((currency) => currency.iso_code === "USD");
        if (initialCurrency) {
          setSelectedCurrency(initialCurrency);
        }
      } catch (error) {
        console.error("Error fetching currencies:", error);
      }
    };

    fetchCurrencies();
  }, []);

  useEffect(() => {
    if (selectedCurrency) {
      initialValues.currency = selectedCurrency;
    }
  }, [selectedCurrency]);

  const initialValues: IAccountProps = {
    country: accountDetails?.country ?? countries.find((c) => c.code === "US"),
    confirmPassword: accountDetails?.confirmPassword ?? "",
    emailAddress: accountDetails?.emailAddress ?? "",
    firstName: accountDetails?.firstName ?? "",
    companyName: accountDetails?.companyName ?? "",
    lastName: accountDetails?.lastName ?? "",
    password: accountDetails?.password ?? "",
    phone: accountDetails?.phone ?? "",
    privacyChecked: accountDetails?.privacyChecked ?? false,
    termsChecked: accountDetails?.termsChecked ?? false,
    title: accountDetails?.title ?? "",
    language: accountDetails?.language ?? "en",
    currency: accountDetails?.currency ?? activeCurrencies.find((currency) => currency.iso_code === "USD"),
  };

  const validationSchema = yup.object({
    country: yup.object(),
    confirmPassword: yup
      .string()
      .required(t(ValidationMessages.REQUIRED))
      .test("passwords-match", t("register:steps.account.password_match_error"), function (value) {
        return this.parent.password === value;
      }),
    emailAddress: yup.string().email(t(ValidationMessages.EMAIL)).required(t(ValidationMessages.REQUIRED)),
    firstName: yup.string().required(t(ValidationMessages.REQUIRED)),
    lastName: yup.string().required(t(ValidationMessages.REQUIRED)),
    companyName: yup.string().required(t(ValidationMessages.REQUIRED)),
    password: yup
      .string()
      .min(8)
      .matches(/^(?=.*[a-z])/)
      .matches(/^(?=.*[A-Z])/)
      .matches(/^(?=.*[0-9])/)
      .matches(/^(?=.*[!@#$%^&*=()_+[\]{}|;:',.<>?/`~"\\-])/)
      .required(),
    phone: yup
      .string()
      .required(t(ValidationMessages.REQUIRED))
      .matches(/^[0-9]+$/, t(ValidationMessages.PHONE)),
    privacyChecked: yup.boolean().oneOf([true]),
    termsChecked: yup.boolean().oneOf([true]),
    marketingChecked: yup.boolean().oneOf([true, false]),
    title: yup.string(),
    language: yup.string(),
    currency: yup.object(),
  });

  const handleNextButtonClick = () => {
    setSubmitAttempted(true);
  };

  const openPolicy = (
    e: React.MouseEvent<HTMLElement>,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => Promise<void | FormikErrors<IAccountProps>> | void,
    checked: boolean,
  ) => {
    e.preventDefault();
    showAppModal(
      <LegalModal countryCode={countryCode} type="privacy" onAccept={() => setFieldValue("privacyChecked", true)} accepted={checked} />,
    );
  };

  const openTerms = (
    e: React.MouseEvent<HTMLElement>,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => Promise<void | FormikErrors<IAccountProps>> | void,
    checked: boolean,
  ) => {
    e.preventDefault();
    showAppModal(
      <LegalModal countryCode={countryCode} type="terms" onAccept={() => setFieldValue("termsChecked", true)} accepted={checked} />,
    );
  };

  return (
    <Formik validateOnMount={true} initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {({ handleSubmit, values, touched, setFieldValue }) => {
        if (!values.currency && selectedCurrency) {
          setFieldValue("currency", selectedCurrency);
        }
        return (
          <>
            <div className="type-large-regular register-form-label">{t("register:steps.account.title")}</div>
            <div className="row" style={{ marginBottom: "16px" }}>
              <FormFieldText
                required={true}
                displayError={true}
                name="firstName"
                className="col-sm-4"
                label={t("register:steps.account.first_name")}
                type="text"
              />
              <FormFieldText
                required={true}
                displayError={true}
                name="lastName"
                className="col-sm-4"
                label={t("register:steps.account.last_name")}
                type="text"
              />
              <FormFieldText
                displayError={true}
                name="title"
                className="col-sm-4"
                label={t("register:steps.account.account_title")}
                type="text"
              />
            </div>
            <div className="row" style={{ marginBottom: "16px" }}>
              <PhoneInput
                onChange={(e) => setCountryCode(e.code)}
                required={true}
                className="col-sm-4"
                name="phone"
                countryName="country"
                label={t("register:steps.account.phone")}
                dataTestId="phone-input"
              />
              <FormFieldText
                required={true}
                displayError={true}
                name="emailAddress"
                className="col-sm-4"
                label={t("register:steps.account.email_address")}
                type="email"
                autoComplete="new-email"
              />
              <FormFieldText
                required={true}
                displayError={true}
                name="companyName"
                className="col-sm-4"
                label={t("register:steps.account.company_name")}
                type="text"
              />
            </div>
            <div className="row">
              {activeCurrencies.length > 0 && selectedCurrency !== null && (
                <>
                  <div className="col-sm-12 type-large-regular register-form-label">{t("register:steps.account.currency_header")}</div>
                  <CurrencySelect
                    required={true}
                    name="currency"
                    label={t("register:steps.account.currency_label")}
                    className="col-sm-4"
                    currencyList={activeCurrencies}
                    value={values.currency || selectedCurrency}
                    onChange={(value) => {
                      setSelectedCurrency(value);
                      setFieldValue("currency", value);
                    }}
                  />
                </>
              )}

              <FormFieldSelect
                className="col-sm-4"
                name="language"
                label={t("register:steps.account.language")}
                options={languages.map((l) => ({
                  label: t(`common:language.${l.code}`),
                  value: l.code,
                }))}
              />
            </div>

            <div className="type-large-regular register-form-label">{t("register:steps.account.create_password")}</div>
            <div className="row">
              <PasswordInput password={values.password} touched={!!touched.password} />
              <FormFieldText
                required={true}
                className="col-sm-4"
                displayError={true}
                name="confirmPassword"
                label={t("register:steps.account.confirm_password")}
                type="password"
              />
            </div>
            <div className="type-large-regular register-form-label">{t("register:steps.account.terms_and_conditions")}</div>
            <div className="terms">
              <FormFieldCheckbox
                required={true}
                name="termsChecked"
                checked={values.termsChecked ?? false}
                disabled={values.termsChecked}
                label={
                  <>
                    {t("register:steps.account.agree")}{" "}
                    <InlineButton onClick={(e) => openTerms(e, setFieldValue, !!values.termsChecked)}>
                      {t("register:steps.account.terms_and_conditions")}
                    </InlineButton>
                  </>
                }
                onChange={(e) => openTerms(e, setFieldValue, !!values.termsChecked)}
              />
              <FormFieldCheckbox
                required={true}
                name="privacyChecked"
                disabled={values.privacyChecked}
                checked={values.privacyChecked ?? false}
                label={
                  <>
                    {t("register:steps.account.agree")}{" "}
                    <InlineButton onClick={(e) => openPolicy(e, setFieldValue, !!values.privacyChecked)}>
                      {t("register:steps.account.privacy_policy")}
                    </InlineButton>
                  </>
                }
                onChange={(e) => openPolicy(e, setFieldValue, !!values.privacyChecked)}
              />
              <FormFieldCheckbox
                onChange={() => setFieldValue("marketingChecked", !values.marketingChecked)}
                required={false}
                name="marketingChecked"
                checked={values.marketingChecked ?? false}
                label={t("register:steps.account.marketing")}
              />
            </div>
            {(!values.termsChecked || !values.privacyChecked) && submitAttempted && (
              <div className="account-error">{t("register:steps.account.legal_unchecked_error")}</div>
            )}
            <StepperActions
              onClickNext={() => {
                handleNextButtonClick();
                handleSubmit();
              }}
              onClickBack={onClickBack}
            />
          </>
        );
      }}
    </Formik>
  );
};
