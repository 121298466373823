import classNames from "classnames";
import styled from "@emotion/styled";
import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { deleteUser, getUser, updateUser, useUsers } from "../../Managers/UsersService";
import { ICON_TOOLTIP_DEFAULT_DELAY, LeftRail, Page, PermissionBlocker, StyledTooltip, SwitchInput, TextInput } from "../../Components";
import { AppState, showAppModal, showSnackbar } from "../../AppState";
import { Autocomplete, CircularProgress, Drawer, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { isLocalWAM, mySearch } from "../../Managers";
import { UserPropertiesModal } from "./Modals";
import { IUser } from "../../Managers/Types";
import { PermissionEnum, UserRoles } from "../../Enums";
import { useNavigate, useSearchParams } from "react-router-dom";
import { UserNav } from "./UsersNav";
import { UserDetails } from "./UserDetails";
import { useTranslation } from "react-i18next";
import "../Accounts/Accounts.scss";
import "./Users.scss";
import { DrawerIcon } from "../../icon";
import { ConfirmModal } from "../../Modals";
import { URLS } from "../URLS";
import { MobileUserHeader } from "./MobileUserHeader";

const Wrapper = styled("div")({
  flex: 1,
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "row",
});

const MobileButtons = styled.div({
  display: "flex",
  gap: 16,
});

export const Users: React.FC = observer(() => {
  const [showSortingMenu, setShowSortingMenu] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [order, setOrder] = useState<string>("asc");
  const [searchUserString, setSearchUserString] = useState(searchParams.get("search") ?? "");
  const [searchMobileUserString, setSearchMobileUserString] = useState(searchParams.get("search") ?? "");
  const [sortedUsers, setSortedUsers] = useState<IUser[]>([]);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [togglingNotificationState, setTogglingNotificationState] = useState(false);
  const [selectedUser, setSelectedUser] = useState<IUser>();
  const [selectedUserId, setSelectedUserId] = useState<number>();

  const navigate = useNavigate();
  const { t } = useTranslation(["users", "common"]);
  const users = useUsers();

  const searchedId = searchParams.get("id");

  useEffect(() => {
    const sortedUsers = mySearch<IUser>([...(users.data ?? [])], searchUserString);
    sortedUsers.sort((a, b) =>
      order === "asc"
        ? a.first_name.toLowerCase().localeCompare(b.first_name.toLowerCase())
        : b.first_name.toLowerCase().localeCompare(a.first_name.toLowerCase()),
    );
    setSortedUsers(sortedUsers);
  }, [users.data, searchUserString, order]);

  useEffect(() => {
    const selectedUserId = searchedId ? Number(searchedId) : AppState.selectedUserId;
    getUser(selectedUserId).then((res) => setSelectedUser(res));
    setSelectedUserId(selectedUserId);
  }, [searchedId, AppState.selectedUserId, sortedUsers]);

  const handleSearchChange = (search: string) => {
    searchParams.set("search", search);
    setSearchParams(searchParams);
    setSearchUserString(search);
  };

  const handleSelectUser = (id?: number) => {
    if (!id) {
      return;
    }

    searchParams.set("id", id.toString());
    setSearchParams(searchParams);
    setSelectedUserId(id);
  };

  if (!selectedUserId && users.isSuccess && sortedUsers.length > 0) {
    console.log("No user selected yet, pre-selecting", sortedUsers[0]);
    handleSelectUser(sortedUsers[0]._id);
    setSelectedUserId(sortedUsers[0]._id);
  }

  if (users.isLoading) {
    return (
      <Page>
        <Wrapper style={{ alignItems: "center", justifyContent: "center" }}>
          <CircularProgress />
        </Wrapper>
      </Page>
    );
  }

  const isUserLimitNotExceed = () => {
    const { usage, limits, skipLimits } = AppState.user?.Limits || {};
    return skipLimits || (usage?.USERS !== undefined && limits?.USERS !== undefined && usage.USERS < limits.USERS);
  };

  const addUserButton = () => {
    return (
      <div className="add-user-plus-button">
        <PermissionBlocker permission={PermissionEnum.EDIT_USERS}>
          {isUserLimitNotExceed() ? (
            <StyledTooltip title={t("users:add")} enterDelay={ICON_TOOLTIP_DEFAULT_DELAY}>
              <button
                className="btn btn-circle btn-primary"
                onClick={() => {
                  setSelectedUserId(0);
                  showAppModal(<UserPropertiesModal />);
                }}>
                <i className="fa fa-plus" />
                <span className="sr-only">{t("add")}</span>
              </button>
            </StyledTooltip>
          ) : null}
        </PermissionBlocker>
      </div>
    );
  };

  const isMyUser = selectedUser?._id === AppState.user?._id;

  const toggleUserNotificationState = (checked: boolean) => {
    setTogglingNotificationState(true);
    let newSelectedUserState = { ...selectedUser, has_notification: !checked } as IUser;

    updateUser(newSelectedUserState)
      .then(() => showSnackbar(t("users:details.update_success"), "success"))
      .catch((e: any) => {
        console.log("Update user error", e);
        showSnackbar(t("users:details.update_error"), "error");
      })
      .finally(() => setTogglingNotificationState(false));
  };

  const confirmUserDeleteModal = () => (
    <ConfirmModal
      header={t("users:details.delete_confirm_title")}
      confirmText={t("users:details.delete_confirm_title")}
      children={t("users:details.delete_confirm_content", { name: `${selectedUser?.first_name ?? ""} ${selectedUser?.last_name ?? ""}` })}
      onCancel={() => showAppModal(null)}
      onConfirm={() =>
        deleteUser(selectedUser?._id ?? 0)
          .then(() => {
            showSnackbar(t("users:details.delete_success"), "success");
            setSelectedUserId(0);
            showAppModal(null);
            navigate(URLS.Users);
          })
          .catch(() => showSnackbar(t("users:details.delete_error"), "error"))
          .finally(() => showAppModal(null))
      }
    />
  );

  const editButton = () => (
    <PermissionBlocker permission={PermissionEnum.EDIT_USERS}>
      <div title={t("users:details.edit")}>
        <button className="btn btn-icon" onClick={() => showAppModal(<UserPropertiesModal user={selectedUser} />)}>
          <i className="fa fa-edit header-icon" />
          <span className="sr-only">{t("common:edit")}</span>
        </button>
      </div>
    </PermissionBlocker>
  );

  const infoControl = () => (
    <div className="header-row-info-control toggle-wrapper u-mobile-hide">
      {editButton()}

      <PermissionBlocker permission={PermissionEnum.EDIT_USERS}>
        {!isMyUser ? (
          <div title={t("users:details.delete_user")}>
            <button className="btn btn-icon" onClick={() => showAppModal(confirmUserDeleteModal())}>
              <i className="fa fa-trash header-icon" />
              <span className="sr-only">{t("common:delete")}</span>
            </button>
          </div>
        ) : null}
      </PermissionBlocker>
      <div className="u-tablet-only" title={t("users:details.no_disturb_tooltip")}>
        <div className={classNames("u-text-small dnd-tooltip")}>
          <i className="icon icon-alarm_no" /> {t("users:details.no_disturb")}
        </div>
      </div>
      <SwitchInput
        className="toggle u-tablet-only"
        onChange={(e) => toggleUserNotificationState(e.target.checked)}
        checked={!selectedUser?.has_notification}
        name="no_disturb"
      />
    </div>
  );
  const controlHeader = () => {
    return !isLocalWAM() ? (
      <div className={classNames("header-row-section u-desktop-only large last", { highlight: !selectedUser?.has_notification })}>
        <input
          type="checkbox"
          id="active-user"
          className="input-checkbox"
          checked={!selectedUser?.has_notification}
          onChange={(e) => toggleUserNotificationState(e.target.checked)}
        />
        <StyledTooltip title={t("users:details.no_disturb_tooltip")}>
          <label htmlFor="active-user" className={classNames("input-label-checkbox u-text-small", { isSaving: togglingNotificationState })}>
            <i className="icon icon-alarm_no" /> {t("users:details.no_disturb")}
          </label>
        </StyledTooltip>
      </div>
    ) : undefined;
  };

  const mobileHeader = () => (
    <>
      <MobileUserHeader
        currentPage={"users"}
        actionRow={
          <>
            <Autocomplete
              onChange={(_e, user) => handleSelectUser(user?._id ?? selectedUserId)}
              className="autocomplete-users u-full-width dark"
              options={sortedUsers}
              getOptionLabel={(o: IUser) => (o.first_name ?? "") + " " + (o.last_name ?? "")}
              getOptionKey={(o: IUser) => o._id}
              renderInput={(params) => (
                <TextInput
                  label={t("find")}
                  displayLabelInside
                  className="account-search-input u-full-width dark"
                  inputClass="dark"
                  onChange={(e) => setSearchMobileUserString(e.target.value)}
                  value={searchMobileUserString}
                  name={params.id}
                  {...params}
                />
              )}
            />
            <MobileButtons>
              <button
                style={{ padding: 1 }}
                className={classNames("btn btn-circle btn-primary bapi-header-nav-item dropdown", { open: showSortingMenu })}
                onClick={() => setShowSortingMenu(!showSortingMenu)}>
                {order === "asc" ? <i className="fa fa-sort-amount-desc" /> : <i className="fa fa-sort-amount-asc" />}
                <section role="menu" className="dropdown-menu dropdown-menu-right">
                  <div className="sorting-radio-wrapper">
                    <RadioGroup
                      aria-labelledby="user-radio-buttons-group-label"
                      value={order}
                      onChange={(e) => setOrder((e.target as HTMLInputElement).value)}
                      name="radio-buttons-group">
                      <FormControlLabel value="asc" control={<Radio />} label={t("users:sort", { context: "asc" })} />
                      <FormControlLabel value="desc" control={<Radio />} label={t("users:sort", { context: "desc" })} />
                    </RadioGroup>
                  </div>
                </section>
              </button>
              {addUserButton()}
            </MobileButtons>
          </>
        }
      />
    </>
  );

  return (
    <Page>
      <Wrapper>
        <Drawer
          variant={AppState.mode === "desktop" ? "permanent" : "temporary"}
          hideBackdrop={AppState.mode === "desktop"}
          onBackdropClick={() => AppState.mode === "tablet" && setDrawerOpen(false)}
          open={drawerOpen || AppState.mode === "desktop"}>
          <LeftRail
            header={
              <div className="drawer-header">
                <UserNav />
                <div className="input-holder u-full-width">
                  <input
                    type="text"
                    className="input input-default"
                    placeholder={t("find")}
                    value={searchUserString}
                    onChange={(e) => handleSearchChange(e.target.value)}
                  />
                  <i className="fa fa-search input-holder-icon" />
                </div>
              </div>
            }
            body={
              <nav className="left-rail-nav">
                <div className="left-rail-nav-header">
                  <h2 className="left-rail-nav-header-title mod-with-btn">
                    <StyledTooltip
                      title={t("common:sort_by_name", { context: order === "asc" ? "desc" : "asc" })}
                      enterDelay={ICON_TOOLTIP_DEFAULT_DELAY}>
                      <button className="btn btn-plain" onClick={() => setOrder(order === "desc" ? "asc" : "desc")}>
                        {order === "asc" ? <i className="fa fa-sort-amount-desc" /> : <i className="fa fa-sort-amount-asc" />}
                      </button>
                    </StyledTooltip>
                    {t("users:list")}
                  </h2>
                  {addUserButton()}
                </div>

                <ul className="left-rail-nav-group">
                  {sortedUsers.map((user) => (
                    <li className={classNames("left-rail-nav-item", { active: selectedUser?._id === user._id })} key={user._id}>
                      <div onClick={() => handleSelectUser(user._id)} style={{ display: "grid" }}>
                        <span>
                          {user.first_name} {user.last_name}
                        </span>
                        <div>
                          {user.role === UserRoles.ADMIN ? (
                            <span className="type-small-regular u-color-light-teal" style={{ marginRight: "5px" }}>
                              ({t("common:admin")})
                            </span>
                          ) : null}
                          {!user.is_invitation_accepted ? (
                            <span className="type-small-regular u-color-light-teal invitation-status">({t("users:invite_pending")})</span>
                          ) : null}
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </nav>
            }
          />
        </Drawer>

        <main className="users bapi-main mod-dashboard">
          {selectedUser?._id ? (
            <UserDetails
              user={selectedUser}
              headerOptions={{
                info: {
                  name: (
                    <>
                      <button onClick={() => setDrawerOpen(!drawerOpen)} className="btn btn-icon u-tablet-only">
                        <DrawerIcon />
                      </button>
                      {AppState.mode !== "mobile" ? (selectedUser.first_name ?? "") + " " + (selectedUser.last_name ?? "") : ""}
                    </>
                  ),
                  width: AppState.mode !== "desktop" ? "100%" : "",
                },
                infoControl: infoControl(),
                control: controlHeader(),
              }}
              mobileHeader={mobileHeader()}
              editUserButton={editButton()}
              toggleNotification={toggleUserNotificationState}
            />
          ) : null}
        </main>
      </Wrapper>
    </Page>
  );
});
