import moment from "moment";
import classNames from "classnames";
import { observer } from "mobx-react-lite";
import React, { useCallback, useEffect, useState } from "react";
import { getDeviceSensorData } from "../../Managers/DeviceService";
import { alertConditionTransform } from "../../Managers/AlertConditionService";
import { measurementTransform } from "../../Managers/MeasurementService";
import { updateNotification } from "../../Managers/NotificationService";
import { isLocalWAM } from "../../Managers/PermissionsService";
import { unitsTransform } from "../../Managers/UnitsService";
import { formatDateCustom } from "../../Managers/Utils";
import { AppState, showAppModal, showSnackbar } from "../../AppState";
import { TimeSeriesLineChart } from "../../Components";
import { useUsers } from "../../Managers/UsersService";
import { INotification } from "../../Managers/Types";
import { prepareChartDataSet } from "../../Managers/ReportManager";
import { XIcon } from "@heroicons/react/solid";
import { useTranslation } from "react-i18next";
import "./AlertResolveModal.scss"

interface IAlertResolveModalProps {
  notification: INotification;
}

// TODO: We need to figure out how this form is meant to be used. It has a bunch of inconsistencies noted below like incorrectly spelled
// fields that would have prevented it from working properly IMO.
export const AlertResolveModal: React.FC<IAlertResolveModalProps> = observer(({ notification }) => {
  const [steps, setSteps] = useState(2);
  const [currentStep, setCurrentStep] = useState(0);
  const [isSaving, setIsSaving] = useState<string | null>(null);
  const [, setIsLoading] = useState<boolean | null>(null);
  // This value has an edit field but never appears to be saved in any wy
  const [alertNotes, setAlertNotes] = useState("");
  const [identifier, setIdentifier] = useState("");
  const [password, setPassword] = useState("");
  const [graphData, setGraphData] = useState<any[]>([]);
  const [setPoints, setSetPoints] = useState<any[]>([]);

  const usersQuery = useUsers();
  const { t } = useTranslation("alert_resolve");

  const _getSensorData = useCallback(() => {
    setGraphData([]);

    // TODO: The old code assumed Sensor would always be set but I saw records where it was null
    if (!notification.Sensor) {
      console.warn("Skipping getting sensor data for invalid notification", notification);
      setIsLoading(false);
      return;
    }

    setIsLoading(true);
    setSetPoints([]);

    const createdAt = new Date(notification.createdAt);
    const endTime = moment(createdAt).endOf("day");
    const startTime = moment(createdAt).startOf("day");

    getDeviceSensorData(notification.Sensor, startTime.toISOString(), endTime.toISOString())
      .then((r) => {
        if (r && r.length) {
          // TODO: The old code referred to Sensor.Device directly but it didn't seem to always be set so I added a fallback
          let sensorData = prepareChartDataSet(r, notification.Sensor!, notification.Sensor?.Device?.is_empirical);
          //let sensorData = transformSensorData(r, notification.Sensor);

          setGraphData([...sensorData]);

          setSetPoints([
            {
              createdAt: new Date(notification.createdAt),
              // TODO: The old code referred to Sensor.Device directly but it didn't seem to always be set so I added fallbacks
              // entry.value.value, [sensor.default_unit, isImperial, sensor.Sensor_type.type, disableRoundingRules]
              value: measurementTransform(notification.value.value, [
                notification.Sensor?.default_unit,
                notification.Sensor?.Device?.is_empirical,
                notification.Sensor?.Sensor_type.type,
              ]),
              _id: 1,
            },
          ]);
        } else {
          setGraphData([]);
        }
      })
      .catch((e) => {
        const errorMessage = t("alert_resolve:sensor_data_fetch_error");
        showSnackbar(errorMessage, "error");
        console.log(errorMessage, e);
      })
      .finally(() => setIsLoading(false));
  }, [notification]);

  const getUserName = (id: number) => {
    const user = AppState.user?._id === id ? AppState.user : (usersQuery.data || []).find((user) => user._id === id);
    return user ? (user.first_name ? user.first_name + " " : "") + (user.last_name ? user.last_name : "") : "";
  };

  useEffect(() => {
    _getSensorData();

    // if it is a shared user, it should have 3 steps
    if (isLocalWAM() && AppState.user?.role === "SHAREDUSER") {
      setSteps(3);
    } else {
      setSteps(2);
    }
  }, [_getSensorData]);

  const proceedAlert = () => {
    const nextStep = currentStep + 1;
    setCurrentStep(nextStep);

    console.log(nextStep, steps);
    if (nextStep > steps) {
      setIsSaving("resolve");
      updateNotification(
        { ...notification, is_resolved: true, resolved_notes: alertNotes, UserId: AppState.user?._id || 0 },
        identifier,
        password,
      )
        .then((r) => {
          console.log("Update result", r);
          showAppModal(null);
          showSnackbar(t("alert_resolve:resolve_alert_success"), "success");
        })
        .catch((e) => {
          console.log("Update error", e);
          setIsSaving("");

          if (AppState.user?.role === "SHAREDUSER") {
            showSnackbar(t("alert_resolve:resolve_alert_error_shared_user"), "error");
          } else {
            showSnackbar(t("alert_resolve:resolve_alert_error"), "error");
          }
        });
    }
  };

  const backward = () => {
    setCurrentStep(Math.max(0, currentStep - 1));
  };

  const snoozeAlert = () => {
    setIsSaving("snooze");
    updateNotification({ ...notification, is_snoozed: true }, identifier, password)
      .then((r) => {
        console.log("Update result", r);
        showAppModal(null);
        showSnackbar(t("alert_resolve:snooze_alert_success"), "success");
      })
      .catch((e) => {
        console.log("Update error", e);

        setIsSaving("");
        showSnackbar(t("alert_resolve:snooze_alert_error"), "error");
      });
  };

  return (
    <div className="modal fade in alert-modal-main" tabIndex={-1} role="dialog">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title pull-left">
              {currentStep > 0 ? <span>{t("alert_resolve:resolve", { currentStep })} </span> : <></>}
              {t("alert_resolve:alert")}
            </h4>
            {currentStep > 0 ? (
              <h4 className="modal-title pull-right">{t("alert_resolve:step_of", { current: currentStep, steps })}</h4>
            ) : null}
            <button
              className="pull-right"
              onClick={() => showAppModal(null)}
              style={{ background: "none", color: "white", border: "none" }}>
              <XIcon style={{ width: "18px", marginBottom: "-4px" }} />
            </button>
          </div>

          <div className="modal-body modal-body-alert-resolve">
            <div className="info">
              <div className="info-alert">
                <p id="alert-value">
                  <span className="alert-value">
                    {measurementTransform(notification.value.value, [
                      notification.Sensor?.default_unit,
                      notification.Sensor?.Device?.is_empirical,
                      notification.Sensor?.Sensor_type.type,
                    ])}
                    <span className="u-degree">
                      {unitsTransform(notification.Sensor?.default_unit, [
                        notification.Sensor?.default_unit,
                        notification.Sensor?.Device?.is_empirical,
                        notification.Sensor?.Sensor_type.type,
                      ])}
                    </span>
                  </span>
                  <span>
                    {!notification.is_snoozed ? <i className="icon icon-alarm" /> : <></>}
                    <br className="br-on-mobile" />
                    {notification.is_snoozed ? <i className="icon icon-alarm_no" /> : <></>} {notification.Alert.name.indexOf('not-transmitting-alert') < 0 && notification.Alert.name}
                  </span>
                </p>

                <p id="alert-condition">
                  <label className="input-label">{"alert condition"}: </label>
                  <br className="br-on-mobile" />
                  {alertConditionTransform(notification.Alert)}
                </p>
                {/* TODO: Moment changed all their formatting tokens, we need to review every one of these */}
                <p id="datetime" className="tablet-dekstop-only">
                  {formatDateCustom(notification.createdAt, "M/d/y - H:mm a")}
                </p>
                <p id="datetime-mobile">
                  {formatDateCustom(notification.createdAt, "M/d/y - H:mm a").replace("-", "\n")}
                </p>
              </div>
              <div className="info-device">
                <p id="info-device-device">
                  <label className="input-label">{t("alert_resolve:device")}: </label>
                  <br className="br-on-mobile" />
                  {notification.Sensor?.Device?.name || ""}
                </p>
                {!notification.is_resolved ? (
                  <p>
                    <label className="input-label">{t("alert_resolve:placement")}: </label>
                    <br className="br-on-mobile" />
                    {notification.Sensor?.Device?.location_note || "--"}
                  </p>
                ) : (
                  <p>
                    <label className="input-label">{t("alert_resolve:user")}: </label>
                    <br className="br-on-mobile" />
                    {/* The old code altered notification to add a user object. We prefer not to mutate server-provided data. */}
                    {getUserName(notification.UserId)}
                  </p>
                )}

                <p id="info-device-serial-num">
                  <label className="input-label">{t("alert_resolve:serial_num")}: </label>
                  <br className="br-on-mobile" />
                  {notification.Sensor?.Device?.serial_number || ""}
                </p>
              </div>
            </div>
            {notification.is_resolved ? (
              <div>
                <label className="input-label">{t("alert_resolve:notes")}: </label>
                <p>{notification.resolved_notes}</p>
              </div>
            ) : (
              <></>
            )}
            {currentStep === 0 ? (
              <div>{graphData.length > 0 ? <TimeSeriesLineChart data={graphData} setPoints={setPoints} /> : <></>}</div>
            ) : (
              <></>
            )}

            {currentStep === 1 ? (
              <div>
                <label className="input-label" htmlFor="protocol-info">
                  {t("alert_resolve:alert_protocol")}:
                </label>
                {/* TODO: I don't see how this could have worked in the old system because protocol was spelled "protocal". Was this feature used? */}
                <textarea name="protocol-info" rows={8} className=" input-textarea disabled input">
                  {notification.Alert.protocol || "No protocol provided."}
                </textarea>
              </div>
            ) : null}

            {currentStep === 2 ? (
              <div>
                <label htmlFor="notes" className="input-label">
                  {t("alert_resolve:notes")}:
                </label>
                <textarea
                  name="notes"
                  rows={8}
                  cols={80}
                  className="input input-textarea"
                  placeholder={t("alert_resolve:notes_placeholder")}
                  value={alertNotes}
                  onChange={(e) => setAlertNotes(e.target.value)}
                />
              </div>
            ) : null}

            {currentStep === 3 ? (
              <div>
                <br />
                <p className="input-label">{t("alert_resolve:shared_user_sign_in_info")}</p>
                <p className="input-label">{t("alert_resolve:fill_in_credentials")}</p>
                <div className="row">
                  <div className="col-sm-6">
                    <label htmlFor="username" className="input-label">
                      {t("alert_resolve:username")}
                    </label>
                    <div className="input-holder mod-block">
                      <input
                        type="text"
                        className="input input-line"
                        required
                        value={identifier}
                        onChange={(e) => setIdentifier(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <label htmlFor="password" className="input-label">
                      {t("alert_resolve:password")}
                    </label>
                    {/* TODO: The old system referred to 'expression' in the template but that was never defined in the code. The term
											    was not even used anywhere in the code base (per git grep) except this one line. Angular would have defaulted it
											    to undefined so error would never have been set. What do we wnt? */}
                    {/*<div className={classNames('input-holder mod-block',{'error': expression})}>*/}
                    <div className={classNames("input-holder mod-block", { error: undefined })}>
                      <input
                        type="password"
                        className="input input-line"
                        required
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>

          <div className="modal-footer modal-footer-alert-resolve">
            {notification && notification.is_resolved ? (
              <div>
                <button className="btn btn-primary" onClick={() => showAppModal(null)}>
                  {t("common:close")}
                </button>
              </div>
            ) : null}

            {!notification.is_resolved ? (
              <div>
                <button type="button" className="btn btn-info hidden-on-mobile" onClick={() => showAppModal(null)}>
                  {t("common:cancel")}
                </button>
                {currentStep === 0 && notification && !notification.is_snoozed ? (
                  <button className="btn btn-info" onClick={() => snoozeAlert()}>
                    {isSaving === "snooze" ? <i className="fa fa-circle-o-notch fa-spin" /> : <></>}
                    {t("alert_resolve:snooze")}
                  </button>
                ) : null}

                {currentStep > 0 ? (
                  <button className="btn btn-info" onClick={() => backward()}>
                    {t("common:back")}
                  </button>
                ) : null}

                <button
                  className="btn btn-primary"
                  onClick={() => proceedAlert()}
                  disabled={currentStep === 3 && (!identifier || !password)}>
                  {isSaving === "resolve" ? <i className="fa fa-circle-o-notch fa-spin" /> : <></>}
                  {t("alert_resolve:resolve_alert")}
                </button>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
});
