import { countries, ICountry } from "../Managers";
import React from "react";
import { FormFieldSelect, IFormFieldSelectProps, SelectInput } from "./SelectInput";
import { useTranslation } from "react-i18next";
import { Flag } from "./Flag";

const mainCountries = countries.filter((c) => c.main);

export interface ICountrySelectProps {
  className?: string;
  countryList?: Array<ICountry>;
  formikControl?: boolean;
  initialValue?: string;
  name?: string;
  onChange?: ((value: string) => void) | ((value: ICountry) => void);
  selectedCountryLabel?: keyof ICountry;
  value?: ICountry;
}

export const CountrySelect: React.FC<ICountrySelectProps> = ({
  className = "",
  countryList = mainCountries,
  selectedCountryLabel,
  formikControl = true,
  initialValue,
  ...rest
}) => {
  const { t } = useTranslation("countries");

  const getOptions = (list: Array<ICountry>) => {
    return list.map((country) => ({
      value: country,
      key: country.code,
      label: (
        <>
          <Flag countryCode={country.code} />
          {t(`countries:${country.code}`)} ({country.dial_code})
        </>
      ),
    }));
  };

  const options = getOptions(countryList);

  const getText = (country: ICountry) => {
    if (selectedCountryLabel === "name") {
      return t(`countries:${country.code}`);
    }
    return selectedCountryLabel ? country[selectedCountryLabel] : "";
  };

  const props: IFormFieldSelectProps = {
    defaultValue: countries.find((country) => country.code === initialValue),
    options,
    MenuProps: {
      style: { maxHeight: "30rem", width: "100%" },
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "left",
      },
      transformOrigin: {
        vertical: "top",
        horizontal: "left",
      },
    },
    renderValue: (country: any) => (
      <>
        <Flag countryCode={country.code} /> {getText(country)}
      </>
    ),
    ...rest,
  };

  return formikControl ? <FormFieldSelect className={className} {...props} /> : <SelectInput {...props} />;
};
