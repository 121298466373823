import React from "react";
import { useField } from "formik";
import classNames from "classnames";
import { IStandardInput } from "./Types";
import { InputLabel } from "./InputLabel";
import { InputError } from "./InputError";
import { observer } from "mobx-react-lite";
import TextField from "@mui/material/TextField";
import { InputBaseComponentProps } from "@mui/material";

interface IFormFieldTextProps extends IStandardInput {
  maxlength?: number;
  stepInput?: number;
  inputClass?: string;
  inputProps?: InputBaseComponentProps;
  displayLabelInside?: boolean;
}

interface ITextInputProps extends IFormFieldTextProps {
  hasError?: string;
  touched?: boolean;
  labelClassName?: string;
  value: string;
}

export const TextInput: React.FC<ITextInputProps> = observer(
  ({
    labelClassName,
    hasError,
    touched,
    name,
    style,
    stepInput,
    dark,
    displayError,
    className,
    label,
    required,
    inputProps = {},
    inputClass = "",
    displayLabelInside = false,
    ...props
  }) => {
    return (
      <div style={style} className={classNames("form-group", className)}>
        {label && !displayLabelInside ? <InputLabel name={name} label={label} required={required} /> : null}
        <div className={classNames("mod-block", labelClassName)}>
          <TextField
            {...props}
            label={displayLabelInside ? label : ""}
            id={name}
            required={required}
            style={{ marginTop: displayLabelInside ? 8 : 0, backgroundColor: dark ? "#042b4a" : "transparent" }}
            className={classNames("input input-line", { labeled: displayLabelInside }, inputClass)}
            data-lpignore="true"
            inputProps={
              stepInput
                ? { step: stepInput, className: inputClass, ...inputProps }
                : {
                    ...inputProps,
                    className: inputClass,
                  }
            }
          />
          <InputError error={hasError} displayError={displayError} touched={touched ?? false} />
        </div>
      </div>
    );
  },
);

export const FormFieldText: React.FC<IFormFieldTextProps> = ({ onChange, name, ...props }) => {
  const [field, meta] = useField(name);

  return (
    <TextInput
      onChange={(e) => {
        field.onChange(e);
        onChange && onChange(e);
      }}
      labelClassName={meta.touched && Boolean(meta.error) ? "error" : ""}
      name={name}
      value={field.value}
      hasError={meta.error}
      touched={meta.touched}
      {...props}
    />
  );
};
