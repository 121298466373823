import classNames from "classnames";
import React, { useCallback, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { AppState, refreshLocationData, showAppModal } from "../../AppState";
import { ActionMenu } from "./ActionMenu";
import { ReportMenu } from "./Reports/ReportMenu";
import { HeaderRow, ICON_TOOLTIP_DEFAULT_DELAY, SelectInput, StyledTooltip, SwitchInput } from "../../Components";
import { EditGatewayModal } from "./Modals/EditGatewayModal";
import { EditDeviceGroupModal } from "./Modals/EditDeviceGroupModal";
import { RemoveGroupModal } from "./Modals/RemoveGroupModal";
import { DeviceSort, IMenuSort, OrderBy } from "../../Enums/SortingTypes";
import { GroupConfig } from "./GroupConfig";
import { IDevice, IDeviceGroup, IGateway } from "../../Managers/Types";
import { useTranslation } from "react-i18next";
import { ManageFirmware } from "./Modals/ManageFirmware";
import { getLatestFirmwares } from "../../Managers/API";
import { DrawerIcon } from "../../icon";
import { SortMenu } from "../../Components/SortMenu";
import { SearchRow } from "../../Components/SearchRow";

interface IHeaderRowProps {
  name: string;
  count?: number;
  selectedGateway?: IGateway | null;
  selectGateway: (gateway: IGateway | null) => void;
  orderBy: OrderBy;
  sortBy: DeviceSort;
  setOrderBy: (by: OrderBy) => void;
  setSortBy: (by: DeviceSort) => void;
  forceRefreshDevices: (order?: OrderBy, sort?: DeviceSort) => void;
  deviceList: IDevice[];
  selectGroup: (group: IDeviceGroup) => void;
  selectedGroup?: IDeviceGroup | null;
  searchString: string;
  setSearchString: (search: string) => void;
  setDrawerOpen: (open: boolean) => void;
  sortOptions: IMenuSort[];
}

export const DashboardHeaderRow: React.FC<IHeaderRowProps> = observer(
  ({
    selectGroup,
    selectedGroup,
    deviceList,
    forceRefreshDevices,
    orderBy,
    sortBy,
    setOrderBy,
    setSortBy,
    selectedGateway,
    selectGateway,
    name,
    count,
    searchString,
    setSearchString,
    setDrawerOpen,
    sortOptions,
  }) => {
    const [showingActions, setShowingActions] = useState(false);
    const [showingReports, setShowingReports] = useState(false);
    const [searchRowOpen, setSearchRowOpen] = useState(false);
    const [selectedDevices, setSelectedDevices] = useState<IDevice[]>([]);

    const { t } = useTranslation(["dashboard", "common"]);

    useEffect(() => {
      setSelectedDevices(AppState.selectedDevices);
    }, [AppState.selectedDeviceIds, AppState.selectedDevices]);

    const getGatewayStatus = useCallback(() => {
      return selectedGateway && !selectedGateway.is_online;
    }, [selectedGateway]);

    const showDeviceFirmwareUpdateAlertBadge = () => {
      if (deviceList.length === 0) {
        return false;
      }

      const devicesToCheckFirmware = selectedDevices?.length > 0 ? selectedDevices : deviceList;
      return !devicesToCheckFirmware.some((d) => {
        let hasOldFirmware = d.Firmware?.isLatest != null;
        let hasMissingFirmware = d.Firmware == null;
        return hasOldFirmware || hasMissingFirmware;
      });
    };

    const openFirmwareModal = async (selectedDevice: IDevice) => {
      refreshLocationData();
      showAppModal(<ManageFirmware latestFirmwares={await getLatestFirmwares()} selectedDevice={selectedDevice} />);
    };

    const actions = selectedGateway
      ? [
          {
            action: () => showAppModal(<EditGatewayModal setGateway={selectGateway} gateway={selectedGateway} mode="edit" />),
            icon: "fa-edit",
          },
          {
            action: () => showAppModal(<EditGatewayModal setGateway={selectGateway} gateway={selectedGateway} mode="delete" />),
            icon: "fa-trash-o",
          },
        ]
      : selectedGroup
      ? [
          {
            action: () => showAppModal(<EditDeviceGroupModal setGroup={selectGroup} group={selectedGroup} />),
            icon: "fa-edit",
          },
          {
            action: () => showAppModal(<RemoveGroupModal selectedGroup={selectedGroup} />),
            icon: "fa-trash-o",
          },
        ]
      : [];

    return (
      <>
        <HeaderRow
          info={{
            width: AppState.mode === "tablet" ? "100%" : "",
            is_offline: getGatewayStatus(),
            name: (
              <>
                <button onClick={() => setDrawerOpen(true)} className="btn btn-icon u-tablet-only">
                  <DrawerIcon />
                </button>
                {name + " (" + count + ")"}
              </>
            ),
          }}
          control={
            <section className="header-row-section u-desktop-only last">
              <div className={classNames("dropdown", { open: showingActions })}>
                <StyledTooltip title={t("dashboard:settings")} enterDelay={ICON_TOOLTIP_DEFAULT_DELAY}>
                  <button className="btn btn-icon" disabled={!selectedDevices.length} onClick={() => setShowingActions(!showingActions)}>
                    <i className="fa fa-cog" />
                    <span className="sr-only">{t("dashboard:settings")}</span>
                  </button>
                </StyledTooltip>
                <ActionMenu onClose={() => setShowingActions(false)} refresh={forceRefreshDevices} open={showingActions} />
              </div>

              <div className={classNames("dropdown", { open: showingReports })}>
                <StyledTooltip title={t("dashboard:reports")} enterDelay={ICON_TOOLTIP_DEFAULT_DELAY}>
                  <button
                    className="btn btn-icon"
                    disabled={selectedDevices.length !== 1}
                    onClick={() => setShowingReports(!showingReports)}>
                    <i className="fa fa-line-chart" />
                    <span className="sr-only">{t("dashboard:reports")}</span>
                  </button>
                </StyledTooltip>
                <ReportMenu onClose={() => setShowingReports(false)} open={showingReports} />
              </div>

              <div className={classNames("device-firmware-button")}>
                <StyledTooltip title={t("dashboard:device_firmware")} enterDelay={ICON_TOOLTIP_DEFAULT_DELAY}>
                  <button
                    className="btn btn-icon"
                    disabled={!selectedDevices.length || selectedDevices.length != 1}
                    onClick={() => openFirmwareModal(selectedDevices[0])}>
                    <i className="fa fa-cloud-upload" />
                    <span className="sr-only">{t("dashboard:device_firmware")}</span>
                    {showDeviceFirmwareUpdateAlertBadge() ? (
                      <span className={classNames("device-firmware-alert-badge badge btn-badge btn-badge-sm btn-badge-alert")} />
                    ) : null}
                  </button>
                </StyledTooltip>
              </div>
            </section>
          }
          infoControl={
            <div className="header-row-info-control">
              {actions.map((item) => (
                <div>
                  <button className="btn btn-icon" onClick={item.action}>
                    <i className={classNames("fa header-icon", item.icon)} />
                  </button>
                </div>
              ))}

              <button className="btn u-text-teal u-desktop-hide btn-icon" onClick={() => setSearchRowOpen(!searchRowOpen)}>
                <i className="fa fa-search header-icon" />
              </button>

              <SortMenu
                className="u-tablet-only"
                iconClassName="header-icon"
                options={sortOptions}
                sort={{ dir: orderBy, prop: sortBy }}
                sortChange={(sort) => {
                  setSortBy(sort.prop as DeviceSort);
                  setOrderBy(sort.dir);
                }}
              />

              {selectedGateway ? (
                <SwitchInput
                  className="switch-gateway-mode"
                  onChange={() =>
                    showAppModal(
                      <EditGatewayModal
                        setGateway={selectGateway}
                        gateway={selectedGateway}
                        mode={selectedGateway?.is_online ? "offline" : "online"}
                      />,
                    )
                  }
                  checked={selectedGateway.is_online}
                  name="online"
                />
              ) : null}

              <SelectInput
                required={false}
                displayError={false}
                className="input-holder filter-holder header-select u-desktop-only"
                label={t("common:sort_by")}
                onChange={setSortBy}
                options={[
                  { value: DeviceSort.Name, label: t("dashboard:name"), key: "name" },
                  { value: DeviceSort.Updated, label: t("dashboard:last_updated"), key: "last-updated" },
                ]}
                initialValue={DeviceSort.Updated}
                value={sortBy}
              />
              <SelectInput
                required={false}
                displayError={false}
                className="input-holder filter-holder header-select u-desktop-only"
                label={t("common:order")}
                onChange={setOrderBy}
                options={[
                  { value: OrderBy.ASC, label: `${t("dashboard:asc").toUpperCase()} ↑`, key: "asc" },
                  { value: OrderBy.DESC, label: `${t("dashboard:desc").toUpperCase()} ↓`, key: "desc" },
                ]}
                initialValue={OrderBy.DESC}
                value={orderBy}
              />
            </div>
          }
          infoSub={<GroupConfig />}
          mainRight={
            <div className="u-flex-center">
              <div className="input-holder u-desktop-only">
                <input
                  type="text"
                  className="input input-default"
                  placeholder={t("common:find")}
                  onChange={(e) => setSearchString(e.target.value)}
                  disabled={!deviceList.length}
                  value={searchString}
                />
                <i className="fa fa-search input-holder-icon" />
              </div>
            </div>
          }
        />
        <SearchRow
          className="u-desktop-hide"
          value={searchString}
          onChange={setSearchString}
          open={searchRowOpen}
          onClose={() => setSearchRowOpen(false)}
        />
      </>
    );
  },
);
