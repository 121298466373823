import { StyledTooltip } from "./StyledTooltip";
import React from "react";

export const InputError: React.FC<{ error?: string; displayError?: boolean; touched: boolean }> = ({
  error,
  displayError = false,
  touched,
}) => {
  return (
    <StyledTooltip title={<span className="u-color-alert">{error ?? ""}</span>}>
      {touched && Boolean(error) && displayError ? (
        <i style={{ zIndex: 10 }} className="fa fa-times input-holder-icon u-color-alert" />
      ) : (
        <></>
      )}
    </StyledTooltip>
  );
};
