import { Endpoint } from "./API";
import { IManageMaintenanceNotification } from "./Types";

const API_PATH = "api/maintenance_notification";
export const QK_MAINTENANCE_NOTIFICATION = ["MAINTENANCE_NOTIFICATION"];

export const getMaintenanceNotification = () => Endpoint.get<IManageMaintenanceNotification>(`${API_PATH}/`).then((r) => r.data);

export const updateMaintenanceNotification = (newMaintenanceNotificationInfo: IManageMaintenanceNotification) =>
  Endpoint.put<IManageMaintenanceNotification>(`${API_PATH}/`, newMaintenanceNotificationInfo).then((r) => {
    return r.data;
  });

export const sendMaintenanceNotificationEmails = () => Endpoint.get(`${API_PATH}/sendEmails`).then((r) => r.data);
export const resetUsersMaintenanceNotificationClosedFlag = () => Endpoint.put(`${API_PATH}/reset`).then((r) => r.data);
export const closeMaintenanceNotification = (userId: number | string) => Endpoint.put(`${API_PATH}/close/${userId}`).then((r) => r.data);
