import { useQuery } from "react-query";
import { Endpoint, invalidateQuery, updateQueryEntryById } from "./API";
import { AppState } from "../AppState";
import { IUser, IUserWithConnections } from "./Types";
import { UserRoles } from "../Enums";

const API_PATH = "api/users";
export const QK_USERS = ["USERS"];

export const getUser = (userId: number | string) => Endpoint.get<IUser>(`${API_PATH}/${userId}`).then((r) => r.data);
export const useUser = (userId: number | string) => useQuery([...QK_USERS, userId], () => getUser(userId));

export const getMe = () => Endpoint.get<IUserWithConnections>(`${API_PATH}/me`).then((r) => r.data);

export const getUsers = (account = false) => Endpoint.get<IUser[]>(`${API_PATH}?account=${account}`).then((r) => r.data);

export const useUsers = () => useQuery(QK_USERS, () => getUsers(), { placeholderData: [] });

export const deleteUser = (id: number) =>
  Endpoint.delete(`${API_PATH}/${id}`).then((r) => {
    invalidateQuery(QK_USERS);
    return r.data;
  });

export const createUser = async (user: IUser) => {
  const path = `${API_PATH}/${user.role === UserRoles.ADMIN ? "admin" : ""}`;

  try {
    const r = await Endpoint.post(path, user);
    invalidateQuery(QK_USERS);
    return r.data;
  } catch (err) {
    return await Promise.reject(err);
  }
};

export const updateUser = (newUserInfo: IUser) =>
  Endpoint.put<IUser>(`${API_PATH}/${newUserInfo._id}`, newUserInfo).then((r) => {
    updateQueryEntryById(QK_USERS, r.data._id, r.data);
    return r.data;
  });

export const resetPassword = (user: IUser) => Endpoint.post(`${API_PATH}/${user._id}/passwordReset`, user).then((r) => r.data);

export const resetLocalPassword = (id: number, passwords: any) => Endpoint.put(`${API_PATH}/${id}/password`, passwords).then((r) => r.data);

// We provide a default for the app user because a) it should never be undefined anyway and b) if something goes wrong we want to
// force this to fail
export const isAccountUser = (user: IUser | null | undefined) => user?._id === (AppState.user?._id || -1);
