import React, { useCallback, useState } from "react";
import { observer } from "mobx-react-lite";
import { addToDeviceGroup, deleteFromDeviceGroup } from "../../../Managers/DeviceGroupService";
import { AppState, getDeviceGroups, setDeviceGroupDevices, setSelectedLocation, showAppModal, showSnackbar } from "../../../AppState";
import { IDevice } from "../../../Managers/Types";
import { Modal } from "../../../Components/Modal";
import { useTranslation } from "react-i18next";
import { SelectInput } from "../../../Components";

interface IAddToGroupProps {
  devices: IDevice[];
  title: string;
  remove?: boolean;
}

export const AddToGroupModal: React.FC<IAddToGroupProps> = observer(({ devices, title, remove = false }) => {
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [selectedGroupId, setSelectedGroupId] = useState(devices[0].DeviceGroupId);

  const { t } = useTranslation(["dashboard", "common"]);

  const possibleGroups = getDeviceGroups();

  const getGroupById = useCallback((id) => possibleGroups.find((group) => group._id === id), [possibleGroups]);
  const submitForm = () => {
    setIsSaving(true);

    const devicesToUpdate = devices.filter((device: IDevice) => device.DeviceGroupId !== selectedGroupId);
    if (devicesToUpdate.length) {
      if (!remove) {
        addToDeviceGroup(selectedGroupId, devices)
          .then(() => {
            setDeviceGroupDevices(selectedGroupId, devices);
            showAppModal(null);
            showSnackbar(t("dashboard:device_group_modal.update_success"), "success");
          })
          .catch((e) => {
            console.error(e);
            showSnackbar(t("dashboard:device_group_modal.update_error"), "error");
            setIsSaving(false);
          });
      }
    }

    if (remove) {
      deleteFromDeviceGroup(selectedGroupId, devices)
        .then(() => {
          setDeviceGroupDevices(null, devices);
          if (AppState.selectedLocation) {
            setSelectedLocation(AppState.selectedLocation);
          }
          showAppModal(null);
          showSnackbar(t("dashboard:device_group_modal.delete_success"), "success");
        })
        .catch((e) => {
          console.error(e);
          showSnackbar(t("dashboard:device_group_modal.delete_error"), "error");
          setIsSaving(false);
        });
    }
  };

  const removeText = t("dashboard:device_group_modal.remove_from_group", {
      deviceName: `${devices[0].name}`,
      groupName: `${getGroupById(devices[0].DeviceGroupId)?.name}`,
  });
  return (
    <Modal
      className="modal-sm"
      title={title}
      buttons={
        <>
          <button className="btn btn-info" onClick={() => showAppModal(null)} disabled={isSaving}>
            {t("common:cancel")}
          </button>
          <button className="btn btn-primary" onClick={submitForm} disabled={isSaving || !selectedGroupId || +selectedGroupId === -1}>
            {isSaving ? <i className="fa fa-spin fa-circle-o-notch" /> : <></>}
            <span>{t("common:continue")}</span>
          </button>
        </>
      }>
      {remove ? (
        <div className="form-group">
          <label htmlFor="groups" dangerouslySetInnerHTML={{__html: removeText}}>

          </label>
        </div>
      ) : (
        <SelectInput
          required={true}
          label={t("dashboard:device_group_modal.select_group")}
          inputClassName="dark"
          menuItemClass="dark"
          displayEmpty={true}
          value={selectedGroupId}
          onChange={(value) => setSelectedGroupId(value || 0)}
          options={[
            {
              label: `-- ${t("dashboard:device_group_modal.select_group")} --`,
              value: "",
            },
            ...possibleGroups.map((group) => ({ value: group._id, label: group.name })),
          ]}
        />
      )}
    </Modal>
  );
});
