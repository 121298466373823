import React, { useEffect, useState } from "react";
import {
  DatepickerDropdown,
  ICON_TOOLTIP_DEFAULT_DELAY,
  LeftRail,
  Page,
  SelectInput,
  StyledTooltip,
  TextInput,
  Wrapper,
} from "../../Components";
import { IAlertHistoryFilters, IDevice, INotification } from "../../Managers/Types";
import classNames from "classnames";
import { Table, TableBody, TableCell, TableHead, TableRow } from "../../Components/Table";
import { Autocomplete, Box, Button, CircularProgress, Drawer } from "@mui/material";
import { AppState, showAppModal, showSnackbar } from "../../AppState";
import "./AlertsHistory.scss";
import { formatDateCustom, getNameSlug, mySearch } from "../../Managers";
import { useDevicesWithProps } from "../../Managers/DeviceService";
import moment from "moment/moment";
import { useSensorTypes } from "../../Managers/AlertService";
import { deleteNotification, getNotificationsHistory } from "../../Managers/NotificationService";
import { measurementTransform } from "../../Managers/MeasurementService";
import { unitsTransform } from "../../Managers/UnitsService";
import { ConfirmModal } from "../../Modals";
import trashIcon from "../../icon/trash.svg";
import AlertsHistoryDetailsModal from "./AlertsHistoryDetailsModal";
import { useTranslation } from "react-i18next";
import { PaginationButtons } from "../../Components/PaginationButtons";
import { FiltersIcon, LeftRailToggleIcon, XMarkIcon } from "../../icon";
import { Colors } from "../../Theme";

export const AlertsHistory: React.FC = () => {
  const [searchDeviceString, setSearchDeviceString] = useState("");
  const [order, setOrder] = useState<"asc" | "desc">("asc");
  const [startDate, setStartDate] = useState<Date>(moment().subtract(1, "weeks").toDate());
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [hideMobileFilters, setHideMobileFilters] = useState<boolean>(true);
  const [tabletAlertsHistoryListOpen, setTabletAlertsHistoryListOpen] = useState<boolean>(true);
  const initialFilters: IAlertHistoryFilters = {
    type: "all",
    sensorTypeId: undefined,
    status: "all",
    show: 20,
    sortBy: "added",
    startDate: null,
    endDate: null,
  };

  const [selectedDevice, setSelectedDevice] = useState<IDevice | null>(null);
  const [selectedFilters, setSelectedFilters] = useState<IAlertHistoryFilters>({ ...initialFilters });
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [activeFiltersCount, setActiveFiltersCount] = useState<number>(0);

  const [initialNotifications, setInitialNotifications] = useState<Array<INotification>>([]);
  const [sortedNotifications, setSortedNotifications] = useState<Array<INotification>>([]);
  const [paginatedNotifications, setPaginatedNotifications] = useState<Array<INotification>>([]);

  const devices = useDevicesWithProps(["_id", "name", "serial_number", "is_active"], true);

  const sensorTypes = useSensorTypes();

  const { t } = useTranslation(["alert_history", "sensor_types", "common"]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const notifications = await getNotificationsHistory(
          moment(startDate).startOf("day").toISOString(true),
          moment(endDate).endOf("day").toISOString(true),
        );
        setInitialNotifications(notifications);
      } catch (error) {
        console.error("Error fetching notifications:", error);
      }
    };

    fetchData().then(() => setIsLoading(false));
  }, [startDate, endDate]);

  const sortedDevices = [...(devices.data || [])].sort((a, b) => {
    if (!a.name || !b.name) return 0;
    return order === "desc" ? b.name.localeCompare(a.name) : a.name.localeCompare(b.name);
  });

  useEffect(() => {
    updatePaginatedNotifications();
  }, [currentPage, sortedNotifications, selectedFilters.show]);

  const updatePaginatedNotifications = () => {
    const startIndex = (currentPage - 1) * selectedFilters.show;
    const endIndex = Math.min(startIndex + selectedFilters.show, sortedNotifications.length);
    const newPaginatedNotifications = sortedNotifications.slice(startIndex, endIndex);
    setPaginatedNotifications(newPaginatedNotifications);
    const pageCount = Math.ceil(sortedNotifications.length / selectedFilters.show);
    setTotalPages(pageCount);
    const filteredNotifications = newPaginatedNotifications.slice(0, selectedFilters.show);
    setPaginatedNotifications(filteredNotifications);
  };

  useEffect(() => {
    setCurrentPage(1);
    setSortedNotifications(initialNotifications);
    setTotalPages(Math.ceil(initialNotifications.length / selectedFilters.show));
  }, [initialNotifications]);

  useEffect(() => {
    const filtersChanged = Object.keys(selectedFilters).some(
      (key) => selectedFilters[key as keyof IAlertHistoryFilters] !== initialFilters[key as keyof IAlertHistoryFilters],
    );

    if (filtersChanged) {
      console.log("updated selected filters", selectedFilters);
      updateSortedNotifications();
      setCurrentPage(1);
    }
  }, [selectedFilters]);

  useEffect(() => {
    setCurrentPage(1);
    updateSortedNotifications();
  }, [selectedFilters, startDate, endDate, selectedDevice]);

  const updateSortedNotifications = () => {
    const isTypeValid = selectedFilters.type !== "";
    const isSensorTypeValid = selectedFilters.sensorTypeId !== undefined && selectedFilters.sensorTypeId > -1;
    const isStatusValid = selectedFilters.status !== "all";
    const isShowValid = selectedFilters.show !== undefined && selectedFilters.show > 0;
    const isSortByValid = selectedFilters.sortBy !== "";
    const isStartDateValid = selectedFilters.startDate !== null;
    const isEndDateValid = selectedFilters.endDate !== null;

    setActiveFiltersCount(0);

    if (isTypeValid || isSensorTypeValid || isStatusValid || isShowValid || isSortByValid || isStartDateValid || isEndDateValid) {
      let filteredNotifications = [...initialNotifications];
      if (isSortByValid) {
        setActiveFiltersCount((prev) => prev + 1);
        switch (selectedFilters.sortBy) {
          case "added":
            filteredNotifications.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
            break;
          case "updated":
            filteredNotifications.sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime());
            break;
          case "asc":
            filteredNotifications.sort((a, b) => {
              const valueA = parseFloat(a.value.value);
              const valueB = parseFloat(b.value.value);
              return valueA - valueB;
            });
            break;
          case "desc":
            filteredNotifications.sort((a, b) => {
              const valueA = parseFloat(a.value.value);
              const valueB = parseFloat(b.value.value);
              return valueB - valueA;
            });
            break;
          default:
            break;
        }
      }

      if (isStatusValid) {
        setActiveFiltersCount((prev) => prev + 1);
        const isResolved = selectedFilters.status === "resolved";
        const showAllStatuses = selectedFilters.status === "all";
        if (!showAllStatuses) {
          filteredNotifications = filteredNotifications.filter((notification) => {
            return notification.is_resolved === isResolved;
          });
        }
      }

      if (isSensorTypeValid) {
        setActiveFiltersCount((prev) => prev + 1);

        filteredNotifications = filteredNotifications.filter((notification) => {
          return notification.Sensor?.Sensor_type._id === selectedFilters.sensorTypeId;
        });
      }

      if (isTypeValid) {
        setActiveFiltersCount((prev) => prev + 1);
        const showAllTypes = selectedFilters.type === "all";
        if (!showAllTypes) {
          filteredNotifications = filteredNotifications.filter((notification) => {
            return notification.Sensor?.Sensor_type?.type?.toLowerCase() === selectedFilters.type;
          });
        }
      }

      if (selectedDevice) {
        filteredNotifications = filteredNotifications.filter((notification) => {
          return notification.Sensor?.DeviceId === selectedDevice._id;
        });
      }

      setSortedNotifications(filteredNotifications);
      updatePaginatedNotifications();
    }
  };

  const afterDeletion = (deletedNotification: INotification) => {
    const updatedInitialNotifications = initialNotifications.filter((item) => item._id !== deletedNotification._id);
    setInitialNotifications(updatedInitialNotifications);

    const updatedSortedNotifications = sortedNotifications.filter((item) => item._id !== deletedNotification._id);
    setSortedNotifications(updatedSortedNotifications);
  };

  if (isLoading) {
    return (
      <Page>
        <Wrapper style={{ alignItems: "center", justifyContent: "center" }}>
          <CircularProgress />
        </Wrapper>
      </Page>
    );
  }

  return (
    <Page>
      {
        <Wrapper>
          <LeftRail
            className="u-mobile-hide u-tablet-hide"
            header={
              <div className="input-holder u-full-width">
                <input
                  type="text"
                  className="input input-default"
                  placeholder={t("alert_history:find_device")}
                  value={searchDeviceString}
                  onChange={(e) => setSearchDeviceString(e.target.value)}
                />
                <i className="fa fa-search input-holder-icon" />
              </div>
            }
            body={
              <nav className="left-rail-nav alert-history-nav">
                <div className="left-rail-nav-header">
                  <h2 className="left-rail-nav-header-title mod-with-btn">
                    <StyledTooltip
                      title={t("common:sort_by_name", { context: order === "asc" ? "desc" : "asc" })}
                      enterDelay={ICON_TOOLTIP_DEFAULT_DELAY}>
                      <button className="btn btn-plain" onClick={() => setOrder(order === "desc" ? "asc" : "desc")}>
                        <i className={classNames("fa fa-sort-amount-asc", { "u-display-none": order === "asc" })} />
                        <i className={classNames("fa fa-sort-amount-desc", { "u-display-none": order !== "asc" })} />
                      </button>
                    </StyledTooltip>{" "}
                    {t("alert_history:device_list")}
                  </h2>
                </div>

                <ul className="left-rail-nav-group">
                  <li className={classNames("left-rail-nav-item", { active: selectedDevice === null })} key="all-devices">
                    <a onClick={() => setSelectedDevice(null)}>{t("alert_history:all_devices", { count: sortedDevices.length })}</a>
                  </li>

                  <div className="input-label">{t("alert_history:active")}</div>
                  {mySearch<IDevice>(
                    sortedDevices.filter((d) => d.is_active),
                    searchDeviceString,
                  ).map((device) => (
                    <li className={classNames("left-rail-nav-item", { active: selectedDevice?._id === device._id })} key={device._id}>
                      <a onClick={() => setSelectedDevice(device)}>{device.name}</a>
                    </li>
                  ))}
                  <div className="input-label">{t("alert_history:inactive")}</div>
                  {mySearch<IDevice>(
                    sortedDevices.filter((d) => !d.is_active),
                    searchDeviceString,
                  ).map((device) => (
                    <li className={classNames("left-rail-nav-item", { active: selectedDevice?._id === device._id })} key={device._id}>
                      <a onClick={() => setSelectedDevice(device)}>{device.name}</a>
                    </li>
                  ))}
                </ul>
              </nav>
            }
          />

          <Drawer
            onBackdropClick={() => setTabletAlertsHistoryListOpen(false)}
            className="u-tablet-only tablet-drawer"
            open={tabletAlertsHistoryListOpen}>
            <div>
              <div className="input-holder find-devices-input-wrapper">
                <input
                  type="text"
                  className="input input-default"
                  placeholder={t("alert_history:find_device")}
                  value={searchDeviceString}
                  onChange={(e) => setSearchDeviceString(e.target.value)}
                />
                <i className="fa fa-search input-holder-icon" />
              </div>
              <nav className="left-rail-nav alert-history-nav">
                <div className="left-rail-nav-header">
                  <h2 className="left-rail-nav-header-title mod-with-btn">
                    <StyledTooltip
                      title={t("common:sort_by_name", { context: order === "asc" ? "desc" : "asc" })}
                      enterDelay={ICON_TOOLTIP_DEFAULT_DELAY}>
                      <button className="btn btn-plain" onClick={() => setOrder(order === "desc" ? "asc" : "desc")}>
                        <i className={classNames("fa fa-sort-amount-asc", { "u-display-none": order === "asc" })} />
                        <i className={classNames("fa fa-sort-amount-desc", { "u-display-none": order !== "asc" })} />
                      </button>
                    </StyledTooltip>{" "}
                    {t("alert_history:device_list")}
                  </h2>
                </div>

                <ul className="left-rail-nav-group">
                  <li className={classNames("left-rail-nav-item", { active: selectedDevice === null })} key="all-devices">
                    <a onClick={() => setSelectedDevice(null)}>{t("alert_history:all_devices", { count: sortedDevices.length })}</a>
                  </li>

                  <div className="input-label">{t("alert_history:active")}</div>
                  {mySearch<IDevice>(
                    sortedDevices.filter((d) => d.is_active),
                    searchDeviceString,
                  ).map((device) => (
                    <li className={classNames("left-rail-nav-item", { active: selectedDevice?._id === device._id })} key={device._id}>
                      <a onClick={() => setSelectedDevice(device)}>{device.name}</a>
                    </li>
                  ))}
                  <div className="input-label">{t("alert_history:inactive")}</div>
                  {mySearch<IDevice>(
                    sortedDevices.filter((d) => !d.is_active),
                    searchDeviceString,
                  ).map((device) => (
                    <li className={classNames("left-rail-nav-item", { active: selectedDevice?._id === device._id })} key={device._id}>
                      <a onClick={() => setSelectedDevice(device)}>{device.name}</a>
                    </li>
                  ))}
                </ul>
              </nav>
            </div>
          </Drawer>

          <main className="bapi-main mod-dashboard alerts-history-main">
            <div className="filters-wrapper u-tablet-hide">
              <span style={{ margin: "auto 24px auto 50px" }}>{selectedDevice?.name || t("alert_history:no_selected_device")}</span>
              <div
                style={{
                  padding: 0,
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                  marginLeft: "40px",
                }}>
                <DatepickerDropdown selectedDate={startDate} maxDate={endDate} onSelectDate={(date) => setStartDate(date)} />

                <span className="to" style={{ padding: "0.5em" }}>
                  {t("common:to")}
                </span>

                <DatepickerDropdown selectedDate={endDate} minDate={startDate} onSelectDate={(date) => setEndDate(date)} />
              </div>

              <div className="select-filters-wrapper">
                <SelectInput
                  required={false}
                  displayError={false}
                  className="input-holder input-filter-holder"
                  label={t("alert_history:type")}
                  onChange={(selectedOption) => {
                    setSelectedFilters((prevFilters) => ({
                      ...prevFilters,
                      type: selectedOption,
                    }));
                  }}
                  options={[
                    { value: "all", key: "all" },
                    { value: "range", key: "range" },
                    { value: "max", key: "max" },
                    { value: "min", key: "min" },
                    { value: "boolean", key: "boolean" },
                    { value: "transmitting", key: "not_transmitting" },
                  ].map((o) => ({ ...o, label: t("alert_history:sensor_type", { context: o.key }) }))}
                  value={selectedFilters.type}
                />

                <SelectInput
                  required={false}
                  displayError={false}
                  className="input-holder input-filter-holder"
                  label={t("alert_history:status")}
                  onChange={(selectedOption) => {
                    setSelectedFilters((prevFilters) => ({
                      ...prevFilters,
                      status: selectedOption,
                    }));
                  }}
                  options={[
                    { value: "all", label: t("alert_history:all"), key: "all" },
                    { value: "resolved", label: t("alert_history:resolved"), key: "resolved" },
                    { value: "active", label: t("alert_history:active"), key: "active" },
                  ]}
                  value={selectedFilters.status}
                />

                {sensorTypes.data ? (
                  <>
                    <SelectInput
                      required={false}
                      displayError={false}
                      className="input-holder input-filter-holder"
                      label={t("alert_history:sensor_type")}
                      onChange={(selectedOption) => {
                        setSelectedFilters((prevFilters) => ({
                          ...prevFilters,
                          sensorTypeId: selectedOption,
                        }));
                      }}
                      options={[
                        { value: -1, label: t("alert_history:all"), key: undefined },
                        ...(sensorTypes.data || []).map((type) => ({
                          value: type._id,
                          label: t(`sensor_types:${getNameSlug(type.name)}`),
                          key: type._id.toString(),
                        })),
                      ]}
                      value={selectedFilters.sensorTypeId ?? -1}
                    />
                  </>
                ) : (
                  <div>{t("alert_history:loading_sensor_types")}</div>
                )}

                <SelectInput
                  required={false}
                  displayError={false}
                  className="input-holder input-filter-holder narrow-filter"
                  label={t("alert_history:show")}
                  onChange={(selectedOption) => {
                    setSelectedFilters((prevFilters) => ({
                      ...prevFilters,
                      show: selectedOption,
                    }));
                  }}
                  options={[
                    { value: 10, label: "10", key: "show-10" },
                    { value: 20, label: "20", key: "show-20" },
                    { value: 30, label: "50", key: "show-50" },
                    { value: 100, label: "100", key: "show-100" },
                  ]}
                  value={selectedFilters.show}
                />

                <SelectInput
                  required={false}
                  displayError={false}
                  className="input-holder input-filter-holder"
                  label={t("common:sort_by")}
                  onChange={(selectedOption) => {
                    setSelectedFilters((prevFilters) => ({
                      ...prevFilters,
                      sortBy: selectedOption,
                    }));
                  }}
                  options={[
                    { value: "added", label: t("alert_history:last_added"), key: "last-added" },
                    { value: "updated", label: t("alert_history:last_updated"), key: "last-updated" },
                    {
                      value: "desc",
                      label: t("alert_history:alert_value", { context: "desc" }),
                      key: "alert-value-desc",
                    },
                    { value: "asc", label: t("alert_history:alert_value", { context: "asc" }), key: "alert-value-asc" },
                  ]}
                  value={selectedFilters.sortBy}
                />
              </div>
            </div>
            <div className="device-list-mobile u-desktop-hide u-tablet-hide">
              {devices.data !== undefined && (
                <Autocomplete
                  onChange={(_e, device) => {
                    setSelectedDevice(device);
                  }}
                  className="autocomplete-account u-full-width dark"
                  options={devices.data}
                  getOptionLabel={(o: IDevice) => o.name}
                  getOptionKey={(o: IDevice) => o._id}
                  renderInput={(params) => (
                    <TextInput
                      label={t("alert_history:choose_device")}
                      displayLabelInside
                      className="account-search-input u-full-width dark"
                      inputClass="dark"
                      onChange={(e) => setSearchDeviceString(e.target.value)}
                      value={searchDeviceString}
                      name={params.id}
                      {...params}
                    />
                  )}
                />
              )}
            </div>
            <div className="filters-wrapper-mobile-tablet u-desktop-hide">
              <div className="device-name">
                <div className="left-rail-toggle-wrapper alerts-left-rail-icon-wrapper u-tablet-only">
                  <button className="btn btn-icon" onClick={() => setTabletAlertsHistoryListOpen(!tabletAlertsHistoryListOpen)}>
                    <LeftRailToggleIcon />
                  </button>
                </div>
                {selectedDevice?.name || t("alert_history:no_selected_device")}
              </div>

              <div className="filters-mobile-icon" onClick={() => setHideMobileFilters(!hideMobileFilters)}>
                {hideMobileFilters ? (
                  <>
                    <button className="btn btn-icon">
                      <FiltersIcon />
                    </button>
                    <div className="mobile-active-filters-count">({activeFiltersCount})</div>
                  </>
                ) : (
                  <button className="btn btn-icon">
                    <XMarkIcon />
                  </button>
                )}
              </div>

              {hideMobileFilters ? null : (
                <>
                  <div>
                    <span className="from" style={{ padding: "0.5em" }}>
                      {t("common:from")}
                    </span>

                    <DatepickerDropdown selectedDate={startDate} maxDate={endDate} onSelectDate={(date) => setStartDate(date)} />
                  </div>

                  <div>
                    <span className="to" style={{ padding: "0.5em" }}>
                      {t("common:to")}
                    </span>
                    <DatepickerDropdown selectedDate={endDate} minDate={startDate} onSelectDate={(date) => setEndDate(date)} />
                  </div>

                  <SelectInput
                    required={false}
                    displayError={false}
                    className="input-holder input-filter-holder"
                    label={t("alert_history:type")}
                    onChange={(selectedOption) => {
                      setSelectedFilters((prevFilters) => ({
                        ...prevFilters,
                        type: selectedOption,
                      }));
                    }}
                    options={[
                      { value: "all", key: "all" },
                      { value: "range", key: "range" },
                      { value: "max", key: "max" },
                      { value: "min", key: "min" },
                      { value: "boolean", key: "boolean" },
                      { value: "transmitting", key: "not_transmitting" },
                    ].map((o) => ({ ...o, label: t("alert_history:sensor_type", { context: o.key }) }))}
                    value={selectedFilters.type}
                  />

                  <SelectInput
                    required={false}
                    displayError={false}
                    className="input-holder input-filter-holder"
                    label={t("alert_history:status")}
                    onChange={(selectedOption) => {
                      setSelectedFilters((prevFilters) => ({
                        ...prevFilters,
                        status: selectedOption,
                      }));
                    }}
                    options={[
                      { value: "all", label: t("alert_history:all"), key: "all" },
                      { value: "resolved", label: t("alert_history:resolved"), key: "resolved" },
                      { value: "active", label: t("alert_history:active"), key: "active" },
                    ]}
                    value={selectedFilters.status}
                  />

                  {sensorTypes.data ? (
                    <>
                      <SelectInput
                        required={false}
                        displayError={false}
                        className="input-holder input-filter-holder"
                        label={t("alert_history:sensor_type")}
                        onChange={(selectedOption) => {
                          setSelectedFilters((prevFilters) => ({
                            ...prevFilters,
                            sensorTypeId: selectedOption,
                          }));
                        }}
                        options={[
                          { value: -1, label: t("alert_history:all"), key: undefined },
                          ...(sensorTypes.data || []).map((type) => ({
                            value: type._id,
                            label: t(`sensor_types:${getNameSlug(type.name)}`),
                            key: type._id.toString(),
                          })),
                        ]}
                        value={selectedFilters.sensorTypeId ?? -1}
                      />
                    </>
                  ) : (
                    <div>{t("alert_history:loading_sensor_types")}</div>
                  )}

                  <SelectInput
                    required={false}
                    displayError={false}
                    className="input-holder input-filter-holder"
                    label={t("common:sort_by")}
                    onChange={(selectedOption) => {
                      setSelectedFilters((prevFilters) => ({
                        ...prevFilters,
                        sortBy: selectedOption,
                      }));
                    }}
                    options={[
                      { value: "added", label: t("alert_history:last_added"), key: "last-added" },
                      { value: "updated", label: t("alert_history:last_updated"), key: "last-updated" },
                      {
                        value: "desc",
                        label: t("alert_history:alert_value", { context: "desc" }),
                        key: "alert-value-desc",
                      },
                      {
                        value: "asc",
                        label: t("alert_history:alert_value", { context: "asc" }),
                        key: "alert-value-asc",
                      },
                    ]}
                    value={selectedFilters.sortBy}
                  />
                </>
              )}
            </div>
            <AlertsHistoryTable
              device={AppState.selectedDevices[0]}
              sortedNotifications={paginatedNotifications}
              afterDeletion={afterDeletion}
            />

            {paginatedNotifications.length > 0 && (
              <div className="text-center pagination-text-showing">
                {t("alert_history:shown_alerts", {
                  from: (currentPage - 1) * selectedFilters.show + 1,
                  to: Math.min(currentPage * selectedFilters.show, sortedNotifications.length),
                })}
              </div>
            )}

            {paginatedNotifications?.length > 0 ? <PaginationButtons onPageChange={setCurrentPage} pageCount={totalPages} /> : null}
          </main>
        </Wrapper>
      }
    </Page>
  );
};

interface IAlertsHistoryTableWrapperProps {
  device: IDevice;
  sortedNotifications: Array<INotification> | [];
  afterDeletion: (deletedNotification: INotification) => void;
}

const AlertsHistoryTable = (props: IAlertsHistoryTableWrapperProps) => {
  const { sortedNotifications, afterDeletion } = props;
  const { t } = useTranslation("alert_history");

  const showAlertDetailsModal = (selectedAlert: INotification) => {
    showAppModal(<AlertsHistoryDetailsModal selectedAlert={selectedAlert} />);
  };

  const handleConfirmDelete = (notification: INotification) => {
    showAppModal(
      <ConfirmModal
        header={t("alert_history:delete_alert")}
        confirmText={t("common:delete")}
        onConfirm={() => handleDeleteAlert(notification)}
        children={
          <>
            <p>{t("alert_history:confirm_delete_content")}</p> <p>{t("alert_history:confirm_delete_warning")}</p>
          </>
        }
      />,
    );
  };

  const handleDeleteAlert = (notification: INotification) => {
    deleteNotification(notification)
      .then((r) => {
        console.log("Delete result", r);
        afterDeletion(notification);
        showAppModal(null);
        showSnackbar(t("alert_history:delete_notification_success"), "success");
      })
      .catch((e) => {
        console.log("Delete error", e);
        showSnackbar(t("alert_history:delete_notification_error"), "error");
      });
  };

  if (sortedNotifications.length === 0) {
    return (
      <section className="main-panel no-results">
        <div className="text-center">{t("alert_history:no_results")}</div>
      </section>
    );
  }

  return (
    <section className="main-panel">
      <Box className="alerts-history-desktop-table">
        <>
          <Table className="full-width">
            <TableHead>
              <TableRow style={{ background: "#00243f" }}>
                <TableCell width={200} className="alerts-history-table-head">
                  {t("alert_history:create_date")}
                </TableCell>
                <TableCell width={200} className="alerts-history-table-head">
                  {t("alert_history:updated")}
                </TableCell>
                <TableCell width={150} className="alerts-history-table-head">
                  {t("alert_history:type")}
                </TableCell>
                <TableCell width={120} className="alerts-history-table-head">
                  {t("alert_history:status")}
                </TableCell>
                <TableCell width={100} className="alerts-history-table-head">
                  {t("alert_history:alert_value")}
                </TableCell>
                <TableCell width={200} className="alerts-history-table-head">
                  {t("alert_history:sensor_type")}
                </TableCell>
                <TableCell width={200} className="alerts-history-table-head"></TableCell>
                <TableCell width={150} className="alerts-history-table-head"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedNotifications.map((notification) => {
                return (
                  <TableRow id={notification._id.toString()} key={`notification-${notification._id}-${Math.floor(Math.random() * 10000)}`}>
                    <TableCell style={{ padding: "5px 16px" }}>{formatDateCustom(notification.createdAt, "M/D/YYYY hh:mm A")}</TableCell>
                    <TableCell style={{ padding: "5px 16px" }}>{formatDateCustom(notification.updatedAt, "M/D/YYYY hh:mm A")}</TableCell>
                    <TableCell>
                      {notification.Sensor?.Sensor_type.type
                        ? t("alert_history:sensor_type", { context: getNameSlug(notification.Sensor?.Sensor_type?.type) })
                        : ""}
                    </TableCell>
                    <TableCell>
                      {notification.is_resolved ? (
                        <div style={{ display: "inline-block" }}>
                          <span
                            style={{
                              color: "green",
                              fontSize: "x-large",
                              verticalAlign: "middle",
                              paddingRight: "5px",
                            }}>
                            &#8226;
                          </span>
                          <span style={{ verticalAlign: "middle" }}>{t("alert_history:resolved")}</span>
                        </div>
                      ) : (
                        <div style={{ display: "inline-block" }}>
                          <span
                            style={{
                              color: "red",
                              fontSize: "x-large",
                              verticalAlign: "middle",
                              paddingRight: "5px",
                            }}>
                            &#8226;
                          </span>
                          <span style={{ verticalAlign: "middle" }}>{t("alert_history:active")}</span>
                        </div>
                      )}
                    </TableCell>
                    <TableCell>
                      <span>
                        {measurementTransform(notification.value.value, [
                          notification.Sensor?.default_unit,
                          notification.Sensor?.Device?.is_empirical,
                          notification.Sensor?.Sensor_type.type,
                        ])}
                        {notification.Sensor?.Sensor_type.type !== "BOOLEAN" && (
                          <span>
                            {" "}
                            <span className="u-degree-history">
                              {unitsTransform(notification.Sensor?.default_unit || "degF", [
                                notification.Sensor?.default_unit || "degF",
                                notification.Sensor?.Device?.is_empirical,
                                notification.Sensor?.Sensor_type.type || t("common:select_options.range"),
                              ])}
                            </span>{" "}
                          </span>
                        )}
                      </span>
                    </TableCell>
                    <TableCell>
                      {notification.Sensor?.Sensor_type ? t(`sensor_types:${getNameSlug(notification.Sensor?.Sensor_type?.name)}`) : ""}
                    </TableCell>
                    <TableCell align="center">
                      <Button onClick={() => showAlertDetailsModal(notification)} variant="contained" style={{ padding: "1px 10px" }}>
                        <span>{t("alert_history:see_details")}</span>
                      </Button>
                    </TableCell>
                    <TableCell>
                      <img
                        src={trashIcon}
                        alt={t("common:delete")}
                        className="custom-icon"
                        onClick={() => handleConfirmDelete(notification)}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </>
      </Box>
      <Box className="mobile-tablet-segments u-mobile-only">
        {sortedNotifications.map((notification) => (
          <div className="notification-segment" key={notification._id}>
            <div className="notification-content">
              <div className="notification-content-mobile-column">
                <div className="notification-label">{t("alert_history:create_date")}</div>
                <div className="notification-date">{formatDateCustom(notification.createdAt, "M/D/YYYY h:mm A")}</div>
              </div>
              <div className="notification-content-mobile-column">
                <div className="notification-label">{t("alert_history:updated")}</div>
                <div className="notification-date">{formatDateCustom(notification.updatedAt, "M/D/YYYY h:mm A")}</div>
              </div>
              <div className="notification-content-mobile-column">
                <div className="notification-label">{t("alert_history:status")}</div>
                <div className={`notification-status ${notification.is_resolved ? "resolved" : "active"}`}>
                  <span className="status-indicator" style={{ color: notification.is_resolved ? Colors.success : Colors.alert }}>
                    &#8226;
                  </span>
                  <span className="status-text">{notification.is_resolved ? t("alert_history:resolved") : t("alert_history:active")}</span>
                </div>
              </div>
              <div className="notification-content-mobile-column">
                <div className="notification-label">{t("alert_history:type")}</div>
                <div className="notification-type">
                  {notification.Sensor?.Sensor_type.type
                    ? t("alert_history:sensor_type", { context: getNameSlug(notification.Sensor?.Sensor_type?.type) })
                    : ""}
                </div>
              </div>
              <div className="notification-content-mobile-column">
                <div className="notification-label">{t("alert_history:alert_value")}</div>
                <div className="notification-alert-value">
                  {measurementTransform(notification.value.value, [
                    notification.Sensor?.default_unit,
                    notification.Sensor?.Device?.is_empirical,
                    notification.Sensor?.Sensor_type.type,
                  ])}
                  {notification.Sensor?.Sensor_type.type !== "BOOLEAN" && (
                    <span>
                      {" "}
                      <span className="u-degree-history">
                        {unitsTransform(notification.Sensor?.default_unit || "degF", [
                          notification.Sensor?.default_unit || "degF",
                          notification.Sensor?.Device?.is_empirical,
                          notification.Sensor?.Sensor_type.type || t("common:select_options.range"),
                        ])}
                      </span>
                    </span>
                  )}
                </div>
              </div>
              <div className="notification-content-mobile-column">
                <div className="notification-label">{t("alert_history:sensor_type")}</div>
                <div className="notification-sensor-type">
                  {notification.Sensor?.Sensor_type ? t(`sensor_types:${getNameSlug(notification.Sensor?.Sensor_type?.name)}`) : ""}
                </div>
              </div>
              <div className="notification-content-mobile-column empty-column"></div>
              <div className="notification-content-mobile-column" style={{ display: "flex" }}>
                <Button onClick={() => showAlertDetailsModal(notification)} variant="text" className="see-details-button">
                  <span>{t("alert_history:see_details")}</span>
                </Button>
                <img
                  src={trashIcon}
                  alt={t("common:delete")}
                  className="custom-icon"
                  style={{ marginRight: 10 }}
                  onClick={() => handleConfirmDelete(notification)}
                />
              </div>
            </div>
          </div>
        ))}
      </Box>
    </section>
  );
};
