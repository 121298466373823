const minIntegerNumber = -2147476980
const maxIntegerNumber = 2147476980

const invalidNumber = (val: number) => {
  return val <= minIntegerNumber || val >= maxIntegerNumber;
}

export const units = {
  temperature: ['celcius', 'fahrenheit'], //metric unit will always be in the first
  foodtemperature: ['degc', 'degf'],
  // humidity: ['rh'],
  // batteryvoltage: ['volts'],
  // signalstrength: ['decibels'],
  // barometricpressure: ['kilopascal'],
  // lightlevel: ['lux'],
  // analogvolt5v: ['voltage'],
  // analogvolt10v: ['voltage'],
  // current: ['milliamp'],
  // voc: ['parts per million'],
  // co2: ['parts per million'],
  // no2: ['parts per million'],
  // co: ['parts per million'],
  // differentialpressure: ['pascals'],
  // contact: ['on', 'off']
} as any;

export const getUnit = (type: string, index: number): string => {
  if (units[type]) {
    return units[type][index];
  }

  return '';
};

// TODO: This needs review. The old function was buggy, unitialized variable returns, the possibility of returning non-strings...
// But it was last touched in 2017 so whatever behaviors it had, are probably "expected" now
export const getType = (unit: string): string => {
  let type: string = '';

  if (!unit) {
    return type;
  }

  unit = unit.toLowerCase();

  Object.keys(units).some((u) => {
    let index = units[u].indexOf(unit);

    if (index > -1) {
      type = u;
    }

    return index > -1;
  });

  return type;
};

export const isEmpirical = (unit: string): number => {
  let index: number = -1;
  unit = unit.toLowerCase();

  Object.keys(units).some((u) => {
    index = units[u].indexOf(unit);

    return index > -1;
  });

  return index; // possible value: -1, 0, 1
};

export const convertUnitTemperature = (value: number, from: string, to: string): number => {
  if (!from || !to) {
    return Number(value);
  }

  const fromIndex = isEmpirical(from.toLowerCase());
  const toIndex = isEmpirical(to.toLowerCase());

  if (from === to || fromIndex < 0 || toIndex < 0) {
    return value !== undefined ? Number(value) : value;
  }

  if (fromIndex === 0 && toIndex === 1) {
    // from celcius to fahrenheit
    return Number(value) * 1.8;
  } else if (fromIndex === 1 && toIndex === 0) {
    // from fahrenheit to celcius
    return Number(value) / 1.8;
  }

  // TODO: This needs review. The old function was buggy, unitialized variable returns, the possibility of returning non-strings...
  // But it was last touched in 2017 so whatever behaviors it had, are probably "expected" now
  return 0;
};

export const formatTemperature = (value: number) => {
  return Number(value / 100);
}

export const convertEmpiricalTemperature = (value: number, isEmpirical: boolean | null): number => {
  if (isEmpirical == null) {
    return Number(value);
  }

  if (isEmpirical) {
    // from celcius to fahrenheit
    return Number(value) * 1.8 + 32;
  } else {
    // from fahrenheit to celcius
    return (Number(value) - 32) / 1.8;
  }

  // TODO: This needs review.The old function was buggy, unitialized variable returns, the possibility of returning non - strings...
  // But it was last touched in 2017 so whatever behaviors it had, are probably "expected" now
};

export const convertTemperature = (value: number, from: string, to: string): number => {
  if (!from || !to) {
    return Number(value);
  }

  const fromIndex = isEmpirical(from.toLowerCase());
  const toIndex = isEmpirical(to.toLowerCase());

  if (from === to || fromIndex < 0 || toIndex < 0) {
    return value;
  }

  if (fromIndex === 0 && toIndex === 1) {
    // from celcius to fahrenheit
    return Number(value) * 1.8 + 32;
  } else if (fromIndex === 1 && toIndex === 0) {
    // from fahrenheit to celcius
    return (Number(value) - 32) / 1.8;
  }

  // TODO: This needs review.The old function was buggy, unitialized variable returns, the possibility of returning non - strings...
  // But it was last touched in 2017 so whatever behaviors it had, are probably "expected" now
  return 0;
};

export const convertMilliAmpToHumidity = (value: number) => {
  return ((6.25) * value - 25.0);
}

export const convertPpmToHumidity = (value: number) => {
  return (value/10);
}

export const convertMilliAmpToTemperatureDegF = (value: number) => {
  return ((12.5) * value);
}

export const transform = (value: string | number | boolean, args: any[]) => {
  let disableRoundingRules = false;
  if (args && args.length >= 2) {
    const isEmpirical = args[1];
    const sensorType = args[2] ? String(args[2]).toLowerCase() : 'range';
    const fromUnit = String(args[0]).toLowerCase();
    disableRoundingRules = args[3] ?? false;
    const convertToHumidityForWai418 = args[4] ?? false;
    const convertToTemperatureForWai418 = args[5] ?? false;

    if (sensorType === 'boolean') {
      const units = fromUnit.split('/');

      value = value === "true" || value === true || value === 1 ? 1 : 0;

      if (Number.isInteger(value) && units.length === 2) {
        return String(units[Number(value)]).toUpperCase();
      }
    } else if (sensorType === 'range') {
      const type = getType(fromUnit);
      let toUnit = getUnit(type, Number(args[1]));
      if (type && toUnit) {
        if (isEmpirical) { // metric convert to degf
          if (toUnit === "degf" || (type === "temperature" || type === "foodtemperature")) {
            if (invalidNumber(Number(value))) {
              return "Invalid";
            }

            value = formatTemperature(Number(value));
          }
        } else {
          const degfValue = formatTemperature(Number(value));
          value = convertEmpiricalTemperature(degfValue, isEmpirical);
        }
      }

      // If unit is set to kpa and imperial convert to inches of Mercury (Hg)
      if (fromUnit.toLowerCase() === 'kpa') {
        const inHg = Number(value) / 1000;
        if (!isEmpirical) {
          value = inHg * 3.386;
        } else {
          value = inHg
        }
      }

      if (fromUnit.toLowerCase() === 'rh') {
        value = Number(value) / 100
      }

      if (fromUnit.toLowerCase() === 'ppm' && convertToHumidityForWai418) {
        let reading = Number(value) / 100;

        value = convertPpmToHumidity(reading);
      }

      if (fromUnit.toLowerCase() === 'ma' && convertToHumidityForWai418){
        let reading = Number(value) / 100;

        value = convertMilliAmpToHumidity(reading);
      }

      if (fromUnit.toLowerCase() === 'ma' && convertToTemperatureForWai418){
        let reading = Number(value) / 100;
        let degFConvertedReading = convertMilliAmpToTemperatureDegF(reading);

        if(isEmpirical) {
          value = degFConvertedReading;
        } else {
          value = convertEmpiricalTemperature(degFConvertedReading, isEmpirical);
        }
      }
    }
  }

  return disableRoundingRules ? value.toString() : Number(value).toFixed(1);
};

// TODO: Helper to support refactoring how the pipes worked into more traditional helper functions
export const measurementTransform = (value: string | number | boolean, args: any[]) => transform(value, args);
