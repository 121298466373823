import * as Measurements from "./MeasurementService";
import { ISensorDatapoint } from "./DeviceService";
import { ILineChartValue } from "../Components";
import * as Units from "./UnitsService";
import { ISensor } from "./Types";

// Transform a dataset that may be "missing" some data values to be more "chartable". We do the following:
//
//  1. Unit conversion based on device/sensor settings
//
//  2. Simplify the data set to simple x,y values for charting
//
//  3. Sort the set just in case the server doesn't do this for us. It's supposed to, but we would break on the next
//     step if that ever failed so we do it here anyway to be safe.
//
//  4. We're supposed to show "gaps" where there's missing data, instead of connecting lines across missed data. The
//     server doesn't give us nulls for those missed items, which the chart needs. We insert placeholders, BUT...
//
//  5. The server also doesn't tell us a reporting interval for a device. We need to identify and insert nulls for the
//     chart to show gaps but we have to know where. So.. we guess. We find the shortest possible gap between two items
//     and assume that's the reporting interval. We then insert nulls at that interval in between items to create those
//     gaps. We insert as many nulls as we can because the chart is zoomable and it would be weird if you zoomed across
//     a big gap if we didn't.
//
// {_id: '60', SensorId: 2873, value: { value: 99.55 }, createdAt: '2022-03-02T08:05:00.000Z'}
// {_id: '61', SensorId: 2873, value: { value: 99.54 }, createdAt: '2022-03-02T08:15:00.000Z'}
// {_id: '62', SensorId: 2873, value: { value: 99.56 }, createdAt: '2022-03-02T08:20:00.000Z'}
// {_id: '63', SensorId: 2873, value: { value: 99.55 }, createdAt: '2022-03-02T08:25:00.000Z'}
//
// TODO: If we wanted to add a test suite this would be a great first thing to add tests for.
//
// NOTE: There are some more efficient approaches e.g. with array.splice() that could eliminate the second sort, that
// sort of thing. But before you waste tie here... I did a timer pass on a month's worth of data thrown in here and
// this took 8.6ms to process 8124 data points. It takes <1ms to process a more reasonable "month's worth" of data. So...
//
export const prepareChartDataSet = (
  data: ISensorDatapoint[],
  sensor: ISensor,
  isImperial: boolean = true,
  convertToHumidityForWai418: boolean = false,
  convertToTempForWai418: boolean = false,
): ILineChartValue[] => {
  const unit = Units.transform(sensor.default_unit, [
    sensor.default_unit,
    isImperial,
    sensor.Sensor_type.type,
    convertToHumidityForWai418,
    convertToTempForWai418,
  ]);
  // console.log('pcd, unit=', unit);
  const disableRoundingRules = false;
  console.log("convertToHumidityForWai418", convertToHumidityForWai418);
  console.log("convertToTempForWai418", convertToTempForWai418);
  let simplifiedSet: ILineChartValue[] = data.map((entry) => ({
    unit,
    x: new Date(entry.createdAt),
    y: !!entry.value ? Measurements.transform(entry.value.value, [
      sensor.default_unit,
      isImperial,
      sensor.Sensor_type.type,
      disableRoundingRules,
      convertToHumidityForWai418,
      convertToTempForWai418,
    ]) : null,
  }));
  simplifiedSet.sort((a, b) => (a.x as Date).getTime() - (b.x as Date).getTime());
  // console.log('pcd, set=', simplifiedSet);

  // Time to get all old school. Hey, I had a 5-hr timebox for all this plus the charting...
  // let smallestGap = Infinity;
  // for (let i = 0; i < simplifiedSet.length - 1; i++) {
  //   const curr = simplifiedSet[i];
  //   const next = simplifiedSet[i + 1];
  //   const diff = (next.x as Date).getTime() - (curr.x as Date).getTime();
  //   if (diff < smallestGap) {
  //     // console.log('New smallest', curr.x, next.x);
  //     smallestGap = diff;
  //   }
  // }
  // // console.log('pcd, gap=', smallestGap);

  // // Sanity check to prevent us from trying to ever draw 500,000 (it happened) data points on a chart because we had 1-second reporting
  // // intervals. Gap-filling to 1 minute does enough without getting too crazy.
  // if (smallestGap < 60000) {
  //   smallestGap = 60000;
  // }

  // // I could have gone crazy with splice() but it would have been super hard to debug and array iteration and sorting in
  // // JS is actually really fast. Don't forget a simple for() loop with a splice-in-place would have had to modify its
  // // increment to account for the new items, and it gets weird stepping through that...
  // const entriesToAdd: ILineChartValue[] = [];
  // for (let i = 0; i < simplifiedSet.length - 1; i++) {
  //   const curr = simplifiedSet[i];
  //   const next = simplifiedSet[i + 1];
  //   let diff = (next.x as Date).getTime() - (curr.x as Date).getTime();

  //   let offset = smallestGap;
  //   while (diff > smallestGap) {
  //     entriesToAdd.push({ x: new Date((curr.x as Date).getTime() + offset), y: null, unit });
  //     diff -= smallestGap;
  //     offset += smallestGap;
  //   }
  // }

  // One last hurrah...
  // simplifiedSet = [...simplifiedSet, ...entriesToAdd];
  simplifiedSet.sort((a, b) => (a.x as Date).getTime() - (b.x as Date).getTime());

  return simplifiedSet;
};

// The old system had a complex, iterative routine based on a set of user-entered numeric values. See report-modal.component.ts and this:
//     i.e. in the ascending slope, find the first number that is bigger than the target value,
//     while in the desending slope, find the first number that is smaller than the target value
// My interpretation was that we should go through each target and find the first matching chart entry that would "cross" that setpoint:
//   (A -> B is increasing, A is <= setpoint, B > setpoint, or
//   (A -> B is decreasing, A is >= setpoint, B < setpoint
//
// But when I tried to repeat this in the old system to make sure it was working properly I had a lot of issues. The old system rendered
// so slowly that in any large data set like a month of data the UI would hang up and hovering didn't work anymore. Also, things like
// elapsed time was showing me empty (--) or zero (00:00) values for various target values. We might need to re-discuss what's needed here.
export const findChartSetPoints = (targets: number[], chartData: ILineChartValue[]) => {
  const setPoints: ILineChartValue[] = [];

  // We have to work without the null entries we may have previously inserted to cover gaps, or the nulls would throw us off
  const nonNullChartData = [...chartData.filter((entry) => entry.y !== null)];

  // For each target, look for "crossings" in the data of that value, until we run out of values.
  targets.forEach((target) => {
    while (nonNullChartData.length >= 2) {
      const currVal = +(nonNullChartData[0].y || 0);
      const nextVal = +(nonNullChartData[1].y || 0);
      const nextDiff = nextVal - currVal;

      // If we crossed the target, mark it and move forward in the chart dataset
      if ((nextDiff >= 0 && nextVal > target) || (nextDiff < 0 && nextVal < target)) {
        setPoints.push({ ...nonNullChartData[1], triggeredTarget: target });
        nonNullChartData.shift();
        return;
      }

      // Otherwise, we still move forward in the chart dataset
      nonNullChartData.shift();
    }
  });

  return setPoints;
};
