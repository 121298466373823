import {
  Table as MuiTable,
  TableBody as MuiTableBody,
  TableBodyProps,
  TableCell as MuiTableCell,
  TableCellProps,
  TableHead as MuiTableHead,
  TableHeadProps,
  TableProps,
  TableRow as MuiTableRow,
  TableRowProps,
} from "@mui/material";
import React from "react";
import { CSS } from "@dnd-kit/utilities";
import { useSortable } from "@dnd-kit/sortable";

export const Table: React.FC<TableProps> = (props) => {
  return <MuiTable {...props} />;
};

export const TableBody: React.FC<TableBodyProps> = (props) => {
  return <MuiTableBody {...props} />;
};

export const TableCell: React.FC<TableCellProps> = (props) => {
  return <MuiTableCell {...props} />;
};

export const TableHead: React.FC<TableHeadProps> = (props) => {
  return <MuiTableHead {...props} />;
};

export const TableRow: React.FC<TableRowProps> = (props) => {
  return <MuiTableRow {...props} />;
};

export const SortableTableRow: React.FC<TableRowProps & { sortable?: boolean }> = ({ children, id = "", sortable = true }) => {
  const { attributes, listeners, setNodeRef, setActivatorNodeRef, transform, transition } = useSortable({
    id,
  });
  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
    backgroundColor: "inherit",
  };

  return (
    <>
      <tr ref={setNodeRef} {...attributes} style={style}>
        {children}
        {sortable ? (
          <TableCell align="center">
            <i ref={setActivatorNodeRef} style={{ cursor: "move" }} className="fa fa-bars" aria-hidden="true" {...listeners} />
          </TableCell>
        ) : null}
      </tr>
    </>
  );
};
