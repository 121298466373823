import React from "react";
import { observer } from "mobx-react-lite";
import { createBrowserRouter, createRoutesFromElements, Navigate, Route, RouterProvider } from "react-router-dom";
import { Dashboard } from "./Dashboard";
import { AuthView } from "./AuthView";
import * as LoginViews from "./Login";
import { URLS } from "./URLS";
import { Users } from "./Users";
import { Alerts } from "./Alerts";
import { Accounts } from "./Accounts";
import { SubscriptionManagement } from "./SubscriptionManagement";
import { SubscriptionExpired } from "./SubscriptionExpired";
import { AlertsHistory } from "./AlertsHistory";
import { ExportUsers } from "./Users/ExportUsers";
import { PermissionEnum, UserRoles } from "../Enums";
import { LegalManagement } from "./TermsAndConditions/LegalManagement";
import { TermsAndPolicy } from "./TermsAndConditions/TermsAndPolicy";
import { PolicyWindow } from "./TermsAndConditions/PolicyWindow";
import { Translations } from "./Translations/Translations";

export const MainRouter: React.FC = observer(() => {
  return <RouterProvider router={router} />;
});

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      {/* Login */}
      <Route path={URLS.Login} element={<LoginViews.Login />} />
      <Route path={URLS.ForgotPassword} element={<LoginViews.ForgotPassword />} />
      <Route path={URLS.Register} element={<LoginViews.Register />} />
      <Route path={URLS.RegisterConfirm} element={<LoginViews.RegisterCompleted />} />

      {/* Dashboard */}
      <Route path={URLS.Users} element={<AuthView role={UserRoles.ADMIN} element={<Users />} />} />
      <Route path={URLS.ExportUsers} element={<AuthView role={UserRoles.APP_ADMIN} element={<ExportUsers />} />} />
      <Route path={URLS.Alerts} element={<AuthView element={<Alerts />} />} />
      <Route path={URLS.Accounts} element={<AuthView element={<Accounts />} />} />
      <Route path={URLS.Dashboard} element={<AuthView element={<Dashboard />} />} />
      <Route path={URLS.SubscriptionManagement} element={<AuthView element={<SubscriptionManagement />} />} />
      <Route path={URLS.SubscriptionIsExpired} element={<SubscriptionExpired />} />
      <Route path={URLS.AlertsHistory} element={<AuthView element={<AlertsHistory />} />} />
      <Route path={URLS.Translations} element={<AuthView role={UserRoles.APP_ADMIN} element={<Translations />} />} />

      <Route path={URLS.TermsAndPolicy} element={<TermsAndPolicy />} />
      <Route
        path={URLS.LegalManagement}
        element={<AuthView role={UserRoles.APP_ADMIN} element={<LegalManagement type={"terms"} />} />}
      />
      <Route path={URLS.TermsOfService} element={<AuthView element={<PolicyWindow type={"terms"} />} />} />
      <Route path={URLS.PrivacyPolicy} element={<AuthView element={<PolicyWindow type={"privacy"} />} />} />
      <Route path={URLS.SupplementalPrivacyPolicy} element={<AuthView element={<PolicyWindow type={"supplemental_policy"} />} />} />

      <Route path="*" element={<Navigate to="/" />} />
    </>,
  ),
);
