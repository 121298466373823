import * as yup from "yup";
import { Formik } from "formik";
import React, { useState } from "react";
import { CountrySelect, FormFieldSelect, FormFieldText, Row, StyledTooltip, ToggleInput } from "../Components";
import { useCurrencies } from "../Managers/API";
import { addCurrency, getCurrencyIsoCodesForDropdown, ICurrency, updateCurrency } from "../Managers/CurrencyService";
import { showAppModal, showSnackbar } from "../AppState";
import classNames from "classnames";
import { ConfirmModal } from "./ConfirmModal";
import { Modal } from "../Components/Modal";
import { countries } from "../Managers";
import { useTranslation } from "react-i18next";

const REQUIRED_MESSAGE = "Field required";

const validationSchema = yup.object({
  name: yup.string().required(REQUIRED_MESSAGE),
  iso_code: yup.string().required(REQUIRED_MESSAGE),
  sign: yup.string().required(REQUIRED_MESSAGE),
  country: yup.object(),
  active: yup.boolean(),
});

export const CurrenciesModal: React.FC = () => {
  const [isSaving, setIsSaving] = useState(false);
  const [mode, setMode] = useState<"editCurrency" | "createCurrency">("createCurrency");
  const [selectedCurrency, setSelectedCurrency] = useState<ICurrency | null>(null);
  const { t } = useTranslation(["currencies_modal", "common"]);

  const currenciesQuery = useCurrencies();

  const initialValues = {
    _id: selectedCurrency?._id ?? 0,
    name: selectedCurrency?.name ?? "",
    iso_code: selectedCurrency?.iso_code ?? "",
    sign: selectedCurrency?.sign ?? "",
    country: selectedCurrency !== null ? selectedCurrency.country : countries.find((c) => c.code === "US"),
    active: selectedCurrency?.active ?? false,
  };

  const selectCurrency = (currency: ICurrency | null) => {
    if (currency == null) {
      setSelectedCurrency(null);
      return;
    }

    setMode("editCurrency");
    setIsSaving(false);
    setSelectedCurrency(currency);
  };

  const handleAddCurrency = () => {
    if (mode !== "createCurrency") {
      setMode("createCurrency");
    }

    setSelectedCurrency(null);
  };

  const _updateCurrency = (values: ICurrency) => {
    setIsSaving(true);

    return updateCurrency(composeValues(values))
      .then(() => showSnackbar(t("currencies_modal:update_success"), "success"))
      .catch((e) => {
        console.log("Add error", e);
        showSnackbar(t("currencies_modal:update_error"), "error");
      })
      .finally(() => setIsSaving(false));
  };

  const createCurrency = (values: ICurrency) => {
    setIsSaving(true);

    return addCurrency(composeValues(values))
      .then((r) => {
        console.log("Add result", r);
        const newSelectedCurrency = { ...r };
        newSelectedCurrency.country = countries.find((c) => c.code === r.country_code);
        setSelectedCurrency(r);
        showSnackbar(t("currencies_modal:add_success"), "success");
      })
      .catch((e) => {
        console.log("Add error", e);
        showSnackbar(t("currencies_modal:add_error"), "error");
      })
      .finally(() => setIsSaving(false));
  };

  const editCurrency = (values: ICurrency) => {
    setIsSaving(true);
    if (mode === "editCurrency" && selectedCurrency) {
      _updateCurrency(composeValues(values));
    } else if (mode === "createCurrency") {
      createCurrency(composeValues(values));
    }
    showAppModal(null);
  };

  const composeValues = (values: ICurrency) => {
    return {
      ...values,
    } as ICurrency;
  };

  const showDeleteConfirm = () => {
    const currencyToSoftDelete = selectedCurrency;
    showAppModal(
      <ConfirmModal
        header={t("currencies_modal:delete_title")}
        confirmText="Delete Currency"
        onConfirm={() => {
          if (currencyToSoftDelete == null) return;
        }}>
        <p>{t("currencies_modal:delete_confirm")}</p>
        <p>{t("currencies_modal:delete_confirm_info")}</p>
      </ConfirmModal>,
    );
  };

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={editCurrency} enableReinitialize={true}>
      {({ isSubmitting, submitForm, setFieldValue, values }) => {
        return (
          <Modal
            title={t("currencies_modal:title")}
            buttons={
              <>
                <button className="btn btn-info" onClick={() => showAppModal(null)}>
                  {t("common:cancel")}
                </button>
                <button className="btn btn-default" onClick={submitForm} disabled={isSubmitting}>
                  {isSubmitting || isSaving ? <i className="fa fa-spin fa-circle-o-notch" /> : <></>}
                  {mode === "editCurrency" ? t("common:save_changes") : t("currencies_modal:add_currency")}
                </button>
              </>
            }>
            <form>
              <div className="left-rail left-panel">
                <div className="left-rail-body">
                  <nav className="left-rail-nav">
                    <div className="left-rail-nav-header">
                      <h5 className="left-rail-nav-header-title">{t("currencies_modal:add_currency")}</h5>
                      <StyledTooltip title={t("currencies_modal:currencies")} enterDelay={500}>
                        <div className="btn btn-circle btn-primary add-button" onClick={handleAddCurrency}>
                          <i className="fa fa-plus" />
                          <span className="sr-only">{t("currencies_modal:add_currency")}</span>
                        </div>
                      </StyledTooltip>
                    </div>

                    <ul className="left-rail-nav-group">
                      {currenciesQuery.data?.map((currency) => (
                        <li
                          key={currency._id}
                          className={classNames("left-rail-nav-item", {
                            active: selectedCurrency !== null && selectedCurrency?._id === currency._id,
                          })}>
                          <div onClick={() => selectCurrency(currency)} style={{ display: "grid" }}>
                            {currency.name || "Currency " + currency._id}
                            <span className="left-rail-nav-item-sub u-display-block"> {currency.iso_code}</span>
                          </div>
                        </li>
                      ))}

                      {mode === "createCurrency" ? (
                        <li className="left-rail-nav-item active">{t("currencies_modal:new_currency")}</li>
                      ) : null}
                    </ul>
                  </nav>
                </div>
              </div>

              <div className="right-panel main">
                {mode === "editCurrency" && currenciesQuery.data && currenciesQuery.data.length > 1 ? (
                  <StyledTooltip title={t("currencies_modal:delete_currency")} enterDelay={500}>
                    <button className="btn btn-icon delete-button" onClick={showDeleteConfirm}>
                      {!isSubmitting ? <i className="fa fa-trash" /> : <></>}
                      <span className="sr-only">{t("currencies_modal:delete_currency")}</span>
                    </button>
                  </StyledTooltip>
                ) : null}

                <div>
                  <FormFieldText
                    name="name"
                    className="col-sm-12"
                    label={t("currencies_modal:currency_name")}
                    placeholder={t("currencies_modal:currency_name_placeholder")}
                    required={true}
                  />

                  <FormFieldSelect
                    value={values?.iso_code}
                    label={t("currencies_modal:currency_iso")}
                    name="iso_code"
                    className="col-sm-6"
                    required={true}
                    options={getCurrencyIsoCodesForDropdown()}
                  />

                  <Row>
                    <FormFieldText
                      name="sign"
                      className="col-sm-6"
                      label={t("currencies_modal:currency_sign")}
                      placeholder={t("currencies_modal:currency_sign_placeholder")}
                      required={true}
                    />

                    <div className="col-sm-6">
                      <label className="input-label u-display-block">{t("currencies_modal:currency_country")}</label>
                      <CountrySelect name="country" selectedCountryLabel="name" countryList={countries} />
                    </div>
                  </Row>

                  <ToggleInput
                    className="col-sm-6"
                    style={{ marginTop: "10px" }}
                    onChange={(value) => setFieldValue("active", value)}
                    name="active"
                    activeLabel={t("common:active")}
                    inactiveLabel={t("common:inactive")}
                    checked={!!values?.active || false}
                  />
                </div>
              </div>
            </form>
          </Modal>
        );
      }}
    </Formik>
  );
};
